import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const paytmApi = createApi({
  reducerPath: 'paytmApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('code');
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    initiatePaytmPayment: builder.mutation({
      query: (body) => ({
        url: `paytm/initiate-transaction`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useInitiatePaytmPaymentMutation } = paytmApi;
