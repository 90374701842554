import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Select, Spin } from "antd";
import Timeline from "../Timeline";
import {
  useGetAllCompaniesByTagIdQuery,
  useGetSectorSuggestedCompanyQuery,
  useGetTimeSeriesValueMutation,
} from "../../redux/HomeAPI";

import ChartJS from "../../Components/ChartJS/ChartJS";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

const items = [
  {
    label: <span className="charts-label">1D</span>,
    key: "1D",
    val: "1day",
  },
  {
    label: <span className="charts-label">1W</span>,
    key: "1W",
    val: "1week",
  },
  {
    label: <span className="charts-label">1M</span>,
    key: "1M",
    val: "1month",
  },
  {
    label: <span className="charts-label">3M</span>,
    key: "3M",
    val: "3month",
  },
  {
    label: <span className="charts-label">1Y</span>,
    key: "1Y",
    val: "1year",
  },
  {
    label: <span className="charts-label">5Y</span>,
    key: "5Y",
    val: "5year",
  },
  {
    label: <span className="charts-label">Max</span>,
    key: "max",
    val: "20year",
  },
];

const RechartsWrapper = (props) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");

  const selectedSector = useMemo(() => props.sectorId, [props.sectorId]);
  const {
    data: sectorCompanies,
    isSuccess: isSectorCompaniesSuccess,
    isLoading: isSectorCompaniesLoading,
  } = useGetSectorSuggestedCompanyQuery(selectedSector);

  const selectedCompanyIdForViewMore = localStorage.getItem(
    "selectedCompanyIdForViewMore",
  );
  const {
    data: tagCompanies,
    isSuccess: isTagCompaniesSuccess,
    isLoading: isTagCompaniesLoading,
  } = useGetAllCompaniesByTagIdQuery({ id: selectedCompanyIdForViewMore });

  const [
    getCompanyData,
    { data: companyStockData, isLoading: companyStockDataLoading },
  ] = useGetTimeSeriesValueMutation();
  const [getIndexData, { data: indexStockData, isLoading: indexDataLoading }] =
    useGetTimeSeriesValueMutation();

  const [days, setDays] = useState(1);

  const isLoading =
    props.selected === "sector"
      ? isSectorCompaniesLoading
      : isTagCompaniesLoading;
  const isSuccess =
    props.selected === "sector"
      ? isSectorCompaniesSuccess
      : isTagCompaniesSuccess;
  const companies =
    props.selected === "sector"
      ? sectorCompanies?.payload?.companyList
      : tagCompanies;

  const onTimelineChange = (key) => {
    if (selectedCompany === "") {
      return;
    }
    if (selectedIndex === "") {
      return;
    }
    let newDay = 2;
    switch (key) {
      case "1D":
        newDay = 1;
        break;
      case "1W":
        newDay = 7;
        break;
      case "1M":
        newDay = 31;
        break;
      case "3M":
        newDay = 91;
        break;
      case "1Y":
        newDay = 365;
        break;
      case "5Y":
        newDay = 365 * 5;
        break;

      default:
        newDay = 365 * 20;
        break;
    }
    setDays(newDay);
    getCompanyData({
      symbol: selectedCompany,
      interval: newDay > 1 ? "1day" : "1h",
    });
    getIndexData({
      symbol: "NSEI",
      interval: newDay > 1 ? "1day" : "1h",
    });
  };
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);

  useEffect(() => {
    if (!isLoading && isSuccess && companies?.length > 0) {
      const [ind, com] = [companies[0]?.bse, companies[0]?.code];
      getCompanyData({
        symbol: com,
        interval: days > 2 ? "1day" : "1h",
      });
      getIndexData({
        symbol: "NSEI",
        interval: days > 2 ? "1day" : "1h",
      });
      setSelectedCompany(com);
      setSelectedOption(com + "+" + ind);
      const companyOptions = Array.from(
        new Set(
          companies.map((cp) => ({
            value: cp.code + "+" + cp.bse,
            label: cp.name,
          })),
        ),
      );
      setCompanyOptions(companyOptions);
      setSelectedIndex(ind);
    }
  }, [isLoading, isSuccess, companies, getCompanyData, days, getIndexData]);
  return (
    <div className="container">
      <Grid container display="flex" justifyContent="start">
        <Grid item>
          <Row>
            <Col span={8}>
              <Select
                // style={{ width: "300px" }}
                placeholder="Select Company"
                defaultValue={selectedOption}
                onChange={async (val) => {
                  const [com, ind] = val.split("+");
                  await getCompanyData({
                    symbol: com,
                    interval: days > 2 ? "1day" : "1h",
                  });
                  await getIndexData({
                    symbol: "NSEI",
                    interval: days > 2 ? "1day" : "1h",
                  });
                  setSelectedCompany(com);
                  setSelectedIndex(ind);
                }}
                options={companyOptions}
              />
            </Col>
          </Row>
        </Grid>
      </Grid>
      <Grid container display="flex" alignItems="center" justifyContent="start">
        <Grid item>
          {(companyStockDataLoading || indexDataLoading) && <Spin />}
        </Grid>
      </Grid>
      <ChartJS
        sectorId={selectedSector}
        companyData={companyStockData?.payload}
        indexData={indexStockData?.payload}
        onTooltipHover={() => {}}
        company={selectedCompany}
        index={selectedIndex}
        days={days}
      />
      <Grid
        container
        display="flex"
        justifyContent={isSmDown ? "center" : "start"}
      >
        <Grid item width="fit-content">
          <Timeline items={items} onChange={onTimelineChange} />
        </Grid>
      </Grid>
    </div>
  );
};

export default RechartsWrapper;
