import "./App.scss";
import React, { useContext, useEffect, useState } from "react";
import Button from "./Components/Button/Button";

// Importing footer-animation
import FooterAnimation from "./Components/FooterAnimation/FooterAnimation";

// GSAP & Cuberto mousefollower imports
import gsap from "gsap";
import { useDispatch } from "react-redux";
import { setThemeDarkMode } from "./redux/Theme";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AuthWrapper from "./Wrappers/AuthWrapper";
import FooterGroup from "./Components/Footer/FooterGroup";
import AuthContext from "./Screens/Auth/AuthContext";
const REACT_APP_WHATSAPP_LINK = process.env.REACT_APP_WHATSAPP_LINK;

// Registering ScrollTrigger for smooth scrolling
//gsap.registerPlugin(ScrollTrigger);
export function getPrefColorScheme() {
  if (!window.matchMedia) return;

  return window.matchMedia("(prefers-color-scheme: dark)").matches;
}
export function getInitialMode() {
  const isReturningUser = "dark" in localStorage;
  const savedMode = JSON.parse(localStorage.getItem("dark"));
  const userPrefersDark = getPrefColorScheme();
  if (isReturningUser) {
    return savedMode;
  } else if (userPrefersDark) {
    return true;
  } else {
    return false;
  }
}

function App(props) {
  const [darkMode, setDarkMode] = useState(getInitialMode());
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("dark", JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    const txtAnimate1 = document.querySelectorAll(".text-slide-up-animation-1");
    const txtAnimate2 = document.querySelectorAll(".text-slide-up-animation-2");
    const txtAnimate3 = document.querySelectorAll(".text-slide-up-animation-3");
    txtAnimate1?.forEach((txt) => {
      const splitWordsArr = txt.innerText.match(/\S+/g);
      txt.innerHTML = "";
      splitWordsArr.forEach((word) => {
        const txtAnimateTemplate1 = `
        <div class="text-slide-up-animation-wrapper-1">
          <div class="text-slide-up-animation-content-1">
            <span class="text-slide-up-animation-text-1">${word}</span>
          </div>
        </div>
        `;

        txt.innerHTML += txtAnimateTemplate1;
      });
    });
    txtAnimate2?.forEach((txt) => {
      const splitWordsArr = txt.innerText.match(/\S+/g);
      txt.innerHTML = "";
      splitWordsArr.forEach((word) => {
        const txtAnimateTemplate2 = `
        <div class="text-slide-up-animation-wrapper-2">
          <div class="text-slide-up-animation-content-2">
            <span class="text-slide-up-animation-text-2">${word}</span>
          </div>
        </div>
        `;

        txt.innerHTML += txtAnimateTemplate2;
      });
    });
    txtAnimate3?.forEach((txt) => {
      const splitWordsArr = txt.innerText.match(/\S+/g);
      txt.innerHTML = "";
      splitWordsArr?.forEach((word) => {
        const txtAnimateTemplate3 = `
        <span class="text-slide-up-animation-wrapper-3">
          <span class="text-slide-up-animation-content-3">
            <span class="text-slide-up-animation-text-3">${word}</span>
          </span>
        </span>
        `;

        txt.innerHTML += txtAnimateTemplate3;
      });
    });

    window.addEventListener("load", () => {
      // Text animation 1
      if (txtAnimate1.length) {
        gsap.fromTo(
          ".text-slide-up-animation-text-1",
          { skewY: "20deg" },
          {
            translateY: "-200%",
            skewY: "0deg",
            duration: 1,
            ease: "power4.out",
            stagger: 0.2,
          },
        );
      }

      // Text animation 2
      if (txtAnimate2.length) {
        gsap.fromTo(
          ".text-slide-up-animation-text-2",
          { rotateX: "-40deg", opacity: 0 },
          {
            translateY: "-130%",
            rotateX: "0deg",
            opacity: 1,
            duration: 1.5,
            ease: "power4.out",
            stagger: 0.1,
          },
        );
      }

      // Text animation 3
      if (txtAnimate3.length > 0) {
        gsap.utils.toArray(".text-slide-up-animation-3").forEach((elem) => {
          const txtAnimate3Wrappers = elem.querySelectorAll(
            ".text-slide-up-animation-wrapper-3",
          );
          const txtAnimate3Text = elem.querySelectorAll(
            ".text-slide-up-animation-text-3",
          );
          gsap
            .timeline({
              scrollTrigger: {
                trigger: txtAnimate3Wrappers,
                start: "top 80%",
              },
            })
            .fromTo(
              txtAnimate3Text,
              { skewY: "2deg" },
              {
                translateY: "-200%",
                skewY: "0deg",
                duration: 1.5,
                ease: "power4.out",
              },
            );
        });
      }
      // Footer slide up animation
      gsap.fromTo(
        ".footer-animation-slide-up",
        { translateY: "100%", opacity: 0 },
        {
          translateY: "0%",
          opacity: 1,
          duration: 2,
          ease: "power4.out",
          scrollTrigger: {
            trigger: ".footer",
            start: "top 80%",
          },
        },
      );

      // Footer mountains slide up animation
      gsap.to(".slideUp", {
        translateY: "50px",
        duration: 3,
        ease: "power4.easeOut",
        scrollTrigger: {
          trigger: ".mountains",
          start: "top 80%",
          scrub: 1,
        },
        stagger: 0.05,
      });

      // Footer sun & moon slide up animation
      if (darkMode) {
        gsap.to(".moon", {
          rotate: "0deg",
          duration: 4,
          ease: "power4.easeOut",
          scrollTrigger: {
            trigger: ".footer",
            start: "top 80%",
          },
        });
      } else {
        gsap.to(".sun", {
          rotate: "0deg",
          duration: 4,
          ease: "power4.easeOut",
          scrollTrigger: {
            trigger: ".footer",
            start: "top 80%",
          },
        });
      }
      const toggleThemeBtn = document.querySelector("#checkbox");
      toggleThemeBtn?.addEventListener("change", () => {
        if (document.body.classList?.contains("dark-mode")) {
          gsap.to(".moon", {
            rotate: "0deg",
            duration: 4,
            ease: "power4.easeOut",
          });
          gsap.to(".sun", {
            rotate: "90deg",
            duration: 4,
            ease: "power4.easeOut",
          });
        } else if (document.body.classList?.contains("light-mode")) {
          gsap.to(".sun", {
            rotate: "0deg",
            duration: 4,
            ease: "power4.easeOut",
          });
          gsap.to(".moon", {
            rotate: "90deg",
            duration: 4,
            ease: "power4.easeOut",
          });
        }
      });
    });
  }, [darkMode]);

  // Adding class on body element according to the value of darkMode variable
  // to be able to perform specific animations according to the mode
  if (darkMode) {
    document.body.classList?.add("dark-mode");
    document.body.classList?.remove("light-mode");
  } else {
    document.body.classList?.add("light-mode");
    document.body.classList?.remove("dark-mode");
  }

  useEffect(() => {
    const containerWrapper = document.querySelector("#nightModeContainer");
    if (!containerWrapper) return;

    const toggleContainer = document.createElement("div");
    toggleContainer.className =
      "toggle-container mt-section align-items-center";
    toggleContainer.style.zIndex = 1;
    toggleContainer.innerHTML = `
    <span>Night Mode</span>
    <span class="toggle">
      <input
        id="checkbox"
        class="checkbox"
        type="checkbox"
      />
      <label for="checkbox"></label>
    </span>
  `;

    const checkbox = toggleContainer.querySelector(".checkbox");
    checkbox.checked = darkMode;
    checkbox.addEventListener("change", () => {
      const isChecked = checkbox.checked;
      setDarkMode(isChecked);
      dispatch(setThemeDarkMode(!isChecked));
      window.localStorage.setItem("dark", isChecked);
      window.dispatchEvent(new Event("storage"));
    });

    containerWrapper.appendChild(toggleContainer);
    // containerWrapper.parentElement.appendChild(toggleContainer);
    return () => {
      containerWrapper.removeChild(toggleContainer);
    };
  }, [darkMode, dispatch]);
  const { renderApp } = useContext(AuthContext);
  return (
    <HelmetProvider>
      <div id="app" className={darkMode ? "dark-mode" : "light-mode"}>
        <div id="scroll-container">
          <div id="container-wrapper">
            {renderApp && <AuthWrapper />}

            <div className="dummy"></div>
            <div className="footer mt-section">
              <div className="footer-animation-slide-up-wrapper">
                <div className="footer-animation-slide-up">
                  <div className="d-flex flex-column-tablet justify-content-between">
                    <div className="d-flex flex-col">
                      <h2 className="fs-95 fw-bold footer-text">
                        Suggesting only
                      </h2>
                      <h2 className="fs-95 fw-bold footer-text">
                        fundamentally
                      </h2>
                      <h2 className="fs-95 fw-bold footer-text">
                        strong company
                      </h2>
                      <h2 className="fs-95 fw-bold footer-text">every week</h2>
                    </div>
                    <div>
                      <h3 className="fs-30">
                        Join us for weekly <br />
                        updates
                      </h3>
                      <div className="mt-1">
                        <a href={REACT_APP_WHATSAPP_LINK}>
                          <Button text="Whatsapp" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <FooterAnimation />
              <FooterGroup
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                dispatch={dispatch}
              />
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title>DARKHORSESTOCKS</title>
        <meta
          name="description"
          content="Best choice to know your investments"
        />
        <meta property="og:title" content="DARKHORSESTOCKS" />
        <meta
          property="og:description"
          content="Best choice to know your investments"
        />
        <meta property="og:image" content="https://i.imgur.com/QtoAuaM.jpg" />
        <meta property="og:url" content="https://www.darkhorsestocks.in/" />
      </Helmet>
    </HelmetProvider>
  );
}

export default App;
