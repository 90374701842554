import { Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import HeroImg from "../../Assets/hereo.png";
import {
  useGetCompaniesByTypeQuery,
  useGetTagDetailsQuery,
} from "../../redux/HomeAPI";
import "./style.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardList from "../../Components/CardList";

export default function Tag() {
  const { tagId } = useParams();
  const {
    data: tagData,
    isSuccess: isSuccessTagData,
    isLoading: isLoadingTagData,
  } = useGetTagDetailsQuery(tagId);
  const {
    data: tagCompanies,
    isSuccess,
    isLoading,
  } = useGetCompaniesByTypeQuery({
    type: "tag",
    typeId: tagId,
  });
  const [companies, setCompanies] = useState([]);
  useEffect(
    () => tagCompanies && setCompanies(tagCompanies.companyList),
    [tagCompanies],
  );

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );

  return (
    <div>
      {isSuccessTagData && !isLoadingTagData ? (
        <>
          <Box
            height="95vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
              <Grid container spacing={2} display="flex">
                <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
                  <Typography
                    fontWeight={600}
                    lineHeight={1.5}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                  >
                    {tagData && tagData.payload ? tagData.payload.name : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <img src={HeroImg} alt="Hero" className="hero-img" />
          </Box>

          <Box
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
              <Grid container spacing={2} display="flex">
                <Grid item lg={2} md={2} sm={3}>
                  <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                    Overview
                  </Typography>
                </Grid>
                <Grid item lg={8} md={10} sm={9}>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    {tagData && tagData.payload
                      ? tagData.payload.description
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box mb="20vh" display="flex" justifyContent="center">
            <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
              <Grid container spacing={2} display="flex">
                <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
                  <Typography
                    fontWeight={600}
                    lineHeight={1.5}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                  >
                    Suggested Companies
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <CardList
            contentList={companies}
            cursorData="Read report"
            isCompany={true}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
}
