import React from "react";
import Button from "../../Components/Button/Button";
import "./style.scss";
import Smile from "../../Assets/1.png";
import { useHistory } from "react-router-dom";
const SorryFreeCompanies = () => {
  const history = useHistory();
  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center justify-content-center h-100 text-center">
        <div className="d-flex align-items-center">
          <h2 className=" fs-95 ">Sorry!</h2>
          <div className="d-flex justify-content-center smile-icon">
            <img src={Smile} alt="icon" />
          </div>
        </div>
        <h6 className=" fs-30 mt-1 semi-font">
          You're not subscribed!
          <br />
          Browse our free companies.
        </h6>

        <div className="mt-6">
          <Button
            text="Free Companies"
            onClick={() => history.push("/special-list/isLatestCompanies")}
          />
        </div>
      </div>
    </div>
  );
};

export default SorryFreeCompanies;
