import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../Components/Button/Button";
import axios from "axios";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const BASE_URL = process.env.REACT_APP_WS_URL;

export default function RequestForgotPassword() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const darkMode = localStorage.getItem("dark");
  const [color, setColor] = useState(darkMode === "true" ? "white" : "black");

  useEffect(() => {
    if (darkMode === "true") {
      setColor("white");
    } else {
      setColor("black");
    }
  }, [darkMode]);

  const onSubmit = (data) => {
    // Handle form submission here, e.g., send the reset password request
    axios
      .post(`${BASE_URL}auth/request-reset-password`, {
        email: data.email,
      })
      .then((res) => {
        if (res.data.error) alert(res.data.error);
        else history.push("/");
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  return (
    <div className="container auth-container">
      <div className="d-flex fs-95 auth-wrapper">
        <div style={{ color: color }} className="border-bottom">
          Request Password Reset
        </div>
      </div>
      <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="text"
                    placeholder="Your email"
                    {...field}
                  />
                  <Box className="input-line-animation__line"></Box>
                </>
              )}
            />
          </Box>
          {errors.email && <span>{errors.email.message}</span>}
        </Box>
        <Button onClick={handleSubmit(onSubmit)} text="Send" />
      </form>
    </div>
  );
}
