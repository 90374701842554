import React from "react";
import Mark from "mark.js";
import { useGetSectorDetailsQuery } from "../../redux/HomeAPI";
import "./style.scss";
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const HorizontalCard = ({
  imageUrl,
  heading,
  subHeading,
  sectors,
  tags,
  onClick,
  search,
  description,
}) => {
  const markRef = React.useRef(null);
  const markRefMob = React.useRef(null);
  React.useEffect(() => {
    const markInstance = new Mark(markRef.current);
    const markInstanceMob = new Mark(markRefMob.current);

    markInstance.mark(search, {
      className: "highlight",
      acrossElements: true,
      separateWordSearch: false,
      exclude: [".unsearchable"],
    });
    markInstanceMob.mark(search, {
      className: "highlight",
      acrossElements: true,
      separateWordSearch: false,
    });
  }, [search]);

  const { data: sector } = useGetSectorDetailsQuery(sectors);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMdDown ? (
        <Box onClick={onClick}>
          <Grid
            container
            ref={markRefMob}
            display="flex"
            justifyContent="center"
          >
            <Grid item xs={12} display="flex" justifyContent="start">
              <Typography fontSize={32} fontWeight={800}>
                {heading}
              </Typography>
            </Grid>
            {subHeading && (
              <Grid
                item
                className="unsearchable"
                xs={12}
                display="flex"
                justifyContent="start"
              >
                <Typography fontSize={16} fontWeight={800}>
                  Heading:{" "}
                </Typography>
                <Typography pl={1} fontSize={16} fontWeight={500}>
                  {subHeading}
                </Typography>
              </Grid>
            )}
            {description && (
              <Grid
                item
                className="unsearchable"
                xs={12}
                display="flex"
                justifyContent="start"
              >
                <Typography fontSize={16} fontWeight={800}>
                  Description:{" "}
                </Typography>
                <Typography pl={1} fontSize={16} fontWeight={500}>
                  {description.length > 50
                    ? `${description.slice(0, 50)}...`
                    : description}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Divider sx={{ marginY: 4, width: "100%" }} />
        </Box>
      ) : (
        <Box onClick={onClick}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={2} display="flex" justifyContent="start">
                <Grid item xs={3}>
                  <img
                    height="100%"
                    style={{ display: "block" }}
                    width="100%"
                    src={imageUrl}
                    alt="Small_Image"
                  />
                </Grid>
                <Grid item xs={9} ref={markRef}>
                  <Grid
                    container
                    display="flex"
                    justifyContent="start"
                    rowSpacing={4}
                  >
                    <Grid item xs={12}>
                      <Typography fontSize={32} fontWeight={800}>
                        {heading}
                      </Typography>
                    </Grid>
                    {subHeading && (
                      <Grid
                        item
                        className="unsearchable"
                        xs={12}
                        display="flex"
                      >
                        <Typography fontSize={16} fontWeight={800}>
                          Heading:{" "}
                        </Typography>
                        <Typography pl={1} fontSize={16} fontWeight={500}>
                          {subHeading}
                        </Typography>
                      </Grid>
                    )}
                    {sectors && (
                      <Grid
                        item
                        className="unsearchable"
                        xs={12}
                        display="flex"
                      >
                        <Typography fontSize={16} fontWeight={800}>
                          Sectors:{" "}
                        </Typography>
                        <Typography pl={1} fontSize={16} fontWeight={500}>
                          {sector?.payload?.name}
                        </Typography>
                      </Grid>
                    )}
                    {tags && (
                      <Grid
                        item
                        className="unsearchable"
                        xs={12}
                        display="flex"
                      >
                        <Typography fontSize={16} fontWeight={800}>
                          Tags:{" "}
                        </Typography>
                        <Typography pl={1} fontSize={16} fontWeight={500}>
                          {tags}
                        </Typography>
                      </Grid>
                    )}
                    {description && (
                      <Grid
                        item
                        className="unsearchable"
                        xs={12}
                        display="flex"
                        justifyContent="start"
                      >
                        <Typography fontSize={16} fontWeight={800}>
                          Description:{" "}
                        </Typography>
                        <Typography pl={1} fontSize={16} fontWeight={500}>
                          {description.length > 158
                            ? `${description.slice(0, 147)}...`
                            : description}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 4, width: "100%" }} />
        </Box>
      )}
    </>
  );
};

export default HorizontalCard;
