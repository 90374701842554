import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SecImage from "../../Assets/image section.png";
import Button from "../Button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Card from "../Card/Card";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import LinearProgressBar from "../LinearProgressBar";
import SkeletonSlider from "../Skeleton card/SkeletonSlider";
import HorizontalTranslate from "../HorizontalTranslate";

const CardList = ({
  contentList,
  isCompany,
  onClick,
  cursorData,
  isLoading,
  isSuccess,
}) => {
  const unsortedList = contentList.slice();
  const [expanded, setExpanded] = useState(false);
  const [expansion, setExpansion] = useState(6);
  const [sortTag, setSortTag] = useState("date");
  const [sortDesc, setSortDesc] = useState(true);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? "20px" : "25px"),
    [isMdDown],
  );
  const selectSortTag = (sortTagSelected) => {
    if (sortTagSelected === sortTag) setSortDesc(!sortDesc);
    else {
      setSortTag(sortTagSelected);
      setSortDesc(true);
    }
  };
  const sortList = useCallback(() => {
    if (unsortedList) {
      if (sortTag === "date") {
        return unsortedList?.sort((a, b) =>
          sortDesc
            ? new Date(b?.date) - new Date(a?.date)
            : new Date(a?.date) - new Date(b?.date),
        );
      } else {
        return unsortedList?.sort((a, b) =>
          sortDesc ? b[sortTag] - a[sortTag] : a[sortTag] - b[sortTag],
        );
      }
    }
  }, [sortTag, sortDesc, unsortedList]);
  const handleMore = () => {
    setExpansion((prev) => prev + 6);
  };
  const history = useHistory();
  const handleLess = () => {
    setExpansion((prev) => (prev >= 12 ? prev - 6 : prev - (prev % 6)));
  };
  useEffect(() => {
    if (expansion <= 6) setExpanded(false);
    else setExpanded(true);
  }, [expansion]);
  const sortedList = useMemo(() => sortList(sortTag), [sortList, sortTag]);
  const contentListToShow = useMemo(
    () => sortedList?.slice(0, expansion),
    [sortedList, expansion],
  );

  const onCompanySelect = (id) => {
    history.push(`/blog/${id}`);
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box width="90vw">
        {isLoading && !isSuccess ? (
          <>
            <LinearProgressBar />
            <SkeletonSlider />
          </>
        ) : contentListToShow?.length !== 0 ? (
          <Grid container display="flex" flexDirection="column" rowSpacing={8}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                fontSize={mediumHeaderFontSize}
                fontWeight={700}
                display="flex"
              >
                <Grid
                  item
                  onClick={() => selectSortTag("date")}
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  alignItems="center"
                >
                  <HorizontalTranslate text="Date" />
                  {sortTag === "date" &&
                    (sortDesc ? (
                      <AiOutlineArrowDown
                        className="icon-mobile"
                        fontSize="inherit"
                      />
                    ) : (
                      <AiOutlineArrowUp
                        className="icon-mobile"
                        fontSize="inherit"
                      />
                    ))}
                </Grid>
                <Grid
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  alignItems="center"
                  item
                  onClick={() => selectSortTag("price")}
                >
                  <HorizontalTranslate text="Price" />
                  {sortTag === "price" &&
                    (sortDesc ? (
                      <AiOutlineArrowDown
                        className="icon-mobile"
                        fontSize="inherit"
                      />
                    ) : (
                      <AiOutlineArrowUp
                        className="icon-mobile"
                        fontSize="inherit"
                      />
                    ))}
                </Grid>
                <Grid
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  alignItems="center"
                  item
                  onClick={() => selectSortTag("change")}
                >
                  <HorizontalTranslate text="Change" />
                  {sortTag === "change" &&
                    (sortDesc ? (
                      <AiOutlineArrowDown
                        className="icon-mobile"
                        fontSize="inherit"
                      />
                    ) : (
                      <AiOutlineArrowUp
                        className="icon-mobile"
                        fontSize="inherit"
                      />
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2} rowSpacing={8} display="flex">
                {contentListToShow.map((item) => (
                  <Grid item lg={4} md={6} sm={6} xs={12} key={item.Id}>
                    <Card
                      {...item}
                      cursorData={cursorData}
                      img={item.image || SecImage}
                      title={item.name}
                      price={item.price}
                      isCompany={isCompany}
                      onClick={() =>
                        onClick
                          ? onClick(item.Id)
                          : onCompanySelect(item.blogId)
                      }
                      rate={item.rate}
                      content={item.description || ""}
                      date={item.date}
                      valueDate={item.valueDate}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                rowSpacing={8}
                display="flex"
                justifyContent="center"
              >
                {unsortedList?.length > expansion && (
                  <Grid item>
                    <Button onClick={handleMore} text="Show More" />
                  </Grid>
                )}
                {expanded && (
                  <Grid item>
                    <Button onClick={handleLess} text="Show Less" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            display="flex"
            alignItems="center"
            height="50vh"
            fontSize={mediumHeaderFontSize}
            flexDirection="column"
          >
            <Grid item textAlign="center" lg={4}>
              Unfortunatly!
            </Grid>
            <Grid item textAlign="center" lg={6}>
              These are no suggested companies here.
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CardList;
