import {
  Box,
  Card,
  CardActions,
  CardContent,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import useAdminUsers from "./useAdminUsers";
import DHSwitch from "../../../Components/DHSwitch";
import { StyledTableContainer } from "../HomePageList";
import Button from "../../../Components/Button/Button";
import ManualSubscriptionModal from "./ManualSubscriptionModal";
import CsvFormModal from "./CsvFormModal";

const AdminUsers = () => {
  const {
    isMdDown,
    paginatedUsers,
    updateUser,
    lockBackgroundColor,
    theme,
    searchKeyword,
    currentPage,
    totalPages,
    handleSearchSubmit,
    handleSearchChange,
    handlePreviousPage,
    handleNextPage,
    fetchUsers,
    userId,
    open,
    handleModalOpen,
    handleModalClose,
    checkUserSubscription,
    history,
    openCsvModal,
    handleCsvModalOpen,
    handleCsvModalClose,
  } = useAdminUsers();
  return (
    <>
      <ManualSubscriptionModal
        fetchUsers={fetchUsers}
        onClose={handleModalClose}
        open={open}
        userId={userId}
      />
      <CsvFormModal onClose={handleCsvModalClose} open={openCsvModal} />

      <Box
        marginY="20vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isMdDown ? "90vw" : "70vw"}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent={`${isMdDown ? "start" : "end"}`}
            mb={2}
          >
            <Grid item>
              <Button onClick={handleCsvModalOpen} text="Export CSV" />
            </Grid>
          </Grid>
          <form onSubmit={handleSearchSubmit}>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              mb={8}
            >
              <Grid item>
                <Box className="d-flex input-wrapper" sx={{ marginBottom: 0 }}>
                  <Box className="input-line-animation__wrapper">
                    <input
                      className="input-form input-line-animation__ip"
                      type="text"
                      value={searchKeyword}
                      onChange={handleSearchChange}
                      placeholder="Search..."
                    />
                    <div className="input-line-animation__line"></div>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Button onClick={handleSearchSubmit} text="Search" />
              </Grid>
            </Grid>
          </form>
          {isMdDown ? (
            <Grid container spacing={2}>
              {paginatedUsers &&
                paginatedUsers.length !== 0 &&
                paginatedUsers.users.map((user) => (
                  <Grid
                    onClick={() => history.push(`/admin-edit-user/${user.id}`)}
                    key={user.id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  ID: {user.id}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>
                                  Is Admin:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <DHSwitch
                                  defaultChecked={user.isAdmin}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    updateUser(user.id);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>Email:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{user.email ?? "N/A"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>Phone:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{user.phone ?? "N/A"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>Name:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{user.name ?? "N/A"}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Fab
                            onClick={(e) => {
                              e.stopPropagation();
                              !checkUserSubscription(user) &&
                                handleModalOpen(user.id);
                            }}
                            variant="extended"
                            sx={{
                              cursor: checkUserSubscription(user)
                                ? "default"
                                : "pointer",
                              color: checkUserSubscription(user)
                                ? "#000"
                                : lockBackgroundColor,
                              backgroundColor: checkUserSubscription(user)
                                ? "#b5b5b5"
                                : theme.palette.getContrastText(
                                    lockBackgroundColor,
                                  ),
                              textTransform: "capitalize",
                              fontWeight: 600,
                              "&:hover": {
                                color: checkUserSubscription(user)
                                  ? "#000"
                                  : theme.palette.getContrastText(
                                      lockBackgroundColor,
                                    ),
                                backgroundColor: checkUserSubscription(user)
                                  ? "#b5b5b5"
                                  : lockBackgroundColor,
                              },
                              border: "1px solid",
                              borderColor: checkUserSubscription(user)
                                ? "#b5b5b5"
                                : lockBackgroundColor,
                              boxShadow: "none",
                            }}
                          >
                            Activate
                          </Fab>
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <StyledTableContainer component={Paper}>
              <Table
                sx={{
                  border: "none",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        borderBottom: 1,
                      },
                    }}
                  >
                    <TableCell
                      sx={{ fontWeight: 600, textTransform: "capitalize" }}
                    >
                      isAdmin
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, textTransform: "capitalize" }}
                    >
                      email
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, textTransform: "capitalize" }}
                    >
                      phone
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, textTransform: "capitalize" }}
                    >
                      name
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 600, textTransform: "capitalize" }}
                    >
                      Activate Subscription
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedUsers &&
                    paginatedUsers.length !== 0 &&
                    paginatedUsers.users.map((user, index) => (
                      <TableRow
                        key={user.id}
                        sx={{
                          "& td, & th": {
                            border: 0, // Set your desired border styling here
                          },
                        }}
                        onClick={() =>
                          history.push(`/admin-edit-user/${user.id}`)
                        }
                      >
                        <TableCell>
                          <DHSwitch
                            defaultChecked={user.isAdmin}
                            onChange={() => {
                              updateUser(user.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>{user.email ?? "N/A"}</TableCell>
                        <TableCell>{user.phone ?? "N/A"}</TableCell>
                        <TableCell>{user.name ?? "N/A"}</TableCell>
                        <TableCell>
                          <Fab
                            onClick={(e) => {
                              e.stopPropagation();
                              !checkUserSubscription(user) &&
                                handleModalOpen(user.id);
                            }}
                            variant="extended"
                            sx={{
                              height: "60px",
                              width: "180px",
                              fontSize: "20px",
                              borderRadius: "100px",
                              cursor: checkUserSubscription(user)
                                ? "default"
                                : "pointer",
                              color: checkUserSubscription(user)
                                ? "#000"
                                : lockBackgroundColor,
                              backgroundColor: checkUserSubscription(user)
                                ? "#b5b5b5"
                                : theme.palette.getContrastText(
                                    lockBackgroundColor,
                                  ),
                              textTransform: "capitalize",
                              fontWeight: 600,
                              "&:hover": {
                                color: checkUserSubscription(user)
                                  ? "#000"
                                  : theme.palette.getContrastText(
                                      lockBackgroundColor,
                                    ),
                                backgroundColor: checkUserSubscription(user)
                                  ? "#b5b5b5"
                                  : lockBackgroundColor,
                              },
                              border: "1px solid",
                              borderColor: checkUserSubscription(user)
                                ? "#b5b5b5"
                                : lockBackgroundColor,
                              boxShadow: "none",
                            }}
                          >
                            Activate
                          </Fab>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            mt={2}
          >
            {/* currentPage !== 1 */}
            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Fab
                onClick={handlePreviousPage}
                variant="extended"
                sx={{
                  height: "60px",
                  width: "180px",
                  fontSize: "20px",
                  borderRadius: "100px",
                  cursor: currentPage === 1 ? "default" : "pointer",
                  color: currentPage === 1 ? "#000" : lockBackgroundColor,
                  backgroundColor:
                    currentPage === 1
                      ? "#b5b5b5"
                      : theme.palette.getContrastText(lockBackgroundColor),
                  textTransform: "capitalize",
                  fontWeight: 600,
                  "&:hover": {
                    color:
                      currentPage === 1
                        ? "#000"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    backgroundColor:
                      currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                  },
                  border: "1px solid",
                  borderColor:
                    currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                  boxShadow: "none",
                }}
              >
                Previous
              </Fab>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  color: lockBackgroundColor,
                }}
              >
                {currentPage} / {totalPages}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Fab
                onClick={handleNextPage}
                variant="extended"
                sx={{
                  height: "60px",
                  width: "180px",
                  fontSize: "20px",
                  borderRadius: "100px",
                  cursor: currentPage === totalPages ? "default" : "pointer",
                  color:
                    currentPage === totalPages ? "#000" : lockBackgroundColor,
                  backgroundColor:
                    currentPage === totalPages
                      ? "#b5b5b5"
                      : theme.palette.getContrastText(lockBackgroundColor),
                  textTransform: "capitalize",
                  fontWeight: 600,
                  "&:hover": {
                    color:
                      currentPage === totalPages
                        ? "#000"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    backgroundColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : lockBackgroundColor,
                  },
                  border: "1px solid",
                  borderColor:
                    currentPage === totalPages
                      ? "#b5b5b5"
                      : lockBackgroundColor,
                  boxShadow: "none",
                }}
              >
                Next
              </Fab>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AdminUsers;
