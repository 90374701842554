import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
// import './index.css';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import Loading from "./Components/Loading/Loading"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "antd/dist/antd.min.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MouseFollower from "mouse-follower";
import { Provider } from "react-redux";
import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
import { SubscriptionScheduleProvider } from "./Contexts/SubscriptionSchedule";
import { AuthProvider } from "./Screens/Auth/AuthContext";
import store from "./store/index";

gsap.registerPlugin(ScrollTrigger);

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = "modal";

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin /* OverscrollPlugin */);

const root = ReactDOM.createRoot(document.getElementById("root"));

MouseFollower.registerGSAP(gsap);
window.addEventListener("load", () => {
  const scrollbar = Scrollbar.init(document.querySelector(".dummy"), {
    damping: 0.25,
    delegateTo: document,
  });
  scrollbar?.addListener(ScrollTrigger.update);

  const fixedElem1 = document.querySelector("#navSticky");
  const fixedElem2 = document.querySelector(".mf-cursor");
  const fixedElem3 = document.querySelector(".menuLabelWrapper");
  const fixedElem4 = document.querySelector("#navBackdropSticky");

  scrollbar?.addListener(function (status) {
    const offset = status.offset;

    fixedElem1.style.top = offset.y + "px";
    fixedElem2.style.top = offset.y + "px";
    fixedElem2.style.left = offset.x + "px";
    fixedElem3.style.top = `${offset.y + 60}px`;
    fixedElem4.style.top = offset.y + "px";
  });
  const menuLabelWrapper = document.querySelector("#menuLabelWrapper");
  const hamburgerMenuLabel = document.querySelector("#hamburgerMenuLabel");
  const navBackdropSticky = document.querySelector("#navBackdropSticky");

  hamburgerMenuLabel?.addEventListener("click", () => {
    menuLabelWrapper.click();
  });

  menuLabelWrapper?.addEventListener("click", (e) => {
    if (menuLabelWrapper?.classList.contains("menuOpen")) {
      scrollbar.updatePluginOptions("modal", { open: false });
    } else {
      scrollbar.updatePluginOptions("modal", { open: true });
    }
  });
  navBackdropSticky?.addEventListener("click", () => {
    if (menuLabelWrapper?.classList.contains("menuOpen")) {
      scrollbar.updatePluginOptions("modal", { open: false });
    } else {
      scrollbar.updatePluginOptions("modal", { open: true });
    }
  });
});
const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

root.render(
  <Suspense>
    <React.StrictMode>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <SubscriptionScheduleProvider>
            <BrowserRouter basename="/">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Provider store={store}>
                  <App />
                </Provider>
              </LocalizationProvider>
            </BrowserRouter>
          </SubscriptionScheduleProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Suspense>,
);
reportWebVitals();
