import React, { useContext, useMemo } from "react";
import {
  Redirect,
  Route,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../Screens/Auth/AuthContext";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const AdminRoute = ({ path, component }) => {
  const { isAdmin } = useContext(AuthContext);
  const theme = useTheme();
  const history = useHistory();

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "30px") : "40px"),
    [isMdDown, isSmDown],
  );

  return (
    <>
      <Box display="flex" justifyContent="center" mt={2}>
        <Box width="90vw">
          <Box width="fit-content">
            <Typography
              sx={{ cursor: "pointer" }}
              data-cursor-text="Admin"
              onClick={() => history.push("/admin-panel")}
              fontSize={mediumHeaderFontSize}
              fontWeight={600}
            >
              Admin Panel
            </Typography>
          </Box>
        </Box>
      </Box>
      <Route
        exact
        path={path}
        render={() => (isAdmin ? component : <Redirect to="/" />)}
      />
    </>
  );
};

export default AdminRoute;
