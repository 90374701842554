import { Box, Grid, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import CustomAccordion from "../../Components/Accordion/CustomAccordion";
import Button from "../../Components/Button/Button";
import EditUserDetailsModal from "./EditUserDetails";
import GstDetails from "./GstDetails";
import GstModal from "./GstModal";
import "./style.scss";
import useProfile from "./useProfile";

export default function Profile() {
  const {
    open,
    handleModalOpen,
    handleModalClose,
    isSubscribed,
    user,
    isAdmin,
    handleLogout,
    gstCredentials,
    fetchGstCredentials,
    openGstForm,
    handleModalOpenGstForm,
    handleModalCloseGstForm,
    activeSubscription,
  } = useProfile();
  const theme = useTheme();
  const isMdDown = theme.breakpoints.down("md");
  const referralCard = () => (
    <div>
      <p className="fs-30">
        You can earn referrals by referring the person and you will get the
        amount when someone subscribes using your referral code. Your referral
        code would be your 10 digit mobile number.
      </p>
      {user &&
        user.referrals &&
        user.referrals.map((referral, idx) => (
          <div key={referral.id}>
            <div className="grid-container fs-30">
              <p>Referal code:</p>
              <p className="fw-bold">
                {referral.code} : {referral.commission || 0} Rs
              </p>
            </div>

            <div className="grid-container fs-30">
              <p>Users Joined:</p>
              <ul className="">
                {referral.referredUsers && referral.referredUsers.length ? (
                  <>
                    {referral.referredUsers.map((referredUser, idx) => (
                      <li key={idx + referredUser.name} className="fs-30">
                        {referredUser.name}
                      </li>
                    ))}
                  </>
                ) : (
                  <li key={"No Referred User Yet"}>None Yet</li>
                )}
              </ul>
            </div>
            {idx < user.referrals.length - 1 && user.referrals.length !== 1 && (
              <hr style={{ width: "100%", height: "2px" }} />
            )}
          </div>
        ))}
    </div>
  );
  return (
    <>
      <EditUserDetailsModal
        handleClose={handleModalClose}
        open={open}
        user={user}
      />

      <GstModal
        gstCredentials={gstCredentials}
        fetchGstCredentials={fetchGstCredentials}
        open={openGstForm}
        handleClose={handleModalCloseGstForm}
      />
      <Box width={isMdDown ? "85%" : "70%"} marginX={"auto"} mt={16}>
        <Grid container>
          <Grid item xs={12}>
            <CustomAccordion
              right="Subscription"
              card={
                <div className="d-flex justify-content-center flex-col fs-30">
                  <div className="grid-container">
                    <p>Subscription Status :</p>
                    <p className="fw-bold">
                      {isAdmin ? "Admin" : isSubscribed ? "Active" : "Inactive"}
                    </p>
                  </div>
                  <div className="grid-container">
                    <p>Expirty Date :</p>
                    <p className="fw-bold">
                      {isSubscribed && activeSubscription
                        ? format(
                            parseISO(activeSubscription.expiresOn),
                            "dd-MMM-yyyy",
                          )
                        : "N/A"}
                    </p>
                  </div>
                  <div className="grid-container">
                    <p>Amount Paid :</p>
                    <p className="fw-bold">
                      {isSubscribed && activeSubscription
                        ? `${activeSubscription.amountPaid} Rs`
                        : "N/A"}
                    </p>
                  </div>
                  <div className="grid-container">
                    <p>Date of Payment :</p>
                    <p className="fw-bold">
                      {isSubscribed && activeSubscription
                        ? format(
                            parseISO(activeSubscription.startedAt),
                            "dd-MMM-yyyy",
                          )
                        : "N/A"}
                    </p>
                  </div>
                  <div className="grid-container">
                    <p>Referral Used :</p>
                    <p className="fw-bold">
                      {isSubscribed && activeSubscription?.referralCode
                        ? activeSubscription.referralCode
                        : "N/A"}
                    </p>
                  </div>
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomAccordion
              right="Account"
              card={
                <div className="fs-30">
                  <div className="d-flex justify-content-center flex-col">
                    <div className="grid-container">
                      <div>
                        <p className="fw-bold">Name</p>
                      </div>

                      <div>
                        <p>{user && user.name ? user.name : "N/A"}</p>
                      </div>
                    </div>
                    <div className="grid-container">
                      <div>
                        <p className="fw-bold">Email</p>
                      </div>

                      <div>
                        <p>{user && user.email ? user.email : "N/A"}</p>
                      </div>
                    </div>
                    <div className="grid-container">
                      <div>
                        <p className="fw-bold">Mobile</p>
                      </div>

                      <div>
                        <p>{user && user.phone ? user.phone : "N/A"}</p>
                      </div>
                    </div>
                    <div className="grid-container">
                      <div>
                        <p className="fw-bold">Linked Accounts</p>
                      </div>

                      <div>
                        <ul>
                          {user && user.Account && user.Account.length ? (
                            <>
                              {user.Account.map((account) => (
                                <li key={account.id} className="fs-30">
                                  {account.provider}
                                </li>
                              ))}
                            </>
                          ) : (
                            <li key={"No Account Provider Yet"}>None Yet</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <Button
                        text="Fill Credentials"
                        onClick={handleModalOpen}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          </Grid>
          {isAdmin && (
            <Grid item xs={12}>
              <CustomAccordion right="Referral" left="" card={referralCard()} />
            </Grid>
          )}
          {
            <>
              <Grid item xs={12}>
                {gstCredentials && (
                  <CustomAccordion
                    defaultExpanded={"panel1"}
                    right="GST Credentials"
                    left=""
                    card={
                      <GstDetails
                        {...gstCredentials}
                        fetchGstCredentials={fetchGstCredentials}
                        planName={activeSubscription?.plan?.name}
                        planAmount={activeSubscription?.plan?.amount}
                        gstAmount={activeSubscription?.plan?.taxAmount}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                display={
                  (!gstCredentials || gstCredentials.isGstDraft) && "flex"
                }
                justifyContent={
                  (!gstCredentials || gstCredentials.isGstDraft) && "center"
                }
                pt={4}
              >
                {(!gstCredentials || gstCredentials.isGstDraft) && (
                  <Button
                    text={!gstCredentials ? "Set up GST" : "Edit GST"}
                    onClick={() => handleModalOpenGstForm()}
                  />
                )}
              </Grid>
            </>
          }
          <Grid item xs={12} display={"flex"} justifyContent={"start"} pt={4}>
            <Button text="Logout" onClick={handleLogout} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
