import * as React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Button from "../../../../Components/Button/Button";
import { useAppSelector } from "../../../../store/hooks";

const SebiConditionsModal = ({ open, onClose }) => {
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="sebi-registration-dialog"
        aria-describedby="sebi-registration-dialog-description"
      >
        <DialogTitle>
          DARKHORSESTOCKS IS NEITHER SEBI REGISTERED NOR ANY KIND OF ADVISORY
          SERVICE
        </DialogTitle>
        <DialogContent>
          <Typography id="sebi-registration-dialog-description" sx={{ mt: 2 }}>
            DARKHORSESTOCKS operates as an online platform dedicated to
            compiling data from diverse sources and presenting it in a
            structured format. Our primary objective is to provide valuable
            insights to our readers through meticulously processed company
            information.
          </Typography>
          <Typography id="sebi-registration-dialog-description" sx={{ mt: 2 }}>
            It's important to note that we do not engage in offering investment
            tips, trading ideas, calls, or any form of investment-related
            suggestions. Instead, our focus remains solely on delivering
            comprehensive data analysis.
          </Typography>
          <Typography id="sebi-registration-dialog-description" sx={{ mt: 2 }}>
            We emphasize that DARKHORSESTOCKS is neither an advisory nor a
            research service. The information shared on our platform is not
            intended as recommendations for making investment decisions. We urge
            subscribers to conduct their own thorough research before taking any
            investment actions.
          </Typography>
          <Typography id="sebi-registration-dialog-description" sx={{ mt: 2 }}>
            While we strive to present our insights with utmost accuracy and
            diligence, we recognize the inherent volatility of the stock market.
            Therefore, DARKHORSESTOCKS and its members cannot be held liable for
            any losses incurred by individuals acting on the information
            provided.
          </Typography>
          <Typography id="sebi-registration-dialog-description" sx={{ mt: 2 }}>
            Our content is sourced from publicly available information believed
            to be reliable. However, we do not guarantee the timeliness,
            accuracy, or completeness of this information. Our commitment lies
            in delivering structured and insightful data to empower our readers.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Close" onClick={onClose} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default SebiConditionsModal;
