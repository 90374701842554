import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import "./style.scss";
import CustomAccordion from "../../../Components/Accordion/CustomAccordion";
const BlogDummyContent = ({
  isMdDown,
  isSmDown,
  headerFontSize,
  paragraphFontSize,
}) => {
  const overlayRef = useRef(null);
  useEffect(() => {
    const overlay = document.createElement("div");
    overlay.className = "blog-blurry-overlay";

    if (overlayRef.current) {
      overlayRef.current.appendChild(overlay);
    }
    const overlayToRemove = overlay;
    return () => {
      if (overlayToRemove.current) {
        overlayToRemove.current.removeChild(overlay);
      }
    };
  }, []);

  return (
    <Grid
      container
      ref={overlayRef}
      sx={{
        pointerEvents: "none",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item display="flex" marginY="30vh" justifyContent="center">
        <Box
          container
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="start"
          width="80vw"
        >
          <Typography fontWeight={600} lineHeight={1} fontSize={headerFontSize}>
            Company Name
          </Typography>
        </Box>
      </Grid>
      <Grid item display="flex" marginBottom="20vh" justifyContent="center">
        <Box
          container
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="start"
          width="80vw"
        >
          <Typography fontWeight={600} fontSize={paragraphFontSize}>
            Market Price at the time of Publishing this report : ########## Rs
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        className="blog-post-rerender container"
        style={{ marginTop: "100px", height: "100%" }}
      >
        <CustomAccordion right="Accordion Title" />
        <CustomAccordion right="Accordion Title" />
        <CustomAccordion right="Accordion Title" />
        <CustomAccordion right="Accordion Title" />
        <CustomAccordion right="Accordion Title" />
        <CustomAccordion right="Accordion Title" />
        <CustomAccordion right="Accordion Title" />
      </Grid>
      <Grid item marginTop="100px">
        <Grid
          container
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isSmDown ? "100vw" : "90vw"}
          spacing={4}
        >
          <Grid item display="flex" justifyContent="center">
            <Paper
              sx={{
                padding: "25px",
                bgcolor: "rgba(171,171,171,0.1)",
                boxShadow: 0,
                borderRadius: 8,
              }}
            >
              <Typography
                style={{
                  color: "#5F5F5F",
                  fontSize: !isMdDown ? "30px" : "24px",
                  fontWeight: 600,
                }}
              >
                Conclusion:
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: !isMdDown ? "24px" : "16px",
                }}
              >
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit
                asperiores nihil magni? Explicabo obcaecati consequatur aliquid
                officiis non quisquam sit, deleniti quia laudantium provident
                repellat dolore optio praesentium! Mollitia, labore. Lorem ipsum
                dolor sit amet consectetur, adipisicing elit. Sit asperiores
                nihil magni? Explicabo obcaecati consequatur aliquid officiis
                non quisquam sit, deleniti quia laudantium provident repellat
                dolore optio praesentium! Mollitia, labore. Lorem ipsum dolor
                sit amet consectetur, adipisicing elit. Sit asperiores nihil
                magni? Explicabo obcaecati consequatur aliquid officiis non
                quisquam sit, deleniti quia laudantium provident repellat dolore
                optio praesentium! Mollitia, labore.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogDummyContent;
