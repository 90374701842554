import axios from "axios";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../../../store/hooks";
import { createTheme } from "@mui/material";
import { format } from "date-fns";
import useSubscriptionModal from "../../EditUser/SubscriptionModal/useSubscriptionModal";
import dayjs from "dayjs";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const SubscriptionEnum = {
  IS_SUBSCRIBED: "IS_SUBSCRIBED",
  NOT_SUBSCRIBED: "NOT_SUBSCRIBED",
  BOTH: "BOTH",
};

const useCsvFormModal = (onClose) => {
  const token = localStorage.getItem("code");
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      planId: `N/A`,
      date: dayjs(),
      isSubscribed: SubscriptionEnum.BOTH,
      darkHorseStocksUSA: false,
      darkHorseStocks: false,
      PMS: false,
      fundalysis: false,
      hasPhone: false,
      hasEmail: false,
    },
  });
  const fetchCsv = useCallback(
    (data) => {
      axios
        .post(`${REACT_APP_BASE_URL}user/users-csv`, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Paid-Users-${format(new Date(), "yyyy-MM-dd")}.csv`,
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          onClose();
        })
        .catch((e) => alert(e));
    },
    [onClose, token],
  );
  const onSubmit = async (data) => {
    let submitData = {};
    if (data.isSubscribed !== SubscriptionEnum.IS_SUBSCRIBED) {
      submitData = {
        hasPhone: data.hasPhone,
        hasEmail: data.hasEmail,
      };
    } else {
      submitData = {
        ...data,
        date: new Date(data.date),
        isSubscribed:
          data.isSubscribed === SubscriptionEnum.IS_SUBSCRIBED ? true : false,
      };
      if (data.planId === "N/A") delete submitData.planId;
    }

    fetchCsv(submitData);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const { plans } = useSubscriptionModal();

  return {
    themeProv,
    handleSubmit,
    onSubmit,
    errors,
    control,
    watch,
    plans: [
      {
        value: `N/A`,
        label: `N/A`,
      },
      ...plans,
    ],
  };
};

export default useCsvFormModal;
