import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Smile from "../../Assets/1.png";
import Button from "../../Components/Button/Button";
import "./style.scss";

export default function Thank() {
  const [gstCredentials, setGstCredentials] = useState(null);
  const fetchGstCredentials = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user/gst-credentials`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) setGstCredentials(res.data);
      })
      .catch((e) => console.error("Error fetching gst credentials:", e));
  }, []);
  useEffect(() => {
    fetchGstCredentials();
  }, [fetchGstCredentials]);
  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center justify-content-center h-100 text-center">
        <div className="d-flex align-items-center">
          <h2 className=" fs-95 ">Thank You</h2>
          <div className="d-flex justify-content-center smile-icon">
            <img src={Smile} alt="icon" />
          </div>
        </div>
        <h6 className=" fs-30 mt-1 semi-font">
          Your subscription means a lot to us.
          <br />
          We will try our best to not let you down.
        </h6>

        <Link to="/" className="mt-6">
          <Button text="Home" />
        </Link>
        {gstCredentials && (
          <Link to="/profile" className="mt-2">
            Visit profile to validate your gst credentials
          </Link>
        )}
      </div>
    </div>
  );
}
