import axios from "axios";
import { useCallback, useMemo, useState } from "react";

const useUserSubscriptionsList = (subscriptions, fetchUser) => {
  const sortedSubscriptions = useMemo(
    () =>
      subscriptions.sort((a, b) => {
        return new Date(b.expiresOn) - new Date(a.expiresOn);
      }),
    [subscriptions],
  );
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  // Calculate pagination
  const lastIndex = page * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentSubscriptions = sortedSubscriptions.slice(firstIndex, lastIndex);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const [subscription, setSubscription] = useState(undefined);
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((subscription) => {
    setSubscription(subscription);
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setSubscription(undefined);
    setOpen(false);
  }, []);
  const token = localStorage.getItem("code");
  const deleteSubscription = useCallback(
    (id) => {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}subscription/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          handleClose();
          fetchUser();
        })
        .catch((e) => {
          handleClose();
          alert(e.message);
        });
    },
    [fetchUser, handleClose, token],
  );
  return {
    currentSubscriptions,
    sortedSubscriptions,
    itemsPerPage,
    page,
    handlePageChange,
    subscription,
    open,
    handleOpen,
    handleClose,
    deleteSubscription,
  };
};
export default useUserSubscriptionsList;
