import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useContext, useMemo, useState } from "react";
import AuthContext from "../../Auth/AuthContext";

const useGstDetails = ({ fetchGstCredentials }) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const fontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const subTitleFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "22px" : "24px") : "30px"),
    [isMdDown, isSmDown],
  );

  const validateGst = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}user/gst-credentials/validate`,
        null,
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        fetchGstCredentials();
      });
  }, [fetchGstCredentials]);

  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleModalClose = () => {
    setOpen(false);
  };
  const { isSubscribed } = useContext(AuthContext);
  return {
    fontSize,
    validateGst,
    open,
    handleModalOpen,
    handleModalClose,
    subTitleFontSize,
    isSubscribed,
  };
};
export default useGstDetails;
