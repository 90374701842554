import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAppSelector } from "../../../store/hooks";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useListApps = () => {
  const [apps, setApps] = useState([]);
  const [appsTable, setAppsTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const fetchApps = useCallback(() => {
    try {
      axios
        .get(
          `${REACT_APP_BASE_URL}apps/paginated?page=${currentPage}&searchKeyword=${searchKeyword}`,
        )
        .then((res) => {
          setApps(res.data.apps);
          setTotalPages(res.data.totalPages);
        })
        .catch((e) => console.error("Error fetching apps:", e));
    } catch (error) {
      console.error("Error fetching apps:", error);
    }
  }, [currentPage, searchKeyword]);
  useEffect(() => fetchApps(), [fetchApps]);
  useEffect(() => {
    if (apps && apps.length) {
      setAppsTable(
        apps.map((app) => ({
          id: app.id,
          name: app.name,
        })),
      );
    }
  }, [apps]);
  const history = useHistory();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const token = localStorage.getItem("code");
  const handleDelete = useCallback(
    (id) => {
      axios
        .delete(`${REACT_APP_BASE_URL}apps/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          fetchApps();
        })
        .catch((e) => console.error("Error fetching apps:", e));
    },
    [fetchApps, token],
  );
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  return {
    appsTable,
    isMdDown,
    history,
    handleSearchSubmit,
    searchKeyword,
    handleSearchChange,
    handlePreviousPage,
    currentPage,
    handleNextPage,
    handleDelete,
    lockBackgroundColor,
    theme,
    totalPages,
  };
};
export default useListApps;
