import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

export default function SkeletonSlider() {
  const darkMode = localStorage.getItem("dark");
  return (
    <div className="skeleton-container">
      <Skeleton
        className="header-mb"
        variant="rectangular"
        width={300}
        height={80}
        sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
      />

      <Grid container spacing={2} className="d-flex justify-content-center">
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={237.5}
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "1rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="60%"
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "6rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={237.5}
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "1rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="60%"
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "6rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={237.5}
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "1rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="60%"
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "6rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
