import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetHomepageSectionsQuery } from "../../redux/HomeAPI";
import { useSendMessageMutation } from "../../redux/WebSocket";
import AuthContext from "../Auth/AuthContext";
const useHomePage = () => {
  const [homepageSections, setHomepageSections] = useState();
  const [latestIdea, setLatestIdea] = useState();
  const {
    data: homepage,
    isLoading,
    isSuccess,
  } = useGetHomepageSectionsQuery();
  const [sendMessage] = useSendMessageMutation();
  const getlatestIdet = useCallback(() => {
    if (homepageSections && homepageSections.length) {
      const latestCompanies = homepageSections.filter(
        (homepageSection) => homepageSection.isLatestCompanies === true,
      );
      setLatestIdea({
        ...latestCompanies[0].companyList[0],
        price: Number(latestCompanies[0].companyList[0].price).toFixed(2),
      });
    }
  }, [homepageSections]);
  useEffect(() => {
    setHomepageSections(homepage);
    getlatestIdet();
  }, [getlatestIdet, homepage]);
  const symbols = useMemo(() => {
    const symbols = [];
    homepageSections &&
      homepageSections.length &&
      homepageSections.map(
        (homepageSection) =>
          homepageSection.companyList &&
          homepageSection.companyList.map(
            (company) => company.code && symbols.push(company.code),
          ),
      );
    return JSON.stringify(symbols.join());
  }, [homepageSections]);

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getUTCHours() + 5;
    const currentDay = currentDate.getUTCDay();

    const isWeekday = currentDay > 0 && currentDay < 6;

    if (isWeekday && currentHour >= 9 && currentHour < 15) {
      sendMessage(symbols);
    } else {
      console.log("Socket not started. Outside the allowed time range.");
    }
  }, [sendMessage, symbols]);
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  const onClickHandler = () => {
    if (isAuthenticated) history.push(`/blog/${latestIdea.blogId}`);
    else history.push("/login");
  };
  return {
    homepage,
    isLoading,
    isSuccess,
    onClickHandler,
    latestIdea,
    homepageSections,
  };
};
export default useHomePage;
