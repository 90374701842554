import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

export default function LatestIdeaSkeleton() {
  const darkMode = localStorage.getItem("dark");
  return (
    <div className="container" style={{ marginBottom: "30px" }}>
      <Grid container spacing={2} className="d-flex justify-content-center">
        <Grid item xs={12} md={12} lg={6}>
          <Skeleton
            variant="text"
            width="70%"
            sx={{
              fontSize: "6rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="60%"
            sx={{
              fontSize: "1rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "6rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
          <Skeleton
            variant="text"
            width="100%"
            sx={{
              fontSize: "1rem",
              bgcolor: darkMode === "true" ? "grey.900" : "",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={280}
            sx={{ bgcolor: darkMode === "true" ? "grey.900" : "" }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
