import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import SubscriptionForm from "./SubscriptionForm";
import useSubscriptionModal from "./useSubscriptionModal";

const SubscriptionModal = ({
  subscription,
  open,
  handleClose,
  userId,
  fetchUser,
}) => {
  const { plans } = useSubscriptionModal();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
      aria-labelledby="terms-and-conditions-dialog"
      aria-describedby="terms-and-conditions-dialog-description"
    >
      <DialogTitle>
        {subscription ? "Edit" : "Create"} Subscription:
      </DialogTitle>
      <DialogContent>
        <SubscriptionForm
          subscription={subscription}
          plans={plans}
          handleClose={handleClose}
          userId={userId}
          fetchUser={fetchUser}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionModal;
