import { Box, Fab, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import Card from "../../../Components/Card/Card";
import SecImage from "../../../Assets/image section.png";
import Button from "../../../Components/Button/Button";
import useListTags from "./useListTags";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
const ListTags = () => {
  const {
    handleSearchSubmit,
    searchKeyword,
    handleSearchChange,
    history,
    tags,
    handlePreviousPage,
    currentPage,
    lockBackgroundColor,
    theme,
    totalPages,
    handleNextPage,
    handleDelete,
  } = useListTags();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginY="10vh"
    >
      <Box
        width="90vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={4}
          >
            <form onSubmit={handleSearchSubmit}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <Box className="d-flex input-wrapper">
                    <Box className="input-line-animation__wrapper">
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        value={searchKeyword}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                      />
                      <div className="input-line-animation__line"></div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Button onClick={handleSearchSubmit} text="Search" />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => history.push("/admin-add-tag")}
                    text="Add Tag"
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid container spacing={2} rowSpacing={8} display="flex">
              {tags.map((item) => (
                <Grid item lg={4} md={6} sm={6} xs={12} key={item.Id}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Card
                        {...item}
                        cursorData="Edit"
                        img={item.image || SecImage}
                        title={item.name}
                        price={item.price}
                        isCompany={false}
                        onClick={() =>
                          history.push(`/admin-edit-tag/${item.Id}`)
                        }
                        rate={item.rate}
                        content={item.description || ""}
                        date={item.date}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <IconButton onClick={() => handleDelete(item.Id)}>
                        <DeleteOutlineIcon color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              {/* currentPage !== 1 */}
              <Grid item>
                <Fab
                  onClick={handlePreviousPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === 1 ? "default" : "pointer",
                    color: currentPage === 1 ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === 1
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === 1
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Previous
                </Fab>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: lockBackgroundColor,
                  }}
                >
                  {currentPage} / {totalPages}
                </Typography>
              </Grid>
              <Grid item>
                <Fab
                  onClick={handleNextPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === totalPages ? "default" : "pointer",
                    color:
                      currentPage === totalPages ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === totalPages
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === totalPages
                          ? "#b5b5b5"
                          : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Next
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListTags;
