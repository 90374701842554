import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useEditSector = () => {
  const { id } = useParams();
  const [sector, setSector] = useState();
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}sectors/${id}`)
      .then((res) => {
        setSector(res.data.payload);
      })
      .catch((e) => console.error("Error fetching companies:", e));
  }, [id]);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      relative_index: sector?.relative_index ?? "",
      image: sector?.image ?? "",
      description: sector?.description ?? "",
      name: sector?.name ?? "",
    },
  });
  useEffect(
    () =>
      reset({
        relative_index: sector?.relative_index ?? "",
        image: sector?.image ?? "",
        description: sector?.description ?? "",
        name: sector?.name ?? "",
      }),
    [reset, sector],
  );

  const token = localStorage.getItem("code");

  const editSector = (data) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}sectors/${id}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-list-sectors");
      })
      .catch((e) => console.error("Error fetching companies:", e));
  };

  const onSubmit = (data) => {
    editSector(data);
    reset(data);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
  };
};
export default useEditSector;
