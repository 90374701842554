import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const razorpayApi = createApi({
  reducerPath: 'razorpayApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('code');
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    initiatePayment: builder.mutation({
      query: (body) => ({
        url: `razorpay`,
        method: 'POST',
        body,
      }),
    }),
    getVerification: builder.mutation({
      query: (body) => ({
        url: `razorpay/verify`,
        method: 'POST',
        body,
      }),
    }),
    getAllPaytms: builder.query({
      query: () => ({
        url: 'razorpay',
      }),
    }),
    getPaytmInit: builder.query({
      query: ({ txnValue, txnCurrency, userId }) => ({
        url: 'razorpay',
        params: { txnValue, txnCurrency, userId },
      }),
    }),
    getPaytmById: builder.query({
      query: (arg) => {
        const { id } = arg;
        return {
          url: `razorpay/${id}`,
        };
      },
    }),
  }),
});

export const { useInitiatePaymentMutation, useGetVerificationMutation, useGetAllPaytmsQuery, useGetPaytmInitQuery, useGetPaytmByIdQuery } = razorpayApi;
