import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Button from "../../Components/Button/Button";
import DHCheckbox from "../../Components/DHCheckbox";
import BankDetailsModal from "./BankDetailsModal";
import UPIModal from "./UPIModal";
import usePlanSubscription from "./usePlanSubscription";

const PlanSubscription = () => {
  const {
    verifyingRef,
    verifiedRef,
    checkboxBorderColor,
    launchRazorpayPayment,
    handleChange,
    onCheck,
    handleVerify,
    launchPaytmPayment,
    plan,
    termsChecked,
    cardFields,
    referralCode,
  } = usePlanSubscription();

  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleModalClose = useCallback(() => {
    setOpen(false);
  }, []);
  const [upiOpen, setUPIOpen] = useState(false);
  const handleModalUPIOpen = useCallback(() => {
    setUPIOpen(true);
  }, []);
  const handleModalUPIClose = useCallback(() => {
    setUPIOpen(false);
  }, []);

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const titleFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "50px" : "70px") : "90px"),
    [isMdDown, isSmDown],
  );
  const subTitleFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "25px") : "30px"),
    [isMdDown, isSmDown],
  );
  const cardParagraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "18px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <>
      <BankDetailsModal open={open} onClose={handleModalClose} />
      <UPIModal open={upiOpen} onClose={handleModalUPIClose} />
      <Box width={"90vw"} marginX={"auto"}>
        <Grid
          container
          mt={4}
          spacing={2}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography fontSize={titleFontSize} fontWeight={400}>
              Awesome!
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography fontSize={subTitleFontSize}>
              You are making the right choice.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          mt={8}
          spacing={4}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid
            item
            xs={12}
            md={8}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Grid container display={"flex"} justifyContent={"end"}>
              <Grid item xs={12} md={8}>
                <div className="input-line-animation__wrapper">
                  <input
                    name="refCode"
                    className="input-form input-line-animation__ip"
                    placeholder="Discount Code"
                    onChange={handleChange}
                    value={referralCode}
                  />
                  <div className="input-line-animation__line"></div>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography>
                  Discount codes aren’t provided separately; they’re included
                  within the company articles. To find the discount code, simply
                  read each full report we present every week. This approach
                  encourages our users to explore each report in depth!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display={"flex"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            <button
              className={`button ${
                !verifyingRef
                  ? "button-white"
                  : verifiedRef
                  ? "button-success"
                  : "button-error"
              }`}
              onClick={handleVerify}
              type="button"
            >
              <span style={{ display: "inline-block" }}>
                {!verifyingRef
                  ? "Verify Now"
                  : verifiedRef
                  ? "Verified"
                  : "Invalid"}
              </span>
            </button>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          mt={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item display={"flex"} alignItems={"center"}>
            <DHCheckbox
              isForm={false}
              checkboxBorderColor={checkboxBorderColor}
              checked={termsChecked}
              onCheck={onCheck}
            />
          </Grid>
          <Grid item display={"flex"} alignItems={"center"}>
            <Typography>Agree to terms & conditions</Typography>
          </Grid>
        </Grid>
        {plan && (
          <Grid
            container
            mt={8}
            spacing={2}
            display={"flex"}
            justifyContent={"center"}
            width={isSmDown ? "60vw" : isMdDown ? "30vw" : "25vw"}
            marginX={"auto"}
          >
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                Plan amount
              </Typography>
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                {cardFields.planAmount}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                Discount
              </Typography>
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                {cardFields.discount || "XXXXXX"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                Tax
              </Typography>
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                {cardFields.tax || "XXXXXX"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                Total
              </Typography>
              <Typography fontSize={cardParagraphFontSize} fontWeight={700}>
                {cardFields.totalAmount}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          className="container"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={6}
          spacing={2}
        >
          <Grid item>
            <Button onClick={handleModalOpen} text="Bank Details" />
          </Grid>
          <Grid item>
            <Button onClick={handleModalUPIOpen} text="Direct UPI" />
          </Grid>
          <Grid item>
            <Button
              onClick={async () => await launchPaytmPayment()}
              text="UPI"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={async () => await launchRazorpayPayment()}
              text="Other"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PlanSubscription;
