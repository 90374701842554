import React from "react";
import useUserForm from "./useUserForm";
import { Grid, TextField } from "@mui/material";
import Button from "../../../../Components/Button/Button";
import { Controller } from "react-hook-form";

const UserForm = ({ user }) => {
  const { control, onSubmit } = useUserForm(user);
  return (
    <form onSubmit={onSubmit}>
      <Grid
        mt={2}
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item display="flex" justifyContent="end" xs={12} md={9}>
          <Controller
            name="notes"
            control={control}
            rules={{ required: "Notes is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="text"
                helperText={error ? error.message : null}
                size="medium"
                error={Boolean(error)}
                onChange={onChange}
                value={value}
                multiline
                rows={4}
                fullWidth
                label="Notes"
                variant="filled"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3} display="flex" justifyContent="start">
          <Button text={"Save Notes"} onClick={onSubmit} />
        </Grid>
      </Grid>
    </form>
  );
};

export default UserForm;
