import { Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../Screens/Auth/AuthContext";
import InvertedButton from "../InvertedButton";
import NextStepModal from "./NextStepModal";
import usePricingCard from "./usePricingCard";

const PricingCard = ({ name, features, featuresConclusion, id }) => {
  const {
    themeProv,
    mediumHeaderFontSize,
    paragraphFontSize,
    history,
    isMdDown,
    bulletPointsFontSize,
    isLgDown,
  } = usePricingCard();
  const [gstCredentials, setGstCredentials] = useState(null);
  const fetchGstCredentials = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user/gst-credentials`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) setGstCredentials(res.data);
      })
      .catch((e) => console.error("Error fetching gst credentials:", e));
  }, []);
  useEffect(() => {
    fetchGstCredentials();
  }, [fetchGstCredentials]);
  const { isAuthenticated } = useContext(AuthContext);

  const [openNextStep, setOpenNextStep] = useState(false);
  const handleModalOpenNextStep = useCallback(() => {
    if (!isAuthenticated) return history.push("/login");
    if (gstCredentials) history.push(`/plan-subscription/${id}`);
    else setOpenNextStep(true);
  }, [gstCredentials, history, id, isAuthenticated]);
  const handleModalCloseNextStep = () => {
    setOpenNextStep(false);
  };

  return (
    <ThemeProvider theme={themeProv}>
      <NextStepModal
        open={openNextStep}
        handleClose={handleModalCloseNextStep}
        id={id}
      />
      <Paper
        sx={{
          display: "flex",
          width: "100%",
          padding: isMdDown ? 4 : 8,
          borderRadius: 12,
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
        elevation={0}
      >
        <Grid container spacing={4}>
          <Grid
            item
            display={"flex"}
            justifyContent={!isMdDown && isLgDown ? "start" : "center"}
            xs={12}
          >
            <Typography fontSize={mediumHeaderFontSize} fontWeight={700}>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={1}>
              {features &&
                features.length !== 0 &&
                features.map((feat, idx) => (
                  <Grid item xs={12} key={`${name}-${idx}`}>
                    <Typography
                      fontSize={bulletPointsFontSize}
                      fontWeight={300}
                      lineHeight={1.2}
                    >
                      &#x2022; {feat.description}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={paragraphFontSize}
              fontWeight={300}
              lineHeight={1.2}
            >
              {featuresConclusion}
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent="center">
            <InvertedButton
              text={"Pay Now"}
              onClick={handleModalOpenNextStep}
            />
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

export default PricingCard;
