import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const SlideUp = ({ children, capitalize }) => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsInView(true);
          setHasAnimated(true);
        }
      },
      { threshold: 0.1 },
    );

    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [hasAnimated]);
  const capitalizeWords = (text) =>
    text.replace(/\b[a-zA-Z'"][a-zA-Z'"]/g, (match) => {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });

  const processedText =
    typeof children === "string" && capitalize
      ? capitalizeWords(children)
      : children;

  const charVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: 0.4, type: "ease", stiffness: 100 },
    }),
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4, type: "ease", stiffness: 100 },
    },
  };
  return typeof children === "string" ? (
    <span
      ref={ref}
      style={{
        display: "flex",
        width: "100%",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
      }}
    >
      <AnimatePresence>
        {isInView && (
          <motion.span
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{
              display: "inline-block",
              width: "100%",
            }}
          >
            <motion.span
              style={{
                display: "inline-block",
                width: "100%",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={charVariants}
            >
              <motion.span
                variants={charVariants}
                style={{
                  display: "inline-block",
                  width: "auto",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {processedText}
              </motion.span>
            </motion.span>
          </motion.span>
        )}
      </AnimatePresence>
    </span>
  ) : (
    <div
      ref={ref}
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <AnimatePresence>
        {isInView && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SlideUp;
