import * as React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Button from "../../../Components/Button/Button";
import { useAppSelector } from "../../../store/hooks";

const TermsDialog = ({ signUp, open, onClose }) => {
  const handleClose = () => onClose();
  const handleSignUp = () => {
    onClose();
    signUp();
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>TERMS AND CONDITIONS</DialogTitle>
        <DialogContent>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            General Terms and Conditions
          </Typography>

          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            This Website offers access to the information gathered , compiled
            and made usefull by the administrators of Darkhorsestock. By placing
            an order for Products through this Website, you agree to the terms
            set forth in this Agreement.
          </Typography>

          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            By visiting our site & surfing on our site you are agreeing to be
            bound by the following terms and conditions. We may change these
            terms and conditions at any time. We request our clients to keep
            visiting our T&C, Disclaimer etc on regular bases for sudden changes
            and to avoid any terms updation,we want to keep all clear &
            transparent mode of services operation.
          </Typography>

          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            USE OF CONTENT
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            All logos, brands, marks headings, labels, names, signatures,
            numerals, shapes or any combinations thereof, appearing in this
            site, except as otherwise noted, are properties either owned, or
            used under licence, by the business and / or its associate entities
            who feature on this Website. The use of these properties or any
            other content on this site, except as provided in these terms and
            conditions or in the site content, is strictly prohibited.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            You may not sell or modify the content of this Website or reproduce,
            display, publicly perform, distribute, or otherwise use the
            materials in any way for any public or commercial purpose without
            the respective organisation’s or entity’s.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Terms and Condition ( Website)
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            We, us or DARKHORSESTOCKS refers to the owner of website. Term 'you'
            refers to the user or viewer of our website.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            This page states the Terms and Conditions under which you may visit
            this www.darkhorsestocks.in . Please read this page carefully. If
            you do not accept the Terms and Conditions stated here, we would
            request you to exit this site. The business, any of its business
            divisions and / or its subsidiaries, associate companies or
            subsidiaries to subsidiaries or such other investment companies (in
            India or abroad) reserve their respective rights to revise these
            Terms and Conditions at any time by updating this posting. You
            should visit this page periodically to re-appraise yourself of the
            Terms and Conditions, because they are binding on all users of this
            Website.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            As a registered member you are responsible for maintaining the
            confidentiality of your account and Password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or Password. Further
            you agree to provide true, accurate, current and complete
            information about yourself in the registration form (such
            information being the "Registration Data") and regularly maintain
            and promptly update the Registration Data to keep it true, accurate,
            current and complete. Darkhorsestocks reserves the right to
            terminate your membership and refuse to provide access to the Site
            in case on any such violation.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            Darkhorsestocks as well as the design and information contained in
            this site is valuable and exclusive property of Darkhorsestocks, and
            nothing in this Agreement shall be construed as transferring or
            assigning any such ownership rights to you or any other person or
            entity. All information on Darkhorsestocks is the proprietary and
            confidential property of Darkhorsestocks and cannot be repeated for
            any reason outside Darkhorsestocks. You agree not to repeat or
            rebroadcast in any way any of the Explanations/Reports made on
            Darkhorsestocks for any reason whatsoever. You agree that if you do
            repeat or re-post any of Darkhorsestocks Reports by any mean, you
            will be liable for actual and punitive damages as determined by
            Darkhorsestocks and additional damages to be determined by Indian
            court of Law.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            You may not resell, redistribute, broadcast or transfer the
            information or use the information in a searchable, machine-readable
            database unless separately and specifically authorized in writing by
            Darkhorsestocks prior to such use. You may not rent, lease,
            sublicense, distribute, transfer, copy, reproduce, publicly display,
            publish, adapt, store or time-share Darkhorsestocks, any part
            thereof, or any of the information received or accessed the reform
            to or through any other person or entity unless separately and
            specifically authorized in writing by Darkhorsestocks prior to such
            use. In addition, you may not remove, alter or obscure any
            copyright, legal or proprietary notices in or on any portions of
            Darkhorsestocks without prior written authorization Except as set
            forth herein, any other use of the information contained in this
            site requires the prior written consent of Darkhorsestocks and may
            require a separate fee.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            Darkhosrsestocks shall not be liable for any loss or liability
            resulting, directly or indirectly, from delays or interruptions due
            to electronic or mechanical equipment failures, telephone
            interconnect problems, defects, weather, strikes, walkouts, fire,
            acts of God, riots, armed conflicts, acts of war, or other like
            causes. Darkhorsestocks shall have no responsibility to provide you
            access to Darkhorsestocks while interruption of Darkhorsestocks is
            due to any such cause shall continue.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            The contents, information, software, products, features and services
            published on this website may include inaccuracies or typographical
            errors. Changes are periodically added to the contents herein.
            Darkhosrsestocks and/or its respective suppliers may make
            improvements and/or changes in this website at any time. This
            website may be temporarily unavailable from time to time due to
            required maintenance, telecommunications interruptions, or other
            disruptions. Darkhosrsestocks shall not be liable to user or member
            or any third party should Darkhosrsestocks exercise its right to
            modify or discontinue any or all of the contents, information,
            software, products, features and services published on this website.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            Darkhosrsestocks and/or its respective associated entities make no
            representations about the suitability of the contents, information,
            software, products, features and services contained on this website
            for any purpose. All such contents, information, software, products,
            features and services are provided "as is" without warranty of any
            kind. Darkhosrsestocks and/or its associated entities hereby
            disclaim all warranties and conditions with regard to these
            contents, information, software, products, features and services,
            including all implied warranties and conditions of merchantability,
            fitness for a particular purpose, title, non-infringement, and
            availability. In no event shall Darkhosrsestocks and/or its
            associated entities be liable for any direct, indirect, punitive,
            incidental, special or consequential damages arising out of or in
            any way connected with the use of this website or with the delay or
            inability to use this website, or for any contents, information,
            software, products, features and services obtained through this
            website, or otherwise arising out of the use of this website,
            whether based on contract, tort, strict liability or otherwise, even
            if Darkhosrsestocks or any of its associated entities has been
            advised of the possibility of damages.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            You acknowledge that the information provided through
            Darkhosrsestocks is compiled from sources, which are beyond the
            control of Darkhosrsestocks Though such information is recognized by
            the parties to be generally reliable, the parties acknowledge that
            inaccuracies may occur and Darkhosrsestocks does not warrant the
            consistency or suitability of the information. For this reason, as
            well as the possibility of human and mechanical errors and other
            factors, you acknowledge that Darkhosrsestocks is provided to you on
            an "as is, with all faults" basis. Darkhosrsestocks expressly
            disclaims any and all warranties, whether express, oral, implied,
            statutory or otherwise, of any kind to the users and/or any third
            party, including any implied warranties of consistency, timeliness,
            completeness, merchantability and fitness for a particular purpose,
            as well as any warranties arising by virtue of custom of trade or
            course of dealing and any implied warranties of title or
            non-infringement. In addition, Darkhosrsestocks, in providing the
            information makes no endorsement of any particular security, market
            participant, or brokerage. Further, Darkhosrsestocks does not
            represent or warrant that it will meet your requirements or is
            suitable for your needs.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            You agree that Darkhosrsestocks shall not in any event be liable for
            any special, incidental or consequential damages arising out of the
            use or inability to use Darkhosrsestocks for any purpose whatsoever.
            Darkhosrsestocks and its affiliates, associates, officers,
            directors, employees and agents shall have no liability in tort,
            contract, or otherwise to user and/or any third party.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            The links in this site will allow you to leave Darkhosrsestocks. The
            linked sites are not under the control of Darkhosrsestocks.
            Darkhosrsestocks has not reviewed, nor approved these sites and is
            not responsible for the contents or omissions of any linked site or
            any links contained in a linked site. The inclusion of any linked
            site does not imply endorsement by Darkhosrsestocks of the site.
            Third party links to Darkhosrsestocks shall be governed by a
            separate agreement.
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            sx={{ mt: 2 }}
          >
            Neither we nor any third parties present any warranty or guarantee
            as to the accuracy, timeliness, efficiency, completeness or
            suitability of the data and supplies discovered or offered on this
            site for any specific purpose. You well known that such information
            and supplies may include inaccuracies or error and we expressly
            exclude liability for such inaccuracies or errors to the fullest
            extent accredited with the aid of regulation.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="I Agree" onClick={handleSignUp} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default TermsDialog;
