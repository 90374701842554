import React from "react";
import useInsertedUsersList from "./useInsertedUsersList";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  styled,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Fab,
} from "@mui/material";
import Button from "../../../Components/Button/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4], // Adjust the elevation (box shadow) value as needed
  borderRadius: "8px", // Adjust the border radius as needed
  padding: 10,
}));
const InsertedUserList = () => {
  const {
    insertedUsersTable,
    isMdDown,
    history,
    handleSearchChange,
    handleSearchSubmit,
    handlePreviousPage,
    handleNextPage,
    searchKeyword,
    currentPage,
    lockBackgroundColor,
    theme,
    totalPages,
    deleteInsertedUser,
    syncInsertedUsersSubs,
  } = useInsertedUsersList();
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return (
    <Box
      marginY="20vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={isMdDown ? "90vw" : "70vw"}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={4}
          >
            <form onSubmit={handleSearchSubmit}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <Box className="d-flex input-wrapper">
                    <Box className="input-line-animation__wrapper">
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        value={searchKeyword}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                      />
                      <div className="input-line-animation__line"></div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Button onClick={handleSearchSubmit} text="Search" />
                </Grid>
                <Grid item>
                  <Button
                    onClick={syncInsertedUsersSubs}
                    text="Syncronize Subscriptions"
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={() => history.push("/admin-add-inserted-user")}
              text="New +"
            />
          </Grid>
          <Grid item xs={12}>
            {isMdDown ? (
              <Grid container spacing={2}>
                {insertedUsersTable.map((insertedUser) => (
                  <Grid key={insertedUser.id} item xs={12} sm={6} md={4}>
                    <Card
                      onClick={() =>
                        history.push(
                          `/admin-edit-inserted-user/${insertedUser.id}`,
                        )
                      }
                    >
                      <CardContent>
                        <Grid
                          container
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  ID: {insertedUser.id}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>Email:</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{insertedUser.email}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>Phone:</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{insertedUser.phone}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>Date:</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {new Date(
                                    insertedUser.date,
                                  ).toLocaleDateString("en-US", dateOptions)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  Plan Id:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{insertedUser.planId}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="end"
                            alignItems="center"
                            xs={12}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteInsertedUser(insertedUser.id);
                              }}
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <StyledTableContainer component={Paper}>
                <Table
                  sx={{
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderBottom: 1,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Phone</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Date</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Plan Id</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {insertedUsersTable.map((insertedUser) => (
                      <TableRow
                        key={insertedUser.id}
                        sx={{
                          "& td, & th": {
                            border: 0, // Set your desired border styling here
                          },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(
                            `/admin-edit-inserted-user/${insertedUser.id}`,
                          )
                        }
                      >
                        <TableCell>{insertedUser.id}</TableCell>
                        <TableCell>{insertedUser.email}</TableCell>
                        <TableCell>{insertedUser.phone}</TableCell>
                        <TableCell>
                          {new Date(insertedUser.date).toLocaleDateString(
                            "en-US",
                            dateOptions,
                          )}
                        </TableCell>
                        <TableCell>{insertedUser.planId}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteInsertedUser(insertedUser.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              {/* currentPage !== 1 */}
              <Grid item>
                <Fab
                  onClick={handlePreviousPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === 1 ? "default" : "pointer",
                    color: currentPage === 1 ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === 1
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === 1
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Previous
                </Fab>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: lockBackgroundColor,
                  }}
                >
                  {currentPage} / {totalPages}
                </Typography>
              </Grid>
              <Grid item>
                <Fab
                  onClick={handleNextPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === totalPages ? "default" : "pointer",
                    color:
                      currentPage === totalPages ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === totalPages
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === totalPages
                          ? "#b5b5b5"
                          : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Next
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InsertedUserList;
