import { CopyAll } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

const CopyCard = ({ isSmDown, copyValue, children }) => {
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: isSmDown ? 1 : 2,
        border: "1px solid gray",
        borderRadius: 1,
      }}
    >
      <Box display={"flex"} alignItems={"center"} minWidth={0} flexShrink={1}>
        {children}
      </Box>
      <IconButton onClick={() => handleCopy(copyValue)} color="inherit">
        <CopyAll />
      </IconButton>
    </Box>
  );
};

export default CopyCard;
