import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";
import useSubscriptionForm from "./useSubscriptionForm";
import Button from "../../../../../Components/Button/Button";

const SubscriptionForm = ({
  plans,
  subscription,
  handleClose,
  userId,
  fetchUser,
}) => {
  const { control, onSubmit, errors } = useSubscriptionForm(
    subscription,
    handleClose,
    userId,
    fetchUser,
  );

  return (
    <form onSubmit={onSubmit}>
      <Grid
        mt={2}
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <FormControl fullWidth variant="filled" error={Boolean(errors.date)}>
            <Controller
              name="startedAt"
              control={control}
              rules={{ required: "Date is required" }}
              render={({ field }) => (
                <DatePicker {...field} fullWidth label="Date" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="planId"
            control={control}
            rules={{ required: "Plan is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <FormControl fullWidth variant="filled" error={Boolean(error)}>
                  <InputLabel id="planId-label">Plan</InputLabel>
                  <Select
                    labelId="planId-label"
                    value={value}
                    label="Plan"
                    onChange={onChange}
                    variant="filled"
                  >
                    {plans?.length !== 0 &&
                      plans.map((plan) => (
                        <MenuItem key={plan.value} value={plan.value}>
                          {plan.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {error && (
                    <FormHelperText error>
                      {error ? error.message : null}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={8} display="flex" justifyContent="end">
          <Button text={subscription ? "Edit" : "Create"} onClick={onSubmit} />
        </Grid>
      </Grid>
    </form>
  );
};

export default SubscriptionForm;
