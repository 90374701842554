// create a context for subscription schedule
import axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";

const SubscriptionScheduleContext = createContext();

const SubscriptionScheduleProvider = ({ children }) => {
  const [activeSchedule, setActiveSchedule] = useState(null);
  const [nearestActiveSchedule, setNearestActiveSchedule] = useState(null);

  const formatSchedule = useCallback((schedule) => {
    return {
      ...schedule,
      startDate: new Date(schedule.startDate),
      endDate: new Date(schedule.endDate),
    };
  }, []);

  const fetchNearestActiveSchedule = useCallback(
    () =>
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}subscription-schedule/nearest-active`,
        )
        .then((response) => {
          setNearestActiveSchedule(
            response.data ? formatSchedule(response.data) : null,
          );
        }),
    [formatSchedule],
  );

  const fetchActiveSchedule = useCallback(
    () =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}subscription-schedule/active`)
        .then((response) => {
          if (!response.data) {
            fetchNearestActiveSchedule();
          } else {
            setActiveSchedule(
              response.data ? formatSchedule(response.data) : null,
            );
          }
        }),
    [formatSchedule, fetchNearestActiveSchedule],
  );
  const token = localStorage.getItem("code");
  const createSchedule = useCallback(
    (data) =>
      axios
        .post(`${process.env.REACT_APP_BASE_URL}subscription-schedule`, data, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          fetchActiveSchedule();
        }),
    [fetchActiveSchedule, token],
  );

  const updateSchedule = useCallback(
    (id, data) =>
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}subscription-schedule/${id}`,
          data,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => fetchActiveSchedule()),
    [fetchActiveSchedule, token],
  );

  const deleteSchedule = useCallback(
    (id) =>
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}subscription-schedule/${id}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => fetchActiveSchedule()),
    [fetchActiveSchedule, token],
  );

  const fetchScheduleById = useCallback(
    (id) =>
      axios
        .get(`${process.env.REACT_APP_BASE_URL}subscription-schedule/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          return formatSchedule(response.data);
        }),
    [formatSchedule, token],
  );

  const fetchSchedulesPaginated = useCallback(
    (page, limit) =>
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}subscription-schedule?page=${page}&limit=${limit}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response) => {
          return {
            ...response.data,
            subscriptionSchedules:
              response.data.subscriptionSchedules.map(formatSchedule),
          };
        }),
    [formatSchedule, token],
  );

  useEffect(() => {
    fetchActiveSchedule();
  }, [fetchActiveSchedule]);

  return (
    <SubscriptionScheduleContext.Provider
      value={{
        activeSchedule,
        fetchActiveSchedule,
        createSchedule,
        updateSchedule,
        deleteSchedule,
        fetchScheduleById,
        fetchSchedulesPaginated,
        nearestActiveSchedule,
      }}
    >
      {children}
    </SubscriptionScheduleContext.Provider>
  );
};

export { SubscriptionScheduleContext, SubscriptionScheduleProvider };
