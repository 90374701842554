import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SecImage from "../../Assets/image section.png";
import CardList from "../../Components/CardList";
import LinearProgressBar from "../../Components/LinearProgressBar";
import SkeletonSlider from "../../Components/Skeleton card/SkeletonSlider";
import SlideUp from "../../Components/SlideUp";
import { useGetTagsQuery } from "../../redux/HomeAPI";
const Tags = () => {
  // const [overview, setOverview] = useState("");
  const { data, isLoading, isSuccess } = useGetTagsQuery();
  const history = useHistory();
  const [tags, setTags] = useState([]);
  useEffect(() => data && setTags(data), [data]);

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );

  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );

  const onTagSelect = (id) => {
    history.push(`/tag/${id}`);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box>
        {isLoading && !isSuccess ? (
          <>
            <LinearProgressBar />
            <SkeletonSlider />
          </>
        ) : (
          tags?.length !== 0 && (
            <>
              <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
                  <Grid container spacing={2} display="flex">
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      overflow="hidden"
                    >
                      <Typography
                        fontWeight={600}
                        lineHeight={1.5}
                        fontSize={headerFontSize}
                        pl={isMdDown ? 0 : 10}
                      >
                        <SlideUp capitalize>tags</SlideUp>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                height="100vh"
                width="100vw"
                sx={{
                  backgroundImage: `url(${tags[0].image || SecImage})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Box>
              <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
                  <Grid container spacing={2} display="flex">
                    <Grid item lg={2} md={2} sm={3}>
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        lineHeight={1.2}
                      >
                        Overview
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={10} sm={9}>
                      <Typography
                        fontSize={paragraphFontSize}
                        fontWeight={500}
                        lineHeight={1.2}
                      >
                        Tags are used to categorize and highlight specific
                        attributes, characteristics, or information related to
                        the companies we recommend. These tags serve as quick
                        reference points to provide insights into various
                        aspects of the companies. It's important to note that
                        while these tags offer valuable information, market
                        conditions, company performance, and other factors can
                        change over time, potentially affecting the relevance
                        of certain tags.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <CardList
                contentList={tags}
                cursorData="See Tag"
                isCompany={false}
                isLoading={isLoading}
                isSuccess={isSuccess}
                onClick={onTagSelect}
              />
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default Tags;
