import React from "react";
import "./style.scss";
const REACT_APP_INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK;
const REACT_APP_TWITTER_LINK = process.env.REACT_APP_TWITTER_LINK;
const REACT_APP_YOUTUBE_LINK = process.env.REACT_APP_YOUTUBE_LINK;

export default function BlogFooter() {
  return (
    <div className="blog-container">
      <div className="fs-75">
        <h2>Have an idea?</h2>
        <p className="blog-footer-text">Tell us about it</p>
      </div>
      <div className="d-flex justify-content-between">
        <div className="blog-contact ">
          <h4 className="fs-20">support@darkhorsestocksinception.in</h4>
          <p className="mt-3 ">
            837 Garden street, Milpitas California 95035, USA
          </p>
          <p className="mt-1">
            {" "}
            Parimit Apartment, Besides parth hospital, Anjali cross road, Bhatta
            380007, Ahmedabad, India.
          </p>
          <p className="mt-3">privacy policy</p>
        </div>

        <div className="footer-social">
          <ul className="blog-footer-list d-flex justify-content-between">
            <li>Linkedin </li>
            <a href={REACT_APP_TWITTER_LINK}>Twitter</a> <li>Behance</li>
          </ul>

          <ul className="blog-footer-list d-flex justify-content-between">
            <li>Dribbble</li>
            <li>
              <a href={REACT_APP_INSTAGRAM_LINK}>Instagram</a>{" "}
            </li>
            <li>
              <a href={REACT_APP_YOUTUBE_LINK}>Youtube</a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
