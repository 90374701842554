import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetAllPlansQuery } from "../../redux/PlansAPI";

const usePricing = () => {
  const history = useHistory();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );

  const [plans, setPlans] = useState([]);

  const { data } = useGetAllPlansQuery();
  useEffect(() => {
    if (data && data.success) {
      setPlans(
        data.payload.map((plan) => ({
          name: plan.name,
          features: plan.features || [],
          featuresConclusion: plan.featuresConclusion,
          id: plan.id,
        })),
      );
    }
  }, [data]);
  return {
    history,
    headerFontSize,
    mediumHeaderFontSize,
    paragraphFontSize,
    plans,
    isSmDown,
    isMdDown,
  };
};
export default usePricing;
