import { Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

const UserInformation = ({ user }) => {
  return (
    <Grid container display="flex" justifyContent="start" alignItems="center">
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Typography fontWeight="bold" paddingX={0.5}>
          This user signed up on:{" "}
        </Typography>
        <Typography paddingX={0.5}>
          {format(new Date(user.createdAt), "dd-MMM-yyyy")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Typography fontWeight="bold" paddingX={0.5}>
          ID:
        </Typography>
        <Typography paddingX={0.5}>{user.id}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Typography fontWeight="bold" paddingX={0.5}>
          Name:
        </Typography>
        <Typography paddingX={0.5}>{user.name}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Typography fontWeight="bold" paddingX={0.5}>
          Email:
        </Typography>
        <Typography paddingX={0.5}>{user.email}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="start"
        alignItems="center"
      >
        <Typography fontWeight="bold" paddingX={0.5}>
          Phone:
        </Typography>
        <Typography paddingX={0.5}>{user.phone}</Typography>
      </Grid>
    </Grid>
  );
};

export default UserInformation;
