import * as React from "react";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useAppSelector } from "../../store/hooks";
import { Controller } from "react-hook-form";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.palette.mode === "dark" ? "#fff" : "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#000" : "#000",
        opacity: 1,
        border: `1px solid ${theme.palette.mode === "dark" ? "#fff" : "#000"}`,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#fff",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#999",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const DHSwitch = ({ onChange, defaultChecked, name, control }) => {
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return (
    <ThemeProvider theme={themeProv}>
      {name ? (
        <FormControlLabel
          control={
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <IOSSwitch
                  sx={{ m: 1 }}
                  value={field.value ?? false}
                  {...field}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            />
          }
        />
      ) : (
        <FormGroup>
          <FormControlLabel
            onChange={onChange}
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={defaultChecked}
                onClick={(e) => e.stopPropagation()}
              />
            }
            label=""
          />
        </FormGroup>
      )}
    </ThemeProvider>
  );
};
export default DHSwitch;
