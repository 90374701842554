import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useSubscriptionCard = ({ id, description, price, duration }) => {
  const history = useHistory();
  const handlePayment = () => {
    history.push(`/plan-subscription/${id}`);
  };

  const parsedPrice = useMemo(() => {
    let priceToParse = price;
    if (priceToParse) {
      return `${priceToParse} INR per Year`;
    }
    return "undetermined yet";
  }, [price]);

  const parsedDuration = useMemo(() => {
    let durationToParse = duration;
    if (!durationToParse) return "undetermined yet";
    if (isNaN(durationToParse)) {
      durationToParse = parseFloat(durationToParse.replace(/[^0-9.]/g, ""));
    }
    if (isNaN(durationToParse)) {
      return "0 years";
    }
    if (durationToParse / 12 < 1) return `${durationToParse} Months`;
    else if (durationToParse % 12 === 0)
      return `${durationToParse / 12} Year${
        !(durationToParse / 12 === 1) ? "s" : ""
      }`;
    else
      return `${durationToParse / 12} Years and ${durationToParse % 12} Months`;
  }, [duration]);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const fontSize = useMemo(() => (isSmDown ? "18px" : "24px"), [isSmDown]);

  return { parsedPrice, parsedDuration, handlePayment, fontSize };
};

export default useSubscriptionCard;
