import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React from "react";
import Button from "../../Button/Button";
import { useAppSelector } from "../../../store/hooks";

const ContactModal = ({ open, onClose }) => {
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="contact-dialog"
        aria-describedby="contact-dialog-description"
      >
        <DialogTitle>Contact</DialogTitle>
        <DialogContent>
          <Typography
            id="contact-dialog-description"
            fontWeight={600}
            sx={{ mt: 2 }}
          >
            Whatsapp:
          </Typography>
          <Typography id="contact-dialog-description" sx={{ mt: 2 }}>
            +917874999975
          </Typography>
          <Typography
            id="contact-dialog-description"
            fontWeight={600}
            sx={{ mt: 2 }}
          >
            Email:
          </Typography>
          <Typography
            id="contact-dialog-description"
            sx={{ mt: 2, textTransform: "none !important" }}
          >
            Support@darkhorsestocks.in
          </Typography>
          <Typography
            id="contact-dialog-description"
            fontWeight={600}
            sx={{ mt: 2 }}
          >
            Address:
          </Typography>
          <Typography id="contact-dialog-description" sx={{ mt: 2 }}>
            - 837 Garden street, Milpitas California 95035, USA
          </Typography>
          <Typography id="contact-dialog-description" sx={{ mt: 2 }}>
            - 2 Parimit Apartment, Besides parth hospital, Anjali cross road,
            Bhatta 380007, Ahmedabad, India.
          </Typography>
          <Typography
            id="contact-dialog-description"
            fontWeight={600}
            sx={{ mt: 2 }}
          >
            Gst Registration type:
          </Typography>
          <Typography id="contact-dialog-description" sx={{ mt: 2 }}>
            Composition
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Close" onClick={onClose} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ContactModal;
