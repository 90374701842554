import { useContext, useEffect } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import Button from "../../Components/Button/Button";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AuthContext from "./AuthContext";
import { Controller, useForm } from "react-hook-form";

const BASE_URL = process.env.REACT_APP_WS_URL;
// const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const REACT_APP_TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID;
const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;

export default function SignIn() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const history = useHistory();
  const { login } = useContext(AuthContext);

  const darkMode = localStorage.getItem("dark");
  const [color, setColor] = useState(darkMode === "true" ? "white" : "black");

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (darkMode === "true") {
      setColor("white");
    } else {
      setColor("black");
    }
  }, [darkMode]);

  useEffect(() => {
    if (scriptLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://otpless.com/auth.js";
      document.body.appendChild(script);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const setupWhatsApp = () => {
      window.otpless = async (otplessUser) => {
        const res = await axios.post(`${BASE_URL}auth/whatsapp/login`, {
          phone: otplessUser?.mobile?.number,
          name: otplessUser?.mobile?.name,
          email: otplessUser?.email?.email,
        });
        if (res.data.error) history.push("/register");
        else login(res.data.tokens.token, history);
      };
    };
    setupWhatsApp();
  }, [login, history]);

  const handleWhatsappLogin = () => {
    setScriptLoaded(true);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        // const access_token = code;
        const res = await axios.post(`${BASE_URL}auth/google`, {
          // /auth/google backend that will exchange the code
          access_token: code,
        });

        login(res.data.tokens.token, history);
      } catch (error) {
        alert("Something went wrong!");
      }
    },
    flow: "auth-code",
  });

  // const responseFacebook = async (response) => {
  //   const access_token = response.accessToken;

  //   try {
  //     // send a request to backend that will exchange the code
  //     const res = await axios.post(`${BASE_URL}auth/facebook`, {
  //       access_token,
  //     });

  //     login(res.data.tokens.token, history);
  //   } catch (error) {
  //     if (error?.response?.status === 424) alert('Please connect this soical media account by signing up first.');
  //     history.push('/register');
  //   }
  // };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const res = await axios.post(`${BASE_URL}auth/login`, {
      ...data,
    });
    if (res.data.error) alert(res.data.error);
    else if (res.data.redirectToLinkAccount)
      history.push(`/postSignup/${res.data.userId}`);
    else {
      login(res.data.tokens.token, history);
    }
  };

  function getTwitterOauthUrl() {
    const rootUrl = "https://twitter.com/i/oauth2/authorize";
    const options = {
      redirect_uri: `${REACT_APP_WS_URL}auth/twitter`,
      client_id: REACT_APP_TWITTER_CLIENT_ID,
      state: "state",
      response_type: "code",
      code_challenge: "challenge",
      code_challenge_method: "plain",
      scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(
        " ",
      ), // add/remove scopes as needed
    };
    const qs = new URLSearchParams(options).toString();
    window.location.href = `${rootUrl}?${qs}`;
  }
  const [classicAuthOn, setClassicAuthOn] = useState(false);
  return (
    <div className="container auth-container">
      <div className="d-flex fs-95 auth-wrapper">
        <div
          style={{ color: color }}
          onClick={() => {
            history.push(`/login`);
          }}
          className="border-bottom"
        >
          Sign in
        </div>

        <div style={{ margin: "0 10px", color: color }}>/</div>
        <div
          style={{ color: color }}
          onClick={() => {
            history.push(`/register`);
          }}
        >
          Sign up
        </div>
      </div>{" "}
      <Box marginY={4} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          {!classicAuthOn && (
            <>
              <Grid
                item
                display="flex"
                justifyContent="center"
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <Button text="Google" onClick={googleLogin} />
              </Grid>

              {/* <Grid item display="flex" justifyContent="center" lg={3} md={3} sm={12} xs={12}>
            <FacebookLogin appId={`${REACT_APP_FACEBOOK_APP_ID}`} callback={responseFacebook} render={(renderProps) => <Button text="Facebook" onClick={renderProps.onClick}></Button>} />
        
            </Grid> */}
              <Grid
                item
                display="flex"
                justifyContent="center"
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <Button text="Twitter" onClick={getTwitterOauthUrl} />
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="center"
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <Button text="Whatsapp" onClick={handleWhatsappLogin} />
              </Grid>
            </>
          )}
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <Button
              text={!classicAuthOn ? "Email" : "Socials"}
              onClick={() => setClassicAuthOn((prev) => !prev)}
            />
          </Grid>
        </Grid>
      </Box>
      {classicAuthOn && (
        <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
          <Box className="d-flex input-wrapper">
            <Box className="input-line-animation__wrapper">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <>
                    <input
                      className="input-form input-line-animation__ip"
                      type="text"
                      placeholder="Your email"
                      {...field}
                    />
                    <div className="input-line-animation__line"></div>
                  </>
                )}
              />
            </Box>
            {errors.email && <span>{errors.email.message}</span>}
          </Box>
          <Box className="d-flex input-wrapper">
            <Box className="input-line-animation__wrapper">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                }}
                render={({ field }) => (
                  <>
                    <input
                      className="input-form input-line-animation__ip"
                      type="password"
                      placeholder="Your password"
                      {...field}
                    />
                    <Box className="input-line-animation__line"></Box>
                  </>
                )}
              />
            </Box>
            {errors.password && <span>{errors.password.message}</span>}
          </Box>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              justifyContent={isSmDown ? "center" : "start"}
            >
              <Button onClick={handleSubmit(onSubmit)} text="Login" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              justifyContent={isSmDown ? "center" : "end"}
            >
              <p
                onClick={() => history.push("/forgot-password")}
                className="line-animation-3 forget-pass"
              >
                Forgot Password?
              </p>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
}
