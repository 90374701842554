import React, { useMemo } from "react";
import Button from "../../Components/Button/Button";
import Sad from "../../Assets/sad-face.png";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
const FeatureUnavailable = () => {
  const history = useHistory();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <Box
      display="flex"
      marginY={12}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
        width={"90vw"}
      >
        <Grid item>
          <Typography fontWeight={"bold"} fontSize={headerFontSize}>
            Sorry
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={Sad}
            height={headerFontSize}
            width={headerFontSize}
            alt="Sad-face"
          />
        </Grid>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
          mt={4}
        >
          <Grid item xs={12} lg={8}>
            <Typography textAlign={"center"} fontSize={paragraphFontSize}>
              This functionality is exclusively available to our paid
              subscribers. it includes a comprehensive list of all the ideas
              shared to date, along with a detailed analysis of the performance
              of Darkhorsestock recommendations.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={isMdDown ? "column" : "row"}
              spacing={2}
              mt={4}
            >
              <Grid item>
                <a href={process.env.REACT_APP_COMPANY_CLIENT_URL}>
                  <Button text={"Features"} />
                </a>
              </Grid>
              <Grid item>
                <Button
                  text={"Premium"}
                  onClick={() => history.push("/subscriptions")}
                />
              </Grid>
              <Grid item>
                <Button text={"Home"} onClick={() => history.push("/")} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeatureUnavailable;
