import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  Button,
  ThemeProvider,
  Grid,
  Box,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useAddApp from "./useAddApp";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddApp = () => {
  const { onSubmit, handleSubmit, control, themeProv } = useAddApp();

  const theme = useTheme();
  const history = useHistory();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  return (
    <>
      <ThemeProvider theme={themeProv}>
        <Box
          marginY="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="90vw">
            <Grid
              container
              display="flex"
              justifyContent="center"
              spacing={2}
              mb={4}
            >
              <Grid item xs={12} md={8}>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      fontSize={mediumHeaderFontSize}
                      fontWeight={600}
                    >
                      Add App
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        height: "60px",
                        width: "180px",
                        fontSize: "20px",
                        borderRadius: "100px",
                        color:
                          theme.palette.getContrastText(lockBackgroundColor),
                        backgroundColor: lockBackgroundColor,
                        textTransform: "capitalize",
                        fontWeight: 600,
                        "&:hover": {
                          color: lockBackgroundColor,
                          backgroundColor:
                            theme.palette.getContrastText(lockBackgroundColor),
                          boxShadow: "none",
                        },
                        border: "1px solid",
                        borderColor: lockBackgroundColor,
                        boxShadow: "none",
                      }}
                      type="button"
                      onClick={() => history.push("/admin-list-apps")}
                      color="primary"
                      variant="contained"
                    >
                      Apps
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={8}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "App Name is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="App Name"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  xs={12}
                  md={8}
                >
                  <Button
                    sx={{
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor: lockBackgroundColor,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color: lockBackgroundColor,
                        backgroundColor:
                          theme.palette.getContrastText(lockBackgroundColor),
                        boxShadow: "none",
                      },
                      border: "1px solid",
                      borderColor: lockBackgroundColor,
                      boxShadow: "none",
                    }}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Add App
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AddApp;
