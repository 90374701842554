import { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "../../Components/Button/Button";
import "./style.scss";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AuthContext from "./AuthContext";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid } from "@mui/material";

const BASE_URL = process.env.REACT_APP_WS_URL;
// const REACT_APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
// const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const REACT_APP_TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID;
const REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export default function PostSignup() {
  const { login } = useContext(AuthContext);
  const history = useHistory();

  const [scriptLoaded, setScriptLoaded] = useState(false);

  const darkMode = localStorage.getItem("dark");
  const [color, setColor] = useState(darkMode === "true" ? "white" : "black");

  useEffect(() => {
    if (darkMode === "true") {
      setColor("white");
    } else {
      setColor("black");
    }
  }, [darkMode]);

  useEffect(() => {
    if (scriptLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://otpless.com/auth.js";
      document.body.appendChild(script);
    }
  }, [scriptLoaded]);
  const { userId } = useParams();

  useEffect(() => {
    const setupWhatsApp = () => {
      window.otpless = async (otplessUser) => {
        const res = await axios.post(`${BASE_URL}auth/link-account/whatsapp`, {
          phone: otplessUser?.mobile?.number,
          name: otplessUser?.mobile?.name,
          userId,
        });
        if (res.data.error) alert(res.data.error);
        else login(res.data.tokens.token, history);
      };
    };
    setupWhatsApp();
  }, [history, login, userId]);

  const handleWhatsappLogin = () => {
    setScriptLoaded(true);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        const res = await axios.post(`${BASE_URL}auth/link-account/google`, {
          access_token: code,
          userId,
        });
        if (res.data.error) alert(res.data.error);
        else login(res.data.tokens.token, history);
      } catch (error) {
        alert("Something went wrong!");
      }
    },
    flow: "auth-code",
  });

  // const responseFacebook = async (response) => {
  //   const access_token = response.accessToken;
  //   // send a request to backend that will exchange the code
  //   const res = await axios.post(`${BASE_URL}auth/facebook`, {
  //     access_token,
  //   });

  //   login(res.data.tokens.token, history);
  // };

  function getTwitterOauthUrl() {
    localStorage.setItem("twitterUserId", userId);
    const rootUrl = "https://twitter.com/i/oauth2/authorize";
    const options = {
      redirect_uri: `${REACT_APP_CLIENT_URL}post-signup/twitter`,
      client_id: REACT_APP_TWITTER_CLIENT_ID,
      state: "state",
      response_type: "code",
      code_challenge: "challenge",
      code_challenge_method: "plain",
      scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(
        " ",
      ),
    };
    const qs = new URLSearchParams(options).toString();
    window.location.href = `${rootUrl}?${qs}`;
  }

  return (
    <div className="container auth-container">
      <div className="d-flex fs-75 auth-wrapper">
        <div style={{ color: color }} className="border-bottom">
          Connect Any Social Media Account
        </div>
      </div>{" "}
      <Box marginY={4} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Button text="Google" onClick={googleLogin} />
          </Grid>

          {/* <Grid item display="flex" justifyContent="center" lg={4} md={4} sm={12} xs={12}>
            <FacebookLogin appId={`${REACT_APP_FACEBOOK_APP_ID}`} callback={responseFacebook} render={(renderProps) => <Button text="Facebook" onClick={renderProps.onClick}></Button>} />
        
            </Grid> */}
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Button text="Twitter" onClick={getTwitterOauthUrl} />
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Button text="Whatsapp" onClick={handleWhatsappLogin} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
