import { Box, Grid, Paper, Typography } from "@mui/material";
import "./style.scss";
const BlogContent = ({
  companyData,
  isMdDown,
  conclusion,
  isSmDown,
  headerFontSize,
  paragraphFontSize,
  replaceAccordionComponents,
  blogPost,
}) => {
  return (
    <Grid container display="flex" justifyContent="center" alignItems="center">
      {companyData && (
        <Grid item display="flex" marginY="30vh" justifyContent="center">
          <Box
            container
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="start"
            width="80vw"
          >
            <Typography
              fontWeight={600}
              lineHeight={1}
              fontSize={headerFontSize}
            >
              {companyData.name}
            </Typography>
          </Box>
        </Grid>
      )}
      {companyData && (
        <Grid item display="flex" marginBottom="20vh" justifyContent="center">
          <Box
            container
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="start"
            width="80vw"
          >
            <Typography fontWeight={600} fontSize={paragraphFontSize}>
              Market Price at the time of Publishing this report :{" "}
              {companyData.price_suggested} Rs
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid
        item
        className="blog-post-rerender container"
        style={{ marginTop: "100px", height: "100%" }}
      >
        {replaceAccordionComponents(blogPost)}
      </Grid>
      <Grid item marginTop="100px">
        <Grid
          container
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isSmDown ? "100vw" : "90vw"}
          spacing={4}
        >
          <Grid item display="flex" justifyContent="center">
            <Paper
              sx={{
                padding: "25px",
                bgcolor: "rgba(171,171,171,0.1)",
                boxShadow: 0,
                borderRadius: 8,
              }}
            >
              <Typography
                style={{
                  color: "#5F5F5F",
                  fontSize: !isMdDown ? "30px" : "24px",
                  fontWeight: 600,
                }}
              >
                Conclusion:
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: !isMdDown ? "24px" : "16px",
                }}
              >
                {companyData && companyData.conclusion
                  ? companyData.conclusion
                  : conclusion.current}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogContent;
