import React from "react";
import "./style.scss";

export default function AccordionCard({
  numberOfStocks,
  typeOfService,
  approx,
  freeTrial,
  plans,
  sebiRegistered,
}) {
  return (
    <div className="accordion-card">
      <div className="d-flex justify-content-between f-direction">
        <div className="w-35">
          <h6>
            {!numberOfStocks || numberOfStocks.length === 0
              ? "N/A"
              : numberOfStocks}
          </h6>
          <p>No of Stocks</p>
        </div>
        <div className="w-35">
          <h6>
            {!typeOfService || typeOfService.length === 0
              ? "N/A"
              : typeOfService}
          </h6>
          <p>Type of Service</p>
        </div>
        <div className="w-35">
          <h6>{!approx || approx.length === 0 ? "N/A" : approx}</h6>
          <p>Approx per stock</p>
        </div>
      </div>
      <div className="d-flex justify-content-between f-direction">
        <div className="w-35">
          <h6>{!freeTrial || freeTrial.length === 0 ? "N/A" : freeTrial}</h6>
          <p>Free Trial</p>
        </div>
        <div className="w-35">
          <h6>{!plans || plans.length === 0 ? "N/A" : plans}</h6>
          <p>No of Plans</p>
        </div>
        <div className="w-35">
          <h6>
            {!sebiRegistered || sebiRegistered.length === 0
              ? "N/A"
              : sebiRegistered}
          </h6>
          <p>Sebi registered</p>
        </div>
      </div>
    </div>
  );
}
