import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { SubscriptionScheduleContext } from "../../../Contexts/SubscriptionSchedule";
import { useAppSelector } from "../../../store/hooks";

const useSubscriptionScheduleList = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  const [subscriptionSchedules, setSubscriptionSchedules] = useState([]);
  const [subscriptionSchedulesTable, setSubscriptionSchedulesTable] = useState(
    [],
  );
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const [currentPage, setCurrentPage] = useState(
    parseInt(params.get("page") || "1", 10),
  );
  const [totalPages, setTotalPages] = useState(1);
  const limit = parseInt(params.get("limit") || "10", 10);

  const { deleteSchedule, fetchSchedulesPaginated } = useContext(
    SubscriptionScheduleContext,
  );

  const fetchSchedules = useCallback(() => {
    fetchSchedulesPaginated(currentPage, limit).then((data) => {
      setSubscriptionSchedules(data.subscriptionSchedules);
      setTotalPages(data.totalPages);
    });
  }, [currentPage, fetchSchedulesPaginated, limit]);

  useEffect(() => {
    params.set("page", String(currentPage));
    params.set("limit", String(limit));
    history.replace({ search: params.toString() });
  }, [currentPage, limit, history, params]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);
  useEffect(() => {
    if (subscriptionSchedules && subscriptionSchedules.length) {
      setSubscriptionSchedulesTable(
        subscriptionSchedules.map((schedule) => ({
          id: schedule.id,
          startDate: schedule.startDate,
          endDate: schedule.endDate,
        })),
      );
    }
  }, [subscriptionSchedules]);
  const handleDelete = useCallback(
    (id) => {
      deleteSchedule(id)
        .then(() => {
          fetchSchedules();
        })
        .catch((e) =>
          console.error("Error fetching subscriptionSchedules:", e),
        );
    },
    [deleteSchedule, fetchSchedules],
  );

  return {
    subscriptionSchedulesTable,
    isMdDown,
    history,
    handlePreviousPage,
    currentPage,
    handleNextPage,
    handleDelete,
    lockBackgroundColor,
    theme,
    totalPages,
  };
};

export default useSubscriptionScheduleList;
