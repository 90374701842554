import React, { useState } from "react";
import "./style.scss";

export default function InvertedButton({
  type = "button",
  text,
  className,
  onClick,
  id,
  custom,
}) {
  const [entered, setEntered] = useState("not-active");

  const enterHandler = () => {
    setEntered("active");
  };

  const leaveHandler = () => {
    setEntered("not-active");
  };
  return (
    <div
      id={id}
      custom={custom}
      onClick={onClick}
      className="magnetic-wrap"
      style={{
        display: "inline-block",
        cursor: "pointer",
      }}
    >
      <div
        className="magnetic-area"
        onMouseLeave={leaveHandler}
        onMouseEnter={enterHandler}
      ></div>
      <button
        type={type}
        className={`inverted-btn-component magnetic-content inverted-btn-animate ${className}
      ${entered}
      `}
      >
        <span className="inverted-btn-animate__filler"></span>
        <span className="inverted-btn-animate__text">{text}</span>
      </button>
    </div>
  );
}
