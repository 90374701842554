import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useMemo } from "react";
import Button from "../../Components/Button/Button";
import SlideUp from "../../Components/SlideUp";
import { SubscriptionScheduleContext } from "../../Contexts/SubscriptionSchedule";
import "../Philosophy Page/style.scss";
import "./style.scss";
const REACT_APP_WHATSAPP_LINK = process.env.REACT_APP_WHATSAPP_LINK;

export default function Subscriptions() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const { nearestActiveSchedule } = useContext(SubscriptionScheduleContext);

  return (
    <div className="container">
      <Box height="90vh" display="flex" justifyContent="center">
        <Box
          width={isSmDown ? "90vw" : "75vw"}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Grid item>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Subscriptions</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems="center"
                overflow="hidden"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    fontWeight={600}
                    lineHeight={1}
                    fontSize={headerFontSize}
                  >
                    <SlideUp capitalize>Subscriptions</SlideUp>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontWeight={600}
                    fontSize={headerFontSize}
                    lineHeight={1}
                  >
                    <SlideUp capitalize>Closed</SlideUp>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Grid container spacing={2} display="flex" flexDirection="column">
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Darkhorsestocks was started with a simple aim of bringing
                    amazing fundamentally strong comapnies to users attention
                    with in depth information about the company.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Unlike others, we are not in this for money and that is why
                    we open for subscriptions only twice a year and that to for
                    a limited period of a month only.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    Keeping subscritiptions open all round the year increases a
                    lot of work. Instead of that
                    <strong>
                      {" "}
                      we want to focus on providing quality research{" "}
                    </strong>
                    and amazing companies. Hope you understand.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {nearestActiveSchedule && (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
            <Grid
              container
              display="flex"
              textAlign="center"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item>
                <Typography
                  fontSize={paragraphFontSize}
                  fontWeight={600}
                  lineHeight={1}
                >
                  Subscriptions will reopen for this period.
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography
                      fontSize={headerFontSize}
                      fontWeight={600}
                      lineHeight={1}
                    >
                      {format(nearestActiveSchedule.startDate, "dd-MMM-yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontSize={headerFontSize}
                      fontWeight={600}
                      lineHeight={1}
                    >
                      to
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontSize={headerFontSize}
                      fontWeight={600}
                      lineHeight={1}
                    >
                      {format(nearestActiveSchedule.endDate, "dd-MMM-yyyy")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                What happens when you subscribe?
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                When you subscribe, you get access to all analytics from
                Darkhorsestocks, including the dashboard and Fundalysis. You’ll
                also receive exclusive insights and paid articles based on your
                selected plan. Your support helps us enhance our research and
                analytics platform.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                What to do now?
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                Subscribe to our free plan for small users, where we provide one
                company insight each month, totaling around 10-12 companies
                annually, along with one infographic every week. Since our paid
                subscription is currently closed, you can join us on WhatsApp to
                access 12 free companies each year and explore our services!
              </Typography>
            </Grid>
            <Grid item>
              <a href={REACT_APP_WHATSAPP_LINK}>
                <Button text="WhatsApp" className={" "}></Button>
              </a>
            </Grid>
            <Grid item>
              <Typography fontWeight={500} lineHeight={1}>
                No spam advertising
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
