import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Button from "../../Components/Button/Button";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box } from "@mui/material";
const BASE_URL = process.env.REACT_APP_WS_URL;

export default function ResetPassword() {
  const history = useHistory();
  const { token } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();

  const darkMode = localStorage.getItem("dark");
  const [color, setColor] = useState(darkMode === "true" ? "white" : "black");

  useEffect(() => {
    if (darkMode === "true") {
      setColor("white");
    } else {
      setColor("black");
    }
  }, [darkMode]);

  const onSubmit = (data) => {
    axios
      .post(`${BASE_URL}auth/reset-password`, {
        password: data.password,
        token,
      })
      .then((res) => {
        if (res.data.error) alert(res.data.error);
        else history.push("/login");
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  return (
    <div className="container auth-container">
      <div className="d-flex fs-95 auth-wrapper">
        <div style={{ color: color }} className="border-bottom">
          Reset Password
        </div>
      </div>
      <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="password"
                    placeholder="Your password"
                    {...field}
                  />
                  <Box className="input-line-animation__line"></Box>
                </>
              )}
            />
          </Box>
          {errors.password && <span>{errors.password.message}</span>}
        </Box>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="passwordVerification"
              control={control}
              defaultValue=""
              rules={{
                required: "Password verification is required",
                validate: (value) =>
                  value === getValues("password") || "Passwords do not match",
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="password"
                    placeholder="Verify password"
                    {...field}
                  />
                  <Box className="input-line-animation__line"></Box>
                </>
              )}
            />
          </Box>
          {errors.passwordVerification && (
            <span>{errors.passwordVerification.message}</span>
          )}
        </Box>
        <Button onClick={handleSubmit(onSubmit)} text="Send" />
      </form>
    </div>
  );
}
