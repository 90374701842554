import React from "react";
import "./style.scss"; // Import your component's CSS file
import { Box } from "@mui/material";

function HorizontalTranslate({ text }) {
  return (
    <Box className="line-animation-3">
      <Box
        className="horizontal-translate"
        data-cursor="-menu -icon-blend"
        data-replace={text}
      >
        <span>{text}</span>
      </Box>
    </Box>
  );
}

export default HorizontalTranslate;
