import React, { useEffect, useMemo, useState } from "react";
import infoImg from "../../Assets/oracle 2.png";
import RechartsWrapper from "./RechartsWrapper";
import {
  useGetCompaniesByTypeQuery,
  useGetSectorDetailsQuery,
} from "../../redux/HomeAPI";
import "./style.scss";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardList from "../../Components/CardList";

export default function SectorPage() {
  const { sectorId } = useParams();
  const {
    data: sector,
    isSuccess: isSectorSuccess,
    isLoading: isSectorsLoading,
  } = useGetSectorDetailsQuery(sectorId);
  const {
    data: sectorCompanies,
    isSuccess,
    isLoading,
  } = useGetCompaniesByTypeQuery({
    type: "sector",
    typeId: sectorId,
  });
  const [seoTags, setSeoTags] = useState("");
  const [companies, setCompanies] = useState([]);
  useEffect(
    () => sectorCompanies && setCompanies(sectorCompanies?.companyList),
    [sectorCompanies],
  );

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  useEffect(() => {
    let concatenatedDescriptions = "";
    companies.length !== 0 &&
      companies.forEach((item) => {
        concatenatedDescriptions += " " + item.seo_descriptions;
      });
    setSeoTags(concatenatedDescriptions);
  }, [companies]);

  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <div>
      <Helmet>
        <meta name="description" content={seoTags} />
      </Helmet>
      <Box>
        <Box
          height="90vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
            <Grid container spacing={2} display="flex">
              <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
                <Typography
                  fontWeight={600}
                  lineHeight={1.5}
                  fontSize={headerFontSize}
                  textTransform="capitalize"
                  pl={isMdDown ? 0 : 10}
                >
                  {!isSectorsLoading &&
                  isSectorSuccess &&
                  sector &&
                  sector.payload
                    ? sector.payload.name
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          height="100vh"
          width="100vw"
          sx={{
            backgroundImage: `url(${
              !isSectorsLoading &&
              isSectorSuccess &&
              sector &&
              sector.payload &&
              sector.payload.image !== "" &&
              sector.payload.image !== "Links"
                ? sector.payload.image
                : infoImg
            })`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></Box>

        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container display="flex" justifyContent="center">
            <Grid item lg={12} md={12} sm={12}>
              <RechartsWrapper sectorId={sectorId} selected="sector" />
            </Grid>
          </Grid>
        </Box>
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
            <Grid container spacing={2} display="flex">
              <Grid item lg={2} md={2} sm={3}>
                <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                  Overview
                </Typography>
              </Grid>
              <Grid item lg={8} md={10} sm={9}>
                <Typography
                  fontSize={paragraphFontSize}
                  fontWeight={500}
                  lineHeight={1.2}
                >
                  {sector?.payload?.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box mb="20vh" display="flex" justifyContent="center">
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
                textTransform="capitalize"
                pl={isMdDown ? 0 : 10}
              >
                Suggested Companies
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CardList
        contentList={companies}
        cursorData="Read report"
        isCompany={true}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </div>
  );
}
