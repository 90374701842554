import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../../Components/Button/Button";
import { SubscriptionScheduleContext } from "../../../Contexts/SubscriptionSchedule";

const Admin = () => {
  const theme = useTheme();
  const history = useHistory();

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "30px") : "40px"),
    [isMdDown, isSmDown],
  );
  const { activeSchedule } = useContext(SubscriptionScheduleContext);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        width="90vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          mt={16}
          mb={16}
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography
              sx={{ cursor: "pointer" }}
              data-cursor-text={
                activeSchedule ? "Edit schedule" : "Create new schedule"
              }
              onClick={() =>
                history.push(
                  activeSchedule
                    ? `/admin-edit-subscription-schedule/${activeSchedule.id}`
                    : "/admin-add-subscription-schedule",
                )
              }
              fontSize={mediumHeaderFontSize}
              fontWeight={600}
              align="center"
            >
              Active Schedule:{" "}
              {activeSchedule
                ? `From ${format(
                    activeSchedule.startDate,
                    "dd-MMM-yyyy",
                  )} To ${format(activeSchedule.endDate, "dd-MMM-yyyy")}`
                : "No current active subscription schedule"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-subscription-schedules")}
                  text="Schedules"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-home-page")}
                  text="Home-Page"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-companies")}
                  text="Companies"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-users")}
                  text="Users"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-tags")}
                  text="Tags"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-sectors")}
                  text="Sectors"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-categories")}
                  text="Categories"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid
                item
                sm={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-plans")}
                  text="Plans"
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-list-apps")}
                  text="Apps"
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  onClick={() => history.push("/admin-inserted-users")}
                  text="Inserted Users"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Admin;
