import axios from "axios";
import { useEffect, useState } from "react";

const useSubscriptionModal = () => {
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}plans`)
      .then((res) => {
        res.data.success &&
          res.data.payload !== null &&
          setPlans(
            res.data.payload.map((plan) => ({
              value: plan.id,
              label: `name: ${plan.name}/ amount: ${plan.amount}`,
            })),
          );
      })
      .catch((e) => console.error("Error fetching plans:", e));
  }, []);
  return { plans };
};
export default useSubscriptionModal;
