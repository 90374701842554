import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useEditCategory = () => {
  const { id } = useParams();
  const [category, setCategory] = useState();
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}category/${id}`)
      .then((res) => {
        setCategory(res.data.payload);
      })
      .catch((e) => console.error("Error fetching companies:", e));
  }, [id]);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      relative_index: category?.relative_index ?? "",
      image: category?.image ?? "",
      description: category?.description ?? "",
      name: category?.name ?? "",
    },
  });
  useEffect(
    () =>
      reset({
        relative_index: category?.relative_index ?? "",
        image: category?.image ?? "",
        description: category?.description ?? "",
        name: category?.name ?? "",
      }),
    [reset, category],
  );

  const token = localStorage.getItem("code");

  const editCategory = (data) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}category/${id}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-list-categories");
      })
      .catch((e) => console.error("Error fetching companies:", e));
  };

  const onSubmit = (data) => {
    editCategory(data);
    reset(data);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
  };
};
export default useEditCategory;
