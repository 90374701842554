import {
  Box,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AnimatingNumber from "../AnimatingNumber/animation-number";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetMessagesQuery } from "../../redux/WebSocket";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { useAppSelector } from "../../store/hooks";
import AuthContext from "../../Screens/Auth/AuthContext";

const Card = ({
  img,
  name,
  title,
  isCompany,
  content,
  date,
  onClick,
  price,
  code,
  Id,
  previous_close,
  gain_perc,
  subscribe,
  cursorData,
  valueDate,
  relative_index,
  ...rest
}) => {
  const [counter2, setCounter2] = useState(price ?? 0);
  const history = useHistory();
  const { data: messages } = useGetMessagesQuery();
  useEffect(() => {
    if (messages) {
      messages.forEach((ms) => {
        const tmp = JSON.parse(ms);
        if (tmp && !tmp.error && tmp.event === "price" && tmp.symbol === code) {
          setCounter2(tmp.price);
        }
      });
    }
  }, [messages, code]);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isXlDown = useMediaQuery(theme.breakpoints.down("xl"));
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  const cardTitleFontSize = useMemo(
    () => (isLgDown ? "20px" : "25px"),
    [isLgDown],
  );
  const cardParagraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "14px" : "16px") : "18px"),
    [isMdDown, isSmDown],
  );
  const { isSubscribed, isAuthenticated } = useContext(AuthContext);
  if (!Id) {
    return;
  }

  const onClickDefault = () => {
    history.push("/");
  };

  const gainValue = (counter2 ? counter2 - previous_close : 0).toFixed(2);
  const gainPercentage = counter2
    ? (((counter2 - previous_close) * 100) / previous_close).toFixed(2)
    : (gain_perc * 100).toFixed(2);
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return (
    <Box
      height={isMdDown && !isSmDown ? "40vh" : "50vh"}
      onClick={onClick ?? onClickDefault}
      data-cursor-text={
        (!isAuthenticated && "login") ||
        (isCompany && rest.paid === "Paid" && !isSubscribed && "subscribe") ||
        cursorData
      }
      sx={{ cursor: "pointer", position: "relative" }}
    >
      <Grid container display="flex">
        <Grid
          item
          height={isMdDown && !isSmDown ? "20vh" : "25vh"}
          xs={12}
          lg={12}
          md={12}
        >
          <img
            height="100%"
            style={{ display: "block" }}
            width="100%"
            src={img}
            alt="company-img"
          />
        </Grid>

        {isCompany && rest.paid === "Paid" && !isAuthenticated && (
          <>
            <Grid
              item
              height={isMdDown && !isSmDown ? "20vh" : "25vh"}
              bgcolor={lockBackgroundColor}
              overflow="hidden"
              xs={12}
              lg={12}
              md={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={3}
              >
                <Grid item xs={8} md={8} lg={8}>
                  <Typography
                    fontSize={cardTitleFontSize}
                    color={theme.palette.getContrastText(lockBackgroundColor)}
                    fontWeight={600}
                    textAlign="center"
                  >
                    Login to view company
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={8}
                  lg={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Fab
                    variant="extended"
                    sx={{
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: lockBackgroundColor,
                      backgroundColor:
                        theme.palette.getContrastText(lockBackgroundColor),
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color:
                          theme.palette.getContrastText(lockBackgroundColor),
                        backgroundColor: lockBackgroundColor,
                      },
                      border: "1px solid",
                      borderColor:
                        theme.palette.getContrastText(lockBackgroundColor),
                      boxShadow: "none",
                    }}
                  >
                    Login
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Fab
                aria-label="login"
                sx={{
                  color: lockBackgroundColor,
                  backgroundColor:
                    theme.palette.getContrastText(lockBackgroundColor),
                  boxShadow: "none",
                }}
              >
                <LockRoundedIcon fontSize="large" />
              </Fab>
            </div>
          </>
        )}
        {isAuthenticated &&
        isCompany &&
        rest.paid === "Paid" &&
        !isSubscribed ? (
          <>
            <Grid
              item
              height={isMdDown && !isSmDown ? "20vh" : "25vh"}
              bgcolor={lockBackgroundColor}
              overflow="hidden"
              xs={12}
              lg={12}
              md={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={8} md={8} lg={8}>
                  <Typography
                    fontSize={cardTitleFontSize}
                    color={theme.palette.getContrastText(lockBackgroundColor)}
                    fontWeight={600}
                    textAlign="center"
                  >
                    Want to read this?
                  </Typography>
                </Grid>
                <Grid item xs={8} md={8} lg={8}>
                  <Typography
                    fontSize={cardParagraphFontSize}
                    color={theme.palette.getContrastText(lockBackgroundColor)}
                    fontWeight={400}
                    textAlign="center"
                  >
                    Become a paid member and get unlimited access.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  md={8}
                  lg={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Fab
                    variant="extended"
                    sx={{
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: lockBackgroundColor,
                      backgroundColor:
                        theme.palette.getContrastText(lockBackgroundColor),
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color:
                          theme.palette.getContrastText(lockBackgroundColor),
                        backgroundColor: lockBackgroundColor,
                      },
                      border: "1px solid",
                      borderColor:
                        theme.palette.getContrastText(lockBackgroundColor),
                      boxShadow: "none",
                    }}
                  >
                    Paid Article
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Fab
                aria-label="subscribe"
                sx={{
                  color: lockBackgroundColor,
                  backgroundColor:
                    theme.palette.getContrastText(lockBackgroundColor),
                  boxShadow: "none",
                }}
              >
                <LockRoundedIcon fontSize="large" />
              </Fab>
            </div>
          </>
        ) : (
          <Grid
            item
            xs={12}
            lg={12}
            md={12}
            height={isMdDown && !isSmDown ? "20vh" : "25vh"}
            display="flex"
          >
            <Grid container display="flex" alignItems="stretch">
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                display="flex"
                alignItems="center"
              >
                <Grid container display="flex">
                  <Grid item xs={12} lg={12} md={12}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          fontSize={cardTitleFontSize}
                          fontWeight={700}
                        >
                          {isCompany
                            ? isXlDown && name.length > 16
                              ? `${name.slice(0, 13)}...`
                              : name
                            : title}
                        </Typography>
                      </Grid>
                      {isCompany && (
                        <Grid
                          item
                          display="flex"
                          fontSize={cardTitleFontSize}
                          fontWeight={500}
                        >
                          ₹
                          <AnimatingNumber
                            value={counter2 ? counter2 : "0000"}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isCompany && (
                    <Grid item xs={12} lg={12} md={12}>
                      <Grid
                        container
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            fontSize={cardParagraphFontSize}
                            fontWeight={500}
                          >
                            {gainValue >= 0 ? "+" : "-"}INR
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          display="flex"
                          fontSize={cardParagraphFontSize}
                          fontWeight={500}
                        >
                          ₹<AnimatingNumber value={gainValue} />
                        </Grid>
                        <Grid
                          item
                          display="flex"
                          fontSize={cardParagraphFontSize}
                          fontWeight={500}
                        >
                          (₹
                          <AnimatingNumber value={gainPercentage} />
                          %)
                        </Grid>
                        {valueDate && (
                          <Grid item display="flex">
                            <Typography
                              fontSize={cardParagraphFontSize}
                              fontWeight={500}
                              lineHeight={1}
                              textTransform="capitalize"
                            >
                              {new Date(valueDate).toLocaleDateString(
                                "en-US",
                                dateOptions,
                              )}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {!isCompany && relative_index && (
                    <Grid item xs={12} lg={12} md={12}>
                      <Typography
                        textTransform="capitalize"
                        fontSize={cardParagraphFontSize}
                        fontWeight={600}
                      >
                        Relative Index: {relative_index}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                display="flex"
                alignItems="center"
              >
                {content && (
                  <Typography
                    fontSize={cardParagraphFontSize}
                    color="GrayText"
                    fontWeight={300}
                  >
                    {content.length > 120
                      ? `${content.slice(0, 120)}...`
                      : content}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                display="flex"
                alignItems="center"
              >
                {date && (
                  <Typography
                    textTransform="capitalize"
                    fontSize={cardParagraphFontSize}
                    fontWeight={600}
                  >
                    published on{" "}
                    {new Date(date).toLocaleDateString("en-US", dateOptions)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Card;
