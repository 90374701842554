import axios from "axios";
import { useCallback, useState } from "react";

const useUserReferralsList = (referrals, fetchUser) => {
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const lastIndex = page * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentReferrals = referrals.slice(firstIndex, lastIndex);
  const handlePageChange = (_, value) => {
    setPage(value);
  };
  const [referral, setReferral] = useState(undefined);
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((referral) => {
    setReferral(referral);
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setReferral(undefined);
    setOpen(false);
  }, []);
  const token = localStorage.getItem("code");
  const deleteReferral = useCallback(
    (id) => {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}referrals/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          fetchUser();
        })
        .catch((e) => {
          alert(e.message);
        });
    },
    [fetchUser, token],
  );
  return {
    currentReferrals,
    itemsPerPage,
    page,
    handlePageChange,
    referral,
    open,
    handleOpen,
    handleClose,
    deleteReferral,
  };
};
export default useUserReferralsList;
