import {
  Box,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useAppSelector } from "../../store/hooks";
import Button from "../Button/Button";

const WelcomeModal = ({ open, handleClose, handleNext, message, title }) => {
  const darkMode = useAppSelector((store) => store.theme.dark);

  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>{title}:</Typography>
            <Typography sx={{ cursor: "pointer" }} onClick={handleClose}>
              {"\u2715"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
          <Grid
            container
            mt={1}
            spacing={2}
            display={"flex"}
            justifyContent={isMdDown ? "center" : "end"}
          >
            <Grid item>
              <Button onClick={handleClose} text={"Remind me later"} />
            </Grid>
            <Grid item>
              <Button onClick={handleNext} text={"Yes"} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default WelcomeModal;
