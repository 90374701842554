import React, { useEffect, useState } from "react";
import "./style.scss";
import { useGetAllBlogsQuery } from "../../redux/BlogsAPI";
import { useHistory } from "react-router-dom";
import Card from "../../Components/Card/Card";
import SecImage from "../../Assets/image section.png";
import SkeletonSlider from "../../Components/Skeleton card/SkeletonSlider";
import LinearProgressBar from "../../Components/LinearProgressBar";

export default function Blog() {
  const { data: blog, isLoading, isSuccess } = useGetAllBlogsQuery();
  const history = useHistory();
  const [blogPosts, setBlogPosts] = useState([]);
  function decodeHTMLEntities(text) {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(
      `<!doctype html><body>${text}`,
      "text/html",
    ).body.textContent;
    return decodedString;
  }

  useEffect(() => {
    if (blog && blog.data) {
      const sortedData = blog.data.slice(0, 6);
      setBlogPosts(
        sortedData.map((blogData) => ({
          id: blogData.id,
          title: decodeHTMLEntities(blogData.title.rendered),
          featMedia: blogData.jetpack_featured_media_url,
        })),
      );
    }
  }, [blog]);
  const renderCards = () => {
    const cards = [];

    if (blogPosts && blogPosts.length) {
      for (let i = 0; i < blogPosts.length; i++) {
        const { title, featMedia, id } = blogPosts[i];
        cards.push(
          <div key={id}>
            <Card
              key={i}
              Id={id}
              img={featMedia || SecImage}
              title={undefined}
              price={undefined}
              isCompany={false}
              onClick={() => history.push(`/blog/${id}`)}
              rate={undefined}
              content={title}
              date={undefined}
            />
          </div>,
        );
      }
    }

    return cards;
  };
  return (
    <div className="sectors-container">
      {isLoading && !isSuccess ? (
        <>
          <LinearProgressBar />
          <SkeletonSlider />
        </>
      ) : (
        <>
          <div className="container open-header">
            <h1 className="fs-85 text-slide-up-animation-1">Blogs</h1>
          </div>
          <div className="cards-container">{renderCards()}</div>
        </>
      )}
    </div>
  );
}
