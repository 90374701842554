import axios from "axios";
import React from "react";
import Button from "../../../Components/Button/Button";

const DownloadInvoice = () => {
  const token = localStorage.getItem("code");
  const downloadInvoice = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}subscription/generate-invoice`,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_BASE_URL}subscription/download-invoice/${response.data}`;
    link.setAttribute("download", true);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return <Button onClick={downloadInvoice}>Invoice</Button>;
};

export default DownloadInvoice;
