import { createTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";

const useNextStepModal = ({ id, handleClose }) => {
  const [gstCredentials, setGstCredentials] = useState(null);

  const history = useHistory();

  const fetchGstCredentials = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user/gst-credentials`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) setGstCredentials(res.data);
      })
      .catch((e) => console.error("Error fetching gst credentials:", e));
  }, []);
  useEffect(() => {
    fetchGstCredentials();
  }, [fetchGstCredentials]);

  const [openGstForm, setOpenGstForm] = useState(false);
  const handleModalOpenGstForm = useCallback(() => {
    if (gstCredentials) history.push(`/plan-subscription/${id}`);
    else setOpenGstForm(true);
  }, [gstCredentials, history, id]);
  const handleModalCloseGstForm = () => {
    setOpenGstForm(false);
    handleClose();
    history.push(`/plan-subscription/${id}`);
  };
  const handleNextStepModalClose = () => {
    handleClose();
    history.push(`/plan-subscription/${id}`);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);

  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    gstCredentials,
    fetchGstCredentials,
    openGstForm,
    handleModalOpenGstForm,
    handleModalCloseGstForm,
    themeProv,
    handleNextStepModalClose,
  };
};

export default useNextStepModal;
