import React, { useContext } from "react";
import { SubscriptionScheduleContext } from "../../../Contexts/SubscriptionSchedule";
import SubscriptionScheduleForm from "../../../Contexts/SubscriptionSchedule/Form";

const AddSubscriptionSchedule = () => {
  const { createSchedule } = useContext(SubscriptionScheduleContext);
  return <SubscriptionScheduleForm submitData={createSchedule} />;
};

export default AddSubscriptionSchedule;
