import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import GstModal from "../../../Screens/Profile/GstModal";
import Button from "../../Button/Button";
import useNextStepModal from "./useNextStepModal";

const NextStepModal = ({ open, handleClose, id }) => {
  const {
    themeProv,
    handleModalOpenGstForm,
    openGstForm,
    handleModalCloseGstForm,
    fetchGstCredentials,
    gstCredentials,
    handleNextStepModalClose,
  } = useNextStepModal({ id, handleClose });
  return (
    <ThemeProvider theme={themeProv}>
      <GstModal
        gstCredentials={gstCredentials}
        fetchGstCredentials={fetchGstCredentials}
        open={openGstForm}
        handleClose={handleModalCloseGstForm}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>GST Credentials:</Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => handleClose()}
            >
              {"\u2715"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Would you like to provide your GST credentials.
          </Typography>
          <Grid container spacing={2} display={"flex"} justifyContent={"end"}>
            <Grid item>
              <Button onClick={handleNextStepModalClose} text={"No"} />
            </Grid>
            <Grid item>
              <Button onClick={handleModalOpenGstForm} text={"Yes"} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default NextStepModal;
