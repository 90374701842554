import { useForm } from "react-hook-form";
import { useGetTagsQuery } from "../../../redux/TagsAPI";
import { useEffect, useMemo, useState } from "react";
import { useGetSectorsQuery } from "../../../redux/SectorsAPI";
import { useGetCategoriesQuery } from "../../../redux/HomeAPI";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import dayjs from "dayjs";
import useCompanyListParser from "../../../utils/useCompanyListParser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useAddCompany = () => {
  const { data: fetchedTags } = useGetTagsQuery();
  const { data: fetchedSectors } = useGetSectorsQuery();
  const { data: fetchedCategories } = useGetCategoriesQuery();
  const [availableTags, setAvailableTags] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (fetchedTags && fetchedTags.length)
      setAvailableTags(
        fetchedTags.map((tag) => ({ id: tag.Id, name: tag.name })),
      );
  }, [fetchedTags]);
  useEffect(() => {
    if (fetchedSectors?.success && fetchedSectors.payload.length)
      setSectors(
        fetchedSectors.payload.map((sector) => ({
          value: sector.Id,
          name: sector.name,
        })),
      );
  }, [fetchedSectors]);
  useEffect(() => {
    if (fetchedCategories?.success && fetchedCategories.payload.length)
      setCategories(
        fetchedCategories.payload.map((category) => ({
          value: category.Id,
          name: category.name,
        })),
      );
  }, [fetchedCategories]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      code: "",
      g_f_code: "",
      price_suggested: "",
      pe: "",
      heading: "",
      seo_descriptions: "",
      relative_index: "",
      image: "",
      paid: "",
      description: "",
      blogId: "",
      sectorId: "",
      categoryId: "",
      name: "",
      bse: "",
      conclusion: "",
      reportLink: "",
      isPasswordProtected: false,
      date: dayjs(),
      tags: [],
    },
  });

  const { parseCompanyData } = useCompanyListParser();
  const token = localStorage.getItem("code");

  const addHomepage = (data) => {
    axios
      .post(
        `${REACT_APP_BASE_URL}company-list`,
        { companyList: data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-list-companies");
      })
      .catch((e) => console.error("Error fetching companies:", e));
  };

  const onSubmit = (data) => {
    addHomepage(parseCompanyData(data));
    reset({
      code: "",
      g_f_code: "",
      price_suggested: "",
      pe: "",
      heading: "",
      seo_descriptions: "",
      relative_index: "",
      image: "",
      paid: "",
      description: "",
      blogId: "",
      sectorId: "",
      categoryId: "",
      name: "",
      bse: "",
      conclusion: "",
      reportLink: "",
      isPasswordProtected: false,
      date: dayjs(),
      tags: [],
    });
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    availableTags,
    sectors,
    categories,
    errors,
    themeProv,
  };
};
export default useAddCompany;
