import React from "react";

const GooglePaySVG = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9959 44.629C21.6989 45.6086 23.7208 45.8732 25.6185 45.365C27.5163 44.8569 29.1353 43.6173 30.1209 41.9178L39.5259 25.6503C39.7922 25.1894 39.9649 24.6806 40.0344 24.1529C40.1038 23.6252 40.0686 23.089 39.9307 22.575C39.7928 22.0609 39.5549 21.5791 39.2306 21.157C38.9064 20.735 38.5021 20.381 38.0409 20.1153L35.2284 18.4953M19.6247 44.4153C18.0774 43.519 16.9489 42.0455 16.487 40.318C16.0251 38.5906 16.2674 36.7504 17.1609 35.2015L24.2822 22.8265C24.8163 21.8968 24.9599 20.7931 24.6815 19.7577C24.4031 18.7222 23.7255 17.8394 22.7972 17.3028L17.6222 14.3215C17.1632 14.0536 16.6558 13.8791 16.1291 13.8082C15.6024 13.7373 15.0669 13.7713 14.5535 13.9083C14.04 14.0453 13.5587 14.2826 13.1374 14.6064C12.716 14.9303 12.3629 15.3343 12.0984 15.7953L6.32718 25.8753C5.08699 28.0382 4.75411 30.6043 5.40138 33.0121C6.04866 35.4199 7.62338 37.4732 9.78093 38.7228L13.7859 41.0403L19.6247 44.4153Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.2209 15.5476C46.0656 16.0326 46.8063 16.6795 47.4005 17.4513C47.9947 18.223 48.4307 19.1044 48.6837 20.045C48.9366 20.9856 49.0014 21.9669 48.8744 22.9326C48.7473 23.8983 48.431 24.8294 47.9434 25.6726L41.3059 37.1251C40.7672 38.0542 39.8823 38.7321 38.845 39.0104C37.8077 39.2887 36.7024 39.1447 35.7709 38.6101L32.9584 36.9789M20.4372 15.9751L22.1022 13.0951C23.3562 10.9269 25.4197 9.34518 27.8392 8.69757C30.2588 8.04996 32.8365 8.38942 35.0059 9.64136L39.0109 11.9589L44.8497 15.3339C43.2994 14.4413 41.4583 14.2006 39.7306 14.6647C38.003 15.1288 36.5303 16.2596 35.6359 17.8089L25.8709 34.6501C25.3343 35.5784 24.4515 36.2561 23.416 36.5344C22.3806 36.8128 21.2769 36.6692 20.3472 36.1351L17.5234 34.5039"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GooglePaySVG;
