import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../Screens/Auth/AuthContext";

const PublicRoute = ({ path, component }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      exact
      path={path}
      render={() => (!isAuthenticated ? component : <Redirect to="/" />)}
    />
  );
};

export default PublicRoute;
