import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import AccordionCard from "../../Components/Accordion Card/AccordionCard";
import CustomAccordion from "../../Components/Accordion/CustomAccordion";
import Button from "../../Components/Button/Button";
import SlideUp from "../../Components/SlideUp";
import "./style.scss";
// import axios from "axios";
import PricingCard from "../../Components/PricingCard";
import advisories from "../List Page/advisoriesList.json";
import usePricing from "./usePricing";

const REACT_APP_WHATSAPP_LINK = process.env.REACT_APP_WHATSAPP_LINK;
const REACT_APP_INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK;
// const REACT_APP_ADVISORIES_LINK = process.env.REACT_APP_ADVISORIES_LINK;
export default function Pricing() {
  const {
    history,
    headerFontSize,
    mediumHeaderFontSize,
    paragraphFontSize,
    plans,
    isSmDown,
  } = usePricing();
  return (
    <Box>
      <Box mt={12} display="flex" justifyContent="center">
        <Box width="90vw" display="flex" flexDirection="column">
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Grid item>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Pricing</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            justifyContent="center"
            marginTop={12}
            marginBottom={24}
            spacing={6}
          >
            {plans &&
              plans.length !== 0 &&
              plans.map((plan) => (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="center"
                  key={plan.id}
                >
                  <SlideUp>
                    <PricingCard {...plan} />
                  </SlideUp>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={6} xs={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Past Performance</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="start"
            spacing={5}
          >
            <Grid item xs={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  Kindly note that past performance does not assure future
                  returns. We refrain from offering any tips or investment
                  advice. Our articles merely highlight companies that have
                  demonstrated strong performance, sometimes benefiting from
                  favorable circumstances. Additionally, all ideas presented are
                  for long-term investment, spanning at least 2 to 3 years.
                </SlideUp>
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <a href={REACT_APP_INSTAGRAM_LINK}>
                <Button text="Performance" className={" "}></Button>
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="20vh"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                mb={1}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>
                  Do you feel that our prices are too high?
                </SlideUp>
              </Typography>
            </Grid>
            <Grid item marginY={6} lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1}
              >
                Take a look at what all others out there are charging.
              </Typography>
            </Grid>
          </Grid>
          <Grid container display="flex" flexDirection="column" spacing={5}>
            <Grid item lg={12} md={12} sm={12}>
              <Grid
                container
                fontSize={mediumHeaderFontSize}
                display="flex"
                justifyContent="space-between"
              >
                <Grid item>
                  Name{" "}
                  <AiOutlineArrowDown className="icon-mobile" fontSize={35} />
                </Grid>
                <Grid item display="flex" flexDirection="column">
                  <Box>
                    Price{" "}
                    <AiOutlineArrowDown className="icon-mobile" fontSize={35} />
                  </Box>
                  <Typography>per year</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                fontSize={paragraphFontSize}
                display="flex"
                flexDirection="column"
              >
                {advisories &&
                  advisories.length !== 0 &&
                  advisories.slice(0, 5).map((item, idx) => (
                    <Grid item key={idx}>
                      <CustomAccordion
                        right={item.name}
                        left={`Rs ${item.chargesPerYear}`}
                        card={
                          <AccordionCard
                            approx={item.pricePerStock}
                            numberOfStocks={item.noOfStocks}
                            plans={item.plans}
                            sebiRegistered={item.sebiRegistration}
                            freeTrial={item.freeTrial}
                            typeOfService={item.typeOfService}
                          />
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
              <Grid
                container
                fontSize={paragraphFontSize}
                display="flex"
                justifyContent="center"
                mt={"3vh"}
              >
                <Grid item>
                  <Button
                    text="View all"
                    onClick={() => {
                      history.push("/list-page");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={6} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>And here’s the Irony</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  None of the above advisories provide this many articles, many
                  of them dont even provide with a detailed research report and
                  almost none of them provide with the real time dashboard like
                  darkhorsestocks.
                </SlideUp>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Plans for the coming year</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={7} md={12} sm={12}>
              <Grid container spacing={1} display="flex" flexDirection="column">
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Establish a system for handling funds, primarily
                      focusing on small ticket sizes ranging from 2 to 5 lakhs.
                    </SlideUp>
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Initiate work on fund analysis, engaging in
                      discussions with developers to explore the implementation
                      of LLM for more sophisticated queries.
                    </SlideUp>
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Develop the darkhorsestocks dashboard from the
                      ground up, aiming to enhance its performance.
                    </SlideUp>
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Create a distinct dashboard tailored for
                      penny-cap companies.
                    </SlideUp>
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Improve the existing darkhorestocks website by
                      enhancing its speed, performance, and efficiency, while
                      ensuring SEO compliance.
                    </SlideUp>
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Draft a dashboard for potential fund management
                      endeavours.
                    </SlideUp>
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <Typography
                    fontWeight={300}
                    fontSize={paragraphFontSize}
                    lineHeight={1.2}
                    display="flex"
                    alignItems="start"
                  >
                    <SlideUp>
                      &#x2022; Develop a separate platform to further educate
                      individuals on existing Portfolio Management Services
                      (PMS).
                    </SlideUp>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={6} xs={12}>
              <Typography
                fontWeight={600}
                lineHeight={1}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Still not Convinced?</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="start"
            spacing={5}
          >
            <Grid item lg={8} xs={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  Subscribe to our free plan for all small users where we
                  suggest one company every month or around 10-12 companies
                  every year and one infographic every week.
                </SlideUp>
              </Typography>
            </Grid>
            <Grid
              item
              lg={8}
              xs={12}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <a href={REACT_APP_WHATSAPP_LINK}>
                <Button text="WhatsApp" className={" "}></Button>
              </a>

              <Typography mt={2} fontWeight={500} lineHeight={1}>
                No spam advertising
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
