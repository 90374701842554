import {
  Box,
  Card,
  CardContent,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../../Components/Button/Button";
import useListPlans from "./useListPlans";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { StyledTableContainer } from "../InsertedUsersList";
import DHSwitch from "../../../Components/DHSwitch";
const ListPlans = () => {
  const {
    handleSearchSubmit,
    searchKeyword,
    handleSearchChange,
    history,
    plans,
    handlePreviousPage,
    currentPage,
    lockBackgroundColor,
    theme,
    totalPages,
    handleNextPage,
    handleDelete,
    isMdDown,
    togglePlanStatus,
  } = useListPlans();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginY="10vh"
    >
      <Box
        width="90vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={4}
          >
            <form onSubmit={handleSearchSubmit}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <Box className="d-flex input-wrapper">
                    <Box className="input-line-animation__wrapper">
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        value={searchKeyword}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                      />
                      <div className="input-line-animation__line"></div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Button onClick={handleSearchSubmit} text="Search" />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => history.push("/admin-add-plan")}
                    text="Add Plan"
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            {isMdDown ? (
              <Grid container spacing={2}>
                {plans.map((plan) => (
                  <Grid key={plan.id} item xs={12} sm={6} md={4}>
                    <Card
                      onClick={() =>
                        history.push(`/admin-edit-inserted-user/${plan.id}`)
                      }
                    >
                      <CardContent>
                        <Grid
                          container
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography variant="h6">
                                  ID: {plan.id}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>Name:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{plan.name}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>
                                  Amount:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{plan.amount}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>
                                  DarkHorseStocks:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {plan.darkHorseStocks === true ? "Yes" : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>
                                  Fundalysis:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {plan.fundalysis === true ? "Yes" : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>
                                  DarkHorseStocksUSA:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {plan.darkHorseStocksUSA === true
                                    ? "Yes"
                                    : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item>
                                <Typography fontWeight={600}>PMS:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  {plan.PMS === true ? "Yes" : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="end"
                            alignItems="center"
                            xs={12}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(plan.id);
                              }}
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <StyledTableContainer component={Paper}>
                <Table
                  sx={{
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderBottom: 1,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Amount</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        DarkHorseStocks
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Fundalysis</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        DarkHorseStocksUSA
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>PMS</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Active Plan
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plans.map((plan) => (
                      <TableRow
                        key={plan.id}
                        sx={{
                          "& td, & th": {
                            border: 0, // Set your desired border styling here
                          },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(`/admin-edit-plan/${plan.id}`)
                        }
                      >
                        <TableCell>{plan.id}</TableCell>
                        <TableCell>{plan.name}</TableCell>
                        <TableCell>{plan.amount}</TableCell>
                        <TableCell>
                          {plan.darkHorseStocks === true ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {plan.fundalysis === true ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {plan.darkHorseStocksUSA === true ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {plan.PMS === true ? "Yes" : "No"}
                        </TableCell>

                        <TableCell>
                          <DHSwitch
                            defaultChecked={plan.isActive}
                            onChange={() => {
                              togglePlanStatus(plan.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(plan.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              {/* currentPage !== 1 */}
              <Grid item>
                <Fab
                  onClick={handlePreviousPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === 1 ? "default" : "pointer",
                    color: currentPage === 1 ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === 1
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === 1
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Previous
                </Fab>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: lockBackgroundColor,
                  }}
                >
                  {currentPage} / {totalPages}
                </Typography>
              </Grid>
              <Grid item>
                <Fab
                  onClick={handleNextPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === totalPages ? "default" : "pointer",
                    color:
                      currentPage === totalPages ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === totalPages
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === totalPages
                          ? "#b5b5b5"
                          : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Next
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListPlans;
