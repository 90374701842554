import React, { useContext } from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import "./style.scss";
import AuthContext from "../../Screens/Auth/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useAppSelector } from "../../store/hooks";
import { useMediaQuery, useTheme } from "@mui/material";

export default function NavBar() {
  const { logout, isAuthenticated, handleLinkTo } = useContext(AuthContext);
  const history = useHistory();
  const handleLogout = () => {
    logout();
  };
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const darkMode = useAppSelector((store) => store.theme.dark);
  return (
    <div className="d-flex justify-content-between container-nav align-items-center">
      <div
        className="logo"
        data-cursor-text="Home"
        onClick={() => history.push("/")}
      >
        DARKHORSESTOCKS
      </div>
      <div className="icons d-flex justify-content-between align-items-center">
        <div
          className="icon search-icon content__item Social-item nav-link-wrapper"
          data-cursor="-menu -icon-blend"
        >
          <Link to={handleLinkTo("/search")} className="nav-link">
            <em className="nav-link-hover-wrapper">
              <span
                data-text="search"
                className="nav-link-hover"
                data-movement="0.2"
              >
                Search
              </span>
              <div className="magnetic-wrap mobile-icon-menu">
                <div className="magnetic-area"></div>
                <span
                  className="nav-link-hover magnetic-content"
                  data-movement="0.2"
                >
                  <SearchIcon
                    sx={{
                      color: darkMode ? "#fff" : "#000",
                    }}
                  ></SearchIcon>
                </span>
              </div>
            </em>
          </Link>
        </div>
        {isAuthenticated ? (
          <div
            onClick={handleLogout}
            className="icon user-icon content__item Social-item nav-link-wrapper"
            data-cursor="-menu -icon-blend"
          >
            <Link to={handleLinkTo("/")} className="nav-link">
              <em className="nav-link-hover-wrapper">
                <span
                  data-text="logout"
                  className="nav-link-hover"
                  data-movement="0.2"
                >
                  logout
                </span>
                <div className="magnetic-wrap mobile-icon-menu">
                  <div className="magnetic-area"></div>
                  <span
                    className="nav-link-hover magnetic-content"
                    data-movement="0.2"
                  >
                    <LogoutIcon
                      sx={{
                        color: darkMode ? "#fff" : "#000",
                      }}
                    ></LogoutIcon>
                  </span>
                </div>
              </em>
            </Link>
          </div>
        ) : (
          <div
            className="icon user-icon content__item Social-item nav-link-wrapper"
            data-cursor="-menu -icon-blend"
          >
            <Link to="/login" className="nav-link">
              <em className="nav-link-hover-wrapper">
                <span
                  data-text="login"
                  className="nav-link-hover"
                  data-movement="0.2"
                >
                  login
                </span>
                <div className="magnetic-wrap mobile-icon-menu">
                  <div className="magnetic-area"></div>
                  <span
                    className="nav-link-hover magnetic-content"
                    data-movement="0.2"
                  >
                    <LoginIcon
                      sx={{
                        color: darkMode ? "#fff" : "#000",
                      }}
                    ></LoginIcon>
                  </span>
                </div>
              </em>
            </Link>
          </div>
        )}
        {!isSmDown && (
          <div>
            <div
              className="icon user-icon content__item Social-item nav-link-wrapper"
              id="hamburgerMenuLabel"
              data-cursor="-menu -icon-blend"
            >
              <div className="nav-link">
                <em className="nav-link-hover-wrapper">
                  <span
                    data-text="menu"
                    className="nav-link-hover"
                    data-movement="0.2"
                  >
                    menu
                  </span>
                </em>
              </div>
            </div>
          </div>
        )}
        <HamburgerMenu />
      </div>
    </div>
  );
}
