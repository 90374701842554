import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useUserReferralsList from "./useUserReferralsList";
import ReferralModal from "./ReferralModal";
import Button from "../../../../Components/Button/Button";

const UserReferralsList = ({ referrals, fetchUser, userId }) => {
  const {
    currentReferrals,
    itemsPerPage,
    page,
    handlePageChange,
    referral: editableReferral,
    open,
    handleOpen,
    handleClose,
    deleteReferral,
  } = useUserReferralsList(referrals, fetchUser);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Paper sx={{ padding: 2, width: "100%" }}>
        <Grid container display="flex" pb={2}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Button text="Add new +" onClick={() => handleOpen()} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {referrals && referrals.length !== 0 ? (
            isMdDown ? (
              <Grid
                container
                display="flex"
                justifyContent="center"
                spacing={2}
                pt={2}
              >
                {currentReferrals
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((referral) => (
                    <Grid
                      item
                      display="flex"
                      justifyContent="center"
                      xs={12}
                      key={referral.id}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          border: (theme) =>
                            `1px solid ${alpha(
                              theme.palette.getContrastText(
                                theme.palette.background.default,
                              ),
                              0.2,
                            )}`,
                          "&:hover": {
                            backgroundColor: (theme) =>
                              alpha(
                                theme.palette.getContrastText(
                                  theme.palette.background.default,
                                ),
                                0.2,
                              ),
                          },
                        }}
                        onClick={() => {
                          handleOpen(referral);
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ID: {referral.id}
                          </Typography>
                          <Typography>Code: {referral.code}</Typography>
                          <Typography>
                            Email referral:{" "}
                            {referral.referralByEmail ? "Yes" : "No"}
                          </Typography>
                          <Typography>
                            Phone referral:{" "}
                            {referral.referralByPhoneNumeber ? "Yes" : "No"}
                          </Typography>
                          <Typography>
                            Custom referral:{" "}
                            {referral.customReferral ? "Yes" : "No"}
                          </Typography>
                          <Typography>
                            Discount Percentage: {referral.discount}%
                          </Typography>
                          <Typography>
                            Comission: {referral.commission || 0} Rs
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteReferral(referral.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={Math.ceil(currentReferrals.length / itemsPerPage)}
                      page={page}
                      onChange={handlePageChange}
                      sx={{
                        backgroundColor: "transparent !important",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Table
                  sx={{
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderBottom: 1,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Code</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Email referral
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Phone referral
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Custom referral
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Discount Percentage
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Comission</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentReferrals.map((referral) => (
                      <TableRow
                        key={referral.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: (theme) =>
                              alpha(
                                theme.palette.getContrastText(
                                  theme.palette.background.default,
                                ),
                                0.2,
                              ),
                          },
                          "& td, & th": {
                            border: 0,
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleOpen(referral);
                        }}
                      >
                        <TableCell>{referral.id}</TableCell>
                        <TableCell>{referral.code}</TableCell>
                        <TableCell>
                          {referral.referralByEmail ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {referral.referralByPhoneNumeber ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {referral.customReferral ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>{referral.discount}%</TableCell>
                        <TableCell>{referral.commission || 0} Rs</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteReferral(referral.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{
                        "& td, & th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan={9}>
                        <Box display="flex" justifyContent="flex-end">
                          <Pagination
                            count={Math.ceil(referrals.length / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            sx={{
                              backgroundColor: "transparent !important",
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )
          ) : (
            <Grid
              item
              display="flex"
              justifyContent="start"
              alignItems="center"
              xs={12}
            >
              <Typography>This user has no referrals</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      <ReferralModal
        handleClose={handleClose}
        fetchUser={fetchUser}
        open={open}
        referral={editableReferral}
        userId={userId}
      />
    </>
  );
};

export default UserReferralsList;
