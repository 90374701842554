import { createTheme } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAppSelector } from "../../../store/hooks";

const useSubscriptionScheduleForm = (defaultValues, submitData) => {
  const history = useHistory();

  const formMethods = useForm({
    defaultValues: {
      startDate: defaultValues ? dayjs(defaultValues.startDate) : dayjs(),
      endDate: defaultValues
        ? dayjs(defaultValues.endDate)
        : dayjs(dayjs().add(1, "day").format("YYYY-MM-DD")),
    },
  });

  const onSubmit = async (data) => {
    try {
      await submitData({
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
      });
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const darkMode = useAppSelector((store) => store.theme.dark);

  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return {
    formMethods,
    onSubmit,
    themeProv,
  };
};

export default useSubscriptionScheduleForm;
