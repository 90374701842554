import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useMemo } from "react";

const UserGstDetails = ({
  firstName,
  gstNumber,
  gstPhoneNumber,
  gstEmail,
  registeredAddress,
  state,
}) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const fontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <Grid container display={"flex"} justifyContent={"center"} spacing={2}>
      <Grid item xs={12}>
        <Grid container rowSpacing={4}>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize} fontWeight={700}>
              First Name:
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize}>{firstName || "N/A"}</Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize} fontWeight={700}>
              GST Number:
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize}>{gstNumber || "N/A"}</Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize} fontWeight={700}>
              GST Phone Number:
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize}>
              {gstPhoneNumber || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize} fontWeight={700}>
              GST Email:
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize}>{gstEmail || "N/A"}</Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize} fontWeight={700}>
              Registered Address:
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize}>
              {registeredAddress || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize} fontWeight={700}>
              State:
            </Typography>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"start"}>
            <Typography fontSize={fontSize}>{state || "N/A"}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserGstDetails;
