import React from "react";
import useSubscriptionScheduleList from "./useSubscriptionScheduleList";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Card,
  CardContent,
  Fab,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import Button from "../../../Components/Button/Button";
import { adminAddSubscriptionSchedule } from "../../../utils/routingConstants";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  borderRadius: "8px",
  padding: 10,
}));
const SubscriptionScheduleList = () => {
  const {
    subscriptionSchedulesTable,
    isMdDown,
    history,
    handlePreviousPage,
    currentPage,
    handleNextPage,
    handleDelete,
    lockBackgroundColor,
    theme,
    totalPages,
  } = useSubscriptionScheduleList();
  return (
    <Box
      marginY="20vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={isMdDown ? "90vw" : "70vw"}>
        <Grid container spacing={4}>
          <Grid item xs={12} display={"flex"} justifyContent={"end"}>
            <Button onClick={() => history.push(adminAddSubscriptionSchedule)}>
              Add New
            </Button>
          </Grid>
          <Grid item xs={12}>
            {isMdDown ? (
              <Grid container spacing={2}>
                {subscriptionSchedulesTable.map((schedule) => (
                  <Grid key={schedule.id} item xs={12} sm={6} md={4}>
                    <Card
                      onClick={() =>
                        history.push(
                          `/admin-edit-subscription-schedule/${schedule.id}`,
                        )
                      }
                    >
                      <CardContent>
                        <Grid
                          container
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  ID: {schedule.id}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  Start Date:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {format(schedule.startDate, "dd-MMM-yyyy")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  End Date:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {format(schedule.endDate, "dd-MMM-yyyy")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="end"
                            alignItems="center"
                            xs={12}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(schedule.id);
                              }}
                            >
                              <DeleteOutlineIcon color="error" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <StyledTableContainer component={Paper}>
                <Table
                  sx={{
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderBottom: 1,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Start Date</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>End Date</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 600,
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptionSchedulesTable.map((schedule) => (
                      <TableRow
                        key={schedule.id}
                        sx={{
                          "& td, & th": {
                            border: 0, // Set your desired border styling here
                          },
                        }}
                        onClick={() =>
                          history.push(
                            `/admin-edit-subscription-schedule/${schedule.id}`,
                          )
                        }
                      >
                        <TableCell>{schedule.id}</TableCell>
                        <TableCell>
                          {format(schedule.startDate, "dd-MMM-yyyy")}
                        </TableCell>
                        <TableCell>
                          {format(schedule.endDate, "dd-MMM-yyyy")}
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(schedule.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item>
                <Fab
                  onClick={handlePreviousPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === 1 ? "default" : "pointer",
                    color: currentPage === 1 ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === 1
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === 1
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === 1 ? "#b5b5b5" : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Previous
                </Fab>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: lockBackgroundColor,
                  }}
                >
                  {currentPage} / {totalPages}
                </Typography>
              </Grid>
              <Grid item>
                <Fab
                  onClick={handleNextPage}
                  variant="extended"
                  sx={{
                    height: "60px",
                    width: "180px",
                    fontSize: "20px",
                    borderRadius: "100px",
                    cursor: currentPage === totalPages ? "default" : "pointer",
                    color:
                      currentPage === totalPages ? "#000" : lockBackgroundColor,
                    backgroundColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : theme.palette.getContrastText(lockBackgroundColor),
                    textTransform: "capitalize",
                    fontWeight: 600,
                    "&:hover": {
                      color:
                        currentPage === totalPages
                          ? "#000"
                          : theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor:
                        currentPage === totalPages
                          ? "#b5b5b5"
                          : lockBackgroundColor,
                    },
                    border: "1px solid",
                    borderColor:
                      currentPage === totalPages
                        ? "#b5b5b5"
                        : lockBackgroundColor,
                    boxShadow: "none",
                  }}
                >
                  Next
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionScheduleList;
