import { createTheme, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import countryCodes from "../../Auth/countryCodes.json";
import axios from "axios";
import AuthContext from "../../Auth/AuthContext";

const useEditUserDetails = (user, handleClose) => {
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const { fetchMe } = useContext(AuthContext);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const formMethods = useForm({
    defaultValues: {
      name: user.name ?? undefined,
      email: user.email ?? undefined,
      phone: user.phone ?? undefined,
      password: user.password ?? undefined,
      verifyPassword: user.password ?? undefined,
    },
  });
  const fullCredentials = useMemo(
    () => user.name && user.email && user.phone && user.password,
    [user],
  );
  useEffect(() => {
    formMethods.reset({
      name: user.name ?? undefined,
      email: user.email ?? undefined,
      phone: user.phone ?? undefined,
      password: user.password ?? undefined,
      verifyPassword: user.password ?? undefined,
    });
  }, [formMethods, user]);
  const token = localStorage.getItem("code");

  const onSubmit = async (data) => {
    if (!fullCredentials) {
      const updatedData = {};
      if (!user.name) updatedData.name = data.name;
      if (!user.email) updatedData.email = data.email;
      if (!user.phone) updatedData.phone = `${data.countryCode}${data.phone}`;
      if (!user.password) updatedData.password = data.password;
      try {
        axios
          .put(`${process.env.REACT_APP_BASE_URL}user/`, updatedData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => fetchMe());
      } catch (error) {
        console.log(error);
      }
    }
    handleClose();
  };
  const selectFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "15px" : "22px") : "40px"),
    [isMdDown, isSmDown],
  );
  return {
    themeProv,
    formMethods,
    isMdDown,
    isSmDown,
    onSubmit,
    countryCodes,
    selectFontSize,
  };
};

export default useEditUserDetails;
