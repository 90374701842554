import {
  Box,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  React,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Button from "../../Components/Button/Button";
import "./style.scss";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AuthContext from "./AuthContext";
import { useGoogleLogin } from "@react-oauth/google";
import { Controller, useForm } from "react-hook-form";
import countryCodes from "./countryCodes.json";
import TermsModal from "./TermsModal";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const BASE_URL = process.env.REACT_APP_WS_URL;
// const REACT_APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
// const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const REACT_APP_TWITTER_CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID;
const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;
export default function SignUp() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const darkMode = localStorage.getItem("dark");
  const [color, setColor] = useState(darkMode === "true" ? "white" : "black");
  const history = useHistory();
  const { login } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [signUp, setSignUp] = useState(undefined);
  const handleModalOpen = useCallback((signUpFn) => {
    setSignUp(() => signUpFn);
    setOpen(true);
  }, []);
  const handleModalClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (darkMode === "true") {
      setColor("white");
    } else {
      setColor("black");
    }
  }, [darkMode]);

  useEffect(() => {
    if (scriptLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://otpless.com/auth.js";
      document.body.appendChild(script);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const setupWhatsApp = () => {
      window.otpless = async (otplessUser) => {
        const res = await axios.post(`${BASE_URL}auth/whatsapp/sign-up`, {
          phone: otplessUser?.mobile?.number,
          name: otplessUser?.mobile?.name,
          email: otplessUser?.email?.email,
        });
        if (res.data.error) {
          history.push("/login");
        } else {
          login(res.data.tokens.token, history);
        }
      };
    };
    setupWhatsApp();
  }, [history, login]);

  const handleWhatsappLogin = () => {
    setScriptLoaded(true);
  };
  const selectFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "15px" : "22px") : "40px"),
    [isMdDown, isSmDown],
  );
  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        const res = await axios.post(`${BASE_URL}auth/google`, {
          access_token: code,
          isRegistering: true,
        });

        login(res.data.tokens.token, history);
      } catch (error) {
        alert("Something went wrong!");
      }
    },
    flow: "auth-code",
  });
  // const responseFacebook = async (response) => {
  //   const access_token = response.accessToken;
  //   // send a request to backend that will exchange the code
  //   const res = await axios.post(`${BASE_URL}auth/facebook`, {
  //     access_token,
  //   });

  //   localStorage.setItem('code', res.data.tokens.token);
  //   history.push('/');
  // };
  function getTwitterOauthUrl() {
    const rootUrl = "https://twitter.com/i/oauth2/authorize";
    const options = {
      redirect_uri: `${REACT_APP_WS_URL}auth/twitter`,
      client_id: REACT_APP_TWITTER_CLIENT_ID,
      state: "state",
      response_type: "code",
      code_challenge: "challenge",
      code_challenge_method: "plain",
      scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(
        " ",
      ), // add/remove scopes as needed
    };
    const qs = new URLSearchParams(options).toString();
    window.location.href = `${rootUrl}?${qs}`;
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const onSubmit = async (data) => {
    handleModalOpen(() => formSubmit(data));
  };
  const formSubmit = async (data) => {
    const res = await axios.post(`${BASE_URL}auth/sign-up`, {
      email: data.email,
      password: data.password,
      phone: `${data.countryCode}${data.phoneNumber}`,
    });
    if (res.data.error) alert(res.data.error);
    if (res.data.redirectToLinkAccount)
      history.push(`/postSignup/${res.data.userId}`);
  };

  return (
    <div className="container auth-container">
      <TermsModal
        open={open}
        onClose={handleModalClose}
        title="Sign Up"
        signUp={signUp}
      />
      <div className="d-flex fs-95 auth-wrapper">
        <div
          style={{ color: color }}
          onClick={() => {
            history.push(`/login`);
          }}
        >
          Sign in
        </div>

        <div style={{ margin: "0 10px", color: color }}>/</div>
        <div
          style={{ color: color }}
          onClick={() => {
            history.push(`/register`);
          }}
          className="border-bottom"
        >
          Sign up
        </div>
      </div>{" "}
      <Box marginY={4} display="flex" justifyContent="center">
        <Grid container spacing={2} display="flex" justifyContent="center">
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Button
              onClick={() => handleModalOpen(googleLogin)}
              text="Google"
            />
          </Grid>

          {/* <Grid item display="flex" justifyContent="center" lg={4} md={4} sm={12} xs={12}>
            <FacebookLogin appId={`${REACT_APP_FACEBOOK_APP_ID}`} callback={responseFacebook} render={(renderProps) => <Button text="Facebook" onClick={renderProps.onClick}></Button>} />
        
            </Grid> */}
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Button
              onClick={() => handleModalOpen(getTwitterOauthUrl)}
              text="Twitter"
            />
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="center"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Button
              onClick={() => handleModalOpen(handleWhatsappLogin)}
              text="Whatsapp"
            />
          </Grid>
        </Grid>
      </Box>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width: "80vw" }}
      >
        <hr />
        <div className="fs-30" style={{ margin: "0 10px" }}>
          OR
        </div>{" "}
        <hr />
      </div>
      <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="text"
                    placeholder="Your email"
                    {...field}
                  />
                  <div className="input-line-animation__line"></div>
                </>
              )}
            />
          </Box>
          {errors.email && <span>{errors.email.message}</span>}
        </Box>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="password"
                    placeholder="Your password"
                    {...field}
                  />
                  <Box className="input-line-animation__line"></Box>
                </>
              )}
            />
          </Box>
          {errors.password && <span>{errors.password.message}</span>}
        </Box>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="passwordVerification"
              control={control}
              defaultValue=""
              rules={{
                required: "Password verification is required",
                validate: (value) =>
                  value === getValues("password") || "Passwords do not match",
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="password"
                    placeholder="Verify password"
                    {...field}
                  />
                  <Box className="input-line-animation__line"></Box>
                </>
              )}
            />
          </Box>
          {errors.passwordVerification && (
            <span>{errors.passwordVerification.message}</span>
          )}
        </Box>
        <Grid
          container
          display="flex"
          width="100%"
          columnSpacing={isMdDown ? undefined : 4}
          rowSpacing={isMdDown ? 6 : undefined}
        >
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Controller
              name="countryCode"
              control={control}
              defaultValue="91"
              rules={{
                required: "Country code is required",
              }}
              render={({ field }) => (
                <>
                  <Select
                    sx={{ fontSize: selectFontSize }}
                    variant="standard"
                    className="input-form input-line-animation__ip"
                    placeholder="Select country code"
                    {...field}
                  >
                    {countryCodes.map((code) => (
                      <MenuItem key={code.value + code.name} value={code.value}>
                        {code.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </Grid>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="d-flex input-wrapper">
              <Box className="input-line-animation__wrapper">
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message:
                        "Invalid phone number (only numbers are allowed)",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        placeholder="Phone number"
                        {...field}
                      />
                      <Box className="input-line-animation__line"></Box>
                    </>
                  )}
                />
              </Box>
              {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={isSmDown ? "center" : "start"}
          >
            <Button onClick={handleSubmit(onSubmit)} text="Sign Up" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={isSmDown ? "center" : "end"}
          >
            <p
              onClick={() => history.push("/forgot-password")}
              className="forget-pass"
            >
              Forgot Password?
            </p>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
