import React, { useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../Screens/Auth/AuthContext";

const PrivateRoute = ({ path, component }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    if (!isAuthenticated && location.pathname === "/profile") {
      localStorage.setItem("profileLocation", true);
    }
  }, [location, isAuthenticated]);
  return (
    <Route
      exact
      path={path}
      render={() => (isAuthenticated ? component : <Redirect to="/login" />)}
    />
  );
};

export default PrivateRoute;
