import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../../Components/Button/Button";
import GstForm from "./GstForm";
import useGstModal from "./useGstModal";

const GstModal = ({
  fetchGstCredentials,
  gstCredentials,
  open,
  handleClose,
}) => {
  const { themeProv, defaultValues } = useGstModal({ gstCredentials });
  return (
    <div>
      <ThemeProvider theme={themeProv}>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          fullWidth
          aria-labelledby="terms-and-conditions-dialog"
          aria-describedby="terms-and-conditions-dialog-description"
        >
          <DialogTitle>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Fill Credentials:</Typography>
              <Button onClick={handleClose} text={"Later"} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <GstForm
              fetchGstCredentials={fetchGstCredentials}
              gstCredentials={defaultValues}
              handleClose={handleClose}
            />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default GstModal;
