import "./style.scss";
import CustomAccordion from "../../Components/Accordion/CustomAccordion";
import SubscriptionCard from "./SubscriptionCard";
import { useGetAllPlansQuery } from "../../redux/PlansAPI";
import { useEffect, useState } from "react";
// import { useGetPaytmInitQuery } from '../../redux/PaytmAPI';

export default function SubscriptionOpen() {
  const { data } = useGetAllPlansQuery();
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    data && data.payload && setPlans(data.payload);
  }, [data]);
  return (
    <div className="container" style={{ paddingTop: "100px" }}>
      <div className="container open-header mt-header">
        <h1 className="fs-95">Awesome!</h1>
        <p className="fs-30">You are making the right choice. </p>
      </div>

      <div className="container">
        {plans &&
          plans.length &&
          plans.map((plan) => (
            <CustomAccordion
              expanded={true}
              key={plan.id}
              right={plan.name}
              left=""
              card={
                <SubscriptionCard
                  id={plan.id}
                  description={plan.description}
                  duration={plan.subscriptionDuration}
                  price={plan.amount}
                />
              }
            />
          ))}
      </div>
    </div>
  );
}
