import React from "react";
import useHomePageList from "./useHomePageList";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  styled,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import DHSwitch from "../../../Components/DHSwitch";
import Button from "../../../Components/Button/Button";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4], // Adjust the elevation (box shadow) value as needed
  borderRadius: "8px", // Adjust the border radius as needed
  padding: 10,
}));
const HomePageList = () => {
  const {
    homepagesTable,
    isMdDown,
    handleActivateHomepage,
    handleDeactivateHomepage,
    history,
  } = useHomePageList();
  return (
    <Box
      marginY="20vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={isMdDown ? "90vw" : "70vw"}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              onClick={() => history.push("/admin-add-home-page")}
              text="New +"
            />
          </Grid>
          <Grid item xs={12}>
            {isMdDown ? (
              <Grid container spacing={2}>
                {homepagesTable.map((homepage) => (
                  <Grid key={homepage.id} item xs={12} sm={6} md={4}>
                    <Card
                      onClick={() =>
                        history.push(`/admin-edit-home-page/${homepage.id}`)
                      }
                    >
                      <CardContent>
                        <Grid
                          container
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid
                            item
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  ID: {homepage.id}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  IsActive:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <DHSwitch
                                  defaultChecked={homepage.isActive}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    homepage.isActive
                                      ? handleDeactivateHomepage(
                                          homepage.id,
                                          false,
                                        )
                                      : handleActivateHomepage(homepage.id);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  Sectors:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{homepage.sectors}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>Tags:</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{homepage.tags}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  Categories:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{homepage.categories}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  All Sectors:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {homepage.allSectors ? "True" : "False"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  All Tags:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {homepage.allTags ? "True" : "False"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            xs={6}
                          >
                            <Grid
                              container
                              display="flex"
                              justifyContent="start"
                              spacing={2}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={600}>
                                  All Categories:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {homepage.allCategories ? "True" : "False"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <StyledTableContainer component={Paper}>
                <Table
                  sx={{
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderBottom: 1,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>IsActive</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Sectors</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Tags</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Categories</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        All Sectors
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>All Tags</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        All Categories
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {homepagesTable.map((homepage) => (
                      <TableRow
                        key={homepage.id}
                        sx={{
                          "& td, & th": {
                            border: 0, // Set your desired border styling here
                          },
                        }}
                        onClick={() =>
                          history.push(`/admin-edit-home-page/${homepage.id}`)
                        }
                      >
                        <TableCell>{homepage.id}</TableCell>
                        <TableCell>
                          <DHSwitch
                            defaultChecked={homepage.isActive}
                            onChange={(e) => {
                              homepage.isActive
                                ? handleDeactivateHomepage(homepage.id, false)
                                : handleActivateHomepage(homepage.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>{homepage.sectors}</TableCell>
                        <TableCell>{homepage.tags}</TableCell>
                        <TableCell>{homepage.categories}</TableCell>
                        <TableCell>
                          {homepage.allSectors ? "True" : "False"}
                        </TableCell>
                        <TableCell>
                          {homepage.allTags ? "True" : "False"}
                        </TableCell>
                        <TableCell>
                          {homepage.allCategories ? "True" : "False"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomePageList;
