import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import "antd/dist/antd.min.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Button,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  ThemeProvider,
  Grid,
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import useEditCompany from "./useEditCompany";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditCompany = () => {
  const {
    availableTags,
    onSubmit,
    handleSubmit,
    control,
    sectors,
    categories,
    errors,
    themeProv,
    handleReset,
  } = useEditCompany();
  const theme = useTheme();
  const darkMode = useAppSelector((store) => store.theme.dark);
  const history = useHistory();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  return (
    <>
      <ThemeProvider theme={themeProv}>
        <Box
          marginY="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="90vw">
            <Grid
              container
              display="flex"
              justifyContent="center"
              spacing={2}
              mb={4}
            >
              <Grid item xs={12} md={8}>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      fontSize={mediumHeaderFontSize}
                      fontWeight={600}
                    >
                      Edit Company List
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        height: "60px",
                        width: "180px",
                        fontSize: "20px",
                        borderRadius: "100px",
                        color:
                          theme.palette.getContrastText(lockBackgroundColor),
                        backgroundColor: lockBackgroundColor,
                        textTransform: "capitalize",
                        fontWeight: 600,
                        "&:hover": {
                          color: lockBackgroundColor,
                          backgroundColor:
                            theme.palette.getContrastText(lockBackgroundColor),
                          boxShadow: "none",
                        },
                        border: "1px solid",
                        borderColor: lockBackgroundColor,
                        boxShadow: "none",
                      }}
                      type="button"
                      onClick={() => history.push("/admin-list-companies")}
                      color="primary"
                      variant="contained"
                    >
                      Companies
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={8}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Company Name is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Name"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="bse"
                    control={control}
                    rules={{ required: "BSE is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={Boolean(error)}
                        >
                          <InputLabel id="bse-label">BSE</InputLabel>
                          <Select
                            labelId="bse-label"
                            value={value}
                            label="BSE"
                            onChange={onChange}
                          >
                            <MenuItem value="">Select BSE</MenuItem>
                            <MenuItem value="BSE">BSE</MenuItem>
                            <MenuItem value="NSE">NSE</MenuItem>
                          </Select>
                          {error && (
                            <FormHelperText error>
                              {error ? error.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.date)}
                  >
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: "Date is required" }}
                      render={({ field }) => (
                        <DatePicker {...field} fullWidth label="Date" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="code"
                    control={control}
                    rules={{ required: "Company Code is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Code"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="g_f_code"
                    control={control}
                    rules={{ required: "Company G F code is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company G F code"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="price_suggested"
                    control={control}
                    rules={{ required: "Company Price Suggested is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="number"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Price Suggested"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="pe"
                    control={control}
                    rules={{ required: "Company PE is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="number"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company PE"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="heading"
                    control={control}
                    rules={{ required: "Company Heading is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Heading"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="seo_descriptions"
                    control={control}
                    rules={{ required: "Company SEO Descriptions is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company SEO Descriptions"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="relative_index"
                    control={control}
                    rules={{ required: "Company Relative Index is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Relative Index"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="image"
                    control={control}
                    rules={{ required: "Company Image is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Image"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="paid"
                    control={control}
                    rules={{ required: "PAID is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={Boolean(error)}
                        >
                          <FormLabel id="paid-row-radio-buttons-group-label">
                            Paid
                          </FormLabel>
                          <RadioGroup
                            onChange={onChange}
                            row
                            value={value}
                            aria-labelledby="paid-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Paid"
                              control={<Radio />}
                              label="Paid"
                            />
                            <FormControlLabel
                              value="Free"
                              control={<Radio />}
                              label="Free"
                            />
                          </RadioGroup>
                          {error && (
                            <FormHelperText error>
                              {error ? error.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Company Description is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        multiline
                        rows={4}
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Description"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="blogId"
                    control={control}
                    rules={{ required: "Company Blog ID is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Blog ID"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="sectorId"
                    control={control}
                    rules={{ required: "Sector is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={Boolean(error)}
                        >
                          <InputLabel id="sectorId-label">Sector</InputLabel>
                          <Select
                            labelId="sectorId-label"
                            value={value}
                            label="Sector"
                            onChange={onChange}
                          >
                            {sectors?.length !== 0 &&
                              sectors.map((sector) => (
                                <MenuItem
                                  key={sector.value}
                                  value={sector.value}
                                >
                                  {sector.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {error && (
                            <FormHelperText error>
                              {error ? error.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="categoryId"
                    control={control}
                    rules={{ required: "Category is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={Boolean(error)}
                        >
                          <InputLabel id="categoryId-label">
                            Category
                          </InputLabel>
                          <Select
                            labelId="categoryId-label"
                            value={value}
                            onChange={onChange}
                          >
                            {categories?.length !== 0 &&
                              categories.map((category) => (
                                <MenuItem
                                  key={category.value}
                                  value={category.value}
                                >
                                  {category.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {error && (
                            <FormHelperText error>
                              {error ? error.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="tags"
                    control={control}
                    rules={{ required: "Tags are required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={Boolean(error)}
                        >
                          <InputLabel id="tags-label">Tags</InputLabel>
                          <Select
                            multiple
                            value={value || []}
                            onChange={onChange}
                            renderValue={(selected) => (
                              <div>
                                {selected.map((tagId) => {
                                  const tag = availableTags.find(
                                    (tag) => tag.id === tagId,
                                  );
                                  return tag ? (
                                    <Chip
                                      sx={{ marginX: "5px" }}
                                      key={tag.id}
                                      label={tag.name}
                                      variant="outlined"
                                    />
                                  ) : null;
                                })}
                              </div>
                            )}
                          >
                            {availableTags.map((tag) => (
                              <MenuItem key={tag.id} value={tag.id}>
                                {tag.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && (
                            <FormHelperText error>
                              {error ? error.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="conclusion"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="text"
                        size="medium"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        multiline
                        rows={4}
                        label="Company Conclusion"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="reportLink"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        type="text"
                        size="medium"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Company Report Link"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="isPasswordProtected"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value ?? false} {...field} />
                        )}
                      />
                    }
                    label="Make it password protected"
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  xs={12}
                  md={8}
                >
                  <Button
                    sx={{
                      margin: 2,
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor: lockBackgroundColor,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color: lockBackgroundColor,
                        backgroundColor:
                          theme.palette.getContrastText(lockBackgroundColor),
                        boxShadow: "none",
                      },
                      border: "1px solid",
                      borderColor: lockBackgroundColor,
                      boxShadow: "none",
                    }}
                    type="button"
                    variant="contained"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    sx={{
                      height: "60px",
                      width: "180px",
                      margin: 2,
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor: lockBackgroundColor,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color: lockBackgroundColor,
                        backgroundColor:
                          theme.palette.getContrastText(lockBackgroundColor),
                        boxShadow: "none",
                      },
                      border: "1px solid",
                      borderColor: lockBackgroundColor,
                      boxShadow: "none",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Edit Company
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default EditCompany;
