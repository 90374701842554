import React, { createRef } from "react";
import Button from "../../Components/Button/Button";
import Card from "../../Components/Card/Card";
import CardImage from "../../Assets/Card.png";
import "./style.scss";
// import Filter from '../../Components/Filter/Filter';
import Slider from "react-slick";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";

export default function CompaniesSuggested(props) {
  const history = useHistory();
  const { companies } = props;

  const onCompanyClick = (id) => {
    history.push(`/blog/${id}`);
  };

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const customeSlider3 = createRef();
  const gotoPrev3 = () => {
    customeSlider3.current.slickPrev();
  };
  const gotoNext3 = () => {
    customeSlider3.current.slickNext();
  };

  return (
    <div>
      <div className="container">
        <h5 className=" fs-95 mt-header">
          suggested <br /> companies
        </h5>
      </div>

      {/* <Filter /> */}

      <Slider {...settings} className="sector-card" ref={customeSlider3}>
        {companies &&
          companies &&
          companies.map((item) => (
            <Card
              valueDate={item.valueDate}
              key={`${item.Id} ${item.date}`}
              {...item}
              img={item.image ?? CardImage}
              title={item.name ?? item.title}
              price={item.price}
              rate={item.rate}
              content={item.description}
              date={item.date}
              onClick={() => onCompanyClick(item.Id)}
            />
          ))}
      </Slider>
      <div className="d-flex slider-arrow ">
        <div className="slider-arrow-left" onClick={() => gotoPrev3()}>
          <BsArrowLeft fontSize={25} />
        </div>
        <div className="slider-arrow-right" onClick={() => gotoNext3()}>
          <BsArrowRight fontSize={25} />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3 ">
        <Button onClick={() => history.goBack()} text="Go back" />
      </div>
    </div>
  );
}
