import React, { useState } from "react";
import { debounce } from "lodash";
import { useSearchAPIMutation } from "../../redux/SearchAPI";
import "./style.scss";
import SearchResult from "./SearchResult";
import SkeletonCard from "../../Components/Skeleton card/SkeletonCard";
import { Field, Form, Formik } from "formik";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

export default function SearchPage() {
  const [search, setSearch] = useState("");
  const [searchAPI, { data, isSuccess, isLoading }] = useSearchAPIMutation();

  const searchWithKey = debounce((key) => {
    searchAPI({ key, filterTag });
  }, 500);
  if (data) {
    const payloadKeys = Object.keys(data?.payload);
    payloadKeys
      .filter((key) => Array.isArray(data?.payload[key]))
      .map((key) => key);
  }
  const [filterTag, setFilterTag] = useState("companies");

  const handleButtonClick = (buttonName) => {
    setFilterTag(buttonName);
  };

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="search-container container">
      <Formik
        initialValues={{
          search: search,
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} style={{ width: "90%" }}>
            <div className="d-flex input-wrapper">
              <div className="input-line-animation__wrapper">
                <Field
                  name="search"
                  type="text"
                  className="search-input-form input-line-animation__ip fs-55"
                  placeholder="SEARCH"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchWithKey(e.target.value);
                  }}
                  value={search}
                />
                <div className="input-line-animation__line"></div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Grid
        container
        spacing={2}
        display="flex"
        flexDirection={isSmDown ? "column" : undefined}
        alignItems="center"
      >
        <Grid item>
          <button
            className={`button ${
              filterTag === "companies" ? "button-success" : "button-white"
            }`}
            onClick={() => handleButtonClick("companies")}
            type="button"
          >
            Company
          </button>
        </Grid>
        <Grid item>
          <button
            className={`button ${
              filterTag === "sectors" ? "button-success" : "button-white"
            }`}
            onClick={() => handleButtonClick("sectors")}
            type="button"
          >
            Sector
          </button>
        </Grid>
        <Grid item>
          <button
            className={`button ${
              filterTag === "categories" ? "button-success" : "button-white"
            }`}
            onClick={() => handleButtonClick("categories")}
            type="button"
          >
            Category
          </button>
        </Grid>
      </Grid>
      {search !== "" &&
        (isLoading ? (
          <SkeletonCard />
        ) : (
          Object.keys(data?.payload ?? {}).map((key) => {
            if (key !== filterTag) return null;
            const value = data.payload[key];
            return (
              <SearchResult
                search={search}
                isSuccess={isSuccess}
                isLoading={isLoading}
                heading={key}
                data={value}
                key={key}
                filterTag={filterTag}
              />
            );
          })
        ))}
    </div>
  );
}
