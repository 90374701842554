import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useAdminUsers = () => {
  const [paginatedUsers, setPaginatedUsers] = useState([]);
  const [userId, setUserId] = useState(undefined);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
  };
  const checkUserSubscription = useCallback(
    (user) =>
      user &&
      user.subscription &&
      user.subscription.length &&
      user.subscription[user.subscription.length - 1]
        ? new Date(user.subscription[user.subscription.length - 1].expiresOn) >=
          new Date()
        : false,
    [],
  );
  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const token = localStorage.getItem("code");
  const fetchUsers = useCallback(() => {
    try {
      axios
        .get(
          `${REACT_APP_BASE_URL}user/all?page=${currentPage}&searchKeyword=${searchKeyword}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          setPaginatedUsers(res.data);
          setTotalPages(res.data?.pageInfo?.totalPages);
        })
        .catch((e) => console.error("Error fetching companies:", e));
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }, [currentPage, searchKeyword, token]);
  const updateUser = useCallback(
    (id) => {
      axios
        .put(`${REACT_APP_BASE_URL}user/set-admin-status/${id}`, null, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => fetchUsers())
        .catch((e) => alert(e));
    },
    [fetchUsers, token],
  );
  useEffect(() => fetchUsers(), [fetchUsers]);
  const theme = useTheme();
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(
    (userId) => {
      setUserId(userId);
      setOpen(true);
    },
    [setUserId],
  );
  const handleModalClose = () => {
    setOpen(false);
  };
  const history = useHistory();

  const [openCsvModal, setOpenCsvModal] = useState(false);
  const handleCsvModalOpen = useCallback(() => {
    setOpenCsvModal(true);
  }, []);
  const handleCsvModalClose = () => {
    setOpenCsvModal(false);
  };
  return {
    paginatedUsers,
    isMdDown,
    updateUser,
    searchKeyword,
    currentPage,
    totalPages,
    handleSearchSubmit,
    handleSearchChange,
    handlePreviousPage,
    handleNextPage,
    lockBackgroundColor,
    theme,
    fetchUsers,
    userId,
    setUserId,
    open,
    handleModalOpen,
    handleModalClose,
    checkUserSubscription,
    history,
    openCsvModal,
    handleCsvModalOpen,
    handleCsvModalClose,
  };
};
export default useAdminUsers;
