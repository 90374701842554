import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useHomePageList = () => {
  const [homepages, setHomepages] = useState([]);
  const [homepagesTable, setHomepagesTable] = useState([]);

  const fetchHomepages = useCallback(() => {
    try {
      axios
        .get(`${REACT_APP_BASE_URL}home-page-sections/all`)
        .then((res) => {
          setHomepages(res.data);
        })
        .catch((e) => console.error("Error fetching companies:", e));
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }, []);
  useEffect(() => fetchHomepages(), [fetchHomepages]);
  const counter = (array, prop) => {
    const newSet = new Set();
    array.forEach((item) => {
      if (item[prop]) {
        newSet.add(item[prop]);
      }
    });
    return newSet.size;
  };
  useEffect(() => {
    if (homepages && homepages.length) {
      setHomepagesTable(
        homepages.map((homepage) => ({
          id: homepage.Id,
          isActive: homepage.isActive,
          sectors: counter(homepage.homepageSections, "sectorId"),
          tags: counter(homepage.homepageSections, "tagId"),
          categories: counter(homepage.homepageSections, "categoryId"),
          allSectors:
            counter(homepage.homepageSections, "allSectors") !== 0
              ? true
              : false,
          allTags:
            counter(homepage.homepageSections, "allTags") !== 0 ? true : false,
          allCategories:
            counter(homepage.homepageSections, "allCategories") !== 0
              ? true
              : false,
        })),
      );
    }
  }, [homepages]);
  const token = localStorage.getItem("code");
  const handleActivateHomepage = (id) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}home-page-sections/main-page/select/${id}`,
        null,
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => fetchHomepages())
      .catch((e) => alert(e.message));
  };
  const handleDeactivateHomepage = (id, isActive) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}home-page-sections/main-page/deactivate/${id}`,
        { isActive },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        fetchHomepages();
      })
      .catch((e) => {
        alert(e.message);
      });
  };
  const history = useHistory();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  return {
    homepagesTable,
    isMdDown,
    handleActivateHomepage,
    handleDeactivateHomepage,
    history,
  };
};
export default useHomePageList;
