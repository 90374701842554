import React from "react";
import useEditUser from "./useEditUser";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AccountsList from "./AccountsList";
import UserInformation from "./UserInformation/indx";
import UserSubscriptionsList from "./UserSubscriptionsList";
import UserReferralsList from "./UserReferralsList";
import UserForm from "./UserForm";
import UserGstDetails from "./UserGstDetails";

const EditUser = () => {
  const {
    user,
    accounts,
    subscriptions,
    themeProv,
    showAccounts,
    setShowAccounts,
    showSubscriptions,
    setShowSubscriptions,
    fetchUser,
    showReferrals,
    setShowReferrals,
    refs,
    showGst,
    setShowGst,
    gstCredentials,
    // fetchUserGstCredentials,
  } = useEditUser();
  return (
    <ThemeProvider theme={themeProv}>
      <Box display="flex" justifyContent="center">
        <Box width="90vw">
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showGst}
                    onChange={(e) => setShowGst(e.target.checked)}
                  />
                }
                label="Show Gst"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSubscriptions}
                    onChange={(e) => setShowSubscriptions(e.target.checked)}
                  />
                }
                label="Show Subscriptions"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showReferrals}
                    onChange={(e) => setShowReferrals(e.target.checked)}
                  />
                }
                label="Show Referrals"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAccounts}
                    onChange={(e) => setShowAccounts(e.target.checked)}
                  />
                }
                label="Show Accounts"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              display="flex"
              justifyContent="start"
              alignItems="center"
              xs={12}
            >
              <Typography fontWeight="bold">User:</Typography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="start"
              alignItems="center"
              xs={12}
            >
              <Paper sx={{ padding: 2, width: "100%" }}>
                {user && <UserInformation user={user} />}
              </Paper>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="start"
              alignItems="center"
              xs={12}
            >
              <Paper sx={{ padding: 2, width: "100%" }}>
                {user && <UserForm user={user} />}
              </Paper>
            </Grid>
          </Grid>
          {showGst && (
            <Grid container pt={2} spacing={2}>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Typography fontWeight="bold">GST Credentials:</Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Paper sx={{ padding: 2, width: "100%" }}>
                  <UserGstDetails {...gstCredentials} />
                </Paper>
              </Grid>
            </Grid>
          )}
          {showSubscriptions && (
            <Grid container pt={2} spacing={2}>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Typography fontWeight="bold">Subscriptions:</Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                {user && subscriptions && (
                  <UserSubscriptionsList
                    userId={user.id}
                    subscriptions={subscriptions}
                    fetchUser={fetchUser}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {showReferrals && (
            <Grid container pt={2} spacing={2}>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Typography fontWeight="bold">Referrals:</Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Paper sx={{ padding: 2, width: "100%" }}>
                  {refs && refs.length && (
                    <UserReferralsList
                      fetchUser={fetchUser}
                      referrals={refs}
                      userId={user.id}
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
          {showAccounts && (
            <Grid container pt={2} spacing={2}>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Typography fontWeight="bold">Accounts:</Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Paper sx={{ padding: 2, width: "100%" }}>
                  {accounts && accounts.length && (
                    <AccountsList accounts={accounts} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default EditUser;
