import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SecImage from "../../Assets/image section.png";
import CardList from "../../Components/CardList";
import LinearProgressBar from "../../Components/LinearProgressBar";
import SkeletonSlider from "../../Components/Skeleton card/SkeletonSlider";
import SlideUp from "../../Components/SlideUp";
import { useGetSectorsQuery } from "../../redux/HomeAPI";

const Sectors = () => {
  const { data, isLoading, isSuccess } = useGetSectorsQuery();
  const [sectors, setSectors] = useState([]);
  useEffect(
    () =>
      data && data.payload && data.payload.length && setSectors(data.payload),
    [data],
  );
  const history = useHistory();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );

  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );

  const onSectorSelect = (id) => {
    history.push(`/sector-page/${id}`);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box>
        {isLoading && !isSuccess ? (
          <>
            <LinearProgressBar />
            <SkeletonSlider />
          </>
        ) : (
          sectors?.length !== 0 && (
            <>
              <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
                  <Grid container spacing={2} display="flex">
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      overflow="hidden"
                    >
                      <Typography
                        fontWeight={600}
                        lineHeight={1.5}
                        fontSize={headerFontSize}
                        textTransform="capitalize"
                        pl={isMdDown ? 0 : 10}
                      >
                        <SlideUp capitalize>Sectors</SlideUp>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                height="100vh"
                width="100vw"
                sx={{
                  backgroundImage: `url(${sectors[0].image || SecImage})`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Box>
              <Box
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
                  <Grid container spacing={2} display="flex">
                    <Grid item lg={2} md={2} sm={3}>
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        lineHeight={1.2}
                      >
                        Overview
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={10} sm={9}>
                      <Typography
                        fontSize={paragraphFontSize}
                        fontWeight={500}
                        lineHeight={1.2}
                      >
                        Discover the many industries that contain the companies
                        represented on Dark Horse Stocks. These companies are
                        defined by the sectors in which these businesses
                        operate. However, it is vital to recognise that when
                        companies adapt and grow, their principal sources of
                        revenue may move, resulting in sector transitions. Dive
                        into the worlds of Speciality Chemicals, Healthcare,
                        Information Technology, and many more to obtain insights
                        into the diverse landscapes of these businesses and the
                        unique companies that operate within them.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <CardList
                contentList={sectors}
                cursorData="See Sector"
                isCompany={false}
                isLoading={isLoading}
                isSuccess={isSuccess}
                onClick={onSectorSelect}
              />
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default Sectors;
