import axios from "axios";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [paid, setPaid] = useState("Free");
  const [renderApp, setRenderApp] = useState(false);
  const [user, setUser] = useState(undefined);
  const [activeSubscription, setActiveSubscription] = useState(undefined);
  const history = useHistory();

  const login = (token, history) => {
    localStorage.setItem("code", `Bearer: ${token}`);
    const blogId = localStorage.getItem("blogId");
    setIsAuthenticated(true);
    if (blogId) {
      localStorage.removeItem("blogId");
      history.push(`/blog/${blogId}`);
      window.location.reload();
      return;
    }
    const planSubsctiptionLocation = localStorage.getItem("profileLocation");
    if (planSubsctiptionLocation) {
      history.push("/profile");
      localStorage.removeItem("profileLocation");
      window.location.reload();
    } else {
      history.push("/");
      window.location.reload();
    }
  };

  const logout = () => {
    localStorage.removeItem("code");
    setIsAuthenticated(false);
    window.location.reload();
  };
  const fetchMe = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((fetchedUser) => {
        if (fetchedUser && fetchedUser.data && !fetchedUser.data.error) {
          setUser(fetchedUser.data);
          setPaid(fetchedUser.data.paid);
          setIsAuthenticated(true);
          setIsAdmin(fetchedUser.data.isAdmin);
          setIsSubscribed(
            fetchedUser.data.isAdmin || fetchedUser.data.isSubscribed,
          );
          if (fetchedUser.data.isSubscribed) {
            const { subscription } = fetchedUser.data;
            setActiveSubscription(
              subscription.sort((a, b) => {
                if (new Date(a.startedAt) <= new Date(b.startedAt)) {
                  return 1;
                } else return -1;
              })[0],
            );
          }
        } else {
          setIsAuthenticated(false);
        }
        setRenderApp(true);
      })
      .catch((error) => {
        console.error(error);
        setRenderApp(true);
      });
  }, []);
  useEffect(() => {
    fetchMe();
  }, [fetchMe]);
  const handleAuthRoute = (pathname) => {
    if (isAuthenticated) history.push(pathname);
    else history.push("/login");
  };
  const handleLinkTo = useCallback(
    (pathname) => {
      if (isAuthenticated) return pathname;
      else return "/login";
    },
    [isAuthenticated],
  );
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdmin,
        paid,
        login,
        logout,
        isSubscribed,
        setIsSubscribed,
        renderApp,
        handleAuthRoute,
        handleLinkTo,
        user,
        fetchMe,
        activeSubscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
export default AuthContext;
