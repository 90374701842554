import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import Button from "../../../Components/Button/Button";
import { useAppSelector } from "../../../store/hooks";

const GstValidationModal = ({
  validateGst,
  gstCredentials,
  open,
  handleClose,
}) => {
  const darkMode = useAppSelector((store) => store.theme.dark);

  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const fontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <div>
      <ThemeProvider theme={themeProv}>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"lg"}
          fullWidth
          aria-labelledby="terms-and-conditions-dialog"
          aria-describedby="terms-and-conditions-dialog-description"
        >
          <DialogTitle>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={fontSize}>
                Validate GST Credentials
              </Typography>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => handleClose()}
                fontSize={fontSize}
              >
                {"\u2715"}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              spacing={2}
              my={6}
            >
              <Grid item xs={12}>
                <Grid container rowSpacing={4}>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize} fontWeight={700}>
                      Firm Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize}>
                      {gstCredentials.firstName || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize} fontWeight={700}>
                      GST Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize}>
                      {gstCredentials.gstNumber || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize} fontWeight={700}>
                      GST Phone Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize}>
                      {gstCredentials.gstPhoneNumber || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize} fontWeight={700}>
                      GST Email:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize}>
                      {gstCredentials.gstEmail || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize} fontWeight={700}>
                      Registered Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize}>
                      {gstCredentials.registeredAddress || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize} fontWeight={700}>
                      State:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"start"}>
                    <Typography fontSize={fontSize}>
                      {gstCredentials.state || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ width: "100%" }}>
            <Grid
              container
              display="flex"
              justifyContent={"start"}
              pl={2}
              spacing={2}
            >
              <Grid item>
                <Button
                  text="Confirm"
                  onClick={() => {
                    validateGst();
                    handleClose();
                  }}
                />
              </Grid>
              <Grid item>
                <Button text="Cancel" onClick={() => handleClose()} />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default GstValidationModal;
