import { useForm } from "react-hook-form";
import { useMemo } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const AddApp = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const token = localStorage.getItem("code");

  const addApp = (data) => {
    axios
      .post(
        `${REACT_APP_BASE_URL}apps`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-list-apps");
      })
      .catch((e) => console.error("Error fetching companies:", e));
  };

  const onSubmit = (data) => {
    addApp(data);
    reset(data);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
  };
};
export default AddApp;
