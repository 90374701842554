import React from 'react';
import './style.scss';

export default function Footer() {
  return (
    <>
      <div className="footer-animation-wrapper">
        <div className="sun-and-moon">
          <div className="sun">
            <svg width="100%" height="100%" max-width="100%" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="full-sun">
                <path id="sun-light" d="M499.999 842.69c189.262 0 342.69-153.427 342.69-342.69 0-189.262-153.428-342.69-342.69-342.69-189.263 0-342.69 153.428-342.69 342.69 0 189.263 153.427 342.69 342.69 342.69Z" fill="url(#paint0_radial_140_460)" />
                <path id="sun" d="M500.002 645.51c80.363 0 145.51-65.147 145.51-145.51 0-80.363-65.147-145.51-145.51-145.51-80.363 0-145.51 65.147-145.51 145.51 0 80.363 65.147 145.51 145.51 145.51Z" fill="#FFC400" />
              </g>
              <defs>
                <radialGradient id="paint0_radial_140_460" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(499.999 500) scale(342.69)">
                  <stop stopColor="#FCEA10" stopOpacity=".7" />
                  <stop offset=".08" stopColor="#FCED2E" stopOpacity=".69" />
                  <stop offset=".29" stopColor="#FDF378" stopOpacity=".66" />
                  <stop offset=".49" stopColor="#FEF8B3" stopOpacity=".63" />
                  <stop offset=".68" stopColor="#FFFCDD" stopOpacity=".61" />
                  <stop offset=".86" stopColor="#FFFEF6" stopOpacity=".6" />
                  <stop offset="1" stopColor="#fff" stopOpacity=".6" />
                </radialGradient>
              </defs>
            </svg>
          </div>
          <div className="moon">
            <svg width="100%" height="100%" max-width="100%" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="full-moon">
                <path id="moon-light" d="M499.999 842.69c189.262 0 342.69-153.427 342.69-342.69 0-189.262-153.428-342.69-342.69-342.69-189.263 0-342.69 153.428-342.69 342.69 0 189.263 153.427 342.69 342.69 342.69Z" fill="url(#paint0_radial_140_457)" />
                <path id="moon" d="M499.998 645.51c80.363 0 145.51-65.147 145.51-145.51 0-80.363-65.147-145.51-145.51-145.51-80.363 0-145.51 65.147-145.51 145.51 0 80.363 65.147 145.51 145.51 145.51Z" fill="#EBF2FC" />
              </g>
              <defs>
                <radialGradient id="paint0_radial_140_457" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(499.999 500) scale(342.69)">
                  <stop stopColor="#3C3C4D" />
                  <stop offset=".54" stopColor="#2D2C39" stopOpacity=".49" />
                  <stop offset="1" stopColor="#1E1D27" stopOpacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="mountains">
          <div className="slideUp shade-back-far">
            <svg width="100%" max-width="100%" height="100%" viewBox="0 0 4800 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="bg-shade-back-far">
                <path id="bg-shade-far" d="M4800 0H0v236h4800V0Z" fill="url(#bg-shade-far-gradient)" />
              </g>
              <defs>
                <linearGradient id="bg-shade-far-gradient" x1="2400" y1="239.17" x2="2400" y2="-5.22" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--shadeBackFarGradientBottom)" stopOpacity=".4" />
                  <stop offset=".64" stopColor="var(--shadeBackFarGradientMiddle)" stopOpacity=".15" />
                  <stop offset="1" stopColor="var(--shadeBackFarGradientTop)" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="slideUp far-mountains">
            <svg width="100%" max-width="100%" height="100%" viewBox="0 0 4800 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="bg-mountains-far-vector" clipPath="url(#clip0_112_1960)">
                <g id="far-mountains-whole">
                  <path
                    id="far-mountains"
                    d="M4803.9 64.03v173.38H-3.898V45.13l135.46 29.62 58.22-38.81 37.78 42.89 8.69-30.12 60.25-20.43 10.73 53.11 26.54-43.92 107.75 30.64 177.71-35.74 25.52 40.85V42.58l107.75 15.83 115.4-21.45 35.23-19.91 38.3 64.34 18.9-32.17 60.768-32.17 40.33 64.34 20.44-32.17h.01l54.61-30.13.11.12 44.83 52.48 8.69-22.47h.01l102.11 8.68 101.12-12.77 25.02-13.79 53.09 37.28 49.54-11.74 77.62 10.21 144.5-38.81 38.81 26.04 18.66-5.07 50.79-13.82 44.43 43.91 21.28-5.24h.01l154.88-38.16 105.19 29.62 29.1-9.7 135.83-22.47 192.52 37.78 57.69-36.25 37.79 41.36 8.69-27.06 58.21-20.43 15.31 47.49 24-37.28 107.75 26.05 178.73-32.17 25.02 45.44V42.58l100.59 16.34 125.62-21.45 33.19-18.38 43.92 66.89 13.27-38.81 60.25-30.12 39.83 70.98 21.46-39.32 55.14-31.66 46.98 55.15 10.73-19.66 115.4 5.87 112.35-25.02 49.02 35.74 52.59-13.27 61.27 12.25 160.34-41.87 35.75 28.59 16.66-5.13h.01l51.25-15.8 47.49 44.42 18.51-4.61 153.57-38.28 113.37 31.66 24.51-12.26 131.74-20.93 61.27 30.13Z"
                    fill="url(#farMountainsGradient)"
                  />
                  <g id="far-mountains-shadow" fill="var(--farMountainsShadowsColor)">
                    <path id="Vector" d="m236.243 48.71-8.68 30.12-37.79-42.89 46.47 12.77Z" />
                    <path id="Vector_2" d="m333.772 37.47-26.55 43.92-10.73-53.11 37.28 9.19Z" />
                    <path id="Vector_3" d="M644.753 42.58v30.64l-25.53-40.85 25.53 10.21Z" />
                    <path id="Vector_4" d="m960.323 49.22-18.89 32.17-38.3-64.34 57.19 32.17Z" />
                    <path id="Vector_5" d="m1081.86 49.22-20.43 32.17-40.34-64.34 60.77 32.17Z" />
                    <path id="Vector_6" d="M1190.13 49.22h-.02l-8.68 22.47-44.84-52.48 53.54 30.01Z" />
                    <path id="Vector_7" d="m1521 56.88-49.53 11.74-53.1-37.28L1521 56.88Z" />
                    <path id="Vector_8" d="m1800.59 49.25-18.65 5.07-38.81-26.04 57.46 20.97Z" />
                    <path id="Vector_9" d="m1917.09 74.1-21.28 5.24-44.42-43.91 65.7 38.67Z" />
                    <path id="Vector_10" d="m2206.28 55.86-29.1 9.7-105.2-29.62 134.3 19.92Z" />
                    <path id="Vector_11" d="m2638.79 49.22-8.68 27.06-37.79-41.36 46.47 14.3Z" />
                    <path id="Vector_12" d="m2736.32 39-24 37.28L2697 28.79 2736.32 39Z" />
                    <path id="Vector_13" d="M3047.81 42.58v35.74l-25.02-45.44 25.02 9.7Z" />
                    <path id="Vector_14" d="m3364.41 47.17-13.28 38.81-43.91-66.89 57.19 28.08Z" />
                    <path id="Vector_15" d="m3485.94 48.71-21.45 39.32-39.83-70.98 61.28 31.66Z" />
                    <path id="Vector_16" d="m3598.79 52.54-10.72 19.66-46.98-55.15 57.7 35.49Z" />
                    <path id="Vector_17" d="m3928.15 55.86-52.59 13.27-49.02-35.74 101.61 22.47Z" />
                    <path id="Vector_18" d="m4202.17 49.7-16.65 5.13-35.75-28.59 52.4 23.46Z" />
                    <path id="Vector_19" d="m4319.43 73.71-18.51 4.61-47.49-44.42 66 39.81Z" />
                    <path id="Vector_20" d="m4610.88 54.83-24.51 12.26L4473 35.43l137.88 19.4Z" />
                    <path id="Vector_21" d="M4803.9 49.22v14.81l-61.28-30.13 61.28 15.32Z" />
                  </g>
                  <g id="far-mountains-lines">
                    <path id="line-3" d="m1082.08 49.33-.21-.11h-.01" fill="var(--farMountainsLinesColor)" />
                  </g>
                  <g id="trees" fill="var(--farMountainsTreesColor)">
                    <g id="Group 95">
                      <path id="Vector_22" d="M4785.77 43c.51-.87-2-3.5-2-3.5s1.23.31 1.4 0c.51-.87-2-3.49-2-3.49s.67.16.77 0c.54-.94-2.16-3.75-2.16-3.75s-2.71 2.81-2.17 3.75c.1.16.77 0 .77 0s-2.53 2.62-2 3.49c.17.31 1.4 0 1.4 0s-2.53 2.63-2 3.5c.53.87 3.33 0 3.33 0v1.85h1.41V43s2.84.7 3.25 0Z" />
                      <path id="Vector_23" d="M4781.71 44.83V32.26c-.25.27-2.65 2.84-2.14 3.73.1.16.77 0 .77 0s-2.53 2.62-2 3.49c.17.31 1.4 0 1.4 0s-2.53 2.63-2 3.5c.53.87 3.33 0 3.33 0v1.85h.64Z" />
                    </g>
                    <g id="Group 93">
                      <path
                        id="Vector_24"
                        d="M4671.64 45.07c.54-.93-2.14-3.71-2.14-3.71s1.3.32 1.49 0c.53-.93-2.15-3.71-2.15-3.71s.71.17.81 0c.58-1-2.29-4-2.29-4s-2.87 3-2.3 4c.1.17.81 0 .81 0s-2.67 2.78-2.14 3.71c.19.32 1.49 0 1.49 0s-2.68 2.78-2.14 3.71c.44.76 3.53 0 3.53 0v2h1.5v-2s3.09.76 3.53 0Z"
                      />
                      <path id="Vector_25" d="M4667.34 47V33.69c-.27.29-2.82 3-2.28 4 .1.17.81 0 .81 0s-2.67 2.78-2.14 3.71c.19.32 1.49 0 1.49 0s-2.68 2.78-2.14 3.71c.44.76 3.53 0 3.53 0v2l.73-.11Z" />
                    </g>
                    <g id="Group 90">
                      <path
                        id="Vector_26"
                        d="M4450.82 41.34c.31-.55-1.27-2.19-1.27-2.19s.77.19.88 0c.31-.55-1.27-2.19-1.27-2.19s.42.1.48 0c.34-.59-1.36-2.35-1.36-2.35s-1.69 1.76-1.35 2.35c.06.1.48 0 .48 0s-1.59 1.64-1.27 2.19c.11.19.88 0 .88 0s-1.58 1.64-1.27 2.19c.31.55 2.09 0 2.09 0v1.16h.88v-1.16s1.83.45 2.1 0Z"
                      />
                      <path id="Vector_27" d="M4448.27 42.5v-7.88c-.16.17-1.66 1.78-1.34 2.34.06.1.48 0 .48 0s-1.59 1.64-1.27 2.19c.11.19.88 0 .88 0s-1.58 1.64-1.27 2.19c.31.55 2.09 0 2.09 0v1.16h.43Z" />
                    </g>
                    <g id="Group 87">
                      <path
                        id="Vector_28"
                        d="M4342.31 67.86c.35-.61-1.41-2.44-1.41-2.44s.86.21 1 0c.35-.61-1.41-2.44-1.41-2.44s.47.12.54 0c.37-.65-1.51-2.61-1.51-2.61s-1.89 2-1.51 2.61c.07.12.53 0 .53 0s-1.75 1.83-1.4 2.44c.12.21 1 0 1 0s-1.76 1.83-1.4 2.44c.36.61 2.32 0 2.32 0v1.29h1v-1.29s1.94.5 2.25 0Z"
                      />
                      <path id="Vector_29" d="M4339.48 69.15v-8.76c-.17.19-1.85 2-1.49 2.59.07.12.53 0 .53 0s-1.75 1.83-1.4 2.44c.12.21 1 0 1 0s-1.76 1.83-1.4 2.44c.36.61 2.32 0 2.32 0v1.29h.44Z" />
                    </g>
                    <g id="Group 102">
                      <path
                        id="Vector_30"
                        d="M4083.42 45.62c.41-.71-1.66-2.86-1.66-2.86s1 .24 1.15 0c.41-.72-1.65-2.87-1.65-2.87s.54.14.62 0c.45-.77-1.77-3.07-1.77-3.07s-2.22 2.3-1.77 3.07c.07.14.62 0 .62 0s-2.07 2.15-1.65 2.87c.14.24 1.14 0 1.14 0s-2.06 2.15-1.65 2.86c.41.71 2.73 0 2.73 0v1.52h1.16v-1.52s2.39.59 2.73 0Z"
                      />
                      <path id="Vector_31" d="M4080.09 47.14v-10.3c-.21.22-2.17 2.32-1.75 3 .07.14.62 0 .62 0s-2.07 2.15-1.65 2.87c.14.24 1.14 0 1.14 0s-2.06 2.15-1.65 2.86c.41.71 2.73 0 2.73 0v1.52l.56.05Z" />
                    </g>
                    <g id="Group 82">
                      <path
                        id="Vector_32"
                        d="M3952.25 60.4c.3-.52-1.18-2-1.18-2s.72.18.82 0c.29-.51-1.18-2.05-1.18-2.05s.39.1.44 0c.32-.54-1.26-2.19-1.26-2.19s-1.58 1.65-1.27 2.19c.06.1.45 0 .45 0s-1.48 1.54-1.18 2.05c.1.18.82 0 .82 0s-1.48 1.53-1.18 2c.3.47 1.95 0 1.95 0v1.08h.82V60.4s1.7.42 1.95 0Z"
                      />
                      <path id="Vector_33" d="M3949.88 61.48v-7.36c-.15.16-1.56 1.66-1.26 2.18.06.1.45 0 .45 0s-1.48 1.54-1.18 2.05c.1.18.82 0 .82 0s-1.48 1.53-1.18 2c.3.47 1.95 0 1.95 0v1.08l.4.05Z" />
                    </g>
                    <g id="Group 79">
                      <path
                        id="Vector_34"
                        d="M3819.17 35.25c.42-.73-1.68-2.91-1.68-2.91s1 .25 1.17 0c.42-.73-1.69-2.92-1.69-2.92s.56.14.64 0c.45-.78-1.8-3.12-1.8-3.12s-2.26 2.34-1.81 3.12c.08.14.64 0 .64 0s-2.1 2.19-1.68 2.92c.15.25 1.17 0 1.17 0s-2.11 2.18-1.69 2.91c.42.73 2.78 0 2.78 0v1.54h1.17v-1.54s2.43.6 2.78 0Z"
                      />
                      <path id="Vector_35" d="M3815.79 36.79V26.32c-.21.23-2.21 2.37-1.79 3.1.08.14.64 0 .64 0s-2.1 2.19-1.68 2.92c.15.25 1.17 0 1.17 0s-2.11 2.18-1.69 2.91c.42.73 2.78 0 2.78 0v1.54h.57Z" />
                    </g>
                    <g id="Group 75">
                      <path
                        id="Vector_36"
                        d="M3654.21 54.77c.44-.77-1.77-3.07-1.77-3.07s1.07.26 1.23 0c.44-.77-1.78-3.08-1.78-3.08s.59.15.67 0c.48-.82-1.9-3.29-1.9-3.29s-2.38 2.47-1.9 3.29c.08.15.67 0 .67 0s-2.22 2.31-1.78 3.08c.16.26 1.23 0 1.23 0s-2.21 2.3-1.77 3.07c.44.77 2.93 0 2.93 0v1.63h1.24v-1.63s2.56.64 2.93 0Z"
                      />
                      <path id="Vector_37" d="M3650.64 56.4v-11c-.22.23-2.33 2.49-1.88 3.27.08.15.67 0 .67 0s-2.22 2.31-1.78 3.08c.16.26 1.23 0 1.23 0s-2.21 2.3-1.77 3.07c.44.77 2.93 0 2.93 0v1.58h.6Z" />
                    </g>
                    <g id="Group 66">
                      <path
                        id="Vector_38"
                        d="M3128.48 54.61c.4-.7-1.62-2.8-1.62-2.8s1 .24 1.12 0c.41-.7-1.61-2.8-1.61-2.8s.53.13.61 0c.43-.75-1.73-3-1.73-3s-2.17 2.25-1.73 3c.07.13.61 0 .61 0s-2 2.1-1.62 2.8c.14.24 1.12 0 1.12 0s-2 2.1-1.61 2.8c.39.7 2.67 0 2.67 0v1.48h1.12v-1.48s2.34.58 2.67 0Z"
                      />
                      <path id="Vector_39" d="M3125.23 56.09V46c-.2.22-2.12 2.27-1.71 3 .07.13.61 0 .61 0s-2 2.1-1.62 2.8c.14.24 1.12 0 1.12 0s-2 2.1-1.61 2.8c.39.7 2.67 0 2.67 0v1.48l.54.01Z" />
                    </g>
                    <g id="Group 69">
                      <path
                        id="Vector_40"
                        d="M3264.16 38.83c.48-.82-1.9-3.29-1.9-3.29s1.15.28 1.32 0c.47-.83-1.91-3.3-1.91-3.3s.63.16.72 0c.51-.88-2-3.53-2-3.53s-2.55 2.65-2 3.53c.09.16.72 0 .72 0s-2.38 2.47-1.91 3.3c.17.28 1.32 0 1.32 0s-2.37 2.47-1.9 3.29c.47.82 3.14 0 3.14 0v1.75h1.24v-1.75s2.77.68 3.16 0Z"
                      />
                      <path id="Vector_41" d="M3260.33 40.58V28.73c-.24.26-2.5 2.68-2 3.51.09.16.72 0 .72 0s-2.38 2.47-1.91 3.3c.17.28 1.32 0 1.32 0s-2.37 2.47-1.9 3.29c.47.82 3.14 0 3.14 0v1.75h.63Z" />
                    </g>
                    <g id="Group 53">
                      <path
                        id="Vector_42"
                        d="M2548.5 66.63c.48-.83-1.92-3.32-1.92-3.32s1.16.29 1.33 0c.48-.83-1.92-3.32-1.92-3.32s.64.16.73 0c.51-.89-2-3.56-2-3.56s-2.57 2.67-2.06 3.56c.09.16.73 0 .73 0s-2.4 2.49-1.92 3.32c.17.29 1.33 0 1.33 0s-2.4 2.49-1.92 3.32c.48.83 3.17 0 3.17 0v1.76h1.33v-1.76s2.72.69 3.12 0Z"
                      />
                      <path id="Vector_43" d="M2544.64 68.39V56.46c-.24.25-2.52 2.69-2 3.53.09.16.73 0 .73 0s-2.4 2.49-1.92 3.32c.17.29 1.33 0 1.33 0s-2.4 2.49-1.92 3.32c.48.83 3.17 0 3.17 0v1.76h.61Z" />
                    </g>
                    <g id="Group 52">
                      <path
                        id="Vector_44"
                        d="M2476.43 61.42c.56-1-2.22-3.86-2.22-3.86s1.35.34 1.54 0c.56-1-2.22-3.85-2.22-3.85s.73.19.84 0c.59-1-2.39-4.13-2.39-4.13s-3 3.1-2.38 4.13c.11.19.84 0 .84 0s-2.78 2.89-2.22 3.85c.19.34 1.54 0 1.54 0s-2.78 2.89-2.22 3.86c.46.79 3.67 0 3.67 0v2h1.55v-2s3.24.79 3.67 0Z"
                      />
                      <path id="Vector_45" d="M2472 63.46V49.61c-.28.3-2.92 3.13-2.36 4.1.11.19.84 0 .84 0s-2.78 2.89-2.22 3.85c.19.34 1.54 0 1.54 0s-2.78 2.89-2.22 3.86c.46.79 3.67 0 3.67 0v2l.75.04Z" />
                    </g>
                    <g id="Group 48">
                      <path
                        id="Vector_46"
                        d="M2267 45.25c.54-.93-2.14-3.71-2.14-3.71s1.3.32 1.48 0c.54-.93-2.14-3.71-2.14-3.71s.71.17.81 0c.58-1-2.29-4-2.29-4s-2.87 3-2.3 4c.1.17.81 0 .81 0s-2.68 2.78-2.14 3.71c.19.32 1.48 0 1.48 0s-2.67 2.78-2.14 3.71c.44.76 3.54 0 3.54 0v2h1.49v-2s3.12.75 3.54 0Z"
                      />
                      <path id="Vector_47" d="M2262.71 47.21V33.88c-.27.28-2.81 3-2.27 3.95.1.17.81 0 .81 0s-2.68 2.78-2.14 3.71c.19.32 1.48 0 1.48 0s-2.67 2.78-2.14 3.71c.44.76 3.54 0 3.54 0v2l.72-.04Z" />
                    </g>
                    <g id="Group 40">
                      <path
                        id="Vector_48"
                        d="M1828.22 42.51c.41-.71-1.63-2.82-1.63-2.82s1 .24 1.13 0c.41-.7-1.63-2.82-1.63-2.82s.54.14.62 0c.43-.75-1.75-3-1.75-3s-2.18 2.27-1.74 3c.08.14.62 0 .62 0s-2 2.12-1.63 2.82c.14.24 1.13 0 1.13 0s-2 2.11-1.63 2.82c.37.71 2.69 0 2.69 0V44h1.13v-1.49s2.35.58 2.69 0Z"
                      />
                      <path id="Vector_49" d="M1825 44V33.87c-.21.22-2.14 2.29-1.73 3 .08.14.62 0 .62 0s-2 2.12-1.63 2.82c.14.24 1.13 0 1.13 0s-2 2.11-1.63 2.82c.37.71 2.69 0 2.69 0V44h.55Z" />
                    </g>
                    <g id="Group 38">
                      <path
                        id="Vector_50"
                        d="M1678.82 46.74c.42-.72-1.65-2.87-1.65-2.87s1 .25 1.15 0c.41-.71-1.66-2.86-1.66-2.86s.55.13.63 0c.44-.77-1.77-3.07-1.77-3.07s-2.22 2.3-1.78 3.07c.08.13.63 0 .63 0s-2.07 2.15-1.66 2.86c.15.25 1.15 0 1.15 0s-2.07 2.15-1.65 2.87c.42.72 2.73 0 2.73 0v1.52h1.15v-1.52s2.39.59 2.73 0Z"
                      />
                      <path id="Vector_51" d="M1675.5 48.26V38c-.21.22-2.18 2.33-1.76 3.06.08.13.63 0 .63 0s-2.07 2.15-1.66 2.86c.15.25 1.15 0 1.15 0s-2.07 2.15-1.65 2.87c.42.72 2.73 0 2.73 0v1.52l.56-.05Z" />
                    </g>
                    <g id="Group 31">
                      <path
                        id="Vector_52"
                        d="M1373.52 47.62c.41-.71-1.62-2.82-1.62-2.82s1 .24 1.13 0c.4-.71-1.63-2.82-1.63-2.82s.54.13.61 0c.44-.76-1.74-3-1.74-3s-2.18 2.26-1.74 3c.07.13.61 0 .61 0s-2 2.11-1.63 2.82c.15.24 1.13 0 1.13 0s-2 2.11-1.63 2.82c.37.71 2.69 0 2.69 0v1.49h1.14v-1.49s2.35.58 2.68 0Z"
                      />
                      <path id="Vector_53" d="M1370.25 49.11V39c-.2.21-2.14 2.28-1.72 3 .07.13.61 0 .61 0s-2 2.11-1.63 2.82c.15.24 1.13 0 1.13 0s-2 2.11-1.63 2.82c.37.71 2.69 0 2.69 0v1.49l.55-.02Z" />
                    </g>
                    <g id="Group 28">
                      <path
                        id="Vector_54"
                        d="M1249.59 53.89c.45-.77-1.77-3.07-1.77-3.07s1.07.26 1.23 0c.44-.77-1.78-3.08-1.78-3.08s.59.15.67 0c.48-.82-1.9-3.29-1.9-3.29s-2.38 2.47-1.9 3.29c.08.15.67 0 .67 0s-2.22 2.31-1.77 3.08c.15.26 1.23 0 1.23 0s-2.22 2.3-1.78 3.07c.44.77 2.93 0 2.93 0v1.63h1.24v-1.63s2.57.64 2.93 0Z"
                      />
                      <path id="Vector_55" d="M1246 55.52V44.47c-.22.23-2.33 2.49-1.88 3.27.08.15.67 0 .67 0s-2.22 2.31-1.77 3.08c.15.26 1.23 0 1.23 0s-2.22 2.3-1.78 3.07c.44.77 2.93 0 2.93 0v1.63h.6Z" />
                    </g>
                    <g id="Group 19">
                      <path
                        id="Vector_56"
                        d="M857.841 39c.48-.83-1.9-3.3-1.9-3.3s1.16.29 1.32 0c.48-.82-1.9-3.29-1.9-3.29s.63.15.72 0c.51-.89-2-3.54-2-3.54s-2.55 2.65-2 3.54c.09.15.72 0 .72 0s-2.38 2.47-1.9 3.29c.16.29 1.32 0 1.32 0s-2.38 2.47-1.91 3.3c.47.83 3.15 0 3.15 0v1.74h1.32V39s2.67.72 3.06 0Z"
                      />
                      <path id="Vector_57" d="M854.003 40.78V28.94c-.24.25-2.5 2.67-2 3.51.09.15.72 0 .72 0s-2.38 2.47-1.9 3.29c.16.29 1.32 0 1.32 0s-2.38 2.47-1.91 3.3c.47.83 3.15 0 3.15 0v1.74h.62Z" />
                    </g>
                    <g id="Group 13">
                      <path
                        id="Vector_58"
                        d="M603.06 35.51c.49-.83-1.92-3.33-1.92-3.33s1.17.29 1.33 0c.48-.84-1.92-3.34-1.92-3.34s.64.16.73 0c.51-.89-2.07-3.57-2.07-3.57s-2.58 2.68-2.06 3.57c.09.16.73 0 .73 0s-2.41 2.5-1.93 3.34c.17.29 1.34 0 1.34 0s-2.41 2.5-1.93 3.33c.48.83 3.18 0 3.18 0v1.77h1.34v-1.77s2.79.69 3.18 0Z"
                      />
                      <path id="Vector_59" d="M599.193 37.28v-12c-.24.26-2.53 2.71-2 3.55.09.16.73 0 .73 0s-2.41 2.5-1.93 3.34c.17.29 1.34 0 1.34 0s-2.41 2.5-1.93 3.33c.48.83 3.18 0 3.18 0v1.77l.61.01Z" />
                    </g>
                    <g id="Group 9">
                      <path
                        id="Vector_60"
                        d="M387.743 52c.41-.72-1.64-2.85-1.64-2.85s1 .24 1.14 0c.41-.72-1.65-2.85-1.65-2.85s.55.13.63 0c.44-.77-1.77-3.06-1.77-3.06s-2.2 2.29-1.76 3.06c.08.13.62 0 .62 0s-2.06 2.13-1.64 2.85c.14.24 1.14 0 1.14 0s-2.06 2.13-1.65 2.85c.41.72 2.72 0 2.72 0v1.51h1.12V52s2.4.55 2.74 0Z"
                      />
                      <path id="Vector_61" d="M384.433 53.48V43.23c-.2.22-2.16 2.31-1.74 3 .08.13.62 0 .62 0s-2.06 2.13-1.64 2.85c.14.24 1.14 0 1.14 0s-2.06 2.13-1.65 2.85c.41.72 2.72 0 2.72 0v1.51l.55.04Z" />
                    </g>
                    <g id="Group 3">
                      <path
                        id="Vector_62"
                        d="M143.91 69.75c.47-.83-1.92-3.32-1.92-3.32s1.16.29 1.33 0c.48-.83-1.92-3.32-1.92-3.32s.64.16.73 0c.51-.89-2.06-3.56-2.06-3.56s-2.57 2.67-2 3.56c.09.16.72 0 .72 0s-2.39 2.49-1.91 3.32c.16.29 1.33 0 1.33 0s-2.4 2.49-1.92 3.32c.48.83 3.16 0 3.16 0v1.76h1.34v-1.76s2.72.69 3.12 0Z"
                      />
                      <path id="Vector_63" d="M140.051 71.51V59.57c-.24.26-2.52 2.7-2 3.54.09.16.72 0 .72 0s-2.391 2.49-1.911 3.32c.16.29 1.331 0 1.331 0s-2.4 2.49-1.92 3.32c.48.83 3.159 0 3.159 0v1.76h.621Z" />
                    </g>
                    <g id="Group 2">
                      <path
                        id="Vector_64"
                        d="M71.843 62.53c.55-1-2.23-3.85-2.23-3.85s1.35.34 1.55 0c.55-1-2.23-3.85-2.23-3.85s.74.18.84 0c.6-1-2.38-4.13-2.38-4.13s-3 3.1-2.38 4.13c.1.18.84 0 .84 0s-2.78 2.89-2.22 3.85c.19.34 1.54 0 1.54 0s-2.78 2.89-2.23 3.85c.46.8 3.68 0 3.68 0v2h1.55v-2s3.21.8 3.67 0Z"
                      />
                      <path id="Vector_65" d="M67.373 64.57V50.73c-.28.29-2.93 3.12-2.36 4.1.1.18.84 0 .84 0s-2.78 2.89-2.22 3.85c.19.34 1.54 0 1.54 0s-2.78 2.89-2.23 3.85c.46.8 3.68 0 3.68 0v2l.75.04Z" />
                    </g>
                    <g id="Group 4">
                      <path id="Vector_66" d="M243.722 55c.3-.51-1.19-2-1.19-2s.72.18.83 0c.29-.51-1.19-2.05-1.19-2.05s.39.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c0 .1.45 0 .45 0s-1.49 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 2 0 2 0v1.09h.82V55s1.67.4 1.92 0Z" />
                      <path id="Vector_67" d="M241.342 56.06v-7.38c-.15.16-1.56 1.67-1.26 2.19 0 .1.45 0 .45 0s-1.49 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 2 0 2 0v1.09l.36.05Z" />
                    </g>
                    <g id="Group 7">
                      <path
                        id="Vector_68"
                        d="M288.612 40.34c.3-.52-1.19-2.06-1.19-2.06s.72.18.83 0c.29-.51-1.19-2.05-1.19-2.05s.39.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c0 .1.45 0 .45 0s-1.48 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2.06c.3.52 2 0 2 0v1.08h.82v-1.08s1.68.42 1.92 0Z"
                      />
                      <path id="Vector_69" d="M286.232 41.42V34c-.15.16-1.56 1.67-1.26 2.19 0 .1.45 0 .45 0s-1.48 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2.06c.3.52 2 0 2 0v1.08l.36.04Z" />
                    </g>
                    <g id="Group 5">
                      <path
                        id="Vector_70"
                        d="M262.573 68.42c.29-.51-1.19-2-1.19-2s.72.18.82 0c.3-.52-1.18-2.06-1.18-2.06s.39.1.45 0c.31-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.05.1.44 0 .44 0s-1.48 1.54-1.18 2.06c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 1.95 0 1.95 0v1.09h.83v-1.09s1.71.43 1.96 0Z"
                      />
                      <path id="Vector_71" d="M260.183 69.51v-7.38c-.15.16-1.56 1.66-1.25 2.18.05.1.44 0 .44 0s-1.48 1.54-1.18 2.06c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 1.95 0 1.95 0v1.09l.4.05Z" />
                    </g>
                    <g id="Group 6">
                      <path
                        id="Vector_72"
                        d="M291.003 75.82c.3-.52-1.19-2.06-1.19-2.06s.72.18.83 0c.29-.51-1.19-2.05-1.19-2.05s.39.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.05.1.45 0 .45 0s-1.48 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2.06c.3.52 2 0 2 0v1.08h.82v-1.08s1.7.42 1.92 0Z"
                      />
                      <path id="Vector_73" d="M288.642 76.9v-7.38c-.15.16-1.56 1.67-1.26 2.19.05.1.45 0 .45 0s-1.48 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2.06c.3.52 2 0 2 0v1.08h.36Z" />
                    </g>
                    <g id="Group 8">
                      <path
                        id="Vector_74"
                        d="M344.1 56.07c.3-.51-1.19-2-1.19-2s.72.18.83 0c.29-.51-1.19-2.05-1.19-2.05s.39.09.45 0c.32-.55-1.27-2.21-1.27-2.21s-1.59 1.66-1.27 2.21c.05.09.45 0 .45 0s-1.49 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 2 0 2 0v1.09h.82v-1.09s1.67.43 1.92 0Z"
                      />
                      <path id="Vector_75" d="M341.72 57.16v-7.38c-.15.16-1.56 1.66-1.26 2.19.05.09.45 0 .45 0s-1.49 1.54-1.19 2.05c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 2 0 2 0v1.09l.36.05Z" />
                    </g>
                    <g id="Group 10">
                      <path
                        id="Vector_76"
                        d="M453.22 66c.47-.8-1.86-3.22-1.86-3.22s1.13.28 1.29 0c.47-.81-1.86-3.23-1.86-3.23s.62.16.71 0c.5-.86-2-3.45-2-3.45s-2.49 2.59-2 3.45c.09.16.7 0 .7 0s-2.32 2.42-1.86 3.23c.16.28 1.29 0 1.29 0s-2.33 2.42-1.86 3.22c.47.8 3.07 0 3.07 0v1.71h1.3V66s2.7.67 3.08 0Z"
                      />
                      <path id="Vector_77" d="M449.48 67.71V56.12c-.23.25-2.45 2.62-2 3.43.09.16.7 0 .7 0s-2.32 2.42-1.86 3.23c.16.28 1.29 0 1.29 0s-2.33 2.42-1.86 3.22c.47.8 3.07 0 3.07 0v1.71h.66Z" />
                    </g>
                    <g id="Group 11">
                      <path id="Vector_78" d="M532.251 75.49c.26-.46-1.06-1.84-1.06-1.84s.64.16.73 0c.27-.46-1.06-1.84-1.06-1.84s.35.09.4 0c.29-.49-1.14-2-1.14-2s-1.42 1.48-1.14 2c0 .09.4 0 .4 0s-1.32 1.38-1.06 1.84c.09.16.74 0 .74 0s-1.33 1.35-1.06 1.84 1.75 0 1.75 0v1h.74v-1s1.51.38 1.76 0Z" />
                      <path id="Vector_79" d="M530.111 76.47v-6.62c-.13.14-1.4 1.49-1.13 2 0 .09.4 0 .4 0s-1.32 1.38-1.06 1.84c.09.16.74 0 .74 0s-1.33 1.31-1.06 1.8 1.75 0 1.75 0v1l.36-.02Z" />
                    </g>
                    <g id="Group 12">
                      <path
                        id="Vector_80"
                        d="M576.002 51.09c.34-.59-1.35-2.34-1.35-2.34s.82.2.94 0c.33-.59-1.36-2.35-1.36-2.35s.45.11.52 0c.36-.62-1.45-2.51-1.45-2.51s-1.81 1.89-1.45 2.51c.06.11.51 0 .51 0s-1.69 1.76-1.35 2.35c.11.2.93 0 .93 0s-1.69 1.75-1.35 2.34c.34.59 2.24 0 2.24 0v1.24h.94v-1.24s1.9.48 2.23 0Z"
                      />
                      <path id="Vector_81" d="M573.231 52.33v-8.42c-.17.18-1.78 1.9-1.43 2.49.06.11.51 0 .51 0s-1.69 1.76-1.35 2.35c.11.2.93 0 .93 0s-1.69 1.75-1.35 2.34c.34.59 2.24 0 2.24 0v1.24h.45Z" />
                    </g>
                    <g id="Group 15">
                      <path
                        id="Vector_82"
                        d="M656.002 47.6c.39-.67-1.55-2.69-1.55-2.69s.94.23 1.08 0c.39-.68-1.56-2.7-1.56-2.7s.52.13.59 0c.42-.72-1.67-2.88-1.67-2.88s-2.08 2.16-1.66 2.88c.07.13.59 0 .59 0s-1.95 2-1.56 2.7c.14.23 1.08 0 1.08 0s-1.95 2-1.56 2.69c.39.69 2.57 0 2.57 0V49h1.09v-1.4s2.21.56 2.56 0Z"
                      />
                      <path id="Vector_83" d="M652.851 49v-9.66c-.2.21-2.05 2.19-1.65 2.87.07.13.59 0 .59 0s-1.95 2-1.56 2.7c.14.23 1.08 0 1.08 0s-1.95 2-1.56 2.69c.39.69 2.57 0 2.57 0V49h.53Z" />
                    </g>
                    <g id="Group 14">
                      <path
                        id="Vector_84"
                        d="M627.112 75.33c.31-.53-1.24-2.14-1.24-2.14s.75.18.86 0c.31-.54-1.24-2.15-1.24-2.15s.41.1.47 0c.33-.57-1.33-2.3-1.33-2.3s-1.63 1.73-1.33 2.26c.06.1.47 0 .47 0s-1.54 1.61-1.23 2.15c.1.18.85 0 .85 0s-1.54 1.61-1.23 2.14c.31.53 2 0 2 0v1.14h.86v-1.1s1.83.45 2.09 0Z"
                      />
                      <path id="Vector_85" d="M624.622 76.47v-7.71c-.16.16-1.63 1.74-1.32 2.28.06.1.47 0 .47 0s-1.54 1.61-1.23 2.15c.1.18.85 0 .85 0s-1.54 1.61-1.23 2.14c.31.53 2 0 2 0v1.14h.46Z" />
                    </g>
                    <g id="Group 17">
                      <path
                        id="Vector_86"
                        d="M744 55.38c.41-.69-1.61-2.79-1.61-2.79s1 .24 1.12 0c.4-.7-1.62-2.8-1.62-2.8s.54.13.61 0c.44-.75-1.73-3-1.73-3s-2.16 2.25-1.73 3c.08.13.61 0 .61 0s-2 2.1-1.61 2.8c.14.24 1.12 0 1.12 0s-2 2.1-1.62 2.79c.38.69 2.67 0 2.67 0v1.49h1.13v-1.49s2.28.62 2.66 0Z"
                      />
                      <path id="Vector_87" d="M740.713 56.87V46.81c-.21.21-2.13 2.27-1.72 3 .08.13.61 0 .61 0s-2 2.1-1.61 2.8c.14.24 1.12 0 1.12 0s-2 2.1-1.62 2.79c.38.69 2.67 0 2.67 0v1.49l.55-.02Z" />
                    </g>
                    <g id="Group 16">
                      <path id="Vector_88" d="M736.871 67.06c.26-.45-1-1.81-1-1.81s.63.16.72 0c.26-.45-1-1.8-1-1.8s.34.08.39 0c.28-.49-1.12-1.94-1.12-1.94s-1.39 1.45-1.11 1.94c0 .08.39 0 .39 0s-1.3 1.35-1 1.8c.09.16.72 0 .72 0s-1.3 1.36-1 1.81c.3.45 1.72 0 1.72 0v1h.73v-1s1.34.37 1.56 0Z" />
                      <path id="Vector_89" d="M734.773 68v-6.5c-.13.14-1.37 1.47-1.1 1.93 0 .08.39 0 .39 0s-1.3 1.35-1 1.8c.09.16.72 0 .72 0s-1.3 1.36-1 1.81c.3.45 1.72 0 1.72 0v1l.27-.04Z" />
                    </g>
                    <g id="Group 101">
                      <path id="Vector_90" d="M785.723 75.65c.26-.45-1-1.8-1-1.8s.63.16.72 0c.26-.45-1-1.81-1-1.81s.34.09.39 0c.28-.48-1.12-1.93-1.12-1.93s-1.39 1.45-1.11 1.93c.05.09.39 0 .39 0s-1.3 1.36-1 1.81c.09.16.72 0 .72 0s-1.3 1.35-1 1.8c.3.45 1.72 0 1.72 0v1h.57v-1s1.5.35 1.72 0Z" />
                      <path id="Vector_91" d="M783.621 76.61v-6.49c-.13.14-1.37 1.46-1.1 1.92.05.09.39 0 .39 0s-1.3 1.36-1 1.81c.09.16.72 0 .72 0s-1.3 1.35-1 1.8c.3.45 1.72 0 1.72 0v1l.27-.04Z" />
                    </g>
                    <g id="Group 18">
                      <path
                        id="Vector_92"
                        d="M830.15 54c.34-.58-1.34-2.33-1.34-2.33s.81.2.93 0c.33-.58-1.34-2.32-1.34-2.32s.44.11.5 0c.36-.63-1.44-2.5-1.44-2.5s-1.8 1.87-1.44 2.5c.07.11.51 0 .51 0s-1.68 1.74-1.34 2.32c.12.2.93 0 .93 0s-1.68 1.75-1.34 2.33c.34.58 2.22 0 2.22 0v1.23h.93V54s1.94.5 2.22 0Z"
                      />
                      <path id="Vector_93" d="M827.45 55.25v-8.36c-.17.18-1.77 1.88-1.43 2.48.07.11.51 0 .51 0s-1.68 1.74-1.34 2.32c.12.2.93 0 .93 0s-1.68 1.75-1.34 2.33c.34.58 2.22-.02 2.22-.02v1.23l.45.02Z" />
                    </g>
                    <g id="Group 20">
                      <path
                        id="Vector_94"
                        d="M900.572 22.74c.27-.47-1.07-1.86-1.07-1.86s.65.16.74 0c.27-.46-1.07-1.85-1.07-1.85s.36.09.41 0c.28-.49-1.15-2-1.15-2s-1.43 1.49-1.14 2c.05.09.4 0 .4 0s-1.34 1.39-1.07 1.85c.09.16.74 0 .74 0s-1.33 1.39-1.07 1.86c.26.47 1.77 0 1.77 0v1h.74v-1s1.55.38 1.77 0Z"
                      />
                      <path id="Vector_95" d="M898.422 23.72v-6.66c-.13.14-1.41 1.5-1.13 2 .05.09.4 0 .4 0s-1.34 1.39-1.07 1.85c.09.16.74 0 .74 0s-1.33 1.39-1.07 1.86c.26.47 1.77 0 1.77 0v1l.36-.05Z" />
                    </g>
                    <g id="Group 22">
                      <path
                        id="Vector_96"
                        d="M970.312 56.44c.32-.55-1.27-2.2-1.27-2.2s.77.19.88 0c.32-.55-1.27-2.2-1.27-2.2s.42.1.48 0c.34-.59-1.36-2.36-1.36-2.36s-1.7 1.77-1.36 2.36c.06.1.48 0 .48 0s-1.59 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.58 1.65-1.27 2.2c.31.55 2.1 0 2.1 0v1.16h.88v-1.16s1.84.45 2.1 0Z"
                      />
                      <path id="Vector_97" d="M967.762 57.6v-7.9c-.16.16-1.67 1.78-1.35 2.34.06.1.48 0 .48 0s-1.59 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.58 1.65-1.27 2.2c.31.55 2.1 0 2.1 0v1.16h.43Z" />
                    </g>
                    <g id="Group 23">
                      <path id="Vector_98" d="M1013.84 27.55c.27-.47-1.09-1.88-1.09-1.88s.66.16.76 0c.27-.48-1.09-1.89-1.09-1.89s.36.09.41 0c.29-.5-1.16-2-1.16-2s-1.46 1.52-1.17 2c0 .09.41 0 .41 0s-1.36 1.41-1.09 1.89c.1.16.76 0 .76 0s-1.36 1.41-1.09 1.88 1.8 0 1.8 0v1h.75v-1s1.58.39 1.8 0Z" />
                      <path id="Vector_99" d="M1011.65 28.55v-6.77c-.13.14-1.43 1.52-1.15 2 0 .09.41 0 .41 0s-1.36 1.41-1.09 1.89c.1.16.76 0 .76 0s-1.36 1.41-1.09 1.88 1.8 0 1.8 0v1h.36Z" />
                    </g>
                    <g id="Group 24">
                      <path
                        id="Vector_100"
                        d="M1085.94 55.77c.25-.43-1-1.74-1-1.74s.61.15.7 0c.25-.43-1-1.74-1-1.74s.33.08.38 0c.27-.47-1.08-1.86-1.08-1.86s-1.35 1.39-1.08 1.86c.05.08.38 0 .38 0s-1.25 1.31-1 1.74c.08.15.69 0 .69 0s-1.25 1.31-1 1.74c.25.43 1.66 0 1.66 0v.93h.7v-.93s1.44.36 1.65 0Z"
                      />
                      <path id="Vector_101" d="M1083.92 56.7v-6.26c-.13.13-1.32 1.41-1.07 1.85.05.08.38 0 .38 0s-1.25 1.31-1 1.74c.08.15.69 0 .69 0s-1.25 1.31-1 1.74c.25.43 1.66 0 1.66 0v.93h.34Z" />
                    </g>
                    <g id="Group 25">
                      <path
                        id="Vector_102"
                        d="M1132.62 31.51c.34-.59-1.36-2.35-1.36-2.35s.82.2.94 0c.34-.59-1.36-2.36-1.36-2.36s.45.11.51 0c.37-.63-1.45-2.52-1.45-2.52s-1.83 1.89-1.46 2.52c.06.11.51 0 .51 0s-1.7 1.77-1.36 2.36c.12.2 1 0 1 0s-1.7 1.76-1.36 2.35c.34.59 2.24 0 2.24 0v1.25h.95v-1.25s1.92.49 2.2 0Z"
                      />
                      <path id="Vector_103" d="M1129.88 32.76v-8.47c-.17.18-1.79 1.91-1.44 2.51.06.11.51 0 .51 0s-1.7 1.77-1.36 2.36c.12.2 1 0 1 0s-1.7 1.76-1.36 2.35c.34.59 2.24 0 2.24 0v1.25h.41Z" />
                    </g>
                    <g id="Group 26">
                      <path id="Vector_104" d="M1157.7 71.79c.26-.45-1-1.79-1-1.79s.63.15.72 0c.26-.45-1-1.8-1-1.8s.34.09.39 0c.28-.48-1.11-1.92-1.11-1.92s-1.39 1.44-1.11 1.92c0 .09.39 0 .39 0s-1.29 1.35-1 1.8c.08.15.71 0 .71 0s-1.29 1.34-1 1.79c.29.45 1.71 0 1.71 0v1h.72v-1s1.37.37 1.58 0Z" />
                      <path id="Vector_105" d="M1155.62 72.75v-6.46c-.13.14-1.36 1.46-1.1 1.91 0 .09.39 0 .39 0s-1.29 1.35-1 1.8c.08.15.71 0 .71 0s-1.29 1.34-1 1.79c.29.45 1.71 0 1.71 0v1l.29-.04Z" />
                    </g>
                    <g id="Group 27">
                      <path
                        id="Vector_106"
                        d="M1204 61.83c.33-.57-1.32-2.29-1.32-2.29s.8.19.92 0c.33-.58-1.33-2.3-1.33-2.3s.44.11.5 0c.36-.61-1.41-2.45-1.41-2.45s-1.78 1.84-1.42 2.45c.06.11.5 0 .5 0s-1.66 1.72-1.32 2.3c.11.19.91 0 .91 0s-1.65 1.72-1.32 2.29c.33.57 2.18 0 2.18 0V63h.93v-1.17s1.94.47 2.18 0Z"
                      />
                      <path id="Vector_107" d="M1201.37 63v-8.2c-.17.18-1.74 1.86-1.4 2.44.06.11.5 0 .5 0s-1.66 1.72-1.32 2.3c.11.19.91 0 .91 0s-1.65 1.72-1.32 2.29c.33.57 2.18 0 2.18 0V63h.45Z" />
                    </g>
                    <g id="Group 29">
                      <path
                        id="Vector_108"
                        d="M1317.29 54.14c.35-.61-1.4-2.43-1.4-2.43s.85.21 1 0c.35-.6-1.4-2.43-1.4-2.43s.46.12.53 0c.38-.65-1.5-2.6-1.5-2.6s-1.88 2-1.51 2.6c.07.12.53 0 .53 0s-1.75 1.83-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.32 0 2.32 0v1.29h1v-1.29s1.94.5 2.23 0Z"
                      />
                      <path id="Vector_109" d="M1314.47 55.43V46.7c-.18.18-1.84 2-1.49 2.58.07.12.53 0 .53 0s-1.75 1.83-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.32 0 2.32 0v1.29h.44Z" />
                    </g>
                    <g id="Group 30">
                      <path
                        id="Vector_110"
                        d="M1345.05 74.89c.35-.61-1.41-2.43-1.41-2.43s.85.21 1 0c.35-.61-1.41-2.43-1.41-2.43s.47.11.53 0c.38-.65-1.5-2.6-1.5-2.6s-1.88 1.95-1.5 2.6c.06.11.53 0 .53 0s-1.75 1.82-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.31 0 2.31 0v1.28h1v-1.28s1.96.5 2.25 0Z"
                      />
                      <path id="Vector_111" d="M1342.23 76.17v-8.73c-.18.19-1.85 2-1.49 2.59.06.11.53 0 .53 0s-1.75 1.82-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.31 0 2.31 0v1.28h.45Z" />
                    </g>
                    <g id="Group 33">
                      <path
                        id="Vector_112"
                        d="M1436.84 71.21c.35-.61-1.43-2.46-1.43-2.46s.87.21 1 0c.35-.62-1.43-2.47-1.43-2.47s.48.12.54 0c.39-.66-1.52-2.64-1.52-2.64s-1.91 2-1.53 2.64c.07.12.54 0 .54 0s-1.78 1.85-1.42 2.47c.12.21 1 0 1 0s-1.77 1.85-1.42 2.46c.35.61 2.35 0 2.35 0v1.31h1v-1.31s2.02.51 2.32 0Z"
                      />
                      <path id="Vector_113" d="M1434 72.52v-8.87c-.18.19-1.87 2-1.51 2.63.07.12.54 0 .54 0s-1.78 1.85-1.42 2.47c.12.21 1 0 1 0s-1.77 1.85-1.42 2.46c.35.61 2.35 0 2.35 0v1.31h.46Z" />
                    </g>
                    <g id="Group 32">
                      <path
                        id="Vector_114"
                        d="M1414.56 35.73c.42-.73-1.69-2.91-1.69-2.91s1 .25 1.17 0c.42-.73-1.68-2.92-1.68-2.92s.55.14.63 0c.45-.78-1.8-3.12-1.8-3.12s-2.25 2.34-1.8 3.12c.08.14.64 0 .64 0s-2.11 2.19-1.69 2.92c.15.25 1.17 0 1.17 0s-2.1 2.18-1.68 2.91c.42.73 2.77 0 2.77 0v1.54h1.18v-1.54s2.43.6 2.78 0Z"
                      />
                      <path id="Vector_115" d="M1411.17 37.27V26.8c-.21.22-2.21 2.36-1.78 3.1.08.14.64 0 .64 0s-2.11 2.19-1.69 2.92c.15.25 1.17 0 1.17 0s-2.1 2.18-1.68 2.91c.42.73 2.77 0 2.77 0v1.54h.57Z" />
                    </g>
                    <g id="Group 34">
                      <path
                        id="Vector_116"
                        d="M1504.25 78.11c.42-.73-1.69-2.91-1.69-2.91s1 .25 1.17 0c.42-.73-1.68-2.91-1.68-2.91s.56.13.64 0c.45-.78-1.81-3.13-1.81-3.13s-2.25 2.35-1.8 3.13c.08.13.64 0 .64 0s-2.11 2.18-1.69 2.91c.15.25 1.17 0 1.17 0s-2.1 2.18-1.68 2.91c.42.73 2.78 0 2.78 0v1.55h1.17v-1.55s2.43.6 2.78 0Z"
                      />
                      <path id="Vector_117" d="M1500.86 79.66V69.18c-.21.23-2.21 2.37-1.78 3.11.08.13.64 0 .64 0s-2.11 2.18-1.69 2.91c.15.25 1.17 0 1.17 0s-2.1 2.18-1.68 2.91c.42.73 2.78 0 2.78 0v1.55h.56Z" />
                    </g>
                    <g id="Group 35">
                      <path
                        id="Vector_118"
                        d="M1548.08 62.06c.3-.52-1.18-2.05-1.18-2.05s.72.18.82 0c.3-.51-1.18-2-1.18-2s.39.1.45 0c.31-.55-1.27-2.19-1.27-2.19s-1.58 1.64-1.27 2.19c.06.1.45 0 .45 0s-1.48 1.54-1.18 2c.1.18.82 0 .82 0s-1.48 1.53-1.18 2.05c.3.52 2 0 2 0v1.08h.82v-1.08s1.66.42 1.9 0Z"
                      />
                      <path id="Vector_119" d="M1545.71 63.14v-7.36c-.15.16-1.56 1.66-1.26 2.18.06.1.45 0 .45 0s-1.48 1.54-1.18 2c.1.18.82 0 .82 0s-1.48 1.53-1.18 2.05c.3.52 2 0 2 0v1.08l.35.05Z" />
                    </g>
                    <g id="Group 36">
                      <path
                        id="Vector_120"
                        d="M1598.9 65.73c.29-.51-1.18-2.05-1.18-2.05s.71.18.82 0c.29-.51-1.18-2-1.18-2s.39.1.44 0c.32-.54-1.26-2.19-1.26-2.19s-1.59 1.65-1.27 2.19c.06.1.45 0 .45 0s-1.48 1.54-1.18 2c.1.18.82 0 .82 0s-1.48 1.54-1.19 2.05c.29.51 1.95 0 1.95 0v1.08h.83v-1.08s1.71.42 1.95 0Z"
                      />
                      <path id="Vector_121" d="M1596.52 66.81v-7.36c-.15.16-1.55 1.66-1.25 2.18.06.1.45 0 .45 0s-1.48 1.54-1.18 2c.1.18.82 0 .82 0s-1.48 1.54-1.19 2.05c.29.51 1.95 0 1.95 0v1.08l.4.05Z" />
                    </g>
                    <g id="Group 37">
                      <path
                        id="Vector_122"
                        d="M1661.65 78.11c.35-.61-1.41-2.44-1.41-2.44s.86.21 1 0c.35-.61-1.41-2.44-1.41-2.44s.47.12.53 0c.38-.65-1.51-2.61-1.51-2.61s-1.88 2-1.51 2.61c.07.12.54 0 .54 0s-1.76 1.83-1.41 2.44c.12.21 1 0 1 0s-1.77 1.83-1.41 2.44c.36.61 2.32 0 2.32 0v1.3h1v-1.3s1.98.51 2.27 0Z"
                      />
                      <path id="Vector_123" d="M1658.82 79.41v-8.78c-.18.19-1.85 2-1.5 2.6.07.12.54 0 .54 0s-1.76 1.83-1.41 2.44c.12.21 1 0 1 0s-1.77 1.83-1.41 2.44c.36.61 2.32 0 2.32 0v1.3h.46Z" />
                    </g>
                    <g id="Group 39">
                      <path
                        id="Vector_124"
                        d="M1725.36 54.68c.34-.58-1.34-2.33-1.34-2.33s.81.2.93 0c.34-.58-1.35-2.33-1.35-2.33s.45.11.51 0c.36-.63-1.44-2.5-1.44-2.5s-1.8 1.87-1.44 2.5c.06.11.51 0 .51 0s-1.69 1.75-1.35 2.33c.12.2.93 0 .93 0s-1.68 1.75-1.34 2.33c.34.58 2.22 0 2.22 0v1.24h.94v-1.24s1.94.48 2.22 0Z"
                      />
                      <path id="Vector_125" d="M1722.65 55.92v-8.39c-.16.18-1.77 1.9-1.42 2.49.06.11.51 0 .51 0s-1.69 1.75-1.35 2.33c.12.2.93 0 .93 0s-1.68 1.75-1.34 2.33c.34.58 2.22 0 2.22 0v1.24h.45Z" />
                    </g>
                    <g id="Group 41">
                      <path id="Vector_126" d="M1850.19 76.47c.29-.49-1.14-2-1.14-2s.69.17.79 0c.29-.49-1.14-2-1.14-2s.38.09.43 0c.31-.53-1.22-2.12-1.22-2.12s-1.54 1.59-1.23 2.12c0 .09.43 0 .43 0s-1.43 1.49-1.14 2c.1.17.79 0 .79 0s-1.43 1.49-1.14 2c.29.51 1.89 0 1.89 0v1h.79v-1s1.7.41 1.89 0Z" />
                      <path id="Vector_127" d="M1847.89 77.52V70.4c-.14.15-1.5 1.61-1.21 2.11 0 .09.43 0 .43 0s-1.43 1.49-1.14 2c.1.17.79 0 .79 0s-1.43 1.49-1.14 2c.29.51 1.89 0 1.89 0v1l.38.01Z" />
                    </g>
                    <g id="Group 42">
                      <path
                        id="Vector_128"
                        d="M1938 70.07c.35-.61-1.4-2.43-1.4-2.43s.85.21 1 0c.35-.61-1.41-2.44-1.41-2.44s.47.12.54 0c.37-.65-1.51-2.61-1.51-2.61s-1.88 2-1.51 2.61c.07.12.53 0 .53 0s-1.75 1.83-1.4 2.44c.12.21 1 0 1 0s-1.76 1.82-1.4 2.43c.36.61 2.32 0 2.32 0v1.29h1v-1.29s1.92.51 2.24 0Z"
                      />
                      <path id="Vector_129" d="M1935.14 71.36V62.6c-.17.19-1.85 2-1.49 2.6.07.12.53 0 .53 0s-1.75 1.83-1.4 2.44c.12.21 1 0 1 0s-1.76 1.82-1.4 2.43c.36.61 2.32 0 2.32 0v1.29h.44Z" />
                    </g>
                    <g id="Group 44">
                      <path
                        id="Vector_130"
                        d="M2015.93 75.68c.4-.7-1.61-2.78-1.61-2.78s1 .24 1.11 0c.4-.7-1.6-2.78-1.6-2.78s.53.13.61 0c.43-.75-1.73-3-1.73-3s-2.15 2.23-1.72 3c.08.13.61 0 .61 0s-2 2.08-1.6 2.78c.14.24 1.11 0 1.11 0s-2 2.08-1.61 2.78c.39.7 2.65 0 2.65 0v1.47h1.12v-1.47s2.32.57 2.66 0Z"
                      />
                      <path id="Vector_131" d="M2012.7 77.15v-10c-.21.22-2.11 2.26-1.71 3 .08.13.61 0 .61 0s-2 2.08-1.6 2.78c.14.24 1.11 0 1.11 0s-2 2.08-1.61 2.78c.39.7 2.65 0 2.65 0v1.47l.55-.03Z" />
                    </g>
                    <g id="Group 45">
                      <path
                        id="Vector_132"
                        d="M2046.22 42.46c.32-.55-1.26-2.19-1.26-2.19s.76.19.87 0c.32-.55-1.26-2.2-1.26-2.2s.42.11.48 0c.34-.58-1.36-2.35-1.36-2.35s-1.7 1.77-1.36 2.35c.06.11.48 0 .48 0s-1.58 1.65-1.26 2.2c.11.19.87 0 .87 0s-1.58 1.64-1.26 2.19c.32.55 2.09 0 2.09 0v1.16h.88v-1.16s1.87.45 2.09 0Z"
                      />
                      <path id="Vector_133" d="M2043.68 43.62v-7.88c-.16.17-1.67 1.78-1.35 2.33.06.11.48 0 .48 0s-1.58 1.65-1.26 2.2c.11.19.87 0 .87 0s-1.58 1.64-1.26 2.19c.32.55 2.09 0 2.09 0v1.16h.43Z" />
                    </g>
                    <g id="Group 46">
                      <path
                        id="Vector_134"
                        d="M2118.24 66.57c.43-.74-1.7-2.95-1.7-2.95s1 .25 1.18 0c.42-.74-1.7-2.95-1.7-2.95s.56.14.64 0c.46-.79-1.82-3.16-1.82-3.16s-2.29 2.37-1.83 3.16c.08.14.65 0 .65 0s-2.13 2.21-1.71 2.95c.15.25 1.18 0 1.18 0s-2.12 2.21-1.7 2.95c.42.74 2.81 0 2.81 0v1.56h1.19v-1.56s2.46.61 2.81 0Z"
                      />
                      <path id="Vector_135" d="M2114.82 68.13v-10.6c-.22.23-2.24 2.39-1.81 3.14.08.14.65 0 .65 0s-2.13 2.21-1.71 2.95c.15.25 1.18 0 1.18 0s-2.12 2.21-1.7 2.95c.42.74 2.81 0 2.81 0v1.56h.58Z" />
                    </g>
                    <g id="Group 47">
                      <path
                        id="Vector_136"
                        d="M2222.75 62.11c.24-.43-1-1.72-1-1.72s.6.15.69 0c.25-.43-1-1.72-1-1.72s.33.08.37 0c.27-.46-1.06-1.84-1.06-1.84s-1.33 1.38-1.06 1.84c0 .08.37 0 .37 0s-1.24 1.29-1 1.72c.08.15.69 0 .69 0s-1.24 1.29-1 1.72c.24.43 1.64 0 1.64 0V63h.7v-.91s1.45.38 1.66.02Z"
                      />
                      <path id="Vector_137" d="M2220.75 63v-6.16c-.13.13-1.31 1.4-1.05 1.83 0 .08.37 0 .37 0s-1.24 1.29-1 1.72c.08.15.69 0 .69 0s-1.24 1.29-1 1.72c.24.43 1.64 0 1.64 0V63h.35Z" />
                    </g>
                    <g id="Group 49">
                      <path
                        id="Vector_138"
                        d="M2294.25 67.58c.36-.63-1.43-2.49-1.43-2.49s.87.21 1 0c.36-.63-1.43-2.49-1.43-2.49s.47.11.54 0c.39-.67-1.54-2.67-1.54-2.67s-1.93 2-1.54 2.67c.07.11.54 0 .54 0s-1.8 1.86-1.44 2.49c.13.21 1 0 1 0s-1.8 1.86-1.44 2.49c.36.63 2.38 0 2.38 0v1.32h1v-1.32s2.11.51 2.36 0Z"
                      />
                      <path id="Vector_139" d="M2291.36 68.9v-9c-.18.19-1.89 2-1.52 2.66.07.11.54 0 .54 0s-1.8 1.86-1.44 2.49c.13.21 1 0 1 0s-1.8 1.86-1.44 2.49c.36.63 2.38 0 2.38 0v1.36h.48Z" />
                    </g>
                    <g id="Group 50">
                      <path
                        id="Vector_140"
                        d="M2321.88 47c.31-.53-1.22-2.12-1.22-2.12s.74.19.85 0c.3-.53-1.23-2.12-1.23-2.12s.41.1.47 0c.32-.57-1.32-2.28-1.32-2.28s-1.64 1.71-1.31 2.28c.06.1.46 0 .46 0s-1.53 1.59-1.22 2.12c.1.19.85 0 .85 0s-1.53 1.59-1.23 2.12c.3.53 2 0 2 0v1.13h.85V47s1.8.46 2.05 0Z"
                      />
                      <path id="Vector_141" d="M2319.42 48.15v-7.63c-.15.16-1.61 1.72-1.3 2.26.06.1.46 0 .46 0s-1.53 1.59-1.22 2.12c.1.19.85 0 .85 0s-1.53 1.59-1.23 2.12c.3.53 2 0 2 0v1.13h.44Z" />
                    </g>
                    <g id="Group 51">
                      <path
                        id="Vector_142"
                        d="M2392.75 42.48c.51-.87-2-3.5-2-3.5s1.22.31 1.39 0c.51-.87-2-3.49-2-3.49s.66.16.76 0c.54-.94-2.16-3.75-2.16-3.75s-2.71 2.81-2.17 3.75c.1.16.77 0 .77 0s-2.53 2.62-2 3.49c.17.31 1.4 0 1.4 0s-2.53 2.63-2 3.5c.53.87 3.33 0 3.33 0v1.85h1.41v-1.85s2.86.72 3.27 0Z"
                      />
                      <path id="Vector_143" d="M2388.69 44.33V31.76c-.25.27-2.65 2.84-2.14 3.73.1.16.77 0 .77 0s-2.53 2.62-2 3.49c.17.31 1.4 0 1.4 0s-2.53 2.63-2 3.5c.53.87 3.33 0 3.33 0v1.85h.64Z" />
                    </g>
                    <g id="Group 43">
                      <path
                        id="Vector_144"
                        d="M1971.66 60.25c.44-.76-1.74-3-1.74-3s1.06.26 1.21 0c.43-.75-1.75-3-1.75-3s.58.14.66 0c.47-.81-1.87-3.24-1.87-3.24s-2.34 2.43-1.87 3.24c.08.14.66 0 .66 0s-2.18 2.27-1.74 3c.15.26 1.21 0 1.21 0s-2.19 2.27-1.75 3c.44.73 2.88 0 2.88 0v1.6h1.22v-1.6s2.52.62 2.88 0Z"
                      />
                      <path id="Vector_145" d="M1968.15 61.85V51c-.22.23-2.29 2.45-1.85 3.22.08.14.66 0 .66 0s-2.18 2.27-1.74 3c.15.26 1.21 0 1.21 0s-2.19 2.27-1.75 3c.44.73 2.88 0 2.88 0v1.6l.59.03Z" />
                    </g>
                    <g id="Group 21">
                      <path
                        id="Vector_146"
                        d="M918.103 65.37c.32-.55-1.27-2.2-1.27-2.2s.77.19.88 0c.32-.55-1.27-2.2-1.27-2.2s.42.11.48 0c.34-.59-1.36-2.35-1.36-2.35s-1.7 1.76-1.36 2.35c.06.11.48 0 .48 0s-1.59 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.59 1.65-1.27 2.2c.32.55 2.1 0 2.1 0v1.17h.88v-1.17s1.84.46 2.1 0Z"
                      />
                      <path id="Vector_147" d="M915.553 66.54v-7.91c-.16.17-1.67 1.79-1.35 2.34.06.11.48 0 .48 0s-1.59 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.59 1.65-1.27 2.2c.32.55 2.1 0 2.1 0v1.17h.43Z" />
                    </g>
                    <g id="Group 54">
                      <path id="Vector_148" d="M2648.31 53.86c.3-.52-1.18-2.06-1.18-2.06s.72.18.82 0c.3-.51-1.19-2-1.19-2s.4.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.06.1.45 0 .45 0s-1.48 1.54-1.19 2c.11.18.83 0 .83 0s-1.49 1.54-1.19 2.06c.3.52 2 0 2 0V55h.83v-1.14s1.67.42 1.91 0Z" />
                      <path id="Vector_149" d="M2645.93 55v-7.44c-.15.16-1.56 1.67-1.26 2.19.06.1.45 0 .45 0s-1.48 1.54-1.19 2c.11.18.83 0 .83 0s-1.49 1.54-1.19 2.06c.3.52 2 0 2 0V55h.36Z" />
                    </g>
                    <g id="Group 56">
                      <path id="Vector_150" d="M2693.2 39.22c.3-.51-1.18-2-1.18-2s.72.17.82 0c.3-.52-1.19-2.06-1.19-2.06s.4.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.06.1.45 0 .45 0s-1.48 1.54-1.18 2.06c.1.17.82 0 .82 0s-1.49 1.54-1.19 2c.3.46 2 0 2 0v1.09h.83v-1.09s1.71.42 1.91 0Z" />
                      <path id="Vector_151" d="M2690.82 40.31v-7.38c-.15.15-1.56 1.66-1.26 2.18.06.1.45 0 .45 0s-1.48 1.54-1.18 2.06c.1.17.82 0 .82 0s-1.49 1.54-1.19 2c.3.46 2 0 2 0v1.09l.36.05Z" />
                    </g>
                    <g id="Group 55">
                      <path id="Vector_152" d="M2667.16 67.3c.3-.51-1.18-2-1.18-2s.71.18.82 0c.29-.51-1.19-2-1.19-2s.39.09.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.06.09.45 0 .45 0s-1.48 1.54-1.19 2c.11.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 2 0 2 0v1.09h.82V67.3s1.68.43 1.92 0Z" />
                      <path id="Vector_153" d="M2664.78 68.39V61c-.15.16-1.56 1.67-1.26 2.19.06.09.45 0 .45 0s-1.48 1.54-1.19 2c.11.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 2 0 2 0v1.09l.36.11Z" />
                    </g>
                    <g id="Group 57">
                      <path id="Vector_154" d="M2695.61 74.7c.3-.51-1.18-2-1.18-2s.72.17.82 0c.3-.52-1.19-2.06-1.19-2.06s.4.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.06.1.45 0 .45 0s-1.48 1.54-1.18 2.06c.1.17.82 0 .82 0s-1.48 1.54-1.19 2c.29.46 2 0 2 0v1.09h.83V74.7s1.67.42 1.91 0Z" />
                      <path id="Vector_155" d="M2693.23 75.79v-7.38c-.15.15-1.56 1.66-1.26 2.18.06.1.45 0 .45 0s-1.48 1.54-1.18 2.06c.1.17.82 0 .82 0s-1.48 1.54-1.19 2c.29.46 2 0 2 0v1.09l.36.05Z" />
                    </g>
                    <g id="Group 58">
                      <path id="Vector_156" d="M2748.69 55c.3-.52-1.18-2.06-1.18-2.06s.72.18.82 0c.3-.51-1.19-2-1.19-2s.4.1.45 0c.32-.55-1.27-2.2-1.27-2.2s-1.59 1.65-1.27 2.2c.06.1.45 0 .45 0s-1.48 1.54-1.19 2c.11.18.83 0 .83 0s-1.49 1.54-1.19 2.06c.3.52 2 0 2 0v1h.82v-1s1.68.38 1.92 0Z" />
                      <path id="Vector_157" d="M2746.31 56v-7.34c-.15.16-1.56 1.67-1.26 2.19.06.1.45 0 .45 0s-1.48 1.54-1.19 2c.11.18.83 0 .83 0s-1.49 1.54-1.19 2.06c.3.52 2 0 2 0V56h.36Z" />
                    </g>
                    <g id="Group 59">
                      <path
                        id="Vector_158"
                        d="M2792.34 50.85c.41-.71-1.65-2.85-1.65-2.85s1 .25 1.14 0c.41-.71-1.64-2.85-1.64-2.85s.54.13.62 0c.44-.76-1.76-3-1.76-3s-2.21 2.29-1.77 3c.08.13.62 0 .62 0s-2.05 2.14-1.64 2.85c.14.25 1.14 0 1.14 0s-2.06 2.14-1.65 2.85c.41.71 2.72 0 2.72 0v1.51h1.15v-1.51s2.38.59 2.72 0Z"
                      />
                      <path id="Vector_159" d="M2789 52.36V42.11c-.21.22-2.17 2.32-1.75 3 .08.13.62 0 .62 0s-2.05 2.14-1.64 2.85c.14.25 1.14 0 1.14 0s-2.06 2.14-1.65 2.85c.41.71 2.72 0 2.72 0v1.51l.56.04Z" />
                    </g>
                    <g id="Group 60">
                      <path
                        id="Vector_160"
                        d="M2858.13 61.2c.46-.81-1.86-3.22-1.86-3.22s1.13.28 1.29 0c.46-.81-1.86-3.23-1.86-3.23s.61.16.7 0c.5-.86-2-3.45-2-3.45s-2.5 2.59-2 3.45c.09.16.71 0 .71 0s-2.33 2.42-1.86 3.23c.16.28 1.29 0 1.29 0s-2.33 2.41-1.87 3.22c.46.81 3.08 0 3.08 0v1.71h1.3V61.2s2.69.67 3.08 0Z"
                      />
                      <path id="Vector_161" d="M2854.39 62.91V51.32c-.24.25-2.45 2.62-2 3.43.09.16.71 0 .71 0s-2.33 2.42-1.86 3.23c.16.28 1.29 0 1.29 0s-2.33 2.41-1.87 3.22c.46.81 3.08 0 3.08 0v1.71h.65Z" />
                    </g>
                    <g id="Group 61">
                      <path
                        id="Vector_162"
                        d="M2936.84 74.38c.27-.46-1.06-1.85-1.06-1.85s.64.16.74 0c.26-.46-1.07-1.84-1.07-1.84s.35.09.41 0c.28-.49-1.14-2-1.14-2s-1.43 1.48-1.14 2c.05.09.4 0 .4 0s-1.33 1.38-1.06 1.84c.09.16.73 0 .73 0s-1.33 1.39-1.06 1.85c.27.46 1.75 0 1.75 0v1h.75v-1s1.53.38 1.75 0Z"
                      />
                      <path id="Vector_163" d="M2934.7 75.35v-6.62c-.13.14-1.39 1.5-1.12 2 .05.09.4 0 .4 0s-1.33 1.38-1.06 1.84c.09.16.73 0 .73 0s-1.33 1.39-1.06 1.85c.27.46 1.75 0 1.75 0v1l.36-.07Z" />
                    </g>
                    <g id="Group 62">
                      <path
                        id="Vector_164"
                        d="M2980.54 50c.34-.59-1.35-2.34-1.35-2.34s.82.2.94 0c.34-.59-1.35-2.34-1.35-2.34s.45.11.51 0c.36-.63-1.45-2.51-1.45-2.51s-1.81 1.88-1.45 2.51c.06.11.51 0 .51 0s-1.69 1.75-1.35 2.34c.12.2.94 0 .94 0s-1.69 1.75-1.35 2.34c.34.59 2.23 0 2.23 0v1.24h.94V50s1.96.45 2.23 0Z"
                      />
                      <path id="Vector_165" d="M2977.83 51.21v-8.42c-.18.18-1.78 1.9-1.44 2.5.06.11.51 0 .51 0s-1.69 1.75-1.35 2.34c.12.2.94 0 .94 0s-1.69 1.75-1.35 2.34c.34.59 2.23 0 2.23 0v1.24h.46Z" />
                    </g>
                    <g id="Group 63">
                      <path
                        id="Vector_166"
                        d="M3007.66 34.39c.48-.83-1.93-3.33-1.93-3.33s1.17.29 1.34 0c.48-.83-1.93-3.33-1.93-3.33s.64.15.73 0c.52-.9-2.06-3.58-2.06-3.58s-2.58 2.68-2.07 3.58c.09.15.73 0 .73 0s-2.4 2.5-1.92 3.33c.16.29 1.33 0 1.33 0s-2.4 2.5-1.92 3.33c.48.83 3.18 0 3.18 0v1.77h1.34v-1.77s2.78.69 3.18 0Z"
                      />
                      <path id="Vector_167" d="M3003.79 36.16v-12c-.25.26-2.53 2.71-2.05 3.56.09.15.73 0 .73 0s-2.4 2.5-1.92 3.33c.16.29 1.33 0 1.33 0s-2.4 2.5-1.92 3.33c.48.83 3.18 0 3.18 0v1.77l.65.01Z" />
                    </g>
                    <g id="Group 65">
                      <path
                        id="Vector_168"
                        d="M3061.05 49.18c.38-.67-1.56-2.69-1.56-2.69s.94.23 1.08 0c.39-.68-1.56-2.7-1.56-2.7s.52.13.59 0c.42-.72-1.67-2.89-1.67-2.89s-2.08 2.17-1.66 2.89c.07.13.59 0 .59 0s-2 2-1.56 2.7c.14.23 1.08 0 1.08 0s-1.94 2-1.56 2.69c.38.69 2.57 0 2.57 0v1.43h1.09v-1.43s2.24.55 2.57 0Z"
                      />
                      <path id="Vector_169" d="M3057.92 50.61v-9.69c-.2.21-2.05 2.19-1.65 2.87.07.13.59 0 .59 0s-2 2-1.56 2.7c.14.23 1.08 0 1.08 0s-1.94 2-1.56 2.69c.38.69 2.57 0 2.57 0v1.43h.53Z" />
                    </g>
                    <g id="Group 64">
                      <path
                        id="Vector_170"
                        d="M3031.7 74.22c.31-.54-1.24-2.15-1.24-2.15s.75.19.86 0c.31-.54-1.24-2.14-1.24-2.14s.41.1.47 0c.33-.58-1.32-2.3-1.32-2.3s-1.66 1.72-1.33 2.3c.06.1.47 0 .47 0s-1.55 1.6-1.24 2.14c.11.19.86 0 .86 0s-1.55 1.61-1.24 2.15c.31.54 2 0 2 0v1.13h.87v-1.13s1.83.44 2.08 0Z"
                      />
                      <path id="Vector_171" d="M3029.21 75.35v-7.71c-.15.17-1.63 1.74-1.31 2.29.06.1.47 0 .47 0s-1.55 1.6-1.24 2.14c.11.19.86 0 .86 0s-1.55 1.61-1.24 2.15c.31.54 2 0 2 0v1.13h.46Z" />
                    </g>
                    <g id="Group 67">
                      <path id="Vector_172" d="M3141.46 65.94c.26-.45-1-1.8-1-1.8s.63.15.72 0c.26-.46-1-1.81-1-1.81s.35.08.39 0c.28-.48-1.11-1.94-1.11-1.94s-1.4 1.46-1.12 1.94c0 .08.39 0 .39 0s-1.3 1.35-1 1.81c.09.15.72 0 .72 0s-1.3 1.35-1 1.8c.3.45 1.72 0 1.72 0v1h.73v-1s1.35.38 1.56 0Z" />
                      <path id="Vector_173" d="M3139.37 66.9v-6.49c-.14.13-1.38 1.46-1.11 1.92 0 .08.39 0 .39 0s-1.3 1.35-1 1.81c.09.15.72 0 .72 0s-1.3 1.35-1 1.8c.3.45 1.72 0 1.72 0v1l.28-.04Z" />
                    </g>
                    <g id="Group 68">
                      <path id="Vector_174" d="M3190.31 74.54c.26-.45-1-1.81-1-1.81s.63.16.72 0c.26-.45-1-1.8-1-1.8s.35.08.4 0c.28-.49-1.12-1.94-1.12-1.94s-1.4 1.45-1.12 1.94c0 .08.39 0 .39 0s-1.3 1.35-1 1.8c.09.16.72 0 .72 0s-1.3 1.36-1 1.81c.3.45 1.72 0 1.72 0v.95h.73v-.95s1.35.37 1.56 0Z" />
                      <path id="Vector_175" d="M3188.22 75.49V69c-.13.14-1.37 1.47-1.11 1.93 0 .08.39 0 .39 0s-1.3 1.35-1 1.8c.09.16.72 0 .72 0s-1.3 1.36-1 1.81c.3.45 1.72 0 1.72 0v.95h.28Z" />
                    </g>
                    <g id="Group 104">
                      <path
                        id="Vector_176"
                        d="M3234.74 52.9c.34-.58-1.34-2.33-1.34-2.33s.82.21.93 0c.34-.58-1.34-2.32-1.34-2.32s.44.11.51 0c.36-.63-1.44-2.5-1.44-2.5s-1.8 1.87-1.44 2.5c.06.11.51 0 .51 0s-1.68 1.74-1.35 2.32c.12.21.93 0 .93 0s-1.67 1.75-1.34 2.33c.33.58 2.22 0 2.22 0v1.23h.94V52.9s1.94.48 2.21 0Z"
                      />
                      <path id="Vector_177" d="M3232 54.13v-8.36c-.17.18-1.76 1.89-1.42 2.48.06.11.51 0 .51 0s-1.68 1.74-1.35 2.32c.12.21.93 0 .93 0s-1.67 1.75-1.34 2.33c.33.58 2.22 0 2.22 0v1.23h.45Z" />
                    </g>
                    <g id="Group 70">
                      <path
                        id="Vector_178"
                        d="M3305.54 24c.26-.46-1.07-1.85-1.07-1.85s.65.16.74 0c.27-.46-1.07-1.85-1.07-1.85s.35.09.4 0c.29-.5-1.14-2-1.14-2s-1.43 1.48-1.15 2c.05.09.41 0 .41 0s-1.34 1.39-1.07 1.85c.09.16.74 0 .74 0s-1.34 1.39-1.07 1.85c.27.46 1.77 0 1.77 0v1h.74v-1s1.55.34 1.77 0Z"
                      />
                      <path id="Vector_179" d="M3303.39 24.94v-6.66c-.14.14-1.41 1.5-1.14 2 .05.09.41 0 .41 0s-1.34 1.39-1.07 1.85c.09.16.74 0 .74 0s-1.34 1.39-1.07 1.85c.27.46 1.77 0 1.77 0v1l.36-.04Z" />
                    </g>
                    <g id="Group 72">
                      <path
                        id="Vector_180"
                        d="M3374.9 55.32c.32-.55-1.27-2.2-1.27-2.2s.77.19.88 0c.32-.55-1.26-2.2-1.26-2.2s.42.1.48 0c.34-.59-1.36-2.36-1.36-2.36s-1.71 1.77-1.37 2.36c.06.1.49 0 .49 0s-1.59 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.59 1.65-1.27 2.2c.32.55 2.09 0 2.09 0v1.16h.89v-1.16s1.83.45 2.09 0Z"
                      />
                      <path id="Vector_181" d="M3372.35 56.48v-7.9c-.16.17-1.67 1.78-1.35 2.34.06.1.49 0 .49 0s-1.59 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.59 1.65-1.27 2.2c.32.55 2.09 0 2.09 0v1.16h.43Z" />
                    </g>
                    <g id="Group 103">
                      <path id="Vector_182" d="M3418.43 26.43c.28-.47-1.08-1.88-1.08-1.88s.66.16.75 0c.27-.47-1.09-1.89-1.09-1.89s.36.09.41 0c.3-.5-1.16-2-1.16-2s-1.46 1.51-1.17 2c0 .09.42 0 .42 0s-1.36 1.42-1.09 1.89c.09.16.75 0 .75 0s-1.36 1.41-1.09 1.88 1.8 0 1.8 0v1h.76v-1s1.57.39 1.79 0Z" />
                      <path id="Vector_183" d="M3416.25 27.43v-6.77c-.14.14-1.43 1.53-1.16 2 0 .09.42 0 .42 0s-1.36 1.42-1.09 1.89c.09.16.75 0 .75 0s-1.36 1.41-1.09 1.88 1.8 0 1.8 0v1h.37Z" />
                    </g>
                    <g id="Group 73">
                      <path id="Vector_184" d="M3490.54 58.42c.26-.43-1-1.74-1-1.74s.61.15.7 0c.25-.43-1-1.74-1-1.74s.33.08.38 0c.27-.47-1.08-1.87-1.08-1.87s-1.34 1.4-1.07 1.87c0 .08.38 0 .38 0s-1.26 1.31-1 1.74c.09.15.7 0 .7 0s-1.26 1.31-1 1.74c.26.43 1.66 0 1.66 0v.93h.7v-.93s1.43.36 1.63 0Z" />
                      <path id="Vector_185" d="M3488.52 59.35v-6.26c-.12.13-1.32 1.41-1.06 1.85 0 .08.38 0 .38 0s-1.26 1.31-1 1.74c.09.15.7 0 .7 0s-1.26 1.31-1 1.74c.26.43 1.66 0 1.66 0v.93h.32Z" />
                    </g>
                    <g id="Group 74">
                      <path
                        id="Vector_186"
                        d="M3537.21 23.84c.34-.59-1.36-2.35-1.36-2.35s.82.2.94 0c.34-.59-1.36-2.36-1.36-2.36s.45.11.52 0c.36-.63-1.46-2.52-1.46-2.52s-1.82 1.89-1.46 2.52c.07.11.52 0 .52 0s-1.7 1.77-1.36 2.36c.12.2.94 0 .94 0s-1.7 1.76-1.36 2.35c.34.59 2.25 0 2.25 0v1.25h.94v-1.25s1.97.49 2.25 0Z"
                      />
                      <path id="Vector_187" d="M3534.48 25.09v-8.47c-.18.18-1.79 1.91-1.45 2.51.07.11.52 0 .52 0s-1.7 1.77-1.36 2.36c.12.2.94 0 .94 0s-1.7 1.76-1.36 2.35c.34.59 2.25 0 2.25 0v1.25h.46Z" />
                    </g>
                    <g id="Group 100">
                      <path id="Vector_188" d="M3562.3 70.68c.26-.45-1-1.8-1-1.8s.63.16.72 0c.26-.45-1-1.8-1-1.8s.35.09.4 0c.27-.48-1.12-1.92-1.12-1.92s-1.39 1.44-1.11 1.92c0 .09.4 0 .4 0s-1.3 1.35-1 1.8c.09.16.72 0 .72 0s-1.3 1.35-1 1.8c.3.45 1.71 0 1.71 0v.95h.73v-.95s1.33.37 1.55 0Z" />
                      <path id="Vector_189" d="M3560.21 71.63v-6.46c-.13.14-1.36 1.46-1.1 1.91 0 .09.4 0 .4 0s-1.3 1.35-1 1.8c.09.16.72 0 .72 0s-1.3 1.35-1 1.8c.3.45 1.71 0 1.71 0v.95h.27Z" />
                    </g>
                    <g id="Group 99">
                      <path
                        id="Vector_190"
                        d="M3608.63 60.71c.33-.57-1.33-2.29-1.33-2.29s.81.2.92 0c.33-.57-1.32-2.29-1.32-2.29s.44.11.5 0c.35-.62-1.42-2.46-1.42-2.46s-1.77 1.84-1.42 2.46c.06.11.5 0 .5 0s-1.65 1.72-1.32 2.29c.11.2.92 0 .92 0s-1.66 1.72-1.33 2.29c.33.57 2.19 0 2.19 0v1.22h.92v-1.22s1.91.47 2.19 0Z"
                      />
                      <path id="Vector_191" d="M3606 61.93v-8.24c-.17.17-1.74 1.85-1.41 2.44.06.11.5 0 .5 0s-1.65 1.72-1.32 2.29c.11.2.92 0 .92 0s-1.66 1.72-1.33 2.29c.33.57 2.19 0 2.19 0v1.22h.45Z" />
                    </g>
                    <g id="Group 76">
                      <path
                        id="Vector_192"
                        d="M3722.81 56.07c.35-.61-1.41-2.43-1.41-2.43s.86.21 1 0c.35-.61-1.41-2.43-1.41-2.43s.47.12.54 0c.37-.65-1.51-2.6-1.51-2.6s-1.88 2-1.5 2.6c.07.12.53 0 .53 0s-1.75 1.82-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.31 0 2.31 0v1.29h1v-1.29s1.96.5 2.25 0Z"
                      />
                      <path id="Vector_193" d="M3720 57.36v-8.74c-.18.19-1.85 2-1.49 2.59.07.12.53 0 .53 0s-1.75 1.82-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.31 0 2.31 0v1.29h.45Z" />
                    </g>
                    <g id="Group 77">
                      <path
                        id="Vector_194"
                        d="M3749.64 73.77c.35-.61-1.4-2.43-1.4-2.43s.85.21 1 0c.35-.61-1.4-2.43-1.4-2.43s.46.12.53 0c.37-.65-1.51-2.6-1.51-2.6s-1.87 2-1.5 2.6c.07.12.53 0 .53 0s-1.75 1.82-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.32 0 2.32 0v1.29h1v-1.29s1.94.5 2.23 0Z"
                      />
                      <path id="Vector_195" d="M3746.82 75.06v-8.74c-.18.19-1.84 2-1.49 2.59.07.12.53 0 .53 0s-1.75 1.82-1.4 2.43c.12.21 1 0 1 0s-1.75 1.82-1.4 2.43c.35.61 2.32 0 2.32 0v1.29h.44Z" />
                    </g>
                    <g id="Group 78">
                      <path
                        id="Vector_196"
                        d="M3778.69 44.8c.4-.71-1.63-2.82-1.63-2.82s1 .24 1.13 0c.4-.71-1.63-2.82-1.63-2.82s.54.13.61 0c.44-.76-1.74-3-1.74-3s-2.18 2.26-1.74 3c.07.13.61 0 .61 0s-2 2.11-1.63 2.82c.15.24 1.13 0 1.13 0s-2 2.11-1.62 2.82c.38.71 2.68 0 2.68 0v1.49h1.14V44.8s2.35.58 2.69 0Z"
                      />
                      <path id="Vector_197" d="M3775.41 46.29V36.16c-.2.21-2.14 2.28-1.72 3 .07.13.61 0 .61 0s-2 2.11-1.63 2.82c.15.24 1.13 0 1.13 0s-2 2.11-1.62 2.82c.38.71 2.68 0 2.68 0v1.49h.55Z" />
                    </g>
                    <g id="Group 80">
                      <path
                        id="Vector_198"
                        d="M3841.43 70.09c.35-.61-1.42-2.46-1.42-2.46s.86.21 1 0c.36-.62-1.42-2.47-1.42-2.47s.47.12.54 0c.38-.66-1.53-2.64-1.53-2.64s-1.91 2-1.52 2.64c.06.12.54 0 .54 0s-1.78 1.85-1.43 2.47c.13.21 1 0 1 0s-1.78 1.85-1.43 2.46c.35.61 2.36 0 2.36 0v1.31h1v-1.31s2.01.51 2.31 0Z"
                      />
                      <path id="Vector_199" d="M3838.57 71.4v-8.86c-.18.19-1.88 2-1.51 2.62.06.12.54 0 .54 0s-1.78 1.85-1.43 2.47c.13.21 1 0 1 0s-1.78 1.85-1.43 2.46c.35.61 2.36 0 2.36 0v1.31h.47Z" />
                    </g>
                    <g id="Group 81">
                      <path
                        id="Vector_200"
                        d="M3908.84 77c.42-.73-1.68-2.92-1.68-2.92s1 .26 1.16 0c.42-.73-1.68-2.91-1.68-2.91s.56.14.64 0c.45-.78-1.8-3.12-1.8-3.12s-2.26 2.34-1.81 3.12c.08.14.64 0 .64 0s-2.1 2.18-1.68 2.91c.14.26 1.16 0 1.16 0s-2.1 2.19-1.68 2.92c.42.73 2.78 0 2.78 0v1.54h1.17V77s2.43.6 2.78 0Z"
                      />
                      <path id="Vector_201" d="M3905.46 78.54V68.07c-.21.22-2.21 2.36-1.79 3.1.08.14.64 0 .64 0s-2.1 2.18-1.68 2.91c.14.26 1.16 0 1.16 0s-2.1 2.19-1.68 2.92c.42.73 2.78 0 2.78 0v1.54h.57Z" />
                    </g>
                    <g id="Group 98">
                      <path id="Vector_202" d="M4003.49 64.61c.3-.51-1.18-2-1.18-2s.72.18.82 0c.3-.51-1.18-2-1.18-2s.39.09.45 0c.31-.55-1.27-2.2-1.27-2.2s-1.58 1.65-1.27 2.2c.06.09.45 0 .45 0s-1.48 1.53-1.18 2c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 1.95 0 1.95 0v1.08h.82v-1.08s1.71.39 1.95 0Z" />
                      <path id="Vector_203" d="M4001.12 65.69v-7.35c-.15.15-1.56 1.66-1.26 2.18.06.09.45 0 .45 0s-1.48 1.53-1.18 2c.1.18.82 0 .82 0s-1.48 1.54-1.18 2c.3.46 1.95 0 1.95 0v1.08l.4.09Z" />
                    </g>
                    <g id="Group 83">
                      <path
                        id="Vector_204"
                        d="M4066.25 77c.35-.61-1.41-2.44-1.41-2.44s.85.21 1 0c.36-.61-1.41-2.44-1.41-2.44s.47.11.54 0c.38-.66-1.51-2.62-1.51-2.62s-1.89 2-1.51 2.62c.06.11.53 0 .53 0s-1.76 1.83-1.41 2.44c.12.21 1 0 1 0s-1.76 1.83-1.41 2.44c.35.61 2.33 0 2.33 0v1.29h1V77s2.01.5 2.26 0Z"
                      />
                      <path id="Vector_205" d="M4063.41 78.29v-8.77c-.17.18-1.85 2-1.49 2.6.06.11.53 0 .53 0s-1.76 1.83-1.41 2.44c.12.21 1 0 1 0s-1.76 1.83-1.41 2.44c.35.61 2.33 0 2.33 0v1.29h.45Z" />
                    </g>
                    <g id="Group 84">
                      <path
                        id="Vector_206"
                        d="M4130 53.56c.33-.58-1.35-2.33-1.35-2.33s.82.2.93 0c.34-.58-1.34-2.33-1.34-2.33s.44.11.51 0c.36-.62-1.45-2.5-1.45-2.5s-1.8 1.88-1.44 2.5c.06.11.51 0 .51 0s-1.68 1.75-1.35 2.33c.12.2.94 0 .94 0s-1.69 1.75-1.35 2.33c.34.58 2.22 0 2.22 0v1.24h.94v-1.24s1.91.49 2.23 0Z"
                      />
                      <path id="Vector_207" d="M4127.25 54.8v-8.38c-.17.18-1.77 1.89-1.43 2.48.06.11.51 0 .51 0s-1.68 1.75-1.35 2.33c.12.2.94 0 .94 0s-1.69 1.75-1.35 2.33c.34.58 2.22 0 2.22 0v1.24h.46Z" />
                    </g>
                    <g id="Group 85">
                      <path
                        id="Vector_208"
                        d="M4232.81 41.39c.41-.7-1.63-2.82-1.63-2.82s1 .25 1.13 0c.41-.7-1.62-2.82-1.62-2.82s.53.14.61 0c.44-.75-1.74-3-1.74-3s-2.18 2.27-1.75 3c.08.14.62 0 .62 0s-2 2.12-1.63 2.82c.14.25 1.13 0 1.13 0s-2 2.12-1.63 2.82c.37.7 2.69 0 2.69 0v1.5h1.13v-1.5s2.36.61 2.69 0Z"
                      />
                      <path id="Vector_209" d="M4229.54 42.89V32.75c-.21.22-2.14 2.29-1.73 3 .08.14.62 0 .62 0s-2 2.12-1.63 2.82c.14.25 1.13 0 1.13 0s-2 2.12-1.63 2.82c.37.7 2.69 0 2.69 0v1.5h.55Z" />
                    </g>
                    <g id="Group 86">
                      <path id="Vector_210" d="M4254.79 75.35c.28-.49-1.15-2-1.15-2s.7.17.79 0c.29-.49-1.14-2-1.14-2s.38.1.43 0c.31-.53-1.22-2.12-1.22-2.12s-1.53 1.59-1.23 2.12c.06.1.44 0 .44 0s-1.43 1.49-1.15 2c.1.17.8 0 .8 0s-1.43 1.49-1.15 2 1.89 0 1.89 0v1.05h.8v-1.05s1.65.41 1.89 0Z" />
                      <path id="Vector_211" d="M4252.49 76.4v-7.12c-.15.16-1.51 1.61-1.22 2.11.06.1.44 0 .44 0s-1.43 1.49-1.15 2c.1.17.8 0 .8 0s-1.43 1.49-1.15 2 1.89 0 1.89 0v1.01h.39Z" />
                    </g>
                    <g id="Group 97">
                      <path
                        id="Vector_212"
                        d="M4420.52 74.56c.4-.69-1.61-2.78-1.61-2.78s1 .24 1.12 0c.4-.69-1.61-2.78-1.61-2.78s.53.13.61 0c.43-.75-1.72-3-1.72-3s-2.15 2.23-1.72 3c.07.13.61 0 .61 0s-2 2.09-1.61 2.78c.14.24 1.11 0 1.11 0s-2 2.09-1.6 2.78c.4.69 2.65 0 2.65 0V76h1.12v-1.44s2.32.57 2.65 0Z"
                      />
                      <path id="Vector_213" d="M4417.29 76V66c-.2.21-2.11 2.25-1.7 3 .07.13.61 0 .61 0s-2 2.09-1.61 2.78c.14.24 1.11 0 1.11 0s-2 2.09-1.6 2.78c.4.69 2.65 0 2.65 0V76h.54Z" />
                    </g>
                    <g id="Group 91">
                      <path
                        id="Vector_214"
                        d="M4522.83 65.45c.43-.74-1.7-3-1.7-3s1 .26 1.18 0c.43-.73-1.7-3-1.7-3s.56.14.64 0c.46-.79-1.82-3.16-1.82-3.16s-2.28 2.37-1.83 3.16c.08.14.65 0 .65 0s-2.13 2.22-1.7 3c.14.26 1.18 0 1.18 0s-2.13 2.21-1.71 3c.42.79 2.82 0 2.82 0V67h1.16v-1.55s2.48.61 2.83 0Z"
                      />
                      <path id="Vector_215" d="M4519.41 67V56.41c-.21.23-2.24 2.4-1.81 3.14.08.14.65 0 .65 0s-2.13 2.22-1.7 3c.14.26 1.18 0 1.18 0s-2.13 2.21-1.71 3c.42.79 2.82 0 2.82 0V67h.57Z" />
                    </g>
                    <g id="Group 92">
                      <path id="Vector_216" d="M4627.34 61c.25-.43-1-1.72-1-1.72s.6.14.68 0c.25-.43-1-1.72-1-1.72s.33.08.38 0c.26-.47-1.07-1.85-1.07-1.85s-1.33 1.38-1.06 1.85c.05.08.37 0 .37 0s-1.24 1.29-1 1.72c.09.14.69 0 .69 0s-1.24 1.29-1 1.72c.24.43 1.64 0 1.64 0v.91h.69V61s1.47.35 1.68 0Z" />
                      <path id="Vector_217" d="M4625.34 61.91v-6.19c-.12.14-1.3 1.4-1.05 1.84.05.08.37 0 .37 0s-1.24 1.29-1 1.72c.09.14.69 0 .69 0s-1.24 1.29-1 1.72c.24.43 1.64 0 1.64 0v.91h.35Z" />
                    </g>
                    <g id="Group 96">
                      <path
                        id="Vector_218"
                        d="M4698.85 66.46c.36-.62-1.44-2.49-1.44-2.49s.87.22 1 0c.36-.62-1.44-2.49-1.44-2.49s.48.12.54 0c.39-.67-1.54-2.67-1.54-2.67s-1.92 2-1.54 2.67c.07.12.55 0 .55 0s-1.8 1.87-1.44 2.49c.12.22 1 0 1 0s-1.79 1.87-1.43 2.49c.36.62 2.37 0 2.37 0v1.32h1v-1.32s2.07.54 2.37 0Z"
                      />
                      <path id="Vector_219" d="M4696 67.78v-9c-.18.19-1.89 2-1.53 2.65.07.12.55 0 .55 0s-1.8 1.87-1.44 2.49c.12.22 1 0 1 0s-1.79 1.87-1.43 2.49c.36.62 2.37 0 2.37 0v1.32l.48.05Z" />
                    </g>
                    <g id="Group 94">
                      <path
                        id="Vector_220"
                        d="M4726.48 45.91c.3-.53-1.23-2.13-1.23-2.13s.75.19.85 0c.31-.53-1.22-2.12-1.22-2.12s.4.1.46 0c.33-.57-1.31-2.27-1.31-2.27s-1.65 1.7-1.32 2.27c.06.1.47 0 .47 0s-1.53 1.59-1.23 2.12c.11.19.85 0 .85 0s-1.53 1.6-1.22 2.13c.31.53 2 0 2 0V47h.85v-1.09s1.79.44 2.05 0Z"
                      />
                      <path id="Vector_221" d="M4724 47v-7.6c-.15.16-1.61 1.72-1.3 2.26.06.1.47 0 .47 0s-1.53 1.59-1.23 2.12c.11.19.85 0 .85 0s-1.53 1.6-1.22 2.13c.31.53 2 0 2 0V47h.43Z" />
                    </g>
                    <g id="Group 88">
                      <path
                        id="Vector_222"
                        d="M4375.24 59.47c.44-.76-1.74-3-1.74-3s1.06.26 1.21 0c.43-.76-1.75-3-1.75-3s.58.15.66 0c.47-.81-1.87-3.24-1.87-3.24s-2.34 2.43-1.87 3.24c.08.15.66 0 .66 0s-2.18 2.27-1.75 3c.16.26 1.21 0 1.21 0s-2.18 2.26-1.74 3c.44.74 2.88 0 2.88 0v1.6h1.22v-1.6s2.52.62 2.88 0Z"
                      />
                      <path id="Vector_223" d="M4371.73 61.07V50.2c-.22.23-2.29 2.45-1.85 3.22.08.15.66 0 .66 0s-2.18 2.27-1.75 3c.16.26 1.21 0 1.21 0s-2.18 2.26-1.74 3c.44.74 2.88 0 2.88 0v1.6l.59.05Z" />
                    </g>
                    <g id="Group 71">
                      <path
                        id="Vector_224"
                        d="M3322.69 64.25c.32-.54-1.27-2.19-1.27-2.19s.77.19.88 0c.32-.55-1.27-2.2-1.27-2.2s.42.1.48 0c.34-.59-1.36-2.36-1.36-2.36s-1.7 1.77-1.36 2.36c.06.1.48 0 .48 0s-1.58 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.58 1.65-1.27 2.19c.31.54 2.1 0 2.1 0v1.17h.88v-1.17s1.84.46 2.1 0Z"
                      />
                      <path id="Vector_225" d="M3320.14 65.42v-7.91c-.16.17-1.67 1.79-1.35 2.35.06.1.48 0 .48 0s-1.58 1.65-1.27 2.2c.11.19.88 0 .88 0s-1.58 1.65-1.27 2.19c.31.54 2.1 0 2.1 0v1.17h.43Z" />
                    </g>
                  </g>
                </g>
              </g>
              <defs>
                <linearGradient id="farMountainsGradient" x1="2400" y1="-9" x2="2400" y2="273.7" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--farMountainsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--farMountainsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--farMountainsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--farMountainsGradientBottom)" />
                </linearGradient>
                <clipPath id="clip0_112_1960">
                  <path fill="#fff" d="M0 0h4800v236H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="slideUp shade-back-near">
            <svg width="100%" max-width="100%" height="100%" viewBox="0 0 4800 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="bg-shade-back-near">
                <path id="bg-shade-near" d="M4800 0H0v236h4800V0Z" fill="url(#bg-shade-near-gradient)" />
              </g>
              <defs>
                <linearGradient id="bg-shade-near-gradient" x1="2400" y1="239.17" x2="2400" y2="-5.22" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--shadeBackNearGradientBottom)" stopOpacity=".4" />
                  <stop offset=".64" stopColor="var(--shadeBackNearGradientMiddle)" stopOpacity=".15" />
                  <stop offset="1" stopColor="var(--shadeBackNearGradientTop)" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="slideUp near-mountains">
            <svg width="100%" max-width="100%" height="100%" viewBox="0 0 4800 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="bg-mountains-near-vector" clipPath="url(#clip0_116_38)">
                <g id="near-mountains-whole">
                  <path
                    id="near-mountains"
                    d="M4813.35 37.99V236H-13.352V34.92l63.21-23.74 65.88 63.57 108.75-18.12h.01l240.51-40.09 87.32 69.7 62.04-37.91 6.9-4.22 365.36-47.48 100.342 72.76 82.75-32.38h.01l5.32-2.09 229.03 16.09 122.55-36 136.34 61.28 75.26-17.87 198.95-47.24 75.83 39.06 9.19-16.08 180-32.94 42.9 56.68 22.1-13.07h.01l49.12-29.06 11.49 45.96L2378.97 39l72.94-32.42 65.1 66.64 112.74-17.74 242.67-38.17 76.59 64.85 74.3-34.98 368.43-50.55 96.51 69.7 79.12-24.58 21.98-6.83 113.36 28.34 229.79-46.72 143.24 59.75 69.03-16.43 201.35-47.92 70.47 39.83 9.19-19.91 183.83-26.04 42.13 54.38 16.08-18.77 87.32-44.8 58.21 41.36Z"
                    fill="url(#near-mountains-gradient)"
                  />
                  <g id="near-mountains-shadows">
                    <path id="Vector" d="m2022.27 34.12-9.19 16.08-75.84-39.06 85.03 22.98Z" fill="url(#paint1_linear_116_38)" />
                    <path id="Vector_2" d="m224.558 56.59-108.75 18.12-65.89-63.57 174.64 45.45Z" fill="url(#paint2_linear_116_38)" />
                    <path id="Vector_3" d="M614.43 48.29 552.39 86.2l-87.32-69.7 149.36 31.79Z" fill="url(#paint3_linear_116_38)" />
                    <path id="Vector_4" d="m1169.79 36.97-82.75 32.38L986.699-3.41l183.091 40.38Z" fill="url(#paint4_linear_116_38)" />
                    <path id="Vector_5" d="m1738.3 58.38-75.26 17.87-136.35-61.28 211.61 43.41Z" fill="url(#paint5_linear_116_38)" />
                    <path id="Vector_6" d="m2267.26 44.79-22.1 13.07-42.9-56.68 65 43.61Z" fill="url(#paint6_linear_116_38)" />
                    <path id="Vector_7" d="m2379.03 38.96-51.14 22.73-11.5-45.96 62.64 23.23Z" fill="url(#paint7_linear_116_38)" />
                    <path id="Vector_8" d="m2629.82 55.44-112.75 17.74-65.1-66.64 177.85 48.9Z" fill="url(#paint8_linear_116_38)" />
                    <path id="Vector_9" d="m3023.38 47.14-74.31 34.98-76.58-64.85 150.89 29.87Z" fill="url(#paint9_linear_116_38)" />
                    <path id="Vector_10" d="m3567.43 41.71-79.11 24.58-96.52-69.7 175.63 45.12Z" fill="url(#paint10_linear_116_38)" />
                    <path id="Vector_11" d="m4144.84 59.82-69.04 16.43-143.23-59.75 212.27 43.32Z" fill="url(#paint11_linear_116_38)" />
                    <path id="Vector_12" d="m4425.85 31.82-9.2 19.91-70.46-39.83 79.66 19.92Z" fill="url(#paint12_linear_116_38)" />
                    <path id="Vector_13" d="m4667.89 41.39-16.08 18.77-42.13-54.38 58.21 35.61Z" fill="url(#paint13_linear_116_38)" />
                  </g>
                </g>
              </g>
              <defs>
                <linearGradient id="near-mountains-gradient" x1="2400.89" y1="47.2" x2="2398.84" y2="250.44" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsGradientTop)" />
                  <stop offset=".63" stopColor="var(--nearMountainsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint1_linear_116_38" x1="1974.79" y1="68.867" x2="1973.11" y2="86.043" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint2_linear_116_38" x1="127.054" y1="105.09" x2="124.868" y2="133.144" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint3_linear_116_38" x1="531.04" y1="119.51" x2="527.98" y2="150.15" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint4_linear_116_38" x1="1067.57" y1="104.122" x2="1064.84" y2="136.195" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint5_linear_116_38" x1="1620.15" y1="105.536" x2="1618.47" y2="132.639" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint6_linear_116_38" x1="2230.97" y1="84.948" x2="2226.43" y2="109.266" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint7_linear_116_38" x1="2344.05" y1="83.654" x2="2340.93" y2="103.569" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint8_linear_116_38" x1="2530.52" y1="105.028" x2="2528.16" y2="134.425" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint9_linear_116_38" x1="2939.13" y1="113.112" x2="2936.51" y2="141.663" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint10_linear_116_38" x1="3469.37" y1="99.6" x2="3466.76" y2="130.324" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint11_linear_116_38" x1="4026.33" y1="104.805" x2="4024.73" y2="131.237" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint12_linear_116_38" x1="4381.37" y1="70.765" x2="4379.5" y2="88.249" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <linearGradient id="paint13_linear_116_38" x1="4635.39" y1="86.148" x2="4630.75" y2="109.364" gradientUnits="userSpaceOnUse">
                  <stop stopColor="var(--nearMountainsShadowsGradientTop1)" />
                  <stop offset=".03" stopColor="var(--nearMountainsShadowsGradientTop2)" />
                  <stop offset=".29" stopColor="var(--nearMountainsShadowsGradientMiddle)" />
                  <stop offset="1" stopColor="var(--nearMountainsShadowsGradientBottom)" />
                </linearGradient>
                <clipPath id="clip0_116_38">
                  <path fill="#fff" d="M0 0h4800v236H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="slideUp trees">
            <svg width="100%" max-width="100%" height="100%" viewBox="0 0 4800 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="bg-trees-vector">
                <g id="trees-only" opacity="var(--treesOnlyOpacity)">
                  <g id="Group 147">
                    <path
                      id="Vector"
                      d="M30.32 84.48v-2.16c0-.29-4 5.94-4.15 6.09A1.06 1.06 0 0 0 26 89c0 .18 0 .39.12.51s.35.16.45.22a.928.928 0 0 0 .33.07c.07 0-1.72 1.77-1.94 2.07-.22.3-.22.53-.1.68.163.122.357.195.56.21a.89.89 0 0 0 .39-.1.62.62 0 0 0 .19-.15s-1.89 2.17-2 2.44a.74.74 0 0 0 0 .69 1 1 0 0 0 .57.19 1.34 1.34 0 0 0 .41-.27s-1.84 2.21-2 2.54a.83.83 0 0 0 0 .75.71.71 0 0 0 .31.33c.25.17.51.324.78.46.16.07 1 .32 1.31.4.31.08 4.19.11 4.19.11v2.65a.39.39 0 0 0 .15.36c.269.041.542.041.81 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_2"
                      d="M30.319 84.48v-2.16c0-.29 4 5.94 4.14 6.09a1 1 0 0 1 .2.57c0 .18 0 .39-.12.51s-.34.16-.45.22a.84.84 0 0 1-.32.07c-.07 0 1.71 1.77 1.93 2.07.22.3.22.53.1.68a1 1 0 0 1-.56.21.82.82 0 0 1-.38-.1.61.61 0 0 1-.2-.15s1.89 2.17 2 2.44a.74.74 0 0 1 0 .69.93.93 0 0 1-.57.19 1.34 1.34 0 0 1-.41-.27s1.84 2.21 2 2.54a.77.77 0 0 1 0 .75.75.75 0 0 1-.3.33 8.007 8.007 0 0 1-.78.46c-.17.07-1 .32-1.32.4-.32.08-4.18.11-4.18.11v2.65a.41.41 0 0 1-.15.36 2.749 2.749 0 0 1-.82 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 146">
                    <path
                      id="Vector_3"
                      d="M96.469 149.75v-1.56c0-.21-2.92 4.29-3 4.4a.66.66 0 0 0-.15.41c0 .13 0 .28.09.37.09.09.25.11.32.16a.857.857 0 0 0 .24.05s-1.24 1.28-1.4 1.5c-.16.22-.16.38-.07.48a.672.672 0 0 0 .4.15.534.534 0 0 0 .28-.06.382.382 0 0 0 .14-.11s-1.32 1.56-1.46 1.76a.5.5 0 0 0 0 .5.65.65 0 0 0 .41.13.997.997 0 0 0 .3-.19s-1.33 1.6-1.46 1.84a.617.617 0 0 0 0 .54.59.59 0 0 0 .23.24c.179.122.366.233.56.33.12.05.72.23.95.29.23.06 3 .08 3 .08V163a.337.337 0 0 0 .11.27c.195.028.394.028.59 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_4"
                      d="M96.47 149.75v-1.56c0-.21 2.93 4.29 3 4.4a.66.66 0 0 1 .14.41.57.57 0 0 1-.08.37c-.08.08-.25.11-.33.16a.727.727 0 0 1-.23.05s1.239 1.28 1.399 1.5c.16.22.15.38.07.48a.685.685 0 0 1-.41.15.516.516 0 0 1-.27-.06.52.52 0 0 1-.15-.11s1.37 1.56 1.48 1.76a.557.557 0 0 1 0 .5.668.668 0 0 1-.41.13 1.004 1.004 0 0 1-.3-.19s1.33 1.6 1.45 1.84a.557.557 0 0 1 0 .54.508.508 0 0 1-.22.24 4.836 4.836 0 0 1-.56.33c-.12.05-.72.23-1 .29-.28.06-3 .08-3 .08V163a.31.31 0 0 1-.11.27 2.013 2.013 0 0 1-.58 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 145">
                    <path
                      id="Vector_5"
                      d="M204.14 129.61v-2.14c0-.29-4 5.88-4.11 6a1.012 1.012 0 0 0-.2.57c0 .18 0 .39.12.51.14.093.291.167.45.22.103.037.211.058.32.06.07 0-1.7 1.76-1.92 2.06-.22.3-.22.53-.1.67.158.124.35.197.55.21a.77.77 0 0 0 .58-.24s-1.88 2.14-2 2.42a.699.699 0 0 0 0 .68c.164.118.359.185.56.19.153-.064.292-.156.41-.27 0 0-1.82 2.2-2 2.52a.817.817 0 0 0 0 .74c.07.14.178.257.31.34.247.167.504.317.77.45.17.06 1 .32 1.3.4.3.08 4.16.1 4.16.1v2.63a.394.394 0 0 0 .15.36c.265.041.535.041.8 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_6"
                      d="M204.138 129.61v-2.14c0-.29 4 5.88 4.11 6a.997.997 0 0 1 .2.57c0 .18 0 .39-.12.51a2.012 2.012 0 0 1-.44.22 1.152 1.152 0 0 1-.33.06c-.07 0 1.7 1.76 1.92 2.06.22.3.22.53.1.67a.993.993 0 0 1-.55.21.797.797 0 0 1-.58-.24s1.88 2.14 2 2.42a.666.666 0 0 1 0 .68.998.998 0 0 1-.56.19 1.352 1.352 0 0 1-.41-.27s1.83 2.2 2 2.52a.805.805 0 0 1 0 .74.784.784 0 0 1-.31.34 6.729 6.729 0 0 1-.77.45c-.17.06-1 .32-1.3.4-.3.08-4.16.1-4.16.1v2.63a.386.386 0 0 1-.15.36 2.617 2.617 0 0 1-.8 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 144">
                    <path
                      id="Vector_7"
                      d="M350.181 132.32v-1.44c0-.19-2.68 4-2.75 4.05a.653.653 0 0 0-.13.38.54.54 0 0 0 .07.34c.095.057.195.104.3.14.07.029.144.046.22.05.05 0-1.14 1.18-1.29 1.38-.15.2-.14.35-.06.44a.64.64 0 0 0 .37.14.448.448 0 0 0 .25-.06.449.449 0 0 0 .14-.1s-1.26 1.44-1.36 1.62a.474.474 0 0 0 0 .46c.11.082.243.128.38.13a.892.892 0 0 0 .27-.18s-1.22 1.47-1.33 1.69a.49.49 0 0 0 0 .49.497.497 0 0 0 .2.23c.08 0 .41.26.52.3l.87.27c.21 0 2.78.07 2.78.07v1.76a.293.293 0 0 0 .023.131.29.29 0 0 0 .077.109c.182.028.368.028.55 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_8"
                      d="M350.182 132.32v-1.44c0-.19 2.69 4 2.76 4.05a.66.66 0 0 1 .13.38c0 .12 0 .26-.08.34-.08.08-.23.1-.3.14a.724.724 0 0 1-.21.05c-.05 0 1.13 1.18 1.28 1.38.15.2.15.35.07.44a.642.642 0 0 1-.37.14.48.48 0 0 1-.26-.06.314.314 0 0 1-.13-.1s1.26 1.44 1.36 1.62a.51.51 0 0 1 0 .46.624.624 0 0 1-.38.13.869.869 0 0 1-.27-.18s1.22 1.47 1.34 1.69a.51.51 0 0 1 0 .49.525.525 0 0 1-.21.23 5.673 5.673 0 0 1-.51.3l-.88.27c-.21 0-2.78.07-2.78.07v1.76a.276.276 0 0 1-.1.24 1.746 1.746 0 0 1-.54 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 143">
                    <path
                      id="Vector_9"
                      d="M441.201 108.77v-2.19c0-.29-4.09 6-4.2 6.16-.121.17-.19.371-.2.58 0 .18 0 .4.12.51.142.098.297.176.46.23a.895.895 0 0 0 .33.07c.07 0-1.74 1.79-2 2.1-.26.31-.22.53-.1.68.161.125.357.199.56.21a.81.81 0 0 0 .39-.09.706.706 0 0 0 .2-.16s-1.91 2.2-2.06 2.47a.703.703 0 0 0 0 .7.93.93 0 0 0 .57.19c.156-.062.299-.154.42-.27 0 0-1.86 2.24-2 2.57a.849.849 0 0 0 .31 1.1c.255.168.519.321.79.46.17.07 1 .33 1.33.41.33.08 4.24.1 4.24.1v2.68a.427.427 0 0 0 .15.37c.272.041.548.041.82 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_10"
                      d="M441.199 108.77v-2.19c0-.29 4.09 6 4.2 6.16.12.17.19.371.2.58 0 .18 0 .4-.12.51a1.818 1.818 0 0 1-.46.23.938.938 0 0 1-.33.07c-.07 0 1.74 1.79 2 2.1.26.31.22.53.1.68a1.003 1.003 0 0 1-.57.21.794.794 0 0 1-.38-.09.706.706 0 0 1-.2-.16s1.91 2.2 2.06 2.47a.737.737 0 0 1 0 .7.94.94 0 0 1-.58.19 1.328 1.328 0 0 1-.41-.27s1.86 2.24 2 2.57a.849.849 0 0 1-.31 1.1 8.419 8.419 0 0 1-.79.46c-.17.07-1 .33-1.33.41-.33.08-4.24.1-4.24.1v2.68a.42.42 0 0 1-.15.37 2.816 2.816 0 0 1-.83 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 142">
                    <path
                      id="Vector_11"
                      d="M493.352 174.24v-1.62c0-.22-3 4.46-3.11 4.57a.761.761 0 0 0-.15.43c0 .14 0 .3.09.39.106.068.22.121.34.16a.856.856 0 0 0 .24.05c.06 0-1.28 1.33-1.45 1.56-.17.23-.17.4-.07.51.116.093.26.146.41.15a.51.51 0 0 0 .29-.07.524.524 0 0 0 .15-.11s-1.42 1.63-1.53 1.83a.538.538 0 0 0 0 .52.687.687 0 0 0 .43.14.961.961 0 0 0 .3-.2s-1.38 1.66-1.51 1.91a.582.582 0 0 0 0 .56c.052.103.131.19.23.25.189.129.386.246.59.35.12 0 .74.24 1 .3s3.15.08 3.15.08v2a.299.299 0 0 0 .11.27c.202.031.407.031.61 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_12"
                      d="M493.35 174.24v-1.62c0-.22 3 4.46 3.12 4.57a.776.776 0 0 1 .15.43c0 .14 0 .3-.09.39a1.507 1.507 0 0 1-.34.16.863.863 0 0 1-.24.05c-.06 0 1.28 1.33 1.45 1.56.17.23.16.4.07.51a.705.705 0 0 1-.42.15.49.49 0 0 1-.28-.07.51.51 0 0 1-.15-.11s1.42 1.63 1.53 1.83a.549.549 0 0 1 0 .52.688.688 0 0 1-.43.14.95.95 0 0 1-.3-.2s1.38 1.66 1.51 1.91a.621.621 0 0 1-.23.81c-.19.129-.387.246-.59.35-.13 0-.75.24-1 .3S494 186 494 186v2a.302.302 0 0 1-.11.27 2.014 2.014 0 0 1-.62 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 141">
                    <path
                      id="Vector_13"
                      d="M629.322 126.4v-1.3c0-.18-2.44 3.58-2.51 3.67a.704.704 0 0 0-.12.34c0 .12 0 .24.07.31.087.059.181.106.28.14a.862.862 0 0 0 .19 0s-1 1.07-1.16 1.25c-.16.18-.14.32-.06.41a.617.617 0 0 0 .33.12c.075.02.155.02.23 0a.407.407 0 0 0 .12-.09s-1.14 1.3-1.23 1.47a.418.418 0 0 0 0 .42.549.549 0 0 0 .34.11.888.888 0 0 0 .25-.16s-1.11 1.33-1.22 1.53a.492.492 0 0 0 0 .45c.044.085.11.158.19.21.07 0 .37.23.47.27.1.04.6.2.79.24.19.04 2.53.07 2.53.07v1.6a.277.277 0 0 0 .018.121.272.272 0 0 0 .072.099c.163.023.328.023.49 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_14"
                      d="M629.318 126.4v-1.3c0-.18 2.44 3.58 2.5 3.67a.607.607 0 0 1 .12.34c0 .12 0 .24-.07.31s-.21.1-.27.14a.955.955 0 0 1-.2 0s1 1.07 1.17 1.25c.17.18.13.32.06.41a.671.671 0 0 1-.34.12.448.448 0 0 1-.23 0 .407.407 0 0 1-.12-.09s1.14 1.3 1.23 1.47a.419.419 0 0 1 0 .42.547.547 0 0 1-.34.11.806.806 0 0 1-.24-.16s1.1 1.33 1.21 1.53a.492.492 0 0 1 0 .45.549.549 0 0 1-.19.21c-.07 0-.37.23-.47.27-.1.04-.6.2-.79.24-.19.04-2.53.07-2.53.07v1.6a.261.261 0 0 1-.09.22 1.748 1.748 0 0 1-.49 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 140">
                    <path
                      id="Vector_15"
                      d="M758.602 153.39v-2.25c0-.3-4.19 6.16-4.3 6.32-.127.172-.2.377-.21.59 0 .2 0 .41.13.53.145.099.303.176.47.23a.895.895 0 0 0 .33.07c.08 0-1.78 1.84-2 2.16-.22.32-.23.55-.11.7a1 1 0 0 0 .58.21.852.852 0 0 0 .4-.09.706.706 0 0 0 .2-.16s-2 2.25-2.11 2.53a.743.743 0 0 0 0 .72 1 1 0 0 0 .59.19c.155-.065.297-.156.42-.27 0 0-1.91 2.29-2.09 2.64a.844.844 0 0 0 0 .77.906.906 0 0 0 .32.35c.261.171.532.328.81.47.18.07 1 .34 1.36.42.36.08 4.35.11 4.35.11v2.75a.443.443 0 0 0 .16.38c.276.058.559.075.84.05"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_16"
                      d="M758.599 153.39v-2.25c0-.3 4.2 6.16 4.31 6.32.128.17.198.377.2.59 0 .2 0 .41-.12.53a1.831 1.831 0 0 1-.47.23.938.938 0 0 1-.33.07c-.08 0 1.77 1.84 2 2.16.23.32.23.55.11.7-.167.129-.37.203-.58.21a.852.852 0 0 1-.4-.09.912.912 0 0 1-.21-.16s2 2.25 2.12 2.53a.743.743 0 0 1 0 .72 1 1 0 0 1-.59.19 1.303 1.303 0 0 1-.42-.27s1.9 2.29 2.09 2.64a.844.844 0 0 1 0 .77.914.914 0 0 1-.32.35 8.834 8.834 0 0 1-.81.47c-.18.07-1 .34-1.36.42-.36.08-4.35.11-4.35.11v2.75a.443.443 0 0 1-.16.38 2.854 2.854 0 0 1-.84.05"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 139">
                    <path
                      id="Vector_17"
                      d="M845.001 100.86v-1.6c0-.21-3 4.38-3.06 4.49a.778.778 0 0 0-.14.42.646.646 0 0 0 .08.38c.106.067.22.121.34.16a.79.79 0 0 0 .24.05s-1.27 1.3-1.43 1.53c-.16.23-.16.39-.07.49.116.095.26.151.41.16a.57.57 0 0 0 .28-.07.524.524 0 0 0 .15-.11s-1.4 1.59-1.5 1.8a.52.52 0 0 0 0 .51.7.7 0 0 0 .42.13c.11-.045.212-.109.3-.19 0 0-1.36 1.63-1.49 1.87a.639.639 0 0 0 0 .55.797.797 0 0 0 .23.25c.184.12.374.23.57.33.13 0 .74.24 1 .3s3.09.08 3.09.08v2a.313.313 0 0 0 .11.27c.199.028.401.028.6 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_18"
                      d="M845.001 100.86v-1.6c0-.21 3 4.38 3.06 4.49a.778.778 0 0 1 .14.42.646.646 0 0 1-.08.38 1.51 1.51 0 0 1-.34.16.856.856 0 0 1-.24.05c-.05 0 1.26 1.3 1.43 1.53.17.23.16.39.07.49a.716.716 0 0 1-.41.16.57.57 0 0 1-.28-.07.524.524 0 0 1-.15-.11s1.4 1.59 1.5 1.8a.52.52 0 0 1 0 .51.701.701 0 0 1-.42.13 1.004 1.004 0 0 1-.3-.19s1.36 1.63 1.49 1.87a.639.639 0 0 1 0 .55.797.797 0 0 1-.23.25c-.184.12-.374.23-.57.33-.13 0-.74.24-1 .3s-3.09.08-3.09.08v2a.313.313 0 0 1-.11.27 2.153 2.153 0 0 1-.6 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 138">
                    <path
                      id="Vector_19"
                      d="M1041.43 157.37v-1.29c0-.17-2.4 3.53-2.46 3.62a.527.527 0 0 0-.12.34c0 .11 0 .23.07.3.08.059.17.103.27.13a.36.36 0 0 0 .19 0s-1 1.05-1.15 1.23c-.15.18-.13.32-.06.4a.6.6 0 0 0 .33.13c.08 0 .16-.02.23-.06a.317.317 0 0 0 .12-.09s-1.13 1.29-1.21 1.45a.48.48 0 0 0-.05.205c0 .071.02.142.05.205.1.071.22.11.34.11.09 0 .24-.16.24-.16s-1.09 1.32-1.19 1.52a.422.422 0 0 0-.05.22c0 .076.01.152.05.22.04.082.1.151.18.2.07 0 .37.23.47.27l.77.24c.19.05 2.49.06 2.49.06v1.57c0 .041 0 .083.02.121a.21.21 0 0 0 .07.099c.16.023.32.023.48 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_20"
                      d="M1041.43 157.37v-1.29c0-.17 2.4 3.53 2.46 3.62a.6.6 0 0 1 .12.34c0 .11 0 .23-.07.3-.08.057-.17.1-.27.13a.36.36 0 0 1-.19 0s1 1.05 1.15 1.23c.15.18.13.32.06.4a.602.602 0 0 1-.34.13.46.46 0 0 1-.22-.06.626.626 0 0 1-.12-.09s1.12 1.29 1.21 1.45a.377.377 0 0 1 0 .41.515.515 0 0 1-.33.11.737.737 0 0 1-.25-.16s1.09 1.32 1.2 1.52a.43.43 0 0 1 0 .44c-.04.08-.1.148-.18.2-.07 0-.37.23-.47.27l-.78.24c-.19.05-2.48.06-2.48.06v1.57c.01.041 0 .083-.02.121a.21.21 0 0 1-.07.099c-.16.023-.33.023-.49 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 137">
                    <path
                      id="Vector_21"
                      d="M1213.34 161.1v-2.63c0-.35-4.92 7.23-5 7.41-.15.199-.23.44-.24.69 0 .23 0 .48.14.62s.42.2.55.27c.13.049.26.08.4.09.08 0-2.09 2.15-2.36 2.52-.27.37-.27.64-.12.82.19.156.43.246.68.26a.971.971 0 0 0 .47-.12.967.967 0 0 0 .24-.18s-2.31 2.64-2.48 3a.88.88 0 0 0-.11.42c0 .147.04.292.11.42.2.145.44.222.69.22.18-.073.35-.182.49-.32 0 0-2.23 2.69-2.44 3.1-.07.14-.11.294-.11.45 0 .156.04.31.11.45.08.169.21.311.37.41.14.08.75.48.95.56.2.08 1.21.39 1.6.49.39.1 5.09.12 5.09.12v3.23a.511.511 0 0 0 .19.44c.33.072.67.092 1 .06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_22"
                      d="M1213.34 161.1v-2.63c0-.35 4.92 7.23 5.05 7.41.15.201.23.441.24.69 0 .23 0 .48-.14.62s-.42.2-.55.27a1.4 1.4 0 0 1-.4.09c-.08 0 2.09 2.15 2.36 2.52.27.37.26.64.12.82-.19.156-.43.246-.68.26a1 1 0 0 1-.47-.12.822.822 0 0 1-.24-.18s2.3 2.64 2.48 3a.893.893 0 0 1 .05.84c-.2.145-.44.222-.69.22-.19-.071-.36-.18-.5-.32 0 0 2.24 2.69 2.45 3.1.11.231.13.495.05.739-.08.243-.25.448-.47.571-.14.08-.74.48-.94.56-.2.08-1.21.39-1.6.49-.39.1-5.1.12-5.1.12v3.23c.01.083 0 .166-.03.243a.677.677 0 0 1-.15.197c-.33.072-.67.092-1 .06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 136">
                    <path
                      id="Vector_23"
                      d="M1347 131v-1.8c0-.24-3.38 5-3.46 5.09a.878.878 0 0 0-.17.47c0 .16 0 .33.1.43.12.081.25.145.38.19.09.029.18.046.27.05.06 0-1.43 1.49-1.62 1.74-.19.25-.18.44-.08.56.14.107.3.169.47.18a.708.708 0 0 0 .32-.08.483.483 0 0 0 .16-.13s-1.58 1.81-1.7 2a.59.59 0 0 0-.08.29c0 .102.03.202.08.29.14.1.31.156.48.16.13-.058.24-.136.34-.23 0 0-1.54 1.85-1.68 2.13a.715.715 0 0 0-.05.498c.05.166.16.309.3.402.1.06.51.33.66.39l1.09.33c.27.07 3.51.09 3.51.09v2.21a.33.33 0 0 0 .02.17.39.39 0 0 0 .1.14c.23.032.46.032.68 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_24"
                      d="M1347 131v-1.8c0-.24 3.38 5 3.47 5.09.1.135.16.299.17.47 0 .16 0 .33-.1.43a1.47 1.47 0 0 1-.38.19c-.09.029-.18.046-.27.05-.06 0 1.43 1.49 1.62 1.74.19.25.18.44.08.56a.832.832 0 0 1-.47.18.66.66 0 0 1-.32-.08.483.483 0 0 1-.16-.13s1.58 1.81 1.7 2c.05.09.07.189.07.29 0 .101-.02.2-.07.29-.14.1-.31.156-.48.16a1.098 1.098 0 0 1-.34-.23s1.53 1.85 1.68 2.13c.05.095.08.202.08.31 0 .108-.03.215-.08.31a.557.557 0 0 1-.26.28c-.21.141-.43.271-.65.39-.14.05-.83.26-1.1.33-.27.07-3.5.09-3.5.09v2.21a.33.33 0 0 1-.02.17.39.39 0 0 1-.1.14 2.42 2.42 0 0 1-.68 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 135">
                    <path
                      id="Vector_25"
                      d="M1471.09 100.34v-1.79c0-.24-3.34 4.91-3.43 5a.881.881 0 0 0-.16.47c0 .15 0 .33.1.42.11.084.24.145.37.18.09.036.18.056.27.06.06 0-1.41 1.47-1.6 1.71-.19.24-.18.44-.08.56a.79.79 0 0 0 .46.17c.11.001.22-.023.32-.07a.483.483 0 0 0 .16-.13s-1.56 1.79-1.68 2a.7.7 0 0 0-.07.285.7.7 0 0 0 .07.285c.14.095.3.148.47.15a.99.99 0 0 0 .34-.22s-1.52 1.83-1.66 2.1a.644.644 0 0 0-.07.305c0 .106.02.211.07.305.05.117.14.214.25.28.21.139.43.266.65.38.14 0 .82.27 1.08.33.26.06 3.46.09 3.46.09v2.19c0 .056 0 .113.02.165.02.053.06.099.1.135.23.032.46.032.68 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_26"
                      d="M1471.09 100.34v-1.79c0-.24 3.34 4.91 3.42 5 .1.135.16.299.17.47 0 .15 0 .33-.1.42-.11.08-.24.141-.37.18a.876.876 0 0 1-.27.06c-.06 0 1.41 1.47 1.59 1.71.18.24.19.44.09.56a.805.805 0 0 1-.46.17.739.739 0 0 1-.32-.07.483.483 0 0 1-.16-.13s1.56 1.79 1.68 2c.05.087.08.185.08.285 0 .1-.03.198-.08.285a.804.804 0 0 1-.47.15.995.995 0 0 1-.33-.22s1.51 1.83 1.66 2.1c.05.094.07.199.07.305 0 .106-.02.211-.07.305a.69.69 0 0 1-.26.28c-.2.141-.42.268-.64.38-.14 0-.82.27-1.08.33-.26.06-3.46.09-3.46.09v2.19c0 .057 0 .114-.03.166a.369.369 0 0 1-.1.134 2.35 2.35 0 0 1-.67 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 134">
                    <path
                      id="Vector_27"
                      d="M1549.56 135v-1.45c0-.19-2.72 4-2.79 4.09a.653.653 0 0 0-.13.38c0 .12 0 .26.08.34.09.064.19.114.3.15a.48.48 0 0 0 .22 0s-1.15 1.19-1.3 1.4c-.15.21-.14.35-.06.45.1.085.23.134.37.14.09 0 .18-.02.26-.06a.465.465 0 0 0 .13-.11s-1.27 1.46-1.37 1.64c-.1.18-.09.41 0 .47a.68.68 0 0 0 .38.12c.1-.043.19-.104.27-.18 0 0-1.23 1.49-1.35 1.71a.545.545 0 0 0 .21.72c.17.114.34.217.52.31l.88.27c.22.05 2.81.07 2.81.07v1.78c-.01.045 0 .091.02.133.02.042.04.079.08.107.18.028.37.028.55 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_28"
                      d="M1549.56 135v-1.45c0-.19 2.71 4 2.78 4.09.08.11.13.243.13.38 0 .12 0 .26-.08.34-.08.08-.23.11-.3.15a.48.48 0 0 1-.22 0s1.15 1.19 1.3 1.4c.15.21.15.35.07.45a.663.663 0 0 1-.38.14.612.612 0 0 1-.26-.06.593.593 0 0 1-.13-.11s1.27 1.46 1.37 1.64a.483.483 0 0 1 0 .47.704.704 0 0 1-.39.12.918.918 0 0 1-.27-.18s1.23 1.49 1.35 1.71a.542.542 0 0 1 0 .5.48.48 0 0 1-.21.22c-.16.116-.34.219-.52.31l-.88.27c-.21.05-2.81.07-2.81.07v1.78c.01.045 0 .091-.02.133a.248.248 0 0 1-.08.107c-.18.028-.37.028-.55 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 133">
                    <path
                      id="Vector_29"
                      d="M1633.13 151.58V149c0-.35-4.77 7-4.89 7.18-.14.194-.23.427-.24.67 0 .22 0 .47.14.6.17.115.35.206.54.27.12.042.25.069.38.08.08 0-2 2.09-2.28 2.44-.28.35-.26.63-.12.8.19.15.42.237.66.25a.997.997 0 0 0 .45-.11.722.722 0 0 0 .23-.18s-2.23 2.56-2.4 2.88a.816.816 0 0 0-.1.405c0 .142.03.281.1.405.19.152.43.23.67.22.18-.07.34-.176.48-.31 0 0-2.16 2.61-2.37 3a.96.96 0 0 0-.11.44c0 .154.04.305.11.44.09.162.21.299.36.4.14.08.72.46.92.54.2.08 1.17.37 1.55.47.38.1 4.94.12 4.94.12v3.13c-.01.081 0 .163.04.238.03.075.08.141.14.192.33.069.67.09 1 .06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_30"
                      d="M1633.13 151.58V149c0-.35 4.77 7 4.9 7.18.14.197.22.429.23.67 0 .22 0 .47-.14.6-.16.113-.34.204-.53.27-.13.041-.26.068-.39.08-.08 0 2 2.09 2.29 2.44.29.35.26.63.11.8-.19.15-.42.237-.66.25a.997.997 0 0 1-.45-.11.853.853 0 0 1-.23-.18s2.23 2.56 2.4 2.88c.07.123.11.263.11.405 0 .142-.04.282-.11.405-.19.152-.43.23-.67.22-.18-.07-.34-.176-.48-.31 0 0 2.17 2.61 2.37 3a.96.96 0 0 1 .11.44 1.022 1.022 0 0 1-.47.84c-.14.08-.72.46-.92.54-.2.08-1.17.37-1.55.47-.38.1-4.94.12-4.94.12v3.13c.01.081 0 .163-.03.238a.653.653 0 0 1-.15.192c-.33.069-.67.09-1 .06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 132">
                    <path
                      id="Vector_31"
                      d="M1801.84 132.18v-1.71c0-.23-3.2 4.71-3.29 4.83a.808.808 0 0 0-.15.45c0 .15 0 .31.09.4.09.09.28.13.36.18.09.012.17.012.26 0 .05 0-1.36 1.41-1.54 1.65-.18.24-.17.42-.08.53.13.103.29.163.45.17.11.001.21-.027.3-.08a.551.551 0 0 0 .16-.11s-1.5 1.71-1.62 1.93a.536.536 0 0 0-.08.275c0 .097.03.192.08.275.13.097.29.149.45.15.12-.054.23-.128.32-.22 0 0-1.45 1.76-1.59 2a.606.606 0 0 0-.07.295.685.685 0 0 0 .31.565c.09 0 .49.31.62.36.13.05.79.26 1 .32.21.06 3.32.08 3.32.08v2.1c0 .054 0 .109.02.16.03.05.06.095.1.13.22.032.43.032.65 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_32"
                      d="M1801.84 132.18v-1.71c0-.23 3.21 4.71 3.29 4.83.1.131.15.287.16.45 0 .15 0 .31-.09.4-.09.09-.28.13-.36.18-.09.011-.17.011-.26 0-.06 0 1.36 1.41 1.53 1.65.17.24.18.42.08.53a.759.759 0 0 1-.44.17.577.577 0 0 1-.3-.08.551.551 0 0 1-.16-.11s1.5 1.71 1.62 1.93c.05.085.07.179.07.275 0 .096-.02.19-.07.275a.786.786 0 0 1-.45.15 1.285 1.285 0 0 1-.33-.22s1.46 1.76 1.6 2c.05.091.07.192.07.295 0 .103-.02.204-.07.295a.603.603 0 0 1-.25.27c-.09 0-.48.31-.61.36-.13.05-.79.26-1 .32-.21.06-3.33.08-3.33.08v2.1c.01.055 0 .11-.02.161a.262.262 0 0 1-.1.129c-.21.032-.43.032-.64 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 131">
                    <path
                      id="Vector_33"
                      d="M2018.86 164.66v-2.86c0-.38-5.34 7.85-5.48 8-.16.217-.25.479-.26.75 0 .25 0 .53.15.68.19.125.39.222.6.29.14.059.28.089.43.09.09 0-2.26 2.35-2.56 2.75-.3.4-.29.7-.13.89.22.163.47.257.74.27.18.006.36-.035.51-.12.1-.052.19-.119.26-.2 0 0-2.5 2.87-2.7 3.23a1.038 1.038 0 0 0-.05.91c.22.165.48.253.75.25.2-.085.39-.207.54-.36 0 0-2.43 2.93-2.66 3.37-.08.154-.12.325-.12.5s.04.346.12.5c.1.183.24.338.41.45.32.218.66.418 1 .6.22.09 1.31.43 1.73.53.42.1 5.54.14 5.54.14v3.5c-.01.092.01.184.04.269.04.085.09.161.16.221.36.071.72.091 1.08.06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_34"
                      d="M2018.86 164.66v-2.86c0-.38 5.35 7.85 5.49 8 .16.219.25.48.26.75 0 .25 0 .53-.15.68-.19.123-.39.22-.6.29-.14.059-.28.089-.43.09-.1 0 2.26 2.35 2.55 2.75.29.4.3.7.13.89-.21.162-.47.257-.73.27a1 1 0 0 1-.51-.12.876.876 0 0 1-.26-.2s2.5 2.87 2.69 3.23a.924.924 0 0 1 .06.91c-.22.165-.48.253-.75.25-.2-.085-.38-.207-.54-.36 0 0 2.43 2.93 2.66 3.37.08.154.13.325.13.5s-.05.346-.13.5c-.1.183-.24.338-.41.45-.16.09-.81.52-1 .6-.19.08-1.31.43-1.74.53-.43.1-5.53.14-5.53.14v3.5a.615.615 0 0 1-.04.269.644.644 0 0 1-.16.221c-.36.071-.72.091-1.08.06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 130">
                    <path
                      id="Vector_35"
                      d="M2086.26 119.75v-1.84c0-.24-3.43 5-3.52 5.17a.917.917 0 0 0-.16.48c0 .16 0 .34.1.43.1.09.29.14.38.19.09.036.18.056.28.06.06 0-1.46 1.5-1.64 1.76-.18.26-.19.45-.09.57.14.103.3.166.47.18a.65.65 0 0 0 .27-.052c.09-.036.17-.09.23-.158 0 0-1.61 1.84-1.73 2.07a.613.613 0 0 0-.08.295c0 .103.03.205.08.295.14.102.31.154.49.15.13-.051.24-.126.34-.22 0 0-1.56 1.87-1.7 2.16a.688.688 0 0 0-.08.315.709.709 0 0 0 .34.595c.21.142.43.273.66.39.14.06.84.27 1.11.34.27.07 3.55.09 3.55.09v2.25c0 .058.01.116.03.17a.39.39 0 0 0 .1.14c.23.032.46.032.69 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_36"
                      d="M2086.26 119.75v-1.84c0-.24 3.43 5 3.52 5.17.1.139.16.306.17.48 0 .16 0 .34-.1.43-.12.081-.25.145-.39.19a.774.774 0 0 1-.27.06c-.06 0 1.45 1.5 1.64 1.76.19.26.19.45.08.57a.86.86 0 0 1-.47.18.683.683 0 0 1-.33-.08.483.483 0 0 1-.16-.13s1.6 1.84 1.73 2.07c.05.091.07.192.07.295 0 .103-.02.204-.07.295a.786.786 0 0 1-.48.15 1.002 1.002 0 0 1-.35-.22s1.56 1.87 1.71 2.16c.05.097.08.205.08.315 0 .11-.03.218-.08.315a.75.75 0 0 1-.26.28c-.21.142-.43.273-.66.39-.15.06-.85.27-1.12.34-.27.07-3.55.09-3.55.09v2.25c0 .058 0 .117-.03.172a.375.375 0 0 1-.1.138c-.23.032-.46.032-.69 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 129">
                    <path
                      id="Vector_37"
                      d="M2175.45 92.13v-1.54c0-.2-2.86 4.22-2.94 4.32a.85.85 0 0 0-.14.41c0 .13 0 .28.08.36.1.065.21.119.32.16.08.011.16.011.24 0 0 0-1.22 1.25-1.38 1.47-.16.22-.15.37-.07.48.12.088.26.14.4.15.1 0 .19-.023.27-.07.05-.029.1-.066.14-.11 0 0-1.34 1.54-1.45 1.73a.524.524 0 0 0 0 .49c.12.091.26.141.41.14a.84.84 0 0 0 .29-.19s-1.31 1.57-1.43 1.8a.605.605 0 0 0 0 .53c.05.098.13.18.22.24.18.119.36.226.55.32.12 0 .71.23.93.29.22.06 3 .07 3 .07v1.88c0 .048 0 .097.02.142.02.046.05.086.08.118.19.028.39.028.58 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_38"
                      d="M2175.45 92.13v-1.54c0-.2 2.87 4.22 3 4.32.08.12.13.263.14.41 0 .13 0 .28-.09.36-.1.069-.21.123-.32.16a.745.745 0 0 1-.23 0c-.05 0 1.22 1.25 1.37 1.47.15.22.16.37.07.48a.693.693 0 0 1-.39.15.52.52 0 0 1-.27-.07.36.36 0 0 1-.14-.11s1.34 1.54 1.44 1.73c.04.074.07.159.07.245s-.03.17-.07.245a.614.614 0 0 1-.4.14.84.84 0 0 1-.29-.19s1.3 1.57 1.43 1.8a.605.605 0 0 1 0 .53.65.65 0 0 1-.22.24c-.18.119-.36.226-.55.32-.12 0-.71.23-.94.29-.23.06-3 .07-3 .07v1.88c.01.049 0 .099-.02.145a.282.282 0 0 1-.09.115c-.19.027-.38.027-.57 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 128">
                    <path
                      id="Vector_39"
                      d="M2261.75 163.27v-2.46c0-.33-4.6 6.75-4.72 6.93-.13.189-.21.41-.22.64 0 .21 0 .45.13.58.13.13.4.19.52.26.12.044.24.068.37.07.08 0-1.95 2-2.2 2.36-.25.36-.25.6-.11.77.18.142.4.226.63.24.15-.002.3-.04.44-.11.08-.042.16-.1.22-.17 0 0-2.15 2.46-2.32 2.77a.906.906 0 0 0-.1.395c0 .137.04.273.1.395.19.141.42.214.65.21.17-.069.33-.171.46-.3 0 0-2.08 2.52-2.28 2.89a.905.905 0 0 0-.11.425c0 .148.04.294.11.425.08.158.2.289.35.38.28.19.58.364.88.52.2.08 1.13.37 1.5.46.37.09 4.76.12 4.76.12v3c-.01.079 0 .158.03.231.03.074.08.138.14.189.31.063.62.08.93.05"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_40"
                      d="M2261.75 163.27v-2.46c0-.33 4.6 6.75 4.72 6.93.14.184.22.409.22.64 0 .21 0 .45-.13.58-.13.13-.4.19-.51.26-.12.044-.24.068-.37.07-.08 0 1.94 2 2.2 2.36.26.36.25.6.11.77a1.16 1.16 0 0 1-.64.24.916.916 0 0 1-.43-.11.764.764 0 0 1-.23-.17s2.16 2.46 2.32 2.77c.07.121.1.257.1.395s-.03.274-.1.395c-.19.141-.41.214-.65.21a1.408 1.408 0 0 1-.46-.3s2.09 2.52 2.28 2.89a.9.9 0 0 1 .11.425.9.9 0 0 1-.11.425c-.07.16-.2.292-.35.38-.28.19-.58.364-.88.52-.19.08-1.13.37-1.49.46-.36.09-4.77.12-4.77.12v3c.01.079 0 .158-.03.231a.495.495 0 0 1-.14.189c-.3.063-.61.08-.92.05"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 127">
                    <path
                      id="Vector_41"
                      d="M2315.09 160.35v-1.22c0-.16-2.27 3.34-2.33 3.42a.532.532 0 0 0-.11.32c0 .11 0 .23.07.29.07.06.19.09.25.12.06.017.12.017.18 0 0 0-1 1-1.08 1.17-.08.17-.13.3-.06.38.09.067.2.105.31.11.07.021.15.021.22 0a.346.346 0 0 0 .11-.08s-1.06 1.21-1.15 1.37a.44.44 0 0 0-.05.195c0 .068.02.135.05.195.09.068.21.104.32.1a.625.625 0 0 0 .23-.15s-1 1.24-1.13 1.43a.506.506 0 0 0-.05.21c0 .073.02.145.05.21.04.076.1.141.17.19.14.091.29.175.44.25l.74.23c.18 0 2.35.06 2.35.06v1.49c0 .038 0 .077.02.112s.04.065.07.088c.15.023.3.023.45 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_42"
                      d="M2315.09 160.35v-1.22c0-.16 2.27 3.34 2.33 3.42.07.093.11.204.11.32 0 .11 0 .23-.06.29a.901.901 0 0 1-.26.12.324.324 0 0 1-.18 0s1 1 1.08 1.17c.08.17.13.3.06.38a.566.566 0 0 1-.31.11.392.392 0 0 1-.22 0 .346.346 0 0 1-.11-.08s1.06 1.21 1.15 1.37c.03.06.05.127.05.195a.44.44 0 0 1-.05.195.516.516 0 0 1-.32.1.72.72 0 0 1-.23-.15s1 1.24 1.13 1.43c.04.064.05.136.05.21 0 .073-.01.146-.05.21a.544.544 0 0 1-.17.19c-.14.091-.29.175-.44.25l-.74.23c-.18 0-2.35.06-2.35.06v1.49c0 .038 0 .077-.02.112a.187.187 0 0 1-.07.088c-.15.023-.3.023-.45 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 126">
                    <path
                      id="Vector_43"
                      d="M2364.11 118.76V116c0-.37-5.17 7.59-5.3 7.79-.15.209-.24.46-.25.72 0 .24 0 .51.15.66.18.123.37.218.57.28.14.049.28.079.42.09.09 0-2.19 2.26-2.47 2.65-.28.39-.28.68-.13.86.2.162.45.256.71.27.17 0 .34-.041.49-.12.1-.046.19-.111.26-.19 0 0-2.42 2.77-2.61 3.12a.956.956 0 0 0-.11.44c0 .154.04.306.11.44.21.153.47.237.73.24.19-.081.37-.197.52-.34 0 0-2.35 2.83-2.57 3.25a.986.986 0 0 0-.12.475c0 .166.04.329.12.475.09.179.22.328.39.43.15.09.78.5 1 .59.22.09 1.27.4 1.68.51.41.11 5.36.13 5.36.13v3.39a.574.574 0 0 0 .19.47c.33.067.67.087 1 .06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_44"
                      d="M2364.11 118.76V116c0-.37 5.17 7.59 5.3 7.79.16.208.25.459.26.72 0 .24 0 .51-.15.66a2.16 2.16 0 0 1-.58.28c-.14.049-.28.079-.42.09-.09 0 2.19 2.26 2.48 2.65.29.39.28.68.12.86-.2.163-.45.258-.71.27-.17 0-.34-.041-.49-.12a.7.7 0 0 1-.25-.19s2.42 2.77 2.6 3.12c.08.13.13.279.14.433a.865.865 0 0 1-.09.447c-.21.153-.46.237-.72.24a1.665 1.665 0 0 1-.52-.34s2.34 2.83 2.57 3.25c.08.146.12.309.12.475 0 .166-.04.329-.12.475-.09.177-.23.325-.4.43-.15.09-.78.5-1 .59-.22.09-1.27.4-1.68.51-.41.11-5.36.13-5.36.13v3.39c.01.088 0 .177-.04.259a.483.483 0 0 1-.15.211c-.33.067-.67.087-1 .06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 125">
                    <path
                      id="Vector_45"
                      d="M2434.45 84.49v-1.83c0-.25-3.43 5-3.51 5.16a.914.914 0 0 0-.17.48c0 .16 0 .33.1.43.12.081.24.145.38.19.09.036.18.056.28.06.06 0-1.46 1.5-1.64 1.76-.18.26-.19.44-.09.57a.88.88 0 0 0 .48.17.66.66 0 0 0 .32-.08c.06-.029.12-.07.17-.12 0 0-1.61 1.83-1.73 2.06a.614.614 0 0 0-.08.295c0 .103.03.204.08.295.14.1.31.156.48.16a1.1 1.1 0 0 0 .34-.23s-1.55 1.88-1.7 2.16a.693.693 0 0 0-.08.315c0 .11.03.218.08.315.06.113.15.21.26.28.21.142.43.273.66.39.14.05.84.27 1.11.34.27.07 3.55.09 3.55.09V100c-.01.058 0 .117.02.172a.4.4 0 0 0 .11.138c.23.037.46.037.69 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_46"
                      d="M2434.45 84.49v-1.83c0-.25 3.42 5 3.51 5.16.1.139.16.306.17.48 0 .16 0 .33-.1.43-.12.081-.25.145-.38.19a.813.813 0 0 1-.28.06c-.06 0 1.45 1.5 1.64 1.76.19.26.19.44.09.57a.88.88 0 0 1-.48.17.66.66 0 0 1-.32-.08.421.421 0 0 1-.17-.12s1.61 1.83 1.73 2.06c.05.09.07.192.07.295 0 .103-.02.204-.07.295-.14.1-.31.156-.48.16a1.1 1.1 0 0 1-.34-.23s1.55 1.88 1.7 2.16c.05.097.08.205.08.315 0 .11-.03.218-.08.315a.75.75 0 0 1-.26.28c-.21.142-.43.273-.66.39-.14.05-.84.27-1.11.34-.27.07-3.55.09-3.55.09V100c.01.058 0 .117-.03.172a.289.289 0 0 1-.1.138c-.23.037-.46.037-.69 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 124">
                    <path
                      id="Vector_47"
                      d="M2498.91 147.6v-1.83c0-.24-3.43 5-3.52 5.16a.926.926 0 0 0-.16.48c0 .16 0 .34.1.44.12.076.24.137.38.18.08.035.18.055.27.06.06 0-1.45 1.5-1.63 1.76-.18.26-.19.45-.09.57.13.103.3.166.47.18a.682.682 0 0 0 .5-.21s-1.61 1.84-1.73 2.07a.59.59 0 0 0-.07.29c0 .101.02.2.07.29.14.109.31.165.49.16.13-.052.24-.131.34-.23 0 0-1.55 1.88-1.7 2.16a.583.583 0 0 0-.08.315c0 .11.02.219.08.315.05.12.14.221.26.29.1 0 .52.33.66.38.14.05.84.28 1.11.34.27.06 3.55.09 3.55.09v2.25c-.01.058 0 .117.02.172a.4.4 0 0 0 .11.138c.23.032.46.032.69 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_48"
                      d="M2498.91 147.6v-1.83c0-.24 3.42 5 3.51 5.16.1.139.16.307.17.48 0 .16 0 .34-.1.44-.12.072-.25.133-.38.18a.825.825 0 0 1-.28.06c-.06 0 1.45 1.5 1.64 1.76.19.26.19.45.08.57a.832.832 0 0 1-.47.18.66.66 0 0 1-.49-.21s1.6 1.84 1.73 2.07c.05.089.08.189.08.29 0 .101-.03.201-.08.29a.742.742 0 0 1-.48.16c-.13-.051-.25-.13-.35-.23 0 0 1.56 1.88 1.71 2.16.05.098.07.206.07.315 0 .109-.02.217-.07.315a.787.787 0 0 1-.26.29c-.1 0-.52.33-.66.38-.14.05-.85.28-1.12.34-.27.06-3.54.09-3.54.09v2.25c0 .058-.01.116-.03.17a.39.39 0 0 1-.1.14c-.23.032-.46.032-.69 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 123">
                    <path
                      id="Vector_49"
                      d="M2609 129.91v-1.84c0-.24-3.42 5-3.51 5.16a.917.917 0 0 0-.17.48c0 .16 0 .34.1.44.12.082.24.143.38.18.09.036.18.056.28.06.06 0-1.45 1.5-1.64 1.76-.19.26-.19.45-.09.57.14.105.31.168.48.18a.66.66 0 0 0 .32-.08c.07-.03.12-.075.17-.13 0 0-1.61 1.84-1.73 2.07a.587.587 0 0 0-.07.29c0 .101.02.201.07.29.14.109.31.166.48.16a.99.99 0 0 0 .34-.22s-1.55 1.87-1.7 2.15a.691.691 0 0 0-.08.315.757.757 0 0 0 .34.605c.1.06.52.33.66.39s.84.27 1.11.33c.27.06 3.55.09 3.55.09v2.25c0 .058.01.116.03.17a.39.39 0 0 0 .1.14c.23.032.46.032.69 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_50"
                      d="M2609 129.91v-1.84c0-.24 3.43 5 3.51 5.16.1.139.16.307.17.48 0 .16 0 .34-.1.44-.11.082-.24.143-.38.18a.825.825 0 0 1-.28.06c-.06 0 1.46 1.5 1.64 1.76.18.26.19.45.09.57a.916.916 0 0 1-.48.18.66.66 0 0 1-.49-.21s1.61 1.84 1.73 2.07c.05.089.08.189.08.29 0 .101-.03.201-.08.29a.742.742 0 0 1-.48.16.99.99 0 0 1-.34-.22s1.55 1.87 1.7 2.15c.05.097.08.205.08.315a.757.757 0 0 1-.34.605c-.1.06-.52.33-.66.39s-.84.27-1.11.33c-.27.06-3.55.09-3.55.09v2.25c0 .058-.01.116-.03.17a.39.39 0 0 1-.1.14c-.23.032-.46.032-.69 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 122">
                    <path
                      id="Vector_51"
                      d="M2845.43 107.34v-2.64c0-.35-4.93 7.24-5.06 7.43-.15.199-.23.44-.24.69 0 .23 0 .49.14.63.17.114.36.205.55.27.13.051.26.078.4.08.09 0-2.09 2.16-2.36 2.53-.27.37-.27.65-.12.82.19.156.43.246.68.26.16-.002.32-.039.47-.11.09-.045.18-.11.24-.19 0 0-2.31 2.64-2.49 3a.883.883 0 0 0-.11.42c0 .147.04.291.11.42.2.148.45.229.7.23.18-.08.35-.192.49-.33 0 0-2.24 2.7-2.45 3.11a.992.992 0 0 0 .38 1.31c.3.209.62.396.95.56.2.08 1.21.39 1.6.49.39.1 5.11.13 5.11.13v3.23c-.01.084 0 .169.04.247.03.079.08.148.14.203.33.072.67.092 1 .06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_52"
                      d="M2845.43 107.34v-2.64c0-.35 4.93 7.24 5.06 7.43.15.201.23.441.24.69 0 .23 0 .49-.14.63-.17.114-.35.205-.55.27-.13.051-.26.078-.4.08-.09 0 2.09 2.16 2.36 2.53.27.37.27.65.12.82-.19.156-.43.246-.68.26a1.131 1.131 0 0 1-.47-.11.791.791 0 0 1-.24-.19s2.31 2.64 2.49 3a.77.77 0 0 1 .13.414.788.788 0 0 1-.08.426c-.2.148-.45.229-.7.23-.18-.08-.35-.192-.49-.33 0 0 2.24 2.7 2.45 3.11.12.226.14.487.07.73a.992.992 0 0 1-.45.58c-.32.211-.65.398-1 .56-.2.08-1.21.39-1.6.49-.39.1-5.11.13-5.11.13v3.23c.01.084 0 .169-.03.247a.466.466 0 0 1-.15.203c-.33.072-.66.092-1 .06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 121">
                    <path
                      id="Vector_53"
                      d="M3033.33 128.15v-1.29c0-.18-2.42 3.55-2.48 3.64a.683.683 0 0 0-.12.34c0 .12 0 .24.07.31.08.057.17.1.27.13.07.017.14.017.2 0 0 0-1 1.07-1.16 1.25-.16.18-.13.31-.06.4.09.078.21.124.33.13.08 0 .16-.02.23-.06a.317.317 0 0 0 .12-.09s-1.13 1.3-1.22 1.46a.394.394 0 0 0 0 .42c.1.069.22.107.34.11a.794.794 0 0 0 .24-.16s-1.1 1.32-1.2 1.52a.446.446 0 0 0 0 .45c.04.084.1.154.18.2.07 0 .37.23.47.27l.78.24c.19 0 2.51.07 2.51.07v1.58c0 .041 0 .083.02.121a.21.21 0 0 0 .07.099c.16.023.33.023.49 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_54"
                      d="M3033.33 128.15v-1.29c0-.18 2.43 3.55 2.49 3.64.07.101.11.218.12.34 0 .12 0 .24-.07.31-.08.057-.17.1-.27.13a.422.422 0 0 1-.2 0s1 1.07 1.16 1.25c.16.18.13.31.06.4a.55.55 0 0 1-.34.13.511.511 0 0 1-.23-.06.434.434 0 0 1-.11-.09s1.13 1.3 1.22 1.46c.04.065.05.137.05.21 0 .073-.01.145-.05.21a.63.63 0 0 1-.34.11.794.794 0 0 1-.24-.16s1.1 1.32 1.2 1.52a.446.446 0 0 1 0 .45.476.476 0 0 1-.18.2c-.07 0-.37.23-.47.27-.1.04-.59.19-.79.24-.2.05-2.5.07-2.5.07v1.58c.01.041 0 .083-.02.121a.21.21 0 0 1-.07.099c-.16.023-.33.023-.49 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 120">
                    <path
                      id="Vector_55"
                      d="M3162.53 149.92v-2.38c0-.32-4.45 6.53-4.57 6.7-.13.185-.21.403-.22.63 0 .2 0 .43.13.56.16.104.32.185.5.24.12.051.24.078.36.08.08 0-1.89 1.95-2.13 2.28-.24.33-.24.58-.11.74.18.138.4.218.62.23.15.003.29-.032.42-.1.08-.04.16-.098.21-.17 0 0-2.08 2.39-2.24 2.69a.834.834 0 0 0-.1.38c0 .133.04.264.1.38.18.134.4.204.62.2.17-.066.32-.165.45-.29 0 0-2 2.43-2.21 2.8a.968.968 0 0 0-.1.41c0 .143.04.283.1.41.08.152.2.28.34.37.13.07.67.43.85.5.18.07 1.1.35 1.45.44.35.09 4.61.12 4.61.12v2.91a.476.476 0 0 0 .16.41c.3.041.6.041.9 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_56"
                      d="M3162.52 149.92v-2.38c0-.32 4.45 6.53 4.57 6.7.13.185.21.403.22.63 0 .2 0 .43-.13.56a1.95 1.95 0 0 1-.5.24.916.916 0 0 1-.36.08c-.08 0 1.89 1.95 2.13 2.28.24.33.24.58.11.74-.18.136-.39.216-.61.23a.895.895 0 0 1-.43-.1.657.657 0 0 1-.21-.17s2.08 2.39 2.24 2.69c.07.115.1.247.1.38s-.03.264-.1.38c-.18.134-.4.204-.62.2a1.354 1.354 0 0 1-.45-.29s2 2.43 2.21 2.8c.06.127.1.267.1.41 0 .143-.04.283-.1.41-.08.152-.2.28-.34.37-.12.07-.67.43-.85.5-.18.07-1.1.35-1.45.44-.35.09-4.61.12-4.61.12v2.91a.476.476 0 0 1-.16.41c-.3.041-.6.041-.9 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 119">
                    <path
                      id="Vector_57"
                      d="M3248.9 101.23v-1.75c0-.23-3.27 4.81-3.36 4.93a.846.846 0 0 0-.16.46c0 .15 0 .32.1.42.11.075.23.132.36.17.09.036.18.056.27.06 0 0-1.39 1.43-1.57 1.68-.18.25-.18.43-.08.54.13.102.29.161.45.17.11.001.21-.023.31-.07a.684.684 0 0 0 .16-.12s-1.53 1.75-1.65 2c-.12.25-.11.49 0 .56.13.096.29.148.46.15a.995.995 0 0 0 .33-.22s-1.49 1.8-1.63 2.06a.644.644 0 0 0-.07.305c0 .106.02.211.07.305.06.11.14.203.25.27.1 0 .5.32.63.37.13.05.81.26 1.06.32s3.39.09 3.39.09V116c0 .057 0 .114.03.166.02.052.06.098.1.134.22.032.43.032.65 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_58"
                      d="M3248.9 101.23v-1.75c0-.23 3.27 4.81 3.36 4.93.09.137.15.295.16.46 0 .15 0 .32-.1.42a1.22 1.22 0 0 1-.37.17.677.677 0 0 1-.26.06c-.06 0 1.39 1.43 1.57 1.68.18.25.18.43.08.54a.809.809 0 0 1-.45.17.739.739 0 0 1-.32-.07.53.53 0 0 1-.15-.12s1.53 1.75 1.65 2c.04.087.07.183.07.28 0 .097-.03.193-.07.28a.834.834 0 0 1-.46.15.995.995 0 0 1-.33-.22s1.48 1.8 1.62 2.06c.05.093.08.198.08.305 0 .107-.03.212-.08.305a.772.772 0 0 1-.24.27c-.1 0-.5.32-.63.37-.13.05-.81.26-1.07.32s-3.39.09-3.39.09V116a.31.31 0 0 1-.02.165.372.372 0 0 1-.1.135 2.28 2.28 0 0 1-.66 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 118">
                    <path
                      id="Vector_59"
                      d="M3344.64 87.87V86.4c0-.2-2.75 4-2.82 4.14a.772.772 0 0 0-.13.39c-.01.121.01.244.07.35.1.064.2.114.31.15.07.01.15.01.22 0 .05 0-1.16 1.2-1.31 1.41-.15.21-.15.36-.07.46a.64.64 0 0 0 .38.14c.09 0 .18-.021.26-.06.05-.029.1-.066.14-.11 0 0-1.29 1.48-1.39 1.67a.484.484 0 0 0 0 .47c.11.083.25.125.39.12.1-.043.19-.104.27-.18 0 0-1.25 1.51-1.36 1.73a.558.558 0 0 0-.06.255c0 .09.02.177.06.255.05.093.12.173.2.23.17.114.35.217.53.31.12 0 .68.22.9.28.22.06 2.85.07 2.85.07v1.8c0 .047 0 .094.02.138.02.043.05.082.08.112.19.028.38.028.56 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_60"
                      d="M3344.64 87.87V86.4c0-.2 2.76 4 2.83 4.14.08.115.13.25.13.39 0 .13 0 .27-.08.35-.09.066-.2.117-.31.15a.682.682 0 0 1-.22 0s1.17 1.2 1.32 1.41c.15.21.15.36.07.46a.685.685 0 0 1-.38.14c-.09 0-.18-.02-.26-.06a.594.594 0 0 1-.14-.11s1.29 1.48 1.39 1.67a.484.484 0 0 1 0 .47.659.659 0 0 1-.39.12c-.1-.044-.2-.105-.28-.18 0 0 1.25 1.51 1.37 1.73.06.127.08.274.04.41a.582.582 0 0 1-.25.33c-.17.116-.35.22-.53.31-.11 0-.67.22-.89.28-.22.06-2.85.07-2.85.07v1.8c0 .047 0 .095-.02.139a.284.284 0 0 1-.09.111c-.18.028-.37.028-.55 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 117">
                    <path
                      id="Vector_61"
                      d="M3444.81 158.74v-1.17c0-.16-2.18 3.21-2.24 3.29a.571.571 0 0 0-.1.31.37.37 0 0 0 .06.27c.06.07.19.09.24.12.06.007.12.007.18 0 0 0-.92 1-1 1.12-.08.12-.12.28-.06.36.08.069.19.111.3.12.07.015.14.015.21 0a.434.434 0 0 0 .11-.09s-1 1.17-1.1 1.32a.42.42 0 0 0-.05.19c0 .066.02.132.05.19.09.06.2.094.31.1a.904.904 0 0 0 .22-.15s-1 1.2-1.08 1.38a.363.363 0 0 0 0 .4c.03.073.09.135.16.18.06 0 .33.21.42.25.09.04.54.17.71.21.17.04 2.26.06 2.26.06v1.43a.15.15 0 0 0 .02.112c.01.035.04.065.07.088.14.024.29.024.43 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_62"
                      d="M3444.81 158.74v-1.17c0-.16 2.19 3.21 2.24 3.29a.5.5 0 0 1 .11.31c0 .1 0 .21-.06.27a.847.847 0 0 1-.25.12.69.69 0 0 1-.17 0s.92 1 1 1.12c.08.12.12.28.06.36a.563.563 0 0 1-.31.12.481.481 0 0 1-.2 0 .434.434 0 0 1-.11-.09s1 1.17 1.1 1.32a.42.42 0 0 1 .05.19.42.42 0 0 1-.05.19.59.59 0 0 1-.3.1.677.677 0 0 1-.22-.15s1 1.2 1.08 1.38c.03.061.05.13.05.2s-.02.139-.05.2a.479.479 0 0 1-.16.18c-.07 0-.33.21-.42.25-.09.04-.54.17-.71.21-.17.04-2.27.06-2.27.06v1.43c0 .037 0 .075-.02.109a.182.182 0 0 1-.06.091c-.15.024-.3.024-.44 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 116">
                    <path
                      id="Vector_63"
                      d="M3616 159.87v-2.34c0-.31-4.39 6.44-4.5 6.6-.13.18-.2.397-.21.62 0 .2 0 .43.12.55.15.104.32.185.49.24.11.051.24.078.36.08.07 0-1.86 1.92-2.1 2.25-.24.33-.24.57-.11.73.17.139.39.219.61.23a.96.96 0 0 0 .41-.1c.08-.042.16-.1.22-.17 0 0-2.06 2.35-2.21 2.65a.82.82 0 0 0-.1.375c0 .131.04.26.1.375.18.128.4.198.62.2.16-.068.31-.167.44-.29 0 0-2 2.4-2.18 2.76a.8.8 0 0 0-.1.4.8.8 0 0 0 .1.4c.07.151.19.279.33.37.28.181.56.348.85.5.18.07 1.08.34 1.42.43.34.09 4.54.12 4.54.12v2.87c-.01.074 0 .149.03.217.03.069.08.128.14.173.29.065.59.086.88.06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_64"
                      d="M3616 159.87v-2.34c0-.31 4.38 6.44 4.5 6.6.13.183.2.398.21.62 0 .2 0 .43-.13.55-.15.108-.31.189-.49.24a.837.837 0 0 1-.35.08c-.08 0 1.86 1.92 2.1 2.25.24.33.24.57.11.73a1.07 1.07 0 0 1-.61.23 1.02 1.02 0 0 1-.42-.1.785.785 0 0 1-.21-.17s2 2.35 2.21 2.65c.06.115.1.244.1.375a.82.82 0 0 1-.1.375c-.18.127-.4.196-.62.2a1.402 1.402 0 0 1-.44-.29s2 2.4 2.18 2.76a.8.8 0 0 1 .1.4.8.8 0 0 1-.1.4.827.827 0 0 1-.34.37c-.27.183-.55.35-.84.5-.18.07-1.08.34-1.42.43-.34.09-4.55.12-4.55.12v2.87a.44.44 0 0 1-.16.39c-.29.065-.58.086-.88.06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 115">
                    <path
                      id="Vector_65"
                      d="M3751 132.1v-1.64c0-.22-3.06 4.5-3.14 4.61a.743.743 0 0 0-.14.43c-.02.135.01.273.08.39.11.072.22.126.35.16.07.035.16.056.24.06.06 0-1.29 1.34-1.46 1.57-.17.23-.17.4-.08.51a.73.73 0 0 0 .43.15.66.66 0 0 0 .24-.046.716.716 0 0 0 .2-.134s-1.44 1.64-1.55 1.85a.59.59 0 0 0-.07.26c0 .091.03.18.07.26.13.088.28.136.43.14a.89.89 0 0 0 .31-.2s-1.39 1.67-1.52 1.92a.648.648 0 0 0 .23.82c.09 0 .46.3.59.35.13.05.75.24 1 .3s3.17.08 3.17.08v2c0 .053 0 .106.02.155.03.048.06.091.1.125.2.031.41.031.61 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_66"
                      d="M3751 132.1v-1.64c0-.22 3.06 4.5 3.14 4.61.09.124.15.275.15.43 0 .14 0 .3-.09.39-.1.072-.22.126-.34.16a.647.647 0 0 1-.25.06c-.05 0 1.3 1.34 1.47 1.57.17.23.16.4.07.51a.724.724 0 0 1-.42.15.66.66 0 0 1-.24-.046.716.716 0 0 1-.2-.134s1.43 1.64 1.54 1.85c.04.08.07.169.07.26a.59.59 0 0 1-.07.26.758.758 0 0 1-.43.14 1.069 1.069 0 0 1-.31-.2s1.39 1.67 1.53 1.92c.04.087.07.183.07.28 0 .097-.03.193-.07.28a.621.621 0 0 1-.24.26c-.09 0-.46.3-.59.35-.13.05-.75.24-1 .3s-3.17.08-3.17.08v2a.324.324 0 0 1-.11.28c-.2.032-.41.032-.62 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 114">
                    <path
                      id="Vector_67"
                      d="M3875.13 102.48v-1.63c0-.22-3.06 4.49-3.14 4.6a.825.825 0 0 0-.15.43c0 .14 0 .3.09.39.09.09.26.12.34.17.08.012.17.012.25 0 .05 0-1.3 1.34-1.47 1.57-.17.23-.16.4-.07.51.12.096.27.152.42.16.1 0 .2-.024.29-.07a.41.41 0 0 0 .15-.12s-1.43 1.64-1.54 1.85a.59.59 0 0 0-.07.26c0 .091.03.18.07.26a.69.69 0 0 0 .43.14.95.95 0 0 0 .31-.2s-1.39 1.67-1.53 1.93a.671.671 0 0 0 0 .56c.06.104.14.19.24.25.19.129.39.246.59.35.12 0 .75.24 1 .3s3.17.08 3.17.08v2c-.01.051 0 .102.02.149a.3.3 0 0 0 .09.121c.21.032.42.032.62 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_68"
                      d="M3875.13 102.48v-1.63c0-.22 3.06 4.49 3.14 4.6.08.128.13.277.14.43a.593.593 0 0 1-.08.39c-.09.09-.27.12-.35.17a.805.805 0 0 1-.24 0s1.29 1.34 1.46 1.57c.17.23.17.4.08.51a.746.746 0 0 1-.43.16.64.64 0 0 1-.29-.07.41.41 0 0 1-.15-.12s1.44 1.64 1.55 1.85c.04.08.07.169.07.26a.59.59 0 0 1-.07.26.716.716 0 0 1-.43.14.89.89 0 0 1-.31-.2s1.39 1.67 1.52 1.93a.55.55 0 0 1 .07.28.55.55 0 0 1-.07.28.596.596 0 0 1-.23.25c-.19.129-.39.246-.59.35-.13 0-.75.24-1 .3s-3.17.08-3.17.08v2c0 .051-.01.103-.03.15a.298.298 0 0 1-.09.12c-.2.031-.41.031-.61 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 113">
                    <path
                      id="Vector_69"
                      d="M3953.43 135.93v-1.24c0-.16-2.32 3.4-2.38 3.48a.561.561 0 0 0-.11.33c0 .1 0 .22.07.29.07.07.2.09.26.13.06.007.12.007.18 0 0 0-1 1-1.1 1.18-.1.18-.13.3-.06.39.09.071.21.113.32.12.08 0 .15-.021.22-.06a.346.346 0 0 0 .11-.08s-1.08 1.24-1.16 1.39a.459.459 0 0 0-.05.2c0 .07.02.139.05.2.1.068.21.104.33.1a.601.601 0 0 0 .23-.15l-1.15 1.46a.499.499 0 0 0-.05.21c0 .073.02.146.05.21.04.079.1.145.18.19.06 0 .35.23.44.27.09.04.57.18.75.22.18.04 2.4.06 2.4.06v1.52c0 .039 0 .078.02.114a.2.2 0 0 0 .06.096c.16.024.32.024.47 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_70"
                      d="M3953.43 135.93v-1.24c0-.16 2.31 3.4 2.37 3.48.07.096.11.211.11.33 0 .1 0 .22-.07.29-.07.07-.2.09-.26.13a.774.774 0 0 1-.18 0s1 1 1.1 1.18c.1.18.13.3.06.39a.566.566 0 0 1-.32.12.483.483 0 0 1-.22-.06.346.346 0 0 1-.11-.08s1.08 1.24 1.17 1.39c.03.062.05.13.05.2 0 .069-.02.138-.05.2a.507.507 0 0 1-.33.1.805.805 0 0 1-.23-.15s1.05 1.27 1.15 1.46a.394.394 0 0 1 0 .42.445.445 0 0 1-.18.19c-.14.099-.29.189-.44.27-.1 0-.57.18-.75.22-.18.04-2.4.06-2.4.06v1.52c0 .039 0 .078-.01.114a.354.354 0 0 1-.07.096c-.15.024-.31.024-.47 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 112">
                    <path
                      id="Vector_71"
                      d="M4206.36 134.32v-1.16c0-.16-2.17 3.19-2.23 3.27a.58.58 0 0 0-.11.31c0 .1 0 .21.07.27.07.06.18.09.24.12.06.007.12.007.18 0 0 0-.93 1-1 1.12-.07.12-.12.28-.06.36.09.067.19.105.3.11.07.015.14.015.21 0a.2.2 0 0 0 .1-.08s-1 1.17-1.09 1.31a.397.397 0 0 0-.05.185c0 .065.02.129.05.185.08.069.19.104.3.1a.636.636 0 0 0 .22-.14s-1 1.19-1.08 1.37a.466.466 0 0 0-.05.2c0 .069.02.138.05.2.04.076.1.138.17.18.06 0 .32.21.41.25.09.04.54.17.71.21.17.04 2.25.06 2.25.06v1.42c0 .037 0 .075.02.109.01.035.03.066.06.091.15.023.29.023.44 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_72"
                      d="M4206.36 134.32v-1.16c0-.16 2.18 3.19 2.23 3.27a.5.5 0 0 1 .11.31c0 .1 0 .21-.06.27a.847.847 0 0 1-.25.12.605.605 0 0 1-.17 0s.92 1 1 1.12c.08.12.12.28.05.36a.52.52 0 0 1-.3.11.45.45 0 0 1-.2 0 .346.346 0 0 1-.11-.08s1 1.17 1.1 1.31c.1.14.07.33 0 .37a.488.488 0 0 1-.31.1.636.636 0 0 1-.22-.14s1 1.19 1.08 1.37c.04.061.05.13.05.2s-.01.139-.05.2a.479.479 0 0 1-.16.18c-.06 0-.33.21-.42.25-.09.04-.54.17-.71.21-.17.04-2.25.06-2.25.06v1.42c0 .037 0 .075-.02.109a.182.182 0 0 1-.06.091c-.15.023-.29.023-.44 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 111">
                    <path
                      id="Vector_73"
                      d="M4332.49 99.85v-1.16c0-.16-2.17 3.19-2.23 3.28a.634.634 0 0 0-.11.3c0 .1 0 .21.07.28.07.07.18.08.24.12.06.012.12.012.17 0 0 0-.92 1-1 1.12-.08.12-.12.28-.05.36.09.067.19.105.3.11a.393.393 0 0 0 .31-.13s-1 1.17-1.09 1.31a.42.42 0 0 0-.05.19c0 .066.02.132.05.19.09.06.2.094.31.1a.746.746 0 0 0 .22-.15s-1 1.19-1.08 1.37c-.03.06-.05.13-.05.2s.02.14.05.2c.03.075.09.138.16.18.07 0 .33.21.42.25.09.04.54.17.71.21.17.04 2.25.06 2.25.06v1.43c0 .036 0 .072.02.105.01.033.03.062.06.085.15.023.3.023.44 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_74"
                      d="M4332.49 99.85v-1.16c0-.16 2.17 3.19 2.23 3.28.07.087.1.191.11.3 0 .1 0 .21-.07.28-.07.07-.18.08-.24.12a.406.406 0 0 1-.17 0s.92 1 1 1.12c.08.12.11.28 0 .36a.535.535 0 0 1-.3.11.393.393 0 0 1-.21-.05.254.254 0 0 1-.1-.08s1 1.17 1.09 1.31a.42.42 0 0 1 .05.19.42.42 0 0 1-.05.19c-.09.06-.2.094-.31.1a.746.746 0 0 1-.22-.15s1 1.19 1.08 1.37c.03.061.05.13.05.2s-.02.139-.05.2a.383.383 0 0 1-.16.18c-.07 0-.33.21-.42.25-.09.04-.54.17-.71.21-.17.04-2.25.06-2.25.06v1.43c0 .036 0 .072-.02.105a.167.167 0 0 1-.06.085c-.15.023-.29.023-.44 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 110">
                    <path
                      id="Vector_75"
                      d="M4422.62 165v-2.76c0-.37-5.16 7.57-5.29 7.77-.16.208-.24.46-.25.72 0 .24 0 .51.14.65.18.12.38.218.58.29.13.051.28.078.42.08.09 0-2.19 2.26-2.47 2.65-.28.39-.28.67-.13.86.21.157.46.248.72.26.17.004.33-.034.48-.11.1-.052.19-.12.26-.2 0 0-2.42 2.77-2.6 3.12a.947.947 0 0 0-.14.432.98.98 0 0 0 .08.448c.21.153.47.234.73.23.19-.078.37-.193.52-.34 0 0-2.34 2.83-2.56 3.25a.973.973 0 0 0-.13.5c0 .175.04.348.13.5.09.176.22.325.39.43.15.08.78.5 1 .58.22.08 1.27.41 1.68.51.41.1 5.34.14 5.34.14v3.37c-.01.088 0 .177.04.259.03.082.08.154.15.211.33.067.67.087 1 .06"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_76"
                      d="M4422.62 165v-2.76c0-.37 5.15 7.57 5.29 7.77.15.21.24.461.25.72 0 .24 0 .51-.15.65-.18.122-.38.22-.58.29-.13.052-.27.079-.41.08-.09 0 2.18 2.26 2.46 2.65.28.39.29.67.13.86-.21.155-.45.246-.71.26a.99.99 0 0 1-.49-.11.763.763 0 0 1-.25-.2s2.41 2.77 2.6 3.12c.08.131.13.28.14.434a.865.865 0 0 1-.09.446c-.21.154-.47.235-.73.23a1.473 1.473 0 0 1-.51-.34s2.34 2.83 2.56 3.25c.09.152.13.324.13.5 0 .175-.04.348-.13.5-.09.174-.23.322-.39.43-.15.08-.78.5-1 .58-.22.08-1.26.41-1.67.51-.41.1-5.34.14-5.34.14v3.37c.01.088 0 .177-.04.259a.483.483 0 0 1-.15.211c-.33.067-.67.087-1 .06"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 109">
                    <path
                      id="Vector_77"
                      d="M4490 121.84v-1.61c0-.22-3 4.42-3.09 4.54a.79.79 0 0 0-.15.42c0 .14 0 .29.09.38.09.09.26.12.34.17.08.029.16.046.24.05.05 0-1.28 1.32-1.44 1.54-.16.22-.17.4-.08.51.12.089.27.141.42.15.1 0 .2-.024.29-.07.05-.029.1-.066.14-.11 0 0-1.41 1.62-1.52 1.82a.57.57 0 0 0-.07.255c0 .089.03.177.07.255a.65.65 0 0 0 .43.14.907.907 0 0 0 .3-.2s-1.37 1.65-1.5 1.9a.673.673 0 0 0-.07.28c0 .098.03.194.07.28.06.101.14.187.23.25.09.05.46.29.58.34.12.05.74.24 1 .3s3.12.08 3.12.08v2c0 .05.01.101.02.148.02.047.05.088.09.122.2.031.41.031.61 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_78"
                      d="M4490 121.84v-1.61c0-.22 3 4.42 3.09 4.54a.79.79 0 0 1 .15.42c0 .14 0 .29-.09.38-.09.09-.26.12-.33.17a.888.888 0 0 1-.25.05c-.05 0 1.28 1.32 1.45 1.54.17.22.16.4.07.51a.792.792 0 0 1-.42.15c-.1 0-.19-.024-.28-.07a.443.443 0 0 1-.15-.11s1.41 1.62 1.52 1.82a.57.57 0 0 1 .07.255.57.57 0 0 1-.07.255.635.635 0 0 1-.42.14.89.89 0 0 1-.31-.2s1.37 1.65 1.5 1.9c.04.086.07.182.07.28 0 .098-.03.194-.07.28a.708.708 0 0 1-.23.25c-.19.124-.38.238-.58.34-.12.05-.74.24-1 .3s-3.12.08-3.12.08v2c.01.051 0 .102-.02.149a.308.308 0 0 1-.09.121c-.2.031-.41.031-.61 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 108">
                    <path
                      id="Vector_79"
                      d="M4580.4 92.49v-1.37c0-.18-2.57 3.78-2.63 3.87a.665.665 0 0 0-.13.36c0 .12 0 .25.08.33.08.08.22.1.28.14.07.017.14.017.21 0 0 0-1.09 1.13-1.23 1.32-.14.19-.14.34-.06.43.1.078.22.123.35.13.09.004.17-.017.25-.06a.316.316 0 0 0 .12-.09s-1.2 1.38-1.3 1.55a.43.43 0 0 0 0 .44c.1.077.23.119.36.12.1-.04.19-.097.26-.17 0 0-1.17 1.41-1.28 1.62a.483.483 0 0 0 0 .47c.04.09.11.164.2.21.16.107.32.204.49.29.28.101.56.188.84.26.2.05 2.66.07 2.66.07v1.68c0 .044 0 .088.02.128.02.041.04.076.08.102.17.028.34.028.51 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_80"
                      d="M4580.4 92.49v-1.37c0-.18 2.58 3.78 2.64 3.87.08.106.12.23.13.36 0 .12 0 .25-.08.33-.08.08-.22.1-.28.14a.452.452 0 0 1-.21 0c-.05 0 1.09 1.13 1.23 1.32.14.19.14.34.06.43a.62.62 0 0 1-.35.13.473.473 0 0 1-.25-.06.418.418 0 0 1-.12-.09s1.2 1.38 1.29 1.55a.427.427 0 0 1 0 .44.634.634 0 0 1-.36.12.832.832 0 0 1-.26-.17s1.17 1.41 1.28 1.62a.483.483 0 0 1 0 .47.434.434 0 0 1-.2.21 4.67 4.67 0 0 1-.49.29c-.11 0-.64.21-.84.26-.2.05-2.66.07-2.66.07v1.68a.19.19 0 0 1-.02.128.229.229 0 0 1-.08.102c-.17.028-.34.028-.51 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 107">
                    <path
                      id="Vector_81"
                      d="M4667 165.29v-2.93c0-.39-5.49 8-5.63 8.26-.16.225-.26.493-.27.77 0 .25 0 .54.16.7.19.126.4.227.62.3.14.05.29.08.44.09.09 0-2.33 2.4-2.63 2.81-.3.41-.3.72-.13.92.22.168.48.265.76.28.18.005.36-.04.52-.13.1-.052.18-.119.26-.2 0 0-2.57 2.94-2.76 3.31a.986.986 0 0 0-.06.94c.22.164.49.251.77.25.21-.084.4-.206.56-.36 0 0-2.49 3-2.73 3.45-.08.154-.13.326-.13.5s.05.346.13.5c.09.188.24.344.42.45.34.231.69.442 1.05.63.23.08 1.35.43 1.78.54.43.11 5.69.14 5.69.14v3.6c-.01.092 0 .185.04.27a.52.52 0 0 0 .16.22c.36.078.73.102 1.1.07"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_82"
                      d="M4667 165.29v-2.93c0-.39 5.48 8 5.62 8.26.17.221.27.491.27.77.03.245-.03.492-.16.7-.19.126-.39.227-.61.3-.14.049-.29.079-.44.09-.1 0 2.32 2.4 2.62 2.81.3.41.3.72.14.92-.22.166-.48.264-.76.28-.18.005-.36-.04-.52-.13a.987.987 0 0 1-.27-.2s2.57 2.94 2.77 3.31c.09.141.13.3.14.464a.976.976 0 0 1-.09.476c-.22.166-.49.254-.77.25-.2-.086-.39-.208-.55-.36 0 0 2.49 3 2.73 3.45.08.155.12.326.12.5s-.04.345-.12.5a1.06 1.06 0 0 1-.42.45c-.34.229-.69.439-1.06.63-.23.08-1.35.43-1.78.54-.43.11-5.68.14-5.68.14v3.6c.01.093 0 .187-.04.273a.522.522 0 0 1-.17.217c-.36.078-.73.102-1.1.07"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 106">
                    <path
                      id="Vector_83"
                      d="M4701.35 99.64v-1.88c0-.25-3.51 5.16-3.61 5.3a.95.95 0 0 0-.17.49c0 .16 0 .34.1.44.1.1.31.15.4.2a.88.88 0 0 0 .28.06c.06 0-1.49 1.54-1.68 1.8-.19.26-.19.46-.09.59a.91.91 0 0 0 .49.18c.12.001.23-.027.33-.08.07-.032.13-.08.17-.14 0 0-1.64 1.89-1.77 2.13a.626.626 0 0 0-.08.3c0 .106.03.209.08.3.15.1.32.156.49.16.13-.056.25-.134.35-.23 0 0-1.59 1.92-1.74 2.21a.708.708 0 0 0-.08.32c0 .112.03.222.08.32.06.123.15.227.26.3.22.142.45.272.68.39.15.06.86.28 1.14.35.28.07 3.64.09 3.64.09v2.31a.378.378 0 0 0 .13.31c.24.037.48.037.71 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_84"
                      d="M4701.35 99.64v-1.88c0-.25 3.51 5.16 3.6 5.3a.8.8 0 0 1 .17.49c0 .16 0 .34-.1.44-.12.088-.25.156-.39.2a.825.825 0 0 1-.28.06c-.06 0 1.49 1.54 1.68 1.8.19.26.19.46.08.59-.14.107-.3.17-.48.18a.687.687 0 0 1-.51-.22s1.65 1.89 1.78 2.13c.05.092.08.195.08.3 0 .105-.03.208-.08.3-.14.1-.31.156-.49.16a1.376 1.376 0 0 1-.36-.23s1.6 1.92 1.75 2.21a.6.6 0 0 1 .08.32.6.6 0 0 1-.08.32.728.728 0 0 1-.27.3 6.51 6.51 0 0 1-.67.39c-.15.06-.87.28-1.14.35-.27.07-3.64.09-3.64.09v2.31c.01.059 0 .119-.03.173a.303.303 0 0 1-.11.137c-.23.037-.47.037-.7 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                  <g id="Group 105">
                    <path
                      id="Vector_85"
                      d="M4773.52 58.12v-1.06c0-.14-2 2.92-2 3a.567.567 0 0 0-.1.28c0 .1 0 .2.06.26.07.044.14.077.22.1.05.006.11.006.16 0 0 0-.84.87-.95 1-.11.13-.11.26-.05.33.08.06.18.096.28.1.06.016.13.016.19 0a.197.197 0 0 0 .09-.08s-.93 1.07-1 1.2a.337.337 0 0 0-.04.17c0 .06.01.117.04.17.08.06.18.09.28.09a.61.61 0 0 0 .2-.13s-.9 1.09-1 1.25a.394.394 0 0 0-.04.185c0 .064.01.127.04.185.04.064.09.12.15.16.12.087.25.164.39.23l.64.2h2.06v1.3c0 .034 0 .069.01.1.02.032.04.06.07.08.13.019.27.019.4 0"
                      fill="var(--treesOnlyLeftColor)"
                    />
                    <path
                      id="Vector_86"
                      d="M4773.52 58.12v-1.06c0-.14 2 2.92 2 3 .06.083.09.18.1.28 0 .1 0 .2-.06.26a.838.838 0 0 1-.22.1.62.62 0 0 1-.16 0s.84.87.95 1c.11.13.11.26.05.33a.501.501 0 0 1-.28.1.402.402 0 0 1-.19 0 .264.264 0 0 1-.09-.08s.93 1.07 1 1.2c.03.053.04.11.04.17 0 .06-.01.117-.04.17a.47.47 0 0 1-.28.09.61.61 0 0 1-.2-.13s.9 1.09 1 1.25c.03.058.05.12.05.185 0 .064-.02.127-.05.185a.338.338 0 0 1-.15.16c-.12.087-.25.164-.39.23l-.64.2h-2.06v1.3c.01.034 0 .069-.01.1a.257.257 0 0 1-.07.08c-.13.019-.27.019-.4 0"
                      fill="var(--treesOnlyRightColor)"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="slideUp shade-front"></div>
        </div>
        <div className="horseRunningSprite-wrapper d-flex">
          <div className="horseRunningSprite"></div>
        </div>
      </div>
    </>
  );
}
