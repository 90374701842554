import { React, useContext, useMemo } from "react";
import AuthContext from "./AuthContext";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "../../Components/Button/Button";

export default function TwitterLogin() {
  const history = useHistory();
  const { login } = useContext(AuthContext);
  const { code } = useParams();

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  return (
    <Box
      marginY="20vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="90vw">
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Typography
              fontSize={headerFontSize}
              fontWeight={500}
              borderBottom="1px solid"
            >
              Authenticating through twitter
            </Typography>
          </Grid>

          <Grid
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Button text="Confirm" onClick={() => login(code, history)} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
