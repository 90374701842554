import React, { useMemo } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import {
  TextField,
  Button,
  ThemeProvider,
  Grid,
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useAddPlan from "./useAddPlan";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddPlan = () => {
  const {
    onSubmit,
    handleSubmit,
    control,
    themeProv,
    apps,
    handleCheckboxChange,
    selectedApps,
  } = useAddPlan();

  const theme = useTheme();
  const history = useHistory();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";

  const { fields, append, remove } = useFieldArray({
    control,
    name: "features",
  });
  return (
    <>
      <ThemeProvider theme={themeProv}>
        <Box
          marginY="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="90vw">
            <Grid
              container
              display="flex"
              justifyContent="center"
              spacing={2}
              mb={4}
            >
              <Grid item xs={12} md={8}>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      fontSize={mediumHeaderFontSize}
                      fontWeight={600}
                    >
                      Add Plan
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        height: "60px",
                        width: "180px",
                        fontSize: "20px",
                        borderRadius: "100px",
                        color:
                          theme.palette.getContrastText(lockBackgroundColor),
                        backgroundColor: lockBackgroundColor,
                        textTransform: "capitalize",
                        fontWeight: 600,
                        "&:hover": {
                          color: lockBackgroundColor,
                          backgroundColor:
                            theme.palette.getContrastText(lockBackgroundColor),
                          boxShadow: "none",
                        },
                        border: "1px solid",
                        borderColor: lockBackgroundColor,
                        boxShadow: "none",
                      }}
                      type="button"
                      onClick={() => history.push("/admin-list-plans")}
                      color="primary"
                      variant="contained"
                    >
                      Plans
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={8}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Plan Name is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Plan Name"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Plan Description is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        multiline
                        rows={3}
                        label="Plan Description"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ required: "Plan amount is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="number"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Plan amount"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="taxPercentile"
                    control={control}
                    rules={{
                      required: "Plan amount tax percentile is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="number"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Plan amount tax percentile"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="taxAmount"
                    control={control}
                    rules={{ required: "Plan tax amount is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="number"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Plan tax amount"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="subscriptionDuration"
                    control={control}
                    rules={{
                      required: "Plan subscription duration is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="number"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Plan subscription duration"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                {fields.map((item, idx) => (
                  <Grid item xs={12} md={8} key={item.id}>
                    <Grid container spacing={2}>
                      <Grid item flex={1}>
                        <Controller
                          name={`features[${idx}].description`}
                          control={control}
                          rules={{
                            required: "Feature is required",
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              type="text"
                              helperText={error ? error.message : null}
                              size="medium"
                              error={Boolean(error)}
                              onChange={onChange}
                              value={value}
                              fullWidth
                              label="Feature"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          sx={{
                            height: "60px",
                            width: "180px",
                            fontSize: "20px",
                            borderRadius: "100px",
                            color:
                              theme.palette.getContrastText(
                                lockBackgroundColor,
                              ),
                            backgroundColor: lockBackgroundColor,
                            textTransform: "capitalize",
                            fontWeight: 600,
                            "&:hover": {
                              color: lockBackgroundColor,
                              backgroundColor:
                                theme.palette.getContrastText(
                                  lockBackgroundColor,
                                ),
                              boxShadow: "none",
                            },
                            border: "1px solid",
                            borderColor: lockBackgroundColor,
                            boxShadow: "none",
                          }}
                          type="button"
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            remove(idx);
                          }}
                        >
                          Delete feature
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}

                <Grid
                  item
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  xs={12}
                  md={8}
                >
                  <Button
                    sx={{
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor: lockBackgroundColor,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color: lockBackgroundColor,
                        backgroundColor:
                          theme.palette.getContrastText(lockBackgroundColor),
                        boxShadow: "none",
                      },
                      border: "1px solid",
                      borderColor: lockBackgroundColor,
                      boxShadow: "none",
                    }}
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => append({ description: "" })}
                  >
                    Add Feature
                  </Button>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="featuresConclusion"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        multiline
                        rows={3}
                        label="Features Conclusion"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="darkHorseStocks"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value ?? false} {...field} />
                        )}
                      />
                    }
                    label="Enable darkHorseStocks"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="fundalysis"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value ?? false} {...field} />
                        )}
                      />
                    }
                    label="Enable fundalysis"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="darkHorseStocksUSA"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value ?? false} {...field} />
                        )}
                      />
                    }
                    label="Enable darkHorseStocksUSA"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="PMS"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value ?? false} {...field} />
                        )}
                      />
                    }
                    label="Enable PMS"
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="isActive"
                        control={control}
                        render={({ field }) => (
                          <Checkbox checked={field.value ?? false} {...field} />
                        )}
                      />
                    }
                    label="Enable Plan"
                  />
                </Grid>
                <Grid item mt={4} xs={12} md={8}>
                  Active apps:
                </Grid>

                {apps &&
                  apps.length &&
                  apps.map((app, idx) => (
                    <Grid key={app.value} item xs={12} md={8}>
                      <Controller
                        name={`apps`}
                        control={control}
                        render={() => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedApps.includes(app.value)}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    app.value,
                                    e.target.checked,
                                  )
                                }
                              />
                            }
                            label={app.label}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                <Grid
                  item
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  xs={12}
                  md={8}
                >
                  <Button
                    sx={{
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor: lockBackgroundColor,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color: lockBackgroundColor,
                        backgroundColor:
                          theme.palette.getContrastText(lockBackgroundColor),
                        boxShadow: "none",
                      },
                      border: "1px solid",
                      borderColor: lockBackgroundColor,
                      boxShadow: "none",
                    }}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Add Plan
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AddPlan;
