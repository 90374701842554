import React from "react";
import Footer from "./Footer";
import { setThemeDarkMode } from "../../redux/Theme";
import { Grid } from "@mui/material";

const FooterGroup = ({ darkMode, setDarkMode, dispatch }) => {
  return (
    <Grid container marginY={4} alignItems="center" display="flex">
      <Grid item xs={10} md={10} lg={10}>
        <Footer />
      </Grid>
      <Grid zIndex={1} item xs={2} md={2} lg={2}>
        <div
          className="toggle-container mt-section align-items-center"
          style={{ zIndex: 1 }}
        >
          <span>Night Mode</span>
          <span className="toggle">
            <input
              checked={darkMode}
              onChange={() => {
                setDarkMode(!darkMode);
                dispatch(setThemeDarkMode(!darkMode));
                window.localStorage.setItem("dark", !darkMode);
                window.dispatchEvent(new Event("storage"));
              }}
              id="checkbox"
              className="checkbox"
              type="checkbox"
            />
            <label htmlFor="checkbox" />
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

export default FooterGroup;
