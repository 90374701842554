import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useAppSelector } from "../../../../store/hooks";
import { createTheme } from "@mui/material";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useManualSubscriptionModal = (onClose, fetchUsers, userId) => {
  const token = localStorage.getItem("code");
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}plans`)
      .then((res) => {
        res.data.success &&
          res.data.payload !== null &&
          setPlans(
            res.data.payload.map((plan) => ({
              value: plan.id,
              label: `name: ${plan.name}/ amount: ${plan.amount}`,
            })),
          );
      })
      .catch((e) => console.error("Error fetching plans:", e));
  }, []);
  const activateSubscription = useCallback(
    (data) => {
      axios
        .post(`${REACT_APP_BASE_URL}user/activate-subscription`, data, {
          headers: {
            Authorization: token,
          },
        })
        .then(async () => await fetchUsers())
        .catch((e) => alert(e.message));
    },
    [fetchUsers, token],
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: dayjs(),
      planId: "",
    },
  });
  useEffect(
    () =>
      reset({
        date: dayjs(),
        planId: "",
      }),
    [reset],
  );
  const onSubmit = async (data) => {
    await activateSubscription({
      planId: Number(data.planId),
      date: new Date(data.date),
      userId,
    });
    handleClose();
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const handleClose = useCallback(() => {
    reset({
      date: dayjs(),
      planId: "",
    });
    onClose();
  }, [onClose, reset]);
  return {
    themeProv,
    handleSubmit,
    onSubmit,
    errors,
    control,
    plans,
    handleClose,
  };
};

export default useManualSubscriptionModal;
