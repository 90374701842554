import { Checkbox } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { React, useContext, useState, useEffect } from "react";
import { registerSchema } from "../../validation";
import Button from "../../Components/Button/Button";
import "./style.scss";
import CountryCode from "../../Components/Country code select/CountryCode";
import axios from "axios";
import AuthContext from "./AuthContext";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const BASE_URL = process.env.REACT_APP_WS_URL;
export default function TwitterSignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassward, setConfirmPassward] = useState("");
  const [mobile, setMobile] = useState("");
  const history = useHistory();

  const darkMode = localStorage.getItem("dark");
  const [color, setColor] = useState(darkMode === "true" ? "white" : "black");
  const { login } = useContext(AuthContext);
  useEffect(() => {
    if (darkMode === "true") {
      setColor("white");
    } else {
      setColor("black");
    }
  }, [darkMode]);
  const { id: providerId, name } = useParams();
  const handleSignUp = async () => {
    const res = await axios.post(`${BASE_URL}auth/twitter-complete-data`, {
      email,
      password,
      providerId,
      name,
      phone: mobile,
    });
    login(res.data.tokens.token, history);
  };

  return (
    <div className="container auth-container">
      <div className="d-flex fs-95 auth-wrapper">
        <div style={{ color: color }} className="border-bottom">
          Complete Your Sign-up Please
        </div>
      </div>
      <Formik
        initialValues={{
          email: email,
          password: password,
          confirmPassward: confirmPassward,
          mobile: mobile,
        }}
        validationSchema={registerSchema}
      >
        {({ handleSubmit, setFieldValue, errors, touched, values }) => (
          <Form className="form-wrapper " onSubmit={handleSubmit}>
            <div className="d-flex input-wrapper">
              <div className="input-line-animation__wrapper">
                <Field
                  name="email"
                  type="text"
                  className="input-form input-line-animation__ip"
                  placeholder="Your email"
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    setEmail(e.target.value);
                  }}
                  value={values.email}
                />
                <div className="input-line-animation__line"></div>
              </div>
              {errors.email && touched.email ? (
                <span className="error">{errors.email}</span>
              ) : null}
            </div>
            <div className="d-flex input-wrapper">
              <div className="input-line-animation__wrapper">
                <Field
                  name="password"
                  type="password"
                  className="input-form input-line-animation__ip"
                  placeholder="Password"
                  onChange={(e) => {
                    setFieldValue("password", e.target.value);
                    setPassword(e.target.value);
                  }}
                  value={values.password}
                />
                <div className="input-line-animation__line"></div>
              </div>
              {errors.password && touched.password ? (
                <span className="error">{errors.password}</span>
              ) : null}
            </div>
            <div className="d-flex input-wrapper">
              <div className="input-line-animation__wrapper">
                <Field
                  name="confirmPassward"
                  type="password"
                  className="input-form input-line-animation__ip"
                  placeholder="Confirm Passward"
                  onChange={(e) => {
                    setFieldValue("confirmPassward", e.target.value);
                    setConfirmPassward(e.target.value);
                  }}
                  value={values.confirmPassward}
                />
                <div className="input-line-animation__line"></div>
              </div>
              {errors.confirmPassward && touched.confirmPassward ? (
                <span className="error">{errors.confirmPassward}</span>
              ) : null}
            </div>
            <div className="d-flex input-wrapper">
              <div className="d-flex input-line-animation__wrapper">
                <CountryCode />
                <div className="input-line-animation__wrapper">
                  <Field
                    name="mobile"
                    type="text"
                    className="input-form input-form-mobile input-line-animation__ip"
                    placeholder="Mobile"
                    onChange={(e) => {
                      setFieldValue("mobile", e.target.value);
                      setMobile(e.target.value);
                    }}
                    value={values.mobile}
                  />
                  <div className="input-line-animation__line"></div>
                </div>
              </div>
              {errors.mobile && touched.mobile ? (
                <span className="error">{errors.mobile}</span>
              ) : null}
            </div>
            <div className="d-flex check-wrapper">
              <Checkbox color="success" />
              <p>Agree to terms & conditions.</p>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-column-mobile">
              <Button onClick={handleSignUp} text="Sign Up" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
