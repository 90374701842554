import React from "react";

const PaytmUPISVG = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7917 35.5056H29.9304V30.4886H35.786C36.5013 30.4886 37.1136 30.2332 37.6227 29.7225C38.1318 29.2134 38.3863 28.6012 38.3863 27.8858V25.097C38.3863 24.3817 38.1318 23.7686 37.6227 23.2579C37.1136 22.7488 36.5013 22.4943 35.786 22.4943H27.7917V35.5056ZM41.7358 35.5056H43.8722V22.4943H41.7334L41.7358 35.5056ZM29.9304 28.3498V24.6306H35.5057C35.6926 24.6306 35.8633 24.7087 36.018 24.865C36.1727 25.0197 36.25 25.1896 36.25 25.3749V27.6055C36.25 27.7924 36.1727 27.9632 36.018 28.1178C35.8633 28.2725 35.6926 28.3498 35.5057 28.3498H29.9304ZM16.4527 35.5056H21.8418C22.5588 35.5056 23.1718 35.251 23.6809 34.7419C24.1916 34.2328 24.447 33.6206 24.447 32.9053V22.4943H22.3058V32.6249C22.3058 32.8118 22.2285 32.9826 22.0738 33.1373C21.9192 33.2919 21.7484 33.3692 21.5615 33.3692H16.7282C16.5429 33.3692 16.3729 33.2919 16.2183 33.1373C16.0636 32.9826 15.9862 32.8118 15.9862 32.6249V22.4943H13.8475V32.9053C13.8475 33.6206 14.1021 34.2328 14.6112 34.7419C15.1219 35.251 15.7341 35.5056 16.4478 35.5056M11.1553 45.9166C10.0437 45.9166 9.11486 45.5444 8.36892 44.8001C7.62297 44.0557 7.25 43.1261 7.25 42.0113V15.9886C7.25 14.8753 7.62297 13.9465 8.36892 13.2022C9.11486 12.4578 10.0429 12.0849 11.1529 12.0833H46.8471C47.9588 12.0833 48.8868 12.4562 49.6311 13.2022C50.3754 13.9481 50.7484 14.8769 50.75 15.9886V42.0137C50.75 43.1253 50.377 44.0541 49.6311 44.8001C48.8851 45.546 47.9571 45.9182 46.8471 45.9166H11.1553ZM11.1553 43.4999H46.8495C47.2201 43.4999 47.5608 43.3453 47.8717 43.0359C48.1827 42.7266 48.3374 42.385 48.3358 42.0113V15.9886C48.3358 15.6164 48.1811 15.2749 47.8717 14.9639C47.5624 14.653 47.2209 14.4983 46.8471 14.4999H11.1529C10.7824 14.4999 10.4416 14.6546 10.1307 14.9639C9.81972 15.2733 9.66506 15.6148 9.66667 15.9886V42.0137C9.66667 42.3842 9.82133 42.725 10.1307 43.0359C10.44 43.3469 10.7807 43.5015 11.1529 43.4999"
        fill="currentColor"
      />
    </svg>
  );
};

export default PaytmUPISVG;
