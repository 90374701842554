import { Box, Grid, MenuItem, Select } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import Button from "../../../../Components/Button/Button";
import countryCodes from "../../../Auth/countryCodes.json";
import states from "./states.json";
import useGstForm from "./useGstForm";

const GstForm = ({ fetchGstCredentials, gstCredentials, handleClose }) => {
  const { control, handleSubmit, errors, selectFontSize, onSubmit, isMdDown } =
    useGstForm({
      defaultValues: gstCredentials,
      fetchGstCredentials,
      handleClose,
    });
  return (
    <div>
      <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: "Firm name is required",
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="text"
                    placeholder="Your firm name"
                    {...field}
                  />
                  <div className="input-line-animation__line"></div>
                </>
              )}
            />
          </Box>
          {errors.firstName && <span>{errors.firstName.message}</span>}
        </Box>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="gstNumber"
              control={control}
              rules={{
                required: "GST number is required",
                pattern: {
                  value:
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i,
                  message: "Invalid GST number",
                },
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    placeholder="Your GST number"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value.toUpperCase());
                    }}
                  />
                  <Box className="input-line-animation__line"></Box>
                </>
              )}
            />
          </Box>
          {errors.gstNumber && <span>{errors.gstNumber.message}</span>}
        </Box>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="gstEmail"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="text"
                    placeholder="Your email"
                    {...field}
                  />
                  <div className="input-line-animation__line"></div>
                </>
              )}
            />
          </Box>
          {errors.gstEmail && <span>{errors.gstEmail.message}</span>}
        </Box>
        <Grid
          container
          display="flex"
          width="100%"
          columnSpacing={isMdDown ? undefined : 4}
          rowSpacing={isMdDown ? 6 : undefined}
        >
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Controller
              name="countryCode"
              control={control}
              defaultValue="91"
              rules={{
                required: "Country code is required",
              }}
              render={({ field }) => (
                <>
                  <Select
                    sx={{ fontSize: selectFontSize }}
                    variant="standard"
                    className="input-form input-line-animation__ip"
                    placeholder="Select country code"
                    {...field}
                  >
                    {countryCodes.map((code) => (
                      <MenuItem key={code.value + code.name} value={code.value}>
                        {code.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </Grid>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="d-flex input-wrapper">
              <Box className="input-line-animation__wrapper">
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message:
                        "Invalid phone number (only numbers are allowed)",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        placeholder="Phone number"
                        {...field}
                      />
                      <Box className="input-line-animation__line"></Box>
                    </>
                  )}
                />
              </Box>
              {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
            </Box>
          </Grid>
        </Grid>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="registeredAddress"
              control={control}
              rules={{
                required: "Registered address is required",
              }}
              render={({ field }) => (
                <>
                  <input
                    className="input-form input-line-animation__ip"
                    type="text"
                    placeholder="Your registered address"
                    {...field}
                  />
                  <div className="input-line-animation__line"></div>
                </>
              )}
            />
          </Box>
          {errors.registeredAddress && (
            <span>{errors.registeredAddress.message}</span>
          )}
        </Box>
        <Box className="d-flex input-wrapper">
          <Box className="input-line-animation__wrapper">
            <Controller
              name="state"
              control={control}
              rules={{
                required: "State is required",
              }}
              render={({ field }) => (
                <>
                  <Select
                    sx={{ fontSize: selectFontSize }}
                    variant="standard"
                    className="input-form input-line-animation__ip"
                    placeholder="Select state"
                    {...field}
                  >
                    {states.map((code) => (
                      <MenuItem
                        key={code.value + code.label}
                        value={code.value}
                      >
                        {code.label}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </Box>
          {errors.state && <span>{errors.state.message}</span>}
        </Box>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="end"
          spacing={2}
        >
          <Grid item>
            <Button onClick={handleSubmit(onSubmit)} text="Save" />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default GstForm;
