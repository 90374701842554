export const defaultRoute = "/";
export const team = "/team";
export const featureUnavailable = "/feature-unavailable";
export const login = "/login";
export const register = "/register";
export const registerTwitter = "/register/twitter/:id/:name";
export const postSignupTwitter = "/post-signup/twitter/";
export const loginTwitter = "/login/twitter/:code";
export const requestForgotPassword = "/forgot-password";
export const resetPassword = "/reset-password/:token";
export const postSignup = "/postSignup/:userId";
export const subscriptionOpen = "/subscription-open";
export const planSubscription = "/plan-subscription/:planId";
export const sectors = "/sectors";
export const specialList = "/special-list/:listType";
export const companyByType = "/companies/:type/:typeId";
export const tags = "/tags";
export const tag = "/tag/:tagId";
export const browseFreeCompanies = "/browse-free-companies";
export const sectorPage = "/sector-page/:sectorId";
export const subscriptions = "/subscriptions";
export const listPage = "/list-page";
export const dashboard = "/dashboard";
export const faq = "/faq";
export const categories = "/categories";
export const categoriesIndividual = "/categories-individual/:categoryId";
export const philosophyPage = "/philosophy-Page";
export const companiesSuggested = "/companies-suggested";
export const newBlog = "/new-blog";
export const fundalysis = "/fundalysis";
export const agencyHome = "/agency-home";
export const search = "/search";
export const searchSomething = "/search-something";
export const blogFooter = "/blog-footer";
export const table = "/table";
export const videoYoutube = "/video-youtube";
export const video = "/video";
export const adminPanel = "/admin-panel";
export const adminPanel1 = "/admin-panel-1";
export const adminListSubscriptionSchedules = "/admin-subscription-schedules";
export const adminAddSubscriptionSchedule = "/admin-add-subscription-schedule";
export const adminEditSubscriptionSchedule =
  "/admin-edit-subscription-schedule/:id";
export const adminAddCompany = "/admin-add-company";
export const adminListCompanies = "/admin-list-companies";
export const adminEditCompany = "/admin-edit-company/:companyListId";
export const adminUsers = "/admin-users";
export const editUsers = "/admin-edit-user/:id";
export const adminHomepage = "/admin-home-page";
export const adminAddHomepage = "/admin-add-home-page/";
export const adminEditHomepage = "/admin-edit-home-page/:id";
export const adminListTags = "/admin-list-tags";
export const adminAddTag = "/admin-add-tag";
export const adminEditTag = "/admin-edit-tag/:id";
export const adminListSectors = "/admin-list-sectors";
export const adminAddSector = "/admin-add-sector";
export const adminEditSector = "/admin-edit-sector/:id";
export const adminListCategories = "/admin-list-categories";
export const adminAddCategory = "/admin-add-category";
export const adminEditCategory = "/admin-edit-category/:id";
export const adminInsertedUsers = "/admin-inserted-users";
export const adminAddInsertedUsers = "/admin-add-inserted-user";
export const adminEditInsertedUsers = "/admin-edit-inserted-user/:id";
export const adminListPlans = "/admin-list-plans";
export const adminAddPlan = "/admin-add-plan";
export const adminEditPlan = "/admin-edit-plan/:id";
export const adminListApps = "/admin-list-apps";
export const adminAddApp = "/admin-add-app";
export const adminEditApp = "/admin-edit-app/:id";
export const adminPanel4 = "/admin-panel-4";
export const adminPanel5 = "/admin-panel-5";
export const thankYou = "/thank-you";
export const sorry = "/sorry";
export const profile = "/profile";
export const pricing = "/pricing";
export const alert = "/alert";

export const viewMore = "/view-more";
export const viewMoreId = "/view-more/:viewMoreId";

export const blog = "/blog";
export const blogWithId = "/blog/:blogId";

export const companyWithId = "/company/:companyId";

export const pageURLs = {
  "Top Gainers": "top-gainers",
  "Top Losers": "top-losers",
  "Debt Free Companies": "debt-free-companies",
  "Latest Ideas": "latest-ideas",
  "Trending Ideas": "trending-ideas",
  "Holding Companies": "holding-companies",
  "Information Technology": "information-technology",
  Pharmaceuticals: "pharmaceuticals",
  "Penny Company": "penny-company",
  Chemicals: "chemicals",
  Sectors: "sectors",
};
