import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  Button,
  ThemeProvider,
  Grid,
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import useAddInsertedUser from "./useAddInsertedUser";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DatePicker } from "@mui/x-date-pickers";

const AddInsertedUser = () => {
  const { onSubmit, handleSubmit, control, themeProv, errors, plans } =
    useAddInsertedUser();

  const theme = useTheme();
  const history = useHistory();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  return (
    <>
      <ThemeProvider theme={themeProv}>
        <Box
          marginY="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="90vw">
            <Grid
              container
              display="flex"
              justifyContent="center"
              spacing={2}
              mb={4}
            >
              <Grid item xs={12} md={8}>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      fontSize={mediumHeaderFontSize}
                      fontWeight={600}
                    >
                      Add Inserted User
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{
                        height: "60px",
                        width: "180px",
                        fontSize: "20px",
                        borderRadius: "100px",
                        color:
                          theme.palette.getContrastText(lockBackgroundColor),
                        backgroundColor: lockBackgroundColor,
                        textTransform: "capitalize",
                        fontWeight: 600,
                        "&:hover": {
                          color: lockBackgroundColor,
                          backgroundColor:
                            theme.palette.getContrastText(lockBackgroundColor),
                          boxShadow: "none",
                        },
                        border: "1px solid",
                        borderColor: lockBackgroundColor,
                        boxShadow: "none",
                      }}
                      type="button"
                      onClick={() => history.push("/admin-inserted-users")}
                      color="primary"
                      variant="contained"
                    >
                      Inserted Users
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={8}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        helperText={error ? error.message : null}
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Inserted User email"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        size="medium"
                        error={Boolean(error)}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        label="Inserted User phone"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={Boolean(errors.date)}
                  >
                    <Controller
                      name="date"
                      control={control}
                      rules={{ required: "Date is required" }}
                      render={({ field }) => (
                        <DatePicker {...field} fullWidth label="Date" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="planId"
                    control={control}
                    rules={{ required: "Plan is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <FormControl
                          fullWidth
                          variant="filled"
                          error={Boolean(error)}
                        >
                          <InputLabel id="planId-label">Plan</InputLabel>
                          <Select
                            labelId="planId-label"
                            value={value}
                            label="Plan"
                            onChange={onChange}
                          >
                            {plans?.length !== 0 &&
                              plans.map((plan) => (
                                <MenuItem key={plan.value} value={plan.value}>
                                  {plan.label}
                                </MenuItem>
                              ))}
                          </Select>
                          {error && (
                            <FormHelperText error>
                              {error ? error.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  xs={12}
                  md={8}
                >
                  <Button
                    sx={{
                      height: "60px",
                      width: "180px",
                      fontSize: "20px",
                      borderRadius: "100px",
                      color: theme.palette.getContrastText(lockBackgroundColor),
                      backgroundColor: lockBackgroundColor,
                      textTransform: "capitalize",
                      fontWeight: 600,
                      "&:hover": {
                        color: lockBackgroundColor,
                        backgroundColor:
                          theme.palette.getContrastText(lockBackgroundColor),
                        boxShadow: "none",
                      },
                      border: "1px solid",
                      borderColor: lockBackgroundColor,
                      boxShadow: "none",
                    }}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AddInsertedUser;
