import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../Auth/AuthContext";
import axios from "axios";

const useProfile = () => {
  const { logout, isSubscribed, user, isAdmin, activeSubscription } =
    useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleModalOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleModalClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const handleLogout = () => {
    logout();
    history.push("/");
  };

  useEffect(() => {
    user && user.error && history.push("/login");
  }, [history, user]);

  const [gstCredentials, setGstCredentials] = useState(null);
  const fetchGstCredentials = useCallback(() => {
    const token = localStorage.getItem("code");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user/gst-credentials`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.data) setGstCredentials(res.data);
      })
      .catch((e) => console.error("Error fetching gst credentials:", e));
  }, []);
  useEffect(() => {
    fetchGstCredentials();
  }, [fetchGstCredentials]);

  const [openGstForm, setOpenGstForm] = useState(false);
  const handleModalOpenGstForm = useCallback(() => {
    setOpenGstForm(true);
  }, []);
  const handleModalCloseGstForm = () => {
    setOpenGstForm(false);
  };
  return {
    open,
    handleModalOpen,
    handleModalClose,
    isSubscribed,
    user,
    isAdmin,
    handleLogout,
    gstCredentials,
    fetchGstCredentials,
    openGstForm,
    handleModalOpenGstForm,
    handleModalCloseGstForm,
    activeSubscription,
  };
};

export default useProfile;
