import axios from "axios";
import { useCallback } from "react";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
const useSubscriptionForm = (subscription, handleClose, userId, fetchUser) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      planId: subscription?.planId ?? "",
      startedAt: subscription?.startedAt
        ? dayjs(subscription.startedAt)
        : dayjs(),
    },
  });
  const token = localStorage.getItem("code");
  const manageSubscription = useCallback(
    (method, data) => {
      axios[method](
        `${process.env.REACT_APP_BASE_URL}subscription${
          method === "put" ? `/${subscription.id}` : ""
        }`,
        { ...data, startedAt: new Date(data.startedAt) },
        {
          headers: {
            Authorization: token,
          },
        },
      )
        .then(() => {
          handleClose();
          fetchUser();
        })
        .catch((e) => {
          handleClose();
          alert(e.message);
        });
    },
    [handleClose, subscription, token, fetchUser],
  );
  const onSubmit = useCallback(
    (data) => {
      const dataToSubmit = { ...data, startedAt: new Date(data.startedAt) };
      if (!subscription) dataToSubmit.userId = userId;
      if (subscription) {
        manageSubscription("put", dataToSubmit);
      } else manageSubscription("post", dataToSubmit);
    },
    [manageSubscription, subscription, userId],
  );

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    watch,
    errors,
  };
};
export default useSubscriptionForm;
