import React from "react";
import Button from "../../../Components/Button/Button";
import useSubscriptionCard from "./useSubscriptionCard";
import { Grid, Typography } from "@mui/material";

const SubscriptionCard = ({ id, description, price, duration }) => {
  const { parsedPrice, parsedDuration, handlePayment, fontSize } =
    useSubscriptionCard({
      id,
      description,
      price,
      duration,
    });

  return (
    <Grid container display="flex" justifyContent="start" spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          display="flex"
          justifyContent="start"
          alignItems="start"
          spacing={1}
        >
          <Grid item md={4} xs={12}>
            <Typography fontSize={fontSize} fontWeight={600}>
              Description:
            </Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography fontSize={fontSize} fontWeight={400}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          display="flex"
          justifyContent="start"
          alignItems="start"
          spacing={1}
        >
          <Grid item md={4} xs={12}>
            <Typography fontSize={fontSize} fontWeight={600}>
              Price:
            </Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography fontSize={fontSize} fontWeight={400}>
              {parsedPrice}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          display="flex"
          justifyContent="start"
          alignItems="start"
          spacing={1}
        >
          <Grid item md={4} xs={12}>
            <Typography fontSize={fontSize} fontWeight={600}>
              Duration:
            </Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography fontSize={fontSize} fontWeight={400}>
              {parsedDuration}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button onClick={handlePayment} text="Purchase" />
      </Grid>
    </Grid>
  );
};

export default SubscriptionCard;
