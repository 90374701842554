import React, { useMemo } from "react";
import "./style.scss";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

export default function FaqCard({ content }) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <Typography fontSize={paragraphFontSize} fontWeight={300} lineHeight={1}>
      {content}
    </Typography>
  );
}
