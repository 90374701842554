import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { SubscriptionScheduleContext } from "../../../Contexts/SubscriptionSchedule";

const useEditSubscriptionSchedule = () => {
  const { id } = useParams();
  const { fetchScheduleById, updateSchedule } = useContext(
    SubscriptionScheduleContext,
  );
  const [scheduleData, setScheduleData] = useState(null);
  useEffect(() => {
    console.log(id);
    fetchScheduleById(id).then((data) => {
      setScheduleData(data);
    });
  }, [id, fetchScheduleById]);

  const submitData = (data) => {
    updateSchedule(id, data);
  };

  return {
    scheduleData,
    submitData,
  };
};

export default useEditSubscriptionSchedule;
