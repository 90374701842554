import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme, useMediaQuery, createTheme } from "@mui/material";
import { useGetTagsQuery } from "../../../redux/TagsAPI";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppSelector } from "../../../store/hooks";
import { useGetSectorsQuery } from "../../../redux/SectorsAPI";
import { useGetCategoriesQuery } from "../../../redux/HomeAPI";
import axios from "axios";
import useHomepageParser from "../../../utils/useHomepageParser";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useEditHomepage = () => {
  const { id } = useParams();
  const { data: fetchedTags } = useGetTagsQuery();
  const { data: fetchedSectors } = useGetSectorsQuery();
  const { data: fetchedCategories } = useGetCategoriesQuery();
  const [availableTags, setAvailableTags] = useState([]);
  const [availableSectors, setAvailableSectors] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [homepage, setHomepage] = useState();
  const { parseHomepageToForm } = useHomepageParser();

  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}home-page-sections/${id}`)
      .then((res) => {
        setHomepage(parseHomepageToForm(res.data));
      })
      .catch((e) => console.error("Error fetching companies:", e));
  }, [id, parseHomepageToForm]);
  useEffect(() => {
    if (fetchedTags)
      setAvailableTags(
        fetchedTags.map((tag) => ({ id: tag.Id, name: tag.name })),
      );
  }, [fetchedTags]);
  useEffect(() => {
    if (fetchedSectors?.success)
      setAvailableSectors(
        fetchedSectors.payload.map((sector) => ({
          id: sector.Id,
          name: sector.name,
        })),
      );
  }, [fetchedSectors]);
  useEffect(() => {
    if (fetchedCategories?.success)
      setAvailableCategories(
        fetchedCategories.payload.map((category) => ({
          id: category.Id,
          name: category.name,
        })),
      );
  }, [fetchedCategories]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      ...homepage,
    },
  });

  useEffect(
    () =>
      reset({
        ...homepage,
      }),
    [homepage, reset],
  );
  const token = localStorage.getItem("code");

  const editHomepage = (data) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}home-page-sections/${id}`,
        { homepageSections: data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-home-page");
      })
      .catch((e) => console.error("Error fetching companies:", e));
  };

  const onSubmit = (data) => {
    editHomepage(data);
    reset(data);
  };

  const theme = useTheme();
  const history = useHistory();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "55px"),
    [isMdDown, isSmDown],
  );
  const darkMode = useAppSelector((store) => store.theme.dark);

  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return {
    mediumHeaderFontSize,
    history,
    themeProv,
    handleSubmit,
    onSubmit,
    control,
    availableTags,
    availableSectors,
    availableCategories,
  };
};
export default useEditHomepage;
