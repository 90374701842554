import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { useMemo } from "react";

const useGstModal = ({ gstCredentials }) => {
  const darkMode = useAppSelector((store) => store.theme.dark);

  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const defaultValues = formatDefaultValues(gstCredentials);
  return {
    themeProv,
    defaultValues,
  };
};
export default useGstModal;

const formatDefaultValues = (gstCredentials) => {
  let defaultCountryCode;
  let defaultPhoneNumber;
  if (gstCredentials && gstCredentials.gstPhoneNumber) {
    defaultCountryCode = gstCredentials.gstPhoneNumber.split("-")[0].slice(1);
    defaultPhoneNumber = gstCredentials.gstPhoneNumber.split("-")[1];
  }
  const defaultValues = {
    ...gstCredentials,
    countryCode: defaultCountryCode,
    phoneNumber: defaultPhoneNumber,
  };
  delete defaultValues.gstPhoneNumber;
  return defaultValues;
};
