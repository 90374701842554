import React from "react";
import "./style.scss";
import { Controller } from "react-hook-form";

const DHCheckbox = ({
  isForm = true,
  control,
  errors,
  name,
  required,
  text,
  checkboxBorderColor,
  checked,
  onCheck,
}) => {
  return (
    <>
      {isForm ? (
        <Controller
          name={name}
          control={control}
          defaultValue={false}
          rules={{
            required,
          }}
          render={({ field }) => (
            <>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  className={`terms-checkbox ${
                    errors ? "terms-checkbox-error" : "terms-checkbox-grey"
                  }`}
                  {...field}
                />
                {text}
              </label>
            </>
          )}
        />
      ) : (
        <input
          type="checkbox"
          className={`terms-checkbox ${checkboxBorderColor}`}
          checked={checked}
          onChange={onCheck}
        />
      )}
    </>
  );
};

export default DHCheckbox;
