import { React, useContext, useState, useEffect, useMemo } from "react";
import AuthContext from "./AuthContext";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "../../Components/Button/Button";
const BASE_URL = process.env.REACT_APP_WS_URL;

export default function TwitterPostSignup() {
  const history = useHistory();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const [code, setCode] = useState(undefined);
  const { login } = useContext(AuthContext);
  const twitterUserId = localStorage.getItem("twitterUserId");

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("code") && url.split("code=")[1]) {
      setCode(url.split("code=")[1]);
    }
  }, []);
  const linkTwitterAccount = () => {
    if (code && twitterUserId)
      axios
        .post(`${BASE_URL}auth/link-account/twitter`, {
          code,
          userId: twitterUserId,
        })
        .then((res) => {
          if (res.data.error) alert(res.data.error);
          else login(res.data.tokens.token, history);
          localStorage.removeItem("twitterUserId");
        })
        .catch((e) => {
          localStorage.removeItem("twitterUserId");
          alert(e.message);
        });
    else alert("Something went wrong! please try again later.");
  };

  return (
    <Box
      marginY="20vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="90vw">
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Typography
              fontSize={headerFontSize}
              fontWeight={500}
              borderBottom="1px solid"
            >
              Linking Twitter Account
            </Typography>
          </Grid>

          <Grid
            item
            display="flex"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Button text="Link" onClick={() => linkTwitterAccount()} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
