import { useForm } from "react-hook-form";
import { useGetTagsQuery } from "../../../redux/TagsAPI";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetSectorsQuery } from "../../../redux/SectorsAPI";
import { useGetCategoriesQuery } from "../../../redux/HomeAPI";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import dayjs from "dayjs";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useGetCompanyQuery } from "../../../redux/CompanyAPI";
import useCompanyListParser from "../../../utils/useCompanyListParser";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const useEditCompany = () => {
  const { companyListId } = useParams();
  const { data: fetchedTags } = useGetTagsQuery();
  const { data: fetchedSectors } = useGetSectorsQuery();
  const { data: fetchedCategories } = useGetCategoriesQuery();
  const { data: fetchedCompany } = useGetCompanyQuery(Number(companyListId));
  const [availableTags, setAvailableTags] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companyList, setCompanyList] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (fetchedCompany && fetchedCompany.success) {
      setCompanyList(fetchedCompany.payload);
    }
  }, [fetchedCompany]);
  useEffect(() => {
    if (fetchedTags)
      setAvailableTags(
        fetchedTags.map((tag) => ({ id: tag.Id, name: tag.name })),
      );
  }, [fetchedTags]);
  useEffect(() => {
    if (fetchedSectors?.success)
      setSectors(
        fetchedSectors.payload.map((sector) => ({
          value: `${sector.Id}`,
          name: sector.name,
        })),
      );
  }, [fetchedSectors]);
  useEffect(() => {
    if (fetchedCategories?.success)
      setCategories(
        fetchedCategories.payload.map((category) => ({
          value: `${category.Id}`,
          name: category.name,
        })),
      );
  }, [fetchedCategories]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      code: companyList?.code ?? "",
      g_f_code: companyList?.g_f_code ?? "",
      price_suggested: companyList?.price_suggested ?? "",
      pe: companyList?.pe ?? "",
      heading: companyList?.heading ?? "",
      seo_descriptions: companyList?.seo_descriptions ?? "",
      relative_index: companyList?.relative_index ?? "",
      image: companyList?.image ?? "",
      paid: companyList?.paid ?? "",
      description: companyList?.description ?? "",
      blogId: companyList?.blogId ?? "",
      sectorId: companyList?.sectorId ? `${companyList?.sectorId}` : "",
      categoryId: companyList?.categoryId ? `${companyList?.categoryId}` : "",
      name: companyList?.name ?? "",
      bse: companyList?.bse ?? "",
      conclusion: companyList?.conclusion ?? "",
      reportLink: companyList?.reportLink ?? "",
      isPasswordProtected: companyList?.isPasswordProtected ?? false,
      date: dayjs(companyList?.date) ?? dayjs(),
      tags: companyList?.tags?.map((tag) => tag.Id) ?? [],
    },
  });
  const handleReset = useCallback(() => {
    reset({
      code: companyList?.code ?? "",
      g_f_code: companyList?.g_f_code ?? "",
      price_suggested: companyList?.price_suggested ?? "",
      pe: companyList?.pe ?? "",
      heading: companyList?.heading ?? "",
      seo_descriptions: companyList?.seo_descriptions ?? "",
      relative_index: companyList?.relative_index ?? "",
      image: companyList?.image ?? "",
      paid: companyList?.paid ?? "",
      description: companyList?.description ?? "",
      blogId: companyList?.blogId ?? "",
      sectorId: companyList?.sectorId ? `${companyList?.sectorId}` : "",
      categoryId: companyList?.categoryId ? `${companyList?.categoryId}` : "",
      name: companyList?.name ?? "",
      bse: companyList?.bse ?? "",
      conclusion: companyList?.conclusion ?? "",
      reportLink: companyList?.reportLink ?? "",
      isPasswordProtected: companyList?.isPasswordProtected ?? false,
      date: dayjs(companyList?.date) ?? dayjs(),
      tags: companyList?.tags?.map((tag) => tag.Id) ?? [],
    });
  }, [companyList, reset]);
  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const { parseCompanyData } = useCompanyListParser();
  const token = localStorage.getItem("code");
  const editCompany = (data) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}company-list/${companyListId}`,
        { companyList: data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((res) => {
        history.push("/admin-list-companies");
      })
      .catch((e) => console.error("Error fetching companies:", e));
  };

  const onSubmit = (data) => {
    editCompany(parseCompanyData(data));
    reset(data);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return {
    onSubmit,
    handleSubmit,
    control,
    availableTags,
    sectors,
    categories,
    errors,
    themeProv,
    handleReset,
  };
};
export default useEditCompany;
