import { Grid, Typography } from "@mui/material";
import React from "react";
import SlideUp from "../../../../Components/SlideUp";

const HeaderText = ({ headerFontSize, textList }) => {
  return textList.map((text, idx) => (
    <Grid key={`${text}-${idx}`} item display="flex" alignItems="center">
      <Typography fontWeight={600} lineHeight={1} fontSize={headerFontSize}>
        <SlideUp>{text}</SlideUp>
      </Typography>
    </Grid>
  ));
};

export default HeaderText;
