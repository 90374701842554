import React from "react";
import { Tabs } from "antd";
import "./Timeline.scss";

const Timeline = (props) => {
  const { items, onChange } = props;

  return (
    <Tabs
      size="large"
      items={items}
      style={{ marginBottom: "2%" }}
      onChange={onChange}
    />
  );
};

export default Timeline;
