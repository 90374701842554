import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import useReferralForm from "./useReferralForm";
import Button from "../../../../../../Components/Button/Button";

const ReferralForm = ({ referral, handleClose, userId, fetchUser }) => {
  const { control, onSubmit, errors } = useReferralForm(
    referral,
    userId,
    handleClose,
    fetchUser,
  );
  return (
    <form onSubmit={onSubmit}>
      <Grid
        mt={2}
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <Controller
            name="code"
            control={control}
            rules={{ required: "Referral Code is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="text"
                helperText={error ? error.message : null}
                size="medium"
                error={Boolean(error)}
                onChange={onChange}
                value={value}
                fullWidth
                label="Referral Code"
                variant="filled"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            name="discount"
            control={control}
            rules={{ required: "Referral discount percentage is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="number"
                helperText={error ? error.message : null}
                size="medium"
                error={Boolean(error)}
                onChange={onChange}
                value={value}
                fullWidth
                label="Referral discount percentage"
                variant="filled"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl
            component="fieldset"
            error={Boolean(errors.referralType)}
          >
            <FormLabel component="legend">Referral Method</FormLabel>
            <Controller
              name="referralType"
              control={control}
              rules={{ required: "Referral method is required" }}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="referralByEmail"
                    control={<Radio />}
                    label="Referral by Email"
                  />
                  <FormControlLabel
                    value="referralByPhoneNumber"
                    control={<Radio />}
                    label="Referral by Phone Number"
                  />
                  <FormControlLabel
                    value="customReferral"
                    control={<Radio />}
                    label="Custom Referral"
                  />
                </RadioGroup>
              )}
            />
            {errors.referralType && (
              <FormHelperText>{errors.referralType.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8} display="flex" justifyContent="end">
          <Button text={referral ? "Edit" : "Create"} onClick={onSubmit} />
        </Grid>
      </Grid>
    </form>
  );
};

export default ReferralForm;
