import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAppSelector } from "../../../store/hooks";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useListPlans = () => {
  const [plans, setPlans] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const theme = useTheme();
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";

  const fetchPlans = useCallback(() => {
    try {
      axios
        .get(
          `${REACT_APP_BASE_URL}plans/all?page=${currentPage}&searchKeyword=${searchKeyword}`,
        )
        .then((res) => {
          setPlans(res.data.data);
          setTotalPages(res.data.totalPages);
        })
        .catch((e) => console.error("Error fetching plans:", e));
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  }, [currentPage, searchKeyword]);
  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);
  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const token = localStorage.getItem("code");
  const togglePlanStatus = useCallback(
    (id) => {
      axios
        .put(`${REACT_APP_BASE_URL}plans/active-status/${id}`, null, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          fetchPlans();
        })
        .catch((e) => console.error("Error fetching plans:", e));
    },
    [fetchPlans, token],
  );
  const handleDelete = useCallback(
    (id) => {
      axios
        .delete(`${REACT_APP_BASE_URL}plans/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          fetchPlans();
        })
        .catch((e) => console.error("Error fetching plans:", e));
    },
    [fetchPlans, token],
  );
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  return {
    handleSearchSubmit,
    searchKeyword,
    handleSearchChange,
    history,
    plans,
    handlePreviousPage,
    currentPage,
    lockBackgroundColor,
    theme,
    totalPages,
    handleNextPage,
    handleDelete,
    isMdDown,
    togglePlanStatus,
  };
};
export default useListPlans;
