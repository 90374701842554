const useCompanyListParser = () => {
  const parseCompanyData = (companyData) => ({
    code: companyData?.code ?? "",
    g_f_code: companyData?.g_f_code ?? "",
    price_suggested:
      companyData?.price_suggested?.length !== 0
        ? Number(companyData?.price_suggested)
        : undefined,
    pe: companyData?.pe?.length !== 0 ? Number(companyData?.pe) : undefined,
    heading: companyData?.heading ?? "",
    seo_descriptions: companyData?.seo_descriptions ?? "",
    relative_index: companyData?.relative_index ?? "",
    image: companyData?.image ?? "",
    paid: companyData?.paid ?? "",
    description: companyData?.description ?? "",
    blogId: companyData?.blogId ?? "",
    conclusion: companyData?.conclusion ?? "",
    reportLink: companyData?.reportLink ?? "",
    sectorId:
      companyData?.sectorId?.length !== 0
        ? Number(companyData?.sectorId)
        : undefined,
    categoryId:
      companyData?.categoryId?.length !== 0
        ? Number(companyData?.categoryId)
        : undefined,
    name: companyData?.name ?? "",
    bse: companyData?.bse ?? "",
    date: new Date(companyData?.date) ?? new Date(),
    isPasswordProtected: companyData?.isPasswordProtected ?? false,
    tags: companyData?.tags?.length ? companyData.tags : [],
  });
  return { parseCompanyData };
};
export default useCompanyListParser;
