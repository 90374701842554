import React, { useContext, useMemo, useRef } from "react";
import "./style.scss";
import { useCallback } from "react";
import { useGetBlogDetailsQuery } from "../../redux/BlogsAPI";
import { useParams } from "react-router-dom/";
import CustomAccordion from "../../Components/Accordion/CustomAccordion";
import { useGetCompanyByBlogIdQuery } from "../../redux/CompanyAPI";
import AuthContext from "../Auth/AuthContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAppSelector } from "../../store/hooks";
import SecImage from "../../Assets/image section.png";
import BlogContent from "./BlogContent";
import Button from "../../Components/Button/Button";
import BlogDummyContent from "./BlogContent/BlogDummyContent";
const TagsLinks = ({ tags, history }) => {
  return (
    <Grid container display="flex">
      {tags &&
        tags.length !== 0 &&
        tags.map((tag, index) => (
          <Grid item key={tag.Id}>
            <Typography
              className="line-animation-3"
              sx={{
                cursor: "pointer",
              }}
              fontWeight={300}
              onClick={() => history.push(`/tag/${tag.Id}`)}
            >
              {tag.name}
            </Typography>
            {index < tags.length - 1 && (
              <span style={{ margin: "0 5px" }}>|</span>
            )}
          </Grid>
        ))}
    </Grid>
  );
};

export default function BlogDetails() {
  const { blogId } = useParams();
  let conclusion = useRef("");
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const smallParagraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "18px" : "20px") : "25px"),
    [isMdDown, isSmDown],
  );

  const { data: blog } = useGetBlogDetailsQuery(blogId);
  const { data: companyData } = useGetCompanyByBlogIdQuery(blogId);
  const { isSubscribed } = useContext(AuthContext);
  const history = useHistory();
  const unauthorizedBlogAccess = useMemo(
    () => companyData && !isSubscribed && companyData.paid === "Paid",
    [companyData, isSubscribed],
  );
  function decodeHTMLEntities(htmlString) {
    const parser = new DOMParser();
    const decodedDocument = parser.parseFromString(htmlString, "text/html");
    const encodedElements = decodedDocument.querySelectorAll(":not(head) > *");

    for (const element of encodedElements) {
      for (const attribute of element.attributes) {
        if (attribute.value.includes("&")) {
          const decodedValue = document.createElement("textarea");
          decodedValue.innerHTML = attribute.value;
          attribute.value = decodedValue.value;
        }
      }
      element.innerHTML = element.innerHTML.replace(/&#[0-9]+;/g, (match) => {
        const decodedValue = document.createElement("textarea");
        decodedValue.innerHTML = match;
        return decodedValue.value;
      });
    }

    return decodedDocument.documentElement.innerHTML;
  }
  const removeClassAttribute = useCallback(() => {
    const classAttributeRegex = /class="[^"]*"/g;
    const styleAttributeRegex = /style="[^"]*"/g;

    // Replace the class attribute with an empty string
    const modifiedHtmlString =
      blog &&
      blog.data &&
      blog.data
        .replace(classAttributeRegex, "")
        .replace(styleAttributeRegex, "");

    return decodeHTMLEntities(modifiedHtmlString);
  }, [blog]);
  const blogPost = useMemo(
    () => blog && blog.data && removeClassAttribute(),
    [blog, removeClassAttribute],
  );

  const formattedTitle = useMemo(() => {
    if (blog && blog.title) {
      return blog.title.replace(/&#[0-9]+;/g, (match) => {
        const code = match.slice(2, -1);
        return String.fromCharCode(code);
      });
    }
    return "";
  }, [blog]);
  const replaceAccordionComponents = useCallback((htmlString) => {
    const parser = new DOMParser();
    const body = parser
      .parseFromString(htmlString, "text/html")
      .querySelector("body");
    const accordionComponents = [];
    let element = body.children[0];
    let i = 0;
    while (element) {
      i++;
      if (
        element.tagName === "H2" &&
        element.querySelector("strong") !== null
      ) {
        const title = element.textContent.trim();
        const contentElements = [];
        element = element.nextSibling;
        while (element) {
          i++;
          if (
            element === null ||
            (element.tagName &&
              (element.tagName === "H1" || element.tagName === "H2"))
          ) {
            if (
              element.tagName === "H1" &&
              element.querySelector("strong") !== null &&
              element
                .querySelector("strong")
                .textContent.trim()
                .toLowerCase() === "conclusion"
            ) {
              conclusion.current =
                element.nextElementSibling.textContent.trim();
              element = element.nextElementSibling?.nextElementSibling;
            } else {
              const htmlContents = contentElements
                .filter((el) => el)
                .map((el, idx) => (
                  <div
                    key={idx + title}
                    dangerouslySetInnerHTML={{ __html: el }}
                  />
                ));
              accordionComponents.push(
                <CustomAccordion
                  key={i}
                  right={title}
                  card={
                    <div style={{ paddingTop: "15px", width: "100%" }}>
                      {htmlContents}
                    </div>
                  }
                />,
              );
            }
            break;
          }
          contentElements.push(element.outerHTML);
          element = element.nextSibling;
        }
      } else {
        accordionComponents.push(
          <div
            key={i}
            dangerouslySetInnerHTML={{ __html: element.outerHTML }}
          />,
        );
        element = element.nextSibling;
      }
    }

    return accordionComponents;
  }, []);
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return (
    <Box>
      {companyData && (
        <>
          <Box display="flex" height="100vh" justifyContent="center">
            <Grid
              container
              flexDirection="column"
              display="flex"
              justifyContent="center"
              alignItems="start"
              width="80vw"
              spacing={4}
            >
              <Grid item display="flex" justifyContent="start">
                <Typography fontWeight={600} fontSize={smallParagraphFontSize}>
                  {companyData.date &&
                    `Published on ${new Date(
                      companyData?.date,
                    ).toLocaleDateString("en-US", dateOptions)}`}
                </Typography>
              </Grid>
              <Grid item display="flex" justifyContent="start">
                {formattedTitle && (
                  <Typography
                    fontWeight={600}
                    lineHeight={1}
                    fontSize={headerFontSize}
                  >
                    {formattedTitle}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="start"
                sx={{
                  textTransform: "capitalize",
                  fontSize: { smallParagraphFontSize },
                }}
              >
                <TagsLinks tags={companyData.tags} history={history} />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" marginY="20vh" justifyContent="center">
            <Box
              container
              flexDirection="column"
              display="flex"
              justifyContent="center"
              alignItems="start"
              width="80vw"
            >
              <Typography fontWeight={600} fontSize={paragraphFontSize}>
                {companyData.description}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      {blog && (
        <>
          {blog.featMedia && (
            <Box
              height="100vh"
              width="100vw"
              sx={{
                backgroundImage: `url(${blog.featMedia || SecImage})`,
                backgroundAttachment: "fixed",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></Box>
          )}
        </>
      )}
      <ThemeProvider theme={themeProv}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {unauthorizedBlogAccess ? (
            <>
              <Grid
                zIndex={10}
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Typography>Subscribe to view content</Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                >
                  <Button
                    text="Subscribe"
                    onClick={() => history.push("/subscriptions")}
                  />
                </Grid>
              </Grid>
              <BlogDummyContent
                isMdDown={isMdDown}
                isSmDown={isSmDown}
                headerFontSize={headerFontSize}
                paragraphFontSize={paragraphFontSize}
              />
            </>
          ) : (
            <BlogContent
              companyData={companyData}
              isMdDown={isMdDown}
              conclusion={conclusion}
              unauthorizedBlogAccess={unauthorizedBlogAccess}
              isSmDown={isSmDown}
              headerFontSize={headerFontSize}
              paragraphFontSize={paragraphFontSize}
              replaceAccordionComponents={replaceAccordionComponents}
              blogPost={blogPost}
            />
          )}
        </Box>
      </ThemeProvider>
    </Box>
  );
}
