import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useUserSubscriptionsList from "./useUserSubscriptionsList";
import SubscriptionModal from "../SubscriptionModal";
import Button from "../../../../Components/Button/Button";

const UserSubscriptionsList = ({ subscriptions, userId, fetchUser }) => {
  const {
    currentSubscriptions,
    sortedSubscriptions,
    itemsPerPage,
    page,
    handlePageChange,
    subscription: editableSubscription,
    open,
    handleOpen,
    handleClose,
    deleteSubscription,
  } = useUserSubscriptionsList(subscriptions, fetchUser);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Paper sx={{ padding: 2, width: "100%" }}>
        <Grid container display="flex" pb={2}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Button text="Add new +" onClick={() => handleOpen()} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {userId && subscriptions && subscriptions.length !== 0 ? (
            isMdDown ? (
              <Grid
                container
                display="flex"
                justifyContent="center"
                spacing={2}
                pt={2}
              >
                {currentSubscriptions
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((subscription) => (
                    <Grid
                      item
                      display="flex"
                      justifyContent="center"
                      xs={12}
                      key={subscription.id}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          border: (theme) =>
                            `1px solid ${alpha(
                              theme.palette.getContrastText(
                                theme.palette.background.default,
                              ),
                              0.2,
                            )}`,
                          "&:hover": {
                            backgroundColor: (theme) =>
                              alpha(
                                theme.palette.getContrastText(
                                  theme.palette.background.default,
                                ),
                                0.2,
                              ),
                          },
                        }}
                        onClick={() => {
                          handleOpen(subscription);
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ID: {subscription.id}
                          </Typography>
                          <Typography>
                            Plan ID: {subscription.planId}
                          </Typography>
                          <Typography>
                            Product Price: {subscription.productPrice}
                          </Typography>
                          <Typography>
                            Amount Paid: {subscription.amountPaid}
                          </Typography>
                          <Typography>
                            Payment Method: {subscription.paymentMethod}
                          </Typography>
                          <Typography>
                            Payment ID:{" "}
                            {subscription.paytmPaymentId ||
                              subscription.razorPayPaymentId ||
                              "N/A"}
                          </Typography>
                          <Typography>
                            Started on:{" "}
                            {format(
                              new Date(subscription.startedAt),
                              "dd-MMM-yyyy",
                            )}
                          </Typography>
                          <Typography>
                            Expires on:{" "}
                            {format(
                              new Date(subscription.expiresOn),
                              "dd-MMM-yyyy",
                            )}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteSubscription(subscription.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination
                      count={Math.ceil(
                        currentSubscriptions.length / itemsPerPage,
                      )}
                      page={page}
                      onChange={handlePageChange}
                      sx={{
                        backgroundColor: "transparent !important",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                display="flex"
                justifyContent="start"
                alignItems="center"
                xs={12}
              >
                <Table
                  sx={{
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                          borderBottom: 1,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Plan ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Product Price
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Amount Paid
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Payment Method
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Payment ID</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Started on</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Expires on</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentSubscriptions.map((subscription) => (
                      <TableRow
                        key={subscription.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: (theme) =>
                              alpha(
                                theme.palette.getContrastText(
                                  theme.palette.background.default,
                                ),
                                0.2,
                              ),
                          },
                          "& td, & th": {
                            border: 0,
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleOpen(subscription);
                        }}
                      >
                        <TableCell>{subscription.id}</TableCell>
                        <TableCell>{subscription.planId}</TableCell>
                        <TableCell>{subscription.productPrice}</TableCell>
                        <TableCell>{subscription.amountPaid}</TableCell>
                        <TableCell>{subscription.paymentMethod}</TableCell>
                        <TableCell>
                          {subscription.paytmPaymentId ||
                            subscription.razorPayPaymentId ||
                            "N/A"}
                        </TableCell>
                        <TableCell>
                          {format(
                            new Date(subscription.startedAt),
                            "dd-MMM-yyyy",
                          )}
                        </TableCell>
                        <TableCell>
                          {format(
                            new Date(subscription.expiresOn),
                            "dd-MMM-yyyy",
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteSubscription(subscription.id);
                            }}
                          >
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{
                        "& td, & th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan={9}>
                        <Box display="flex" justifyContent="flex-end">
                          <Pagination
                            count={Math.ceil(
                              sortedSubscriptions.length / itemsPerPage,
                            )}
                            page={page}
                            onChange={handlePageChange}
                            sx={{
                              backgroundColor: "transparent !important",
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )
          ) : (
            <Grid
              item
              display="flex"
              justifyContent="start"
              alignItems="center"
              xs={12}
            >
              <Typography>This is a free user</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      <SubscriptionModal
        handleClose={handleClose}
        fetchUser={fetchUser}
        open={open}
        subscription={editableSubscription}
        userId={userId}
      />
    </>
  );
};

export default UserSubscriptionsList;
