import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";
import { SubscriptionScheduleContext } from "../../Contexts/SubscriptionSchedule";
import { pricing, subscriptions } from "../../utils/routingConstants";

const ActiveScheduleRoute = ({ path, component, shouldBeActive }) => {
  const { activeSchedule } = useContext(SubscriptionScheduleContext);
  console.log(path, activeSchedule && shouldBeActive);
  return (
    <Route
      exact
      path={path}
      render={() =>
        (activeSchedule && shouldBeActive && component) ||
        (!activeSchedule && !shouldBeActive && component) ||
        (!activeSchedule && shouldBeActive && (
          <Redirect to={subscriptions} />
        )) ||
        (activeSchedule && !shouldBeActive && <Redirect to={pricing} />)
      }
    />
  );
};

export default ActiveScheduleRoute;
