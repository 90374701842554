import React from "react";
import SubscriptionScheduleForm from "../../../Contexts/SubscriptionSchedule/Form";
import useEditSubscriptionSchedule from "./useEditSubscriptionSchedule";

const EditSubscriptionSchedule = () => {
  const { scheduleData, submitData } = useEditSubscriptionSchedule();
  return (
    scheduleData && (
      <SubscriptionScheduleForm
        defaultValues={scheduleData}
        submitData={submitData}
      />
    )
  );
};

export default EditSubscriptionSchedule;
