import React from "react";
import { Controller } from "react-hook-form";
import {
  Checkbox,
  FormControlLabel,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Grid,
  ThemeProvider,
} from "@mui/material";
import useAddHomepage from "./useAddHomepage";
import Button from "../../../Components/Button/Button";

const AddHomepage = () => {
  const {
    mediumHeaderFontSize,
    history,
    themeProv,
    handleSubmit,
    onSubmit,
    control,
    availableTags,
    availableSectors,
    availableCategories,
  } = useAddHomepage();
  return (
    <Container maxWidth="md">
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mb={2}
      >
        <Grid item>
          <Typography fontSize={mediumHeaderFontSize} fontWeight={600}>
            Add Homepage Sectoins
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/admin-home-page")}
            text="Homepages"
          ></Button>
        </Grid>
      </Grid>
      <ThemeProvider theme={themeProv}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} display="flex" justifyContent="start">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name="allSectors"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value ?? false} {...field} />
                    )}
                  />
                }
                label="All Sectors Section"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name="allTags"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value ?? false} {...field} />
                    )}
                  />
                }
                label="All Tags Section"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name="allCategories"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value ?? false} {...field} />
                    )}
                  />
                }
                label="All Categories Section"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="tags"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="tags-label">Tags</InputLabel>
                      <Select
                        multiple
                        value={value || []}
                        onChange={onChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((tagId) => {
                              const tag = availableTags.find(
                                (tag) => tag.id === tagId,
                              );
                              return tag ? (
                                <Chip
                                  sx={{ marginX: "5px" }}
                                  key={tag.id}
                                  label={tag.name}
                                  variant="outlined"
                                />
                              ) : null;
                            })}
                          </div>
                        )}
                      >
                        {availableTags.map((tag) => (
                          <MenuItem key={tag.id} value={tag.id}>
                            {tag.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="sectors"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="sectors-label">Sectors</InputLabel>
                      <Select
                        multiple
                        value={value || []}
                        onChange={onChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((sectorId) => {
                              const sector = availableSectors.find(
                                (sector) => sector.id === sectorId,
                              );
                              return sector ? (
                                <Chip
                                  sx={{ marginX: "5px" }}
                                  key={sector.id}
                                  label={sector.name}
                                  variant="outlined"
                                />
                              ) : null;
                            })}
                          </div>
                        )}
                      >
                        {availableSectors.map((sector) => (
                          <MenuItem key={sector.id} value={sector.id}>
                            {sector.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="categories"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="categories-label">Categories</InputLabel>
                      <Select
                        multiple
                        value={value || []}
                        onChange={onChange}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((categoryId) => {
                              const category = availableCategories.find(
                                (category) => category.id === categoryId,
                              );
                              return category ? (
                                <Chip
                                  sx={{ marginX: "5px" }}
                                  key={category.id}
                                  label={category.name}
                                  variant="outlined"
                                />
                              ) : null;
                            })}
                          </div>
                        )}
                      >
                        {availableCategories.map((tag) => (
                          <MenuItem key={tag.id} value={tag.id}>
                            {tag.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Controller
                    name="isActive"
                    control={control}
                    render={({ field }) => (
                      <Checkbox checked={field.value ?? false} {...field} />
                    )}
                  />
                }
                label="Make this main homepage"
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
              <Button text="Create Sections" onClick={handleSubmit(onSubmit)}>
                Create Sections
              </Button>
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
    </Container>
  );
};

export default AddHomepage;
