import { Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import HeroImg from "../../Assets/hereo.png";
import {
  useGetCategoryDetailsQuery,
  useGetCompaniesByTypeQuery,
} from "../../redux/HomeAPI";
import "./style.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardList from "../../Components/CardList";

export default function CategoriesIndividual() {
  const { categoryId } = useParams();
  const {
    data: categoryData,
    isSuccess: isCategoryDataSuccess,
    isLoading: isCategoryDataLoading,
  } = useGetCategoryDetailsQuery(categoryId);
  const {
    data: sectorCompanies,
    isSuccess,
    isLoading,
  } = useGetCompaniesByTypeQuery({
    type: "category",
    typeId: categoryId,
  });
  const [companies, setCompanies] = useState([]);
  useEffect(
    () => sectorCompanies && setCompanies(sectorCompanies?.companyList),
    [sectorCompanies],
  );

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  return (
    <div>
      {isCategoryDataSuccess && !isCategoryDataLoading ? (
        <>
          <Box
            height="95vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
              <Grid container spacing={2} display="flex">
                <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
                  <Typography
                    fontWeight={600}
                    lineHeight={1.5}
                    fontSize={headerFontSize}
                    textTransform="capitalize"
                    pl={isMdDown ? 0 : 10}
                  >
                    {categoryData && categoryData.payload
                      ? categoryData.payload.name
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className="hero-img-container mt-section">
            <img src={HeroImg} alt="Hero" className="hero-img" />
          </div>

          <Box
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
              <Grid container spacing={2} display="flex">
                <Grid item lg={2} md={2} sm={3}>
                  <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                    Overview
                  </Typography>
                </Grid>
                <Grid item lg={8} md={10} sm={9}>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={300}
                    lineHeight={1.2}
                  >
                    {categoryData && categoryData.payload
                      ? categoryData.payload.description
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <Spin />
      )}
      <Box mb="20vh" display="flex" justifyContent="center">
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
                textTransform="capitalize"
                pl={isMdDown ? 0 : 10}
              >
                Suggested Companies
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CardList
        contentList={companies}
        cursorData="Read report"
        isCompany={true}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </div>
  );
}
