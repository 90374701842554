import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import Button from "../../../Components/Button/Button";
import useEditUserDetails from "./useEditUserDetails";

const EditUserDetailsModal = ({ open, handleClose, user }) => {
  const {
    themeProv,
    formMethods,
    onSubmit,
    isMdDown,
    countryCodes,
    selectFontSize,
  } = useEditUserDetails(user, handleClose);
  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>Fill Credentials:</DialogTitle>
        <DialogContent>
          <form
            className="form-wrapper"
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <Box className="d-flex input-wrapper">
              <Box className="input-line-animation__wrapper">
                <Controller
                  name="name"
                  control={formMethods.control}
                  defaultValue=""
                  rules={{
                    required: "Name is required",
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        placeholder="Your name"
                        disabled={user.name ? true : false}
                        {...field}
                      />
                      <div className="input-line-animation__line"></div>
                    </>
                  )}
                />
              </Box>
              {formMethods.formState.errors.name && (
                <span>{formMethods.formState.errors.name.message}</span>
              )}
            </Box>
            <Box className="d-flex input-wrapper">
              <Box className="input-line-animation__wrapper">
                <Controller
                  name="email"
                  control={formMethods.control}
                  defaultValue=""
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        className="input-form input-line-animation__ip"
                        type="text"
                        placeholder="Your email"
                        disabled={user.email ? true : false}
                        {...field}
                      />
                      <div className="input-line-animation__line"></div>
                    </>
                  )}
                />
              </Box>
              {formMethods.formState.errors.email && (
                <span>{formMethods.formState.errors.email.message}</span>
              )}
            </Box>
            <Box className="d-flex input-wrapper">
              <Box className="input-line-animation__wrapper">
                <Controller
                  name="password"
                  control={formMethods.control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        className="input-form input-line-animation__ip"
                        type="password"
                        disabled={user.password ? true : false}
                        placeholder="Your password"
                        {...field}
                      />
                      <Box className="input-line-animation__line"></Box>
                    </>
                  )}
                />
              </Box>
              {formMethods.formState.errors.password && (
                <span>{formMethods.formState.errors.password.message}</span>
              )}
            </Box>
            <Box className="d-flex input-wrapper">
              <Box className="input-line-animation__wrapper">
                <Controller
                  name="verifyPassword"
                  control={formMethods.control}
                  defaultValue=""
                  rules={{
                    required: "Password verification is required",
                    validate: (value) =>
                      value === formMethods.getValues("password") ||
                      "Passwords do not match",
                  }}
                  render={({ field }) => (
                    <>
                      <input
                        className="input-form input-line-animation__ip"
                        type="password"
                        disabled={user.password ? true : false}
                        placeholder="Verify password"
                        {...field}
                      />
                      <Box className="input-line-animation__line"></Box>
                    </>
                  )}
                />
              </Box>
              {formMethods.formState.errors.passwordVerification && (
                <span>
                  {formMethods.formState.errors.passwordVerification.message}
                </span>
              )}
            </Box>
            <Grid
              container
              display="flex"
              width="100%"
              columnSpacing={isMdDown ? undefined : 4}
              rowSpacing={isMdDown ? 6 : undefined}
            >
              <Grid item xs={12}>
                <Controller
                  name="countryCode"
                  control={formMethods.control}
                  defaultValue="91"
                  rules={{
                    required: "Country code is required",
                  }}
                  render={({ field }) => (
                    <>
                      <Select
                        sx={{ fontSize: selectFontSize }}
                        variant="standard"
                        className="input-form input-line-animation__ip"
                        placeholder="Select country code"
                        disabled={user.phone ? true : false}
                        {...field}
                      >
                        {countryCodes.map((code) => (
                          <MenuItem
                            key={code.value + code.name}
                            value={code.value}
                          >
                            {code.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="d-flex input-wrapper">
                  <Box className="input-line-animation__wrapper">
                    <Controller
                      name="phone"
                      control={formMethods.control}
                      defaultValue=""
                      rules={{
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message:
                            "Invalid phone number (only numbers are allowed)",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            className="input-form input-line-animation__ip"
                            type="text"
                            placeholder="Phone number"
                            disabled={user.phone ? true : false}
                            {...field}
                          />
                          <Box className="input-line-animation__line"></Box>
                        </>
                      )}
                    />
                  </Box>
                  {formMethods.formState.errors.phoneNumber && (
                    <span>
                      {formMethods.formState.errors.phoneNumber.message}
                    </span>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Done" onClick={formMethods.handleSubmit(onSubmit)} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditUserDetailsModal;
