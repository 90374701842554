import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useAddPlan = () => {
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      subscriptionDuration: 12,
      amount: "",
      description: "",
      featuresConclusion: "",
      name: "",
      taxPercentile: "",
      taxAmount: "",
      darkHorseStocks: false,
      fundalysis: false,
      darkHorseStocksUSA: false,
      PMS: false,
      isActive: true,
      features: [],
      apps: [],
    },
  });
  const selectedApps = watch("apps");

  const handleCheckboxChange = (appId, isChecked) => {
    const currentSelectedApps = selectedApps || [];
    if (isChecked) {
      setValue("apps", [...currentSelectedApps, appId]);
    } else {
      setValue(
        "apps",
        currentSelectedApps.filter((id) => id !== appId),
      );
    }
  };
  const token = localStorage.getItem("code");
  const [apps, setApps] = useState([]);
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}apps`)
      .then((res) => {
        setApps(res.data.map((app) => ({ label: app.name, value: app.id })));
      })
      .catch((e) => console.error("Error adding plan:", e));
  }, []);
  const addPlan = (data) => {
    axios
      .post(
        `${REACT_APP_BASE_URL}plans`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-list-plans");
      })
      .catch((e) => console.error("Error adding plan:", e));
  };

  const onSubmit = (data) => {
    addPlan({
      subscriptionDuration: data.subscriptionDuration,
      amount: Number(data.amount),
      description: data.description,
      name: data.name,
      taxPercentile: Number(data.taxPercentile),
      taxAmount: Number(data.taxAmount),
      darkHorseStocks: data.darkHorseStocks,
      fundalysis: data.fundalysis,
      darkHorseStocksUSA: data.darkHorseStocksUSA,
      PMS: data.PMS,
      isActive: data.isActive,
      featuresConclusion:
        data.featuresConclusion.length !== 0
          ? data.featuresConclusion
          : undefined,
      features: data.features,
      apps: data.apps,
    });
    reset({
      subscriptionDuration: 12,
      amount: "",
      description: "",
      name: "",
      taxPercentile: "",
      taxAmount: "",
      darkHorseStocks: false,
      fundalysis: false,
      darkHorseStocksUSA: false,
      PMS: false,
      isActive: true,
      featuresConclusion: "",
      features: [],
      apps: [],
    });
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
    apps,
    handleCheckboxChange,
    selectedApps,
  };
};
export default useAddPlan;
