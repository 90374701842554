import * as React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Button from "../../../Components/Button/Button";
import { useAppSelector } from "../../../store/hooks";

const BankDetailsModal = ({ open, onClose }) => {
  const handleClose = () => onClose();
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>Bank Details</DialogTitle>
        <DialogContent>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Mobile
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            +91-7874999975 (Googlepay)
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            UPI
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            incognitostocks@oksbi
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h5"
            sx={{ mt: 2 }}
          >
            Bank Account details
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Bank
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            THE AHMEDABAD DISTRICT CO-OPERATIVE BANK LTD
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            Account Type
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            Current Account
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            A/C holders Name
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            DARKHORSESTOCKS
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            A/c
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            107012154131
          </Typography>
          <Typography
            id="terms-and-conditions-dialog-description"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            IFSC
          </Typography>

          <Typography id="terms-and-conditions-dialog-description">
            GSCB0ADC001
          </Typography>
          <Typography mt={4} id="terms-and-conditions-dialog-description">
            Users are requested to send the screenshot of payment on Email and
            Whatsapp if they use above mentioned modes of payment.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Close" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default BankDetailsModal;
