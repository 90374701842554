import axios from "axios";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
const useReferralForm = (referral, userId, handleClose, fetchUser) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      userId: userId,
      code: referral?.code || "",
      referralType:
        (referral?.referralByEmail && "referralByEmail") ||
        (referral?.referralByPhoneNumber && "referralByPhoneNumber") ||
        (referral?.customReferral && "customReferral") ||
        "",
      discount: referral?.discount || 0,
    },
  });
  const token = localStorage.getItem("code");
  const generateRandomCode = () => {
    return `dhs_code_${Math.random().toString(36).substr(2, 8)}`;
  };
  const referralType = watch("referralType");
  useEffect(() => {
    if (
      referralType === "referralByEmail" ||
      referralType === "referralByPhoneNumber"
    ) {
      setValue("code", generateRandomCode());
    }
  }, [setValue, referralType]);
  const manageReferral = useCallback(
    (method, data) => {
      axios[method](
        `${process.env.REACT_APP_BASE_URL}referrals${
          method === "put" ? `/${referral.id}` : ""
        }`,
        data,
        {
          headers: {
            Authorization: token,
          },
        },
      )
        .then(() => {
          handleClose();
          fetchUser();
        })
        .catch((e) => {
          handleClose();
          alert(e.message);
        });
    },
    [handleClose, referral, token, fetchUser],
  );
  const onSubmit = useCallback(
    (data) => {
      const finalData = {
        ...data,
        discount: Number(data.discount),
        referralByEmail: data.referralType === "referralByEmail",
        referralByPhoneNumber: data.referralType === "referralByPhoneNumber",
        customReferral: data.referralType === "customReferral",
      };
      delete finalData.referralType;

      if (!referral) {
        manageReferral("post", finalData);
      } else {
        manageReferral("put", finalData);
      }
    },
    [manageReferral, referral],
  );

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    watch,
    errors,
  };
};
export default useReferralForm;
