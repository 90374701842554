import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useAddInsertedUser = () => {
  const token = localStorage.getItem("code");
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}plans`)
      .then((res) => {
        res.data.success &&
          res.data.payload !== null &&
          setPlans(
            res.data.payload.map((plan) => ({
              value: plan.id,
              label: `name: ${plan.name}/ amount: ${plan.amount}`,
            })),
          );
      })
      .catch((e) => console.error("Error fetching plans:", e));
  }, []);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      phone: "",
      date: dayjs(),
      planId: "",
    },
  });
  useEffect(
    () =>
      reset({
        email: "",
        phone: "",
        date: dayjs(),
        planId: "",
      }),
    [reset],
  );

  const addInsertedUser = (data) => {
    axios
      .post(
        `${REACT_APP_BASE_URL}user/old-users/`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-inserted-users");
      })
      .catch((e) => console.error("Error fetching inserted users:", e));
  };

  const onSubmit = (data) => {
    addInsertedUser({
      ...data,
      planId: Number(data.planId),
      date: new Date(data.date),
    });
    reset(data);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
    plans,
  };
};
export default useAddInsertedUser;
