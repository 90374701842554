import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAppSelector } from "../../../store/hooks";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useInsertedUsersList = () => {
  const [insertedUsers, setInsertedUsers] = useState([]);
  const [insertedUsersTable, setInsertedUsersTable] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem("code");

  const fetchInsertedUsers = useCallback(() => {
    try {
      axios
        .get(
          `${REACT_APP_BASE_URL}user/old-users/all?page=${currentPage}&searchKeyword=${searchKeyword}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          setInsertedUsers(res.data.users);
          setTotalPages(res.data.pageInfo.totalPages);
        })
        .catch((e) => console.error("Error fetching companies:", e));
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }, [currentPage, searchKeyword, token]);
  useEffect(() => fetchInsertedUsers(), [fetchInsertedUsers]);
  useEffect(() => {
    if (insertedUsers && insertedUsers.length) {
      setInsertedUsersTable(
        insertedUsers.map((insertedUser) => ({
          id: insertedUser.id,
          email: insertedUser.email ?? "N/A",
          phone: insertedUser.phone ?? "N/A",
          date: insertedUser.date ?? "N/A",
          planId: insertedUser.planId ?? "N/A",
        })),
      );
    } else setInsertedUsersTable([]);
  }, [insertedUsers]);
  const history = useHistory();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const lockBackgroundColor = darkMode ? "#fff" : "#000";
  const deleteInsertedUser = useCallback(
    (id) => {
      try {
        axios
          .delete(`${REACT_APP_BASE_URL}user/old-users/${id}`, {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            fetchInsertedUsers();
          })
          .catch((e) => console.error("Error fetching companies:", e));
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    [fetchInsertedUsers, token],
  );
  const syncInsertedUsersSubs = useCallback(() => {
    try {
      axios
        .post(`${REACT_APP_BASE_URL}user/old-users/sync`, null, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          fetchInsertedUsers();
        })
        .catch((e) => console.error("Error fetching companies:", e));
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }, [fetchInsertedUsers, token]);
  return {
    insertedUsersTable,
    isMdDown,
    history,
    handleSearchChange,
    handleSearchSubmit,
    handlePreviousPage,
    handleNextPage,
    searchKeyword,
    currentPage,
    lockBackgroundColor,
    theme,
    totalPages,
    deleteInsertedUser,
    syncInsertedUsersSubs,
  };
};
export default useInsertedUsersList;
