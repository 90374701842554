import { useCallback } from "react";

const useHomepageParser = () => {
  const parseHomepageToForm = useCallback(
    (homepage) => ({
      allSectors: homepage?.homepageSections?.length
        ? homepage.homepageSections.find((sec) => sec.allSectors) !== undefined
        : false,
      allCategories: homepage?.homepageSections?.length
        ? homepage.homepageSections.find((sec) => sec.allCategories) !==
          undefined
        : false,
      allTags: homepage?.homepageSections?.length
        ? homepage.homepageSections.find((sec) => sec.allTags) !== undefined
        : false,
      tags: homepage?.homepageSections?.length
        ? homepage.homepageSections
            .filter((section) => section.tagId)
            .map((section) => section.tagId)
        : [],
      sectors: homepage?.homepageSections?.length
        ? homepage.homepageSections
            .filter((section) => section.sectorId)
            .map((section) => section.sectorId)
        : [],
      categories: homepage?.homepageSections?.length
        ? homepage.homepageSections
            .filter((section) => section.categoryId)
            .map((section) => section.categoryId)
        : [],
      isActive: homepage?.isActive ?? false,
    }),
    [],
  );
  return { parseHomepageToForm };
};
export default useHomepageParser;
