import { FormControl, Grid, ThemeProvider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";
import Button from "../../../Components/Button/Button";
import useSubscriptionScheduleForm from "./useSubscriptionScheduleForm";

const SubscriptionScheduleForm = ({ defaultValues, submitData }) => {
  const { formMethods, onSubmit, themeProv } = useSubscriptionScheduleForm(
    defaultValues,
    submitData,
  );
  return (
    <ThemeProvider theme={themeProv}>
      <form onSubmit={onSubmit}>
        <Grid
          mt={2}
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={8}>
            <FormControl
              fullWidth
              variant="filled"
              error={Boolean(formMethods.formState.errors.startDate)}
            >
              <Controller
                name="startDate"
                control={formMethods.control}
                rules={{
                  required: "Start Date is required",
                  validate: (value) => {
                    if (
                      new Date(value) >=
                      new Date(formMethods.getValues("endDate"))
                    ) {
                      return "Start Date should be less than End Date";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <DatePicker {...field} fullWidth label="Start Date" />
                )}
              />
              {formMethods.formState.errors.startDate && (
                <p
                  style={{
                    color: "#ff0000",
                  }}
                >
                  {formMethods.formState.errors.startDate.message}
                </p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl
              fullWidth
              variant="filled"
              error={Boolean(formMethods.formState.errors.endDate)}
            >
              <Controller
                name="endDate"
                control={formMethods.control}
                rules={{
                  required: "End Date is required",
                  validate: (value) => {
                    if (
                      new Date(value) <=
                      new Date(formMethods.getValues("startDate"))
                    ) {
                      return "End Date should be greater than Start Date";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <DatePicker {...field} fullWidth label="End Date" />
                )}
              />
              {/* display error */}
              {formMethods.formState.errors.endDate && (
                <p
                  style={{
                    color: "#ff0000",
                  }}
                >
                  {formMethods.formState.errors.endDate.message}
                </p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Button onClick={() => formMethods.handleSubmit(onSubmit)()}>
              {defaultValues ? "Edit" : "Create"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </ThemeProvider>
  );
};

export default SubscriptionScheduleForm;
