import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Button from "../../../Components/Button/Button";
import { useAppSelector } from "../../../store/hooks";

import { Email, Telegram, WhatsApp } from "@mui/icons-material";
import CopyCard from "./CopyCard";
import GooglePaySVG from "./GooglePaySVG";
import PaytmUPISVG from "./PaytmUPISVG";
import PhonePaySVG from "./PhonePaySVG";

const UPIModal = ({ open, onClose }) => {
  const handleClose = () => onClose();
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const theme = useTheme();

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold" }}>
            UPI ID
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              p: isSmDown ? 0 : 3,
              borderRadius: 2,
              marginX: isMdDown ? 0 : 2,
            }}
          >
            <Stack spacing={2}>
              {/* UPI ID */}
              <CopyCard isSmDown={isSmDown} copyValue={"darkhorsestocks@upi"}>
                <PaytmUPISVG />
                <Typography
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  ml={1}
                  maxWidth={"180px"}
                >
                  darkhorsestocks@upi
                </Typography>
              </CopyCard>

              {/* Phone Numbers */}
              <CopyCard isSmDown={isSmDown} copyValue={"7874999975"}>
                <GooglePaySVG />{" "}
                <Typography
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  ml={1}
                  maxWidth={"180px"}
                >
                  7874999975
                </Typography>
              </CopyCard>

              <CopyCard isSmDown={isSmDown} copyValue={"7874999975"}>
                <PhonePaySVG />{" "}
                <Typography
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  ml={1}
                  maxWidth={"180px"}
                >
                  7874999975
                </Typography>
              </CopyCard>
            </Stack>

            {/* Instructions */}
            <Typography variant="body2" sx={{ mt: 3, mb: 2 }}>
              Once you make the payment, please send a screenshot of the payment
              confirmation along with your email address and mobile number via
              WhatsApp/Telegram to <strong>+91-7874999980</strong> and email it
              to <strong>support@darkhorsestocks.in</strong>.
            </Typography>
            <Typography variant="body2">
              Your account will be activated within a maximum of 24 hours once
              we receive your confirmation.
            </Typography>

            {/* Contact Icons */}
            <Grid mt={3} display={"flex"} container spacing={2}>
              <Grid
                item
                display={"flex"}
                justifyContent={isSmDown ? "center" : "start"}
                xs={isSmDown ? 12 : undefined}
              >
                <Button>
                  <a
                    style={{ color: "inherit", textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_WHATSAPP_LINK}
                  >
                    <WhatsApp fontSize="large" />
                  </a>
                </Button>
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={isSmDown ? "center" : "start"}
                xs={isSmDown ? 12 : undefined}
              >
                <Button>
                  <a
                    style={{ color: "inherit", textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_TELEGRAM_LINK}
                  >
                    <Telegram fontSize="large" />
                  </a>
                </Button>
              </Grid>
              <Grid
                item
                display={"flex"}
                justifyContent={isSmDown ? "center" : "start"}
                xs={isSmDown ? 12 : undefined}
              >
                <Button>
                  <a
                    style={{ color: "inherit", textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_EMAIL_LINK}
                  >
                    <Email fontSize="large" />
                  </a>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Close" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default UPIModal;
