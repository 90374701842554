import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const useUserForm = (user) => {
  const token = localStorage.getItem("code");
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { notes: user.notes || "" },
  });
  useEffect(() => {
    reset({ notes: user.notes || "" });
  }, [reset, user]);
  const onSubmit = (data) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}user/notes/${user.id}`, data, {
      headers: {
        Authorization: token,
      },
    });
  };
  return { control, onSubmit: handleSubmit(onSubmit) };
};

export default useUserForm;
