import React, { useCallback, useState } from "react";
import "./style.scss";
import { useTheme, useMediaQuery, Grid, Typography } from "@mui/material";
import ContactModal from "./ContactModal";

export default function Footer() {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleOpenModal = useCallback(() => setOpen(true), []);
  const handleCloseModal = useCallback(() => setOpen(false), []);
  return (
    <Grid
      container
      display="flex"
      spacing={2}
      className="footer-list"
      flexDirection={isMdDown ? "column" : "row"}
      paddingRight={4}
      mt={isMdDown ? 2 : undefined}
    >
      <ContactModal open={open} onClose={handleCloseModal} />
      <Grid item zIndex={5}>
        <a
          href="https://darkhorsestocks.in/Main.php"
          target="_blank"
          rel="noopener noreferrer"
          className="line-animation-3"
          style={{ color: "#838282" }}
        >
          DARKHORSESTOCKS 2023
        </a>
      </Grid>
      <Grid item zIndex={5}>
        <a
          href="https://darkhorsestocks.com/archives/10704"
          target="_blank"
          rel="noopener noreferrer"
          className="line-animation-3"
          style={{ color: "#838282" }}
        >
          PRIVACY POLICY
        </a>
      </Grid>
      <Grid item zIndex={5}>
        <a
          href="https://darkhorsestocks.com/archives/10707"
          target="_blank"
          rel="noopener noreferrer"
          className="line-animation-3"
          style={{ color: "#838282" }}
        >
          T&C
        </a>
      </Grid>
      <Grid item zIndex={5}>
        <a
          href="https://darkhorsestocks.com/archives/10708"
          target="_blank"
          rel="noopener noreferrer"
          className="line-animation-3"
          style={{ color: "#838282" }}
        >
          REFUND POLICY
        </a>
      </Grid>
      <Grid item zIndex={5}>
        <a
          href="https://darkhorsestocks.com/archives/10709"
          target="_blank"
          rel="noopener noreferrer"
          className="line-animation-3"
          style={{ color: "#838282" }}
        >
          DISCLAIMER
        </a>
      </Grid>
      <Grid item zIndex={5}>
        <Typography
          onClick={handleOpenModal}
          className="line-animation-3"
          sx={{ color: "#838282", cursor: "pointer" }}
        >
          CONTACT
        </Typography>
      </Grid>
      <Grid item zIndex={5}>
        <a
          href="https://www.darkhorsestocks.in/mutual-fund/about-us"
          target="_blank"
          rel="noopener noreferrer"
          className="line-animation-3"
          style={{ color: "#838282" }}
        >
          ABOUT US
        </a>
      </Grid>
    </Grid>
  );
}
