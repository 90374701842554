import { createTheme, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const usePricingCard = () => {
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (!darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const mediumHeaderFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "25px" : "40px") : "45px"),
    [isMdDown, isSmDown],
  );
  const bulletPointsFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "16px" : "18px") : "24px"),
    [isMdDown, isSmDown],
  );
  const history = useHistory();
  return {
    themeProv,
    mediumHeaderFontSize,
    paragraphFontSize,
    history,
    isMdDown,
    bulletPointsFontSize,
    isLgDown,
  };
};
export default usePricingCard;
