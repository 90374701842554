import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import ReferralForm from "./ReferralForm";

const ReferralModal = ({ referral, open, handleClose, userId, fetchUser }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
      aria-labelledby="terms-and-conditions-dialog"
      aria-describedby="terms-and-conditions-dialog-description"
    >
      <DialogTitle>{referral ? "Edit" : "Create"} Referral:</DialogTitle>
      <DialogContent>
        <ReferralForm
          referral={referral}
          handleClose={handleClose}
          userId={userId}
          fetchUser={fetchUser}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ReferralModal;
