import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useEditPlan = () => {
  const { id } = useParams();
  const history = useHistory();
  const [plan, setPlan] = useState();
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}plans/${id}`)
      .then((res) => {
        setPlan(res.data.payload);
      })
      .catch((e) => console.error("Error fetching companies:", e));
  }, [id]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      subscriptionDuration: plan?.subscriptionDuration ?? 12,
      amount: plan?.amount ?? "",
      description: plan?.description ?? "",
      name: plan?.name ?? "",
      taxPercentile: plan?.taxPercentile ?? "",
      taxAmount: plan?.taxAmount ?? "",
      darkHorseStocks: plan?.darkHorseStocks ?? false,
      fundalysis: plan?.fundalysis ?? false,
      darkHorseStocksUSA: plan?.darkHorseStocksUSA ?? false,
      PMS: plan?.PMS ?? false,
      isActive: plan?.isActive ?? true,
      featuresConclusion: plan?.featuresConclusion ?? "",
      features: plan?.features
        ? plan?.features.map((feat) => ({ description: feat.description }))
        : [],
      apps: plan?.apps ?? [],
    },
  });

  const selectedApps = watch("apps");

  const handleCheckboxChange = (appId, isChecked) => {
    const currentSelectedApps = selectedApps || [];
    if (isChecked) {
      setValue("apps", [...currentSelectedApps, appId]);
    } else {
      setValue(
        "apps",
        currentSelectedApps.filter((id) => id !== appId),
      );
    }
  };

  const [apps, setApps] = useState([]);
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}apps`)
      .then((res) => {
        setApps(res.data.map((app) => ({ label: app.name, value: app.id })));
      })
      .catch((e) => console.error("Error adding plan:", e));
  }, []);
  useEffect(
    () =>
      reset({
        subscriptionDuration: plan?.subscriptionDuration ?? 12,
        amount: plan?.amount ?? "",
        description: plan?.description ?? "",
        name: plan?.name ?? "",
        taxPercentile: plan?.taxPercentile ?? "",
        taxAmount: plan?.taxAmount ?? "",
        darkHorseStocks: plan?.darkHorseStocks ?? false,
        fundalysis: plan?.fundalysis ?? false,
        darkHorseStocksUSA: plan?.darkHorseStocksUSA ?? false,
        PMS: plan?.PMS ?? false,
        isActive: plan?.isActive ?? true,
        featuresConclusion: plan?.featuresConclusion ?? "",
        features: plan?.features
          ? plan?.features.map((feat) => ({ description: feat.description }))
          : [],
        apps: plan?.apps ?? [],
      }),
    [reset, plan],
  );

  const token = localStorage.getItem("code");

  const editPlan = (data) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}plans/${id}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        reset({
          subscriptionDuration: plan?.subscriptionDuration ?? 12,
          amount: plan?.amount ?? "",
          description: plan?.description ?? "",
          name: plan?.name ?? "",
          taxPercentile: plan?.taxPercentile ?? "",
          taxAmount: plan?.taxAmount ?? "",
          darkHorseStocks: plan?.darkHorseStocks ?? false,
          fundalysis: plan?.fundalysis ?? false,
          darkHorseStocksUSA: plan?.darkHorseStocksUSA ?? false,
          PMS: plan?.PMS ?? false,
          isActive: plan?.isActive ?? true,
          featuresConclusion: plan?.featuresConclusion ?? "",
          features: plan?.features
            ? plan?.features.map((feat) => ({ description: feat.description }))
            : [],
          apps: plan?.apps ?? [],
        });
        history.push("/admin-list-plans");
      })
      .catch((e) => console.error("Error editing plan:", e));
  };

  const onSubmit = (data) => {
    editPlan({
      subscriptionDuration: data.subscriptionDuration,
      amount: Number(data.amount),
      description: data.description,
      name: data.name,
      taxPercentile: Number(data.taxPercentile),
      taxAmount: Number(data.taxAmount),
      darkHorseStocks: data.darkHorseStocks,
      fundalysis: data.fundalysis,
      darkHorseStocksUSA: data.darkHorseStocksUSA,
      PMS: data.PMS,
      isActive: data.isActive,
      features: data.features,
      featuresConclusion: data.featuresConclusion,
      apps: data.apps,
    });
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
    apps,
    handleCheckboxChange,
    selectedApps,
  };
};
export default useEditPlan;
