import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";

const AccountsList = ({ accounts }) => {
  const [currAccount, setCurrAccount] = useState(accounts[0]);
  const selectAccount = useCallback(
    (e) => {
      setCurrAccount(accounts.find((acc) => acc.id === e.target.value));
    },
    [accounts],
  );
  const accountList = useMemo(
    () => accounts.map(({ id, provider }) => ({ id, provider })),
    [accounts],
  );
  return (
    <Grid container spacing={2}>
      {accountList && (
        <Grid item xs={12}>
          <FormControl variant="standard">
            <InputLabel id="account-select-label">Account</InputLabel>
            <Select
              labelId="account-select-label"
              id="account-select"
              value={currAccount.id}
              label="Account"
              onChange={selectAccount}
            >
              {accountList.map((acc) => (
                <MenuItem key={acc.id} value={acc.id}>
                  {acc.provider}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {currAccount && (
        <Grid
          item
          display="flex"
          justifyContent="start"
          alignItems="center"
          xs={12}
        >
          <Grid
            container
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Typography fontWeight="bold" paddingX={0.5}>
                Account ID:
              </Typography>
              <Typography paddingX={0.5}>{currAccount.id}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Typography fontWeight="bold" paddingX={0.5}>
                Provider:
              </Typography>
              <Typography paddingX={0.5}>{currAccount.provider}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Typography fontWeight="bold" paddingX={0.5}>
                Provider ID:
              </Typography>
              <Typography paddingX={0.5}>{currAccount.providerId}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Typography paddingX={0.5} fontWeight="bold">
                This account was created on:{" "}
              </Typography>
              <Typography paddingX={0.5}>
                {format(new Date(currAccount.createdAt), "dd-MMM-yyyy")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Typography paddingX={0.5} fontWeight="bold">
                This account was updated on:{" "}
              </Typography>
              <Typography paddingX={0.5}>
                {format(new Date(currAccount.updatedAt), "dd-MMM-yyyy")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AccountsList;
