import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import AnimatingNumber from "../../../../Components/AnimatingNumber/animation-number";
import Button from "../../../../Components/Button/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LatestIdeaCardDummy = ({
  unauthorizedIdeaBySubscription,
  isAuthenticated,
  mediumHeaderFontSize,
  smallParagraphFontSize,
  dateOptions,
  paragraphFontSize,
}) => {
  const history = useHistory();
  const overlayRef = useRef(null);
  useEffect(() => {
    const overlay = document.createElement("div");
    overlay.className = "blurry-overlay";

    if (overlayRef.current) {
      overlayRef.current.appendChild(overlay);
    }
    const overlayToRemove = overlay;
    return () => {
      if (overlayToRemove.current) {
        overlayToRemove.current.removeChild(overlay);
      }
    };
  }, []);
  return (
    <>
      <Grid
        zIndex={10}
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
      >
        <Grid
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <Typography>
            {!isAuthenticated
              ? "Login to discover our content"
              : "Subscribe to view content"}
          </Typography>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {!isAuthenticated ? (
            <Button text="Login" onClick={() => history.push("/login")} />
          ) : (
            <Button
              text="Subscribe"
              onClick={() => history.push("/subscriptions")}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        ref={overlayRef}
        sx={{
          pointerEvents: "none",
        }}
        container
        display="flex"
        flexDirection="column"
        spacing={2}
      >
        <Grid item>
          <Typography
            fontWeight={600}
            lineHeight={1}
            fontSize={mediumHeaderFontSize}
            textTransform="capitalize"
          >
            Article Name
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            display="flex"
            justifyContent="start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography
                fontSize={smallParagraphFontSize}
                color="green"
                display="flex"
              >
                ₹###
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={smallParagraphFontSize}>+INR</Typography>
            </Grid>
            <Grid item display="flex" fontSize={smallParagraphFontSize}>
              ₹
              <AnimatingNumber value="###" />
            </Grid>
            <Grid item display="flex" fontSize={smallParagraphFontSize}>
              (₹
              <AnimatingNumber value="###" />
              %)
            </Grid>
            <Grid item display="flex">
              <Typography
                fontSize={smallParagraphFontSize}
                lineHeight={1}
                textTransform="capitalize"
              >
                {new Date().toLocaleDateString("en-US", dateOptions)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            fontWeight={300}
            color="GrayText"
            lineHeight={1.2}
            textTransform="capitalize"
            fontSize={paragraphFontSize}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quod,
            vel fugit voluptas ut dolorem eligendi vitae in, soluta rerum, sunt
            odio dolor ullam dolorum dolore quasi rem quaerat reprehenderit!
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontWeight={600}
            lineHeight={1}
            textTransform="capitalize"
          >
            published on {new Date().toLocaleDateString("en-US", dateOptions)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default LatestIdeaCardDummy;
