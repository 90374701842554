import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppSelector } from "../../store/hooks";

export default function LinearProgressBar() {
  const [progress, setProgress] = React.useState(0);
  const darkMode = useAppSelector((store) => store.theme.dark);
  const progressBarColor = darkMode ? "#fff" : "#000";

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
      }}
    >
      <LinearProgress
        color="inherit"
        sx={{
          height: "1vh",
          color: progressBarColor,
        }}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
}
