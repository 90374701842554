import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const useEditInsertedUser = () => {
  const { id } = useParams();
  const [oldUser, setOldUser] = useState();
  const [plans, setPlans] = useState([]);
  const token = localStorage.getItem("code");
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}plans`)
      .then((res) => {
        res.data.success &&
          res.data.payload !== null &&
          setPlans(
            res.data.payload.map((plan) => ({
              value: plan.id,
              label: `name: ${plan.name}/ amount: ${plan.amount}`,
            })),
          );
      })
      .catch((e) => console.error("Error fetching plans:", e));
  }, []);
  useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_URL}user/old-users/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setOldUser(res.data);
      })
      .catch((e) => console.error("Error fetching inserted user:", e));
  }, [id, token]);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: oldUser?.email ?? "",
      phone: oldUser?.phone ?? "",
      date: dayjs(oldUser?.date) ?? dayjs(),
      planId:
        oldUser?.planId && oldUser.planId.length !== 0
          ? `${oldUser?.planId}`
          : "",
    },
  });
  useEffect(
    () =>
      reset({
        email: oldUser?.email ?? "",
        phone: oldUser?.phone ?? "",
        date: dayjs(oldUser?.date) ?? dayjs(),
        planId:
          oldUser?.planId && oldUser.planId.length !== 0
            ? `${oldUser?.planId}`
            : "",
      }),
    [reset, oldUser],
  );

  const editInsertedUser = (data) => {
    axios
      .put(
        `${REACT_APP_BASE_URL}user/old-users/${id}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        history.push("/admin-inserted-users");
      })
      .catch((e) => console.error("Error fetching inserted users:", e));
  };

  const onSubmit = (data) => {
    editInsertedUser({
      ...data,
      planId: Number(data.planId),
      date: new Date(data.date),
    });
    reset(data);
  };
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    themeProv,
    plans,
  };
};
export default useEditInsertedUser;
