import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

const useGstForm = ({ defaultValues, fetchGstCredentials, handleClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });
  const updateGstData = useCallback(
    (data) => {
      const token = localStorage.getItem("code");
      axios
        .put(`${process.env.REACT_APP_BASE_URL}user/gst-credentials`, data, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          fetchGstCredentials();
          handleClose();
        });
    },
    [fetchGstCredentials, handleClose],
  );

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const selectFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "15px" : "22px") : "40px"),
    [isMdDown, isSmDown],
  );
  const onSubmit = (data) => {
    const gstPhoneNumber = `+${data.countryCode}-${data.phoneNumber}`;
    delete data.countryCode;
    delete data.phoneNumber;
    updateGstData({ ...data, gstPhoneNumber });
  };
  return {
    control,
    handleSubmit,
    errors,
    selectFontSize,
    onSubmit,
    isMdDown,
  };
};

export default useGstForm;
